<template>
  <div class='points'>
    <div class="left">
      <div class="title">{{ item.memo }}</div>
      <div class="time">{{ item.createtime | toBlockTime }}</div>
    </div>
    <div class="right">
      {{ item.score > 0 ? '+' : '-' }}<span>￥{{ item.score }}</span> 
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.points {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D8D8D8;
  .left {
    .title {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
    }
    .time {
      font-size: 14px;
      color: #999999;   
    }
  }
  .right {
    font-weight: 500;
    font-size: 16px;
    color: #EA2627;
    span {
      font-size: 18px;
    }
  }
}
</style>