<template>
  <div class='message-exchange-contactinfo'>
    <div class="title">{{ title }}</div>
    <div class="sub-title">您是否同意</div>
    <div class="btns" v-if="needBtn && result">
      <div class="refuse disabled">{{ result.status == 2 ? '已拒绝' : '拒绝'}}</div>
      <div class="agree disabled">{{ result.status == 1 ? '已同意' : '同意' }}</div>
    </div>
    <div class="btns" v-if="needBtn && !result">
      <div class="refuse" @click="btnClick(2)">拒绝</div>
      <div class="agree" @click="btnClick(1)">同意</div>
    </div>
  </div>
</template>

<script>
import { imMessage } from '@/api/jobSeeker'

export default {
  props: {
    title: {
      type: String
    },
    needBtn: {
      type: Boolean,
      default: true
    },
    type: {
      type: String
    },
    message: { //
      type: Object
    }
  },
  // inject: ['$_home'],
  data() {
    return {
      result: {}
    }
  },
  methods: {
    btnClick(status) {
      this.$emit('btnClick', this.type, status, this.message)
    },
    getStatus(id) {
      let params = {
        handle: "find",
        msg_id: id
      }
      imMessage(params).then(res=>{
        if(res.code == 1) {
          this.result = res.data
        }
      })
    },
    updateStatus(id){
      this.getStatus(id)
    }
  },
  created() {
    if(this.message) {
      this.getStatus(this.message.ID)
    }
  },
}
</script>

<style lang="less" scoped>
.message-exchange-contactinfo{
  width: 233px;
  // height: 128px;
  padding: 17px;
  background: #F7FAFF;
  border-radius: 10px 10px 10px 10px;
  border: 0px solid #E8E8E8;

  .title {
    font-weight: 500;
    font-size: 15px;
    color: #222222;
    margin-bottom: 10px;
  }
  .sub-title {
    font-size: 12px;
    color: #222222;
  }

  .btns {
    margin-top: 13px;
    display: flex;
    align-items: center;

    .refuse{
      width: 93px;
      height: 33px;
      margin-right: 20px;
      line-height: 33px;
      font-weight: 500;
      font-size: 12px;
      color: #585858;
      text-align: center;
      background: #FFFFFF;
      border-radius: 7px 7px 7px 7px;
      cursor: pointer;
    }
    .agree{
      width: 93px;
      height: 33px;
      line-height: 33px;
      font-weight: 500;
      font-size: 12px;
      color: #FFFFFF;
      text-align: center;
      background: #2676F7;
      border-radius: 7px 7px 7px 7px;
      cursor: pointer;
    }
    .disabled {
      cursor: text;
      opacity: 0.5;
    }
  }
}

</style>