<template>
  <div class='check-result'>
    <el-dialog class="dialog" 
      :visible.sync="showDialog" 
      :close-on-click-modal="false" 
      :close-on-press-escape="false"
      @closed="closed" 
      title="提示">
      <div class="content">
        您的企业还未通过审核，点击查看详情
      </div>
      <div class="bottom">
        <span class="confirm" @click="confirm()">查看</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDialog: true,
    }
  },
  methods: {
    closed() {
      this.$emit('closed')
    },
    confirm() {
      this.$emit('check')
    }
  }
}
</script>

<style lang="less" scoped>
.check-result {
  .dialog {
    .content {
      display: flex;
      font-size: 17px;
      color: #585858;
      padding-bottom: 50px;
    }

    .bottom {
      margin-top: 30px;
      margin-right: 30px;
      display: flex;
      justify-content: right;

      span {
        display: inline-block;
        height: 27px;
        line-height: 27px;
        padding: 0 17px;
        text-align: center;
        border-radius: 7px 7px 7px 7px;
        cursor: pointer;
      }

      .clear {
        margin-right: 10px;
        background: rgba(38, 118, 247, 0.08);
        color: #2676F7;
      }

      .confirm {
        background: #2676F7;
        color: white;
      }
    }
  }

  /deep/.el-dialog {
    width: 520px;
    border-radius: 7px;
  }

  /deep/ .el-dialog__title {
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }

  /deep/.el-dialog__body {
    padding: 20px;
  }
  /deep/.el-dialog__headerbtn {
    display: none;
  }
}
</style>