<template>
  <div class='job-manage'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        职位管理
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="interview-record-tab">
          <div class="record-tab-item" :class="{'active-tab': jobMangeStatusActiveIndex == item.status}" v-for="(item, index) in jobManageStatusTab" :key="index" @click="handleStatusItem(item)">{{ item.title }}</div>
        </div>
        <div class="view">
          <jobManageItem :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in jobsDataList" :key="index" :item="item" @btnClick="btnClick"></jobManageItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jobManageItem from '@/components/jobManageItem.vue'
import { positionList, setPositionStatus } from '@/api/company'

export default {
  components: {
    jobManageItem,
  },
  data() {
    return {
      jobMangeStatusActiveIndex: 2,
      jobManageStatusTab: [
        {
          title: '开放中',
          // index: 1,
          status: 2,
        },{
          title: '审核成功',
          // index: 2,
          status: 1,
        },{
          title: '待审核',
          // index: 3,
          status: 0,
        },{
          title: '审核失败',
          // index: 4,
          status: 3,
        },{
          title: '关闭中',
          // index: 5, 
          status: -1,
        },
      ],
      jobsDataList: [],
      params: {
        status: 2,
        page: 1,
        limit: 100,
      }
    }
  },
  methods: { 
    back() {
      this.$router.back()
    },
    handleStatusItem(item){
      this.jobMangeStatusActiveIndex = item.status
      this.params.status = item.status
      this.getList()
      // this.jobsDataList.map(obj=>{
      //   obj.status = item.status
      // })
    },
    // 职位底部按钮点击事件回调
    btnClick(item, status) {
      if(status == 100) {
        this.goJobDetail(item)
        return
      }
      let params = {
        id: item.id,
        status: status
      }
      setPositionStatus(params).then(res=>{
        if(res.code == 1) {
          this.getList()
        }
      })
    },
    getList() {
      positionList(this.params).then(res=>{
        if(res.code == 1) {
          this.jobsDataList = res.data
        }
      })
    },
    goJobDetail(item) {
      this.$router.push({path: 'publishjob', query: {id: item.id}})
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.job-manage {
  .interview-record-tab{
    width: 100%;
    height: 92px;
    background: #FFFFFF;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 58px;
    margin-bottom: 20px;

    .record-tab-item{
      width: 167px;
      height: 45px;
      line-height: 45px;
      font-size: 20px;
      color: #585858;
      text-align: center;
      background: #F7F7F7;
      border-radius: 7px;
      cursor: pointer;
    }

    .active-tab{
      background: rgba(38,118,247,0.08);
      border-radius: 7px 7px 7px 7px;
      color: #2676F7;
    }

  }

  .view{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .mr0 {
    margin-right: 0;
  }
}
</style>