<template>
  <div class='lesson-detail'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        课程详情
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="content">
          <video class="video" ref="video" controls :src="videoUrl" @play="onPlay" @timeupdate="videoTimeUpdate" @ended="videoEnd"></video>
          <div class="tip" v-if="Number(detailInfo.price)>0 && !detailInfo.order_info.id">该视频为付费内容，仅支持试看五分钟，请点击下方“立即购买”按钮获取全部内容</div>
          <div class="title">课程介绍</div>
          <div class="sub-title">{{ detailInfo.title }}</div>
          <div class="cont">{{ detailInfo.introduce }}</div>
          <img v-for="(item, index) in imagesUrl" :src="item" :key="index" alt="">
        </div>
      </div>
    </div>
    <div class="pay-div" v-if="Number(detailInfo.price)>0 && !detailInfo.order_info.id">
      <div class="pay-cont">
        <div class="mr40">￥ <span class="amount">{{detailInfo.price}}</span></div>
        <div class="pay-btn" @click="payNow()">立即购买</div>
      </div>
    </div>

    <el-dialog class="dialog" :visible.sync="showDialog" :title="'付费课程'">
      <div class="tip1">您的试听已结束，</div> 
      <div class="tip2">可付费之后再进行观看！</div>
      <div class="oper" @click="payNow()">
        <span class="amount">￥ {{ detailInfo.price }}</span> <span class="pay">立即付费</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { lessonDetail } from '@/api/jobSeeker'
import { userInfo } from '@/api/userInfo'
import { addMyLesson} from '@/api/jobSeeker'

export default {
  data() {
    return {
      showDialog: false,
      userInfo: {},
      detailInfo: {}
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    initUserInfo() {
      userInfo().then(res=>{
        if(res.code == 1) {
          this.userInfo = res.data.userinfo
        }
      })
    },
    payNow() {
      this.showDialog = false
      let url = `https://zhp.digginggoldroad.com/api/jobseeker/order?lesson_id=${this.detailInfo.id}&method=web&user_id=${this.userInfo.id}`
      window.open(url)
    },
    getLessonDetail(id) {
      lessonDetail({id: id}).then(res=>{
        if(res.code == 1) {
          this.detailInfo = res.data
        }
      })
    },
    videoTimeUpdate(e) {// 视频时长变化
      if(Number(this.detailInfo.price)>0 && !this.detailInfo.order_info.id) {
        let currentTime = e.target.currentTime
        if (currentTime - 300 > 0) {// 超过5分钟
          this.$refs.video.pause()
          this.showDialog = true
        }
      }
    },
    videoEnd() {
      let params = {
        lesson_id: this.detailInfo.id
      }
      addMyLesson(params).then(res=>{
        if (res === '已获取') {
          this.$message.success('您已获取过该证书')
        } else {
          this.$message.success('已经学习完成的课程，可在我的-我的课程中找到')
        }
      })
    },
    // 禁止快进
    onPlay(event) {
      // 获取视频总时长和当前播放时间
      const duration = this.$refs.video.duration;
      const currentTime = this.$refs.video.currentTime;
 
      // 如果当前播放时间小于总时长，则暂停视频，重新播放
      if (currentTime > 0 && currentTime < duration) {
        this.$refs.video.pause();
        this.$refs.video.currentTime = 0;
        this.$refs.video.play();
      }
    }
  },
  created() {
    let id = this.$route.query.id
    if(id) {
      this.getLessonDetail(id)
    }
    this.initUserInfo()
  },
  computed: {
    videoUrl() {
      if(this.detailInfo.recorded) {
        return this.$store.getters.imgeCDN + this.detailInfo.recorded
      }
      return ''
    },
    imagesUrl() {
      if(this.detailInfo.images) {
        let arr = this.detailInfo.images.split(',')
        arr = arr.map(item=>{
          return this.$store.getters.imgeCDN + item
        })
        return arr
      }
      return []
    }
  }
}
</script>

<style lang="less" scoped>
.lesson-detail {
  .content {
    width: 100%;
    padding: 33px;
    background: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 20px;
    color: #222222;

    img {
      width: 100%;
      height: 533px;
      background: rgba(0,0,0,0.3);
      border-radius: 13px 13px 13px 13px;
    }
    .video {
      width: 100%;
      height: 533px;
      border-radius: 13px 13px 13px 13px;
      margin-bottom: 40px;
    }

    .tip {
      color: #f04e3f;
      margin-bottom: 10px;
      font-size: 20px;
    }

    .title {
      font-weight: 600;
      font-size: 27px;
      margin-bottom: 27px;
    }
    .sub-title{
      font-weight: 600;
      font-size: 23px;
      margin-bottom: 20px;
    }

    .cont {
      font-size: 23px;
      margin-bottom: 20px;
    }
  }

  .pay-div{
    width: 100%;
    height: 87px;
    background: #FFFFFF;
    border-radius: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .pay-cont {
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: right;
      font-size: 27px;
      color: #E11E34;
      font-weight: 600;

      span{
        display: inline-block;
        font-size: 43px;
      }
    }

    .pay-btn {
      width: 200px;
      height: 65px;
      line-height: 65px;
      text-align: center;
      background: #E11E34;
      border-radius: 13px 13px 13px 13px;
      font-weight: 500;
      font-size: 23px;
      color: #FFFFFF;
      cursor: pointer;
    }

    .mr40{
      margin-right: 40px;
    }
  }

  .dialog{
    .tip1 {
      line-height: 20px;
    }
    .tip2 {
      line-height: 20px;
      margin-bottom: 30px;
    }
    .oper {
      height: 27px;
      line-height: 27px;
      padding: 0 26px;
      color: white;
      background: #E11E34;
      border-radius: 7px 7px 7px 7px;
      cursor: pointer;

      .amount {
        font-weight: 600;
        font-size: 13px;
        margin-right: 7px;
      }
      .pay {
        font-size: 12px;
      }
    }
  }

  /deep/.el-dialog {
    width: 350px;
    border-radius: 7px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    // font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px 20px 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #222222;
    // font-size: 13px;
  }
}
</style>