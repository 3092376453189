<template>
  <div class='deliver-item' @click="itemClick()">
    <div class="top-content">
      <div class="top">
        <div class="job-name">{{ item.post_name }}</div>
        <div class="num">{{ item.salary_name }}</div>
      </div>
      <div class="detail">
        <div class="com">
          <span class="company-name">{{ item.company_name }}</span><span>{{ item.company_financing }}</span>
          <!-- <span>{{ item.scale_name }} </span> -->
        </div>
        <span>{{ city }}</span><span>{{ item.min_education_name }}</span><span>{{ item.work_name }}</span>
      </div>
    </div>
    <div class="bottom">
      <div class="left"> 
        <img :src="avatarUrl" alt="">{{ item.recruiter_info.nickname }}·{{ item.recruiter_info.position}}
      </div>
      <div>{{ item.createtime | toBlockTime2 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  methods: {
    itemClick() {
      this.$emit('itemClick', this.item)
    }
  },
  computed: {
    avatarUrl() {
      if(this.item.recruiter_info.avatar) {
        return this.$store.getters.imgeCDN + this.item.recruiter_info.avatar
      }
      return ''
    },
    city() {
      let cityArr = this.item.area_name.split(',')
      if(cityArr.length == 3){
        return cityArr[1]
      } else {
        return ''
      }
    },
    areaAndCity() {
      let cityArr = this.item.area_name.split(',')
      if(cityArr.length == 3){
        return `${cityArr[0]}  ${cityArr[1]}`
      } else {
        return ''
      }
    }
  },
}
</script>

<style lang="less" scoped>
.deliver-item{
  width: 386px;
  height: 225px;
  background: #FFFFFF;
  border-radius: 13px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  .top-content{
    height: 157px;
    padding: 27px 27px 0 27px;
    text-align: left;

    .top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 13px;

      .job-name{
        max-width: 260px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: 20px;
        color: #222222;
      }

      .num{
        font-weight: 500;
        font-size: 20px;
        color: #E11E34;
      }
    }
    .detail{
      .com{
        margin-bottom: 13px;
        display: flex;
        align-items: center;
        span{
          background: none;
          padding: 0;
          display: inline-block;
          // margin: 0;
        }
        .company-name {
          display: inline-block;
          max-width: 260px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      span{
        padding: 4px 10px;
        font-weight: 400;
        font-size: 15px;
        color: #585858;
        background-color: #F7F7F7;
        margin-right: 13px;
        border-radius: 3px;
      }
    }
  }

  .bottom{
    height: 68px;
    background-color: #F9FBFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    color: #585858;
    padding: 0 27px;
    border-radius: 0 0 13px 13px;

    .left{
      display: flex;
      align-items: center;
      img{
        width: 33px;
        height: 33px;
        border-radius: 17px;
        margin-right: 13px;
      }
    }

    span{
      margin-right: 20px;
    }
  }
}

</style>