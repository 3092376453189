<template>
  <div class='re-interview-record-item' @click="itemClick()">
    <div class="left">
      <div>{{ date.date }}</div>
      <div>{{ date.time }}</div>
    </div>
    <div class="center">
      <div class="comp">{{ item.real_name }}</div>
      <div class="job">
        <span>{{ item.name }}</span> <span>{{ item.salary_text }}</span>
      </div>
    </div>
    <div class="right">{{ item.status | interviewStatus }} </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  methods: {
    itemClick(){
      this.$emit('itemClick', this.item)
    }
  },
  computed: {
    date() {
      var time = new Date(parseInt(this.item.interview_time) * 1000);
      var m = time.getMonth()+1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      if(m < 10){
        m = "0" + m;
      }
      if(d < 10){
        d = "0" + d;
      }
      if(h < 10){
        h = "0" + h;
      }
      if(mm < 10){
        mm = "0" + mm;
      }
      return {
        date: `${m}月${d}日`,
        time: `${h}:${mm}`
      }
    }
  }
}
</script>

<style lang="less" scoped>
.re-interview-record-item{
  width: 100%;
  height: 144px;
  background: #FFFFFF;
  border-radius: 13px 13px 13px 13px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .left{
    width: 185px;
    height: 100%;
    background: #F9FBFF;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .center{
    width: calc(100% - 370px);
    height: 100%;
    padding: 33px 0 0 47px;

    .comp{
      font-weight: 500;
      font-size: 23px;
      color: #222222;
      margin-bottom: 17px;
    }

    .job {
      span:nth-child(2) {
        color: #E11E34;
      }
    }

    span{
      font-weight: 400;
      font-size: 20px;
      color: #585858;
      margin-right: 27px;
    }

    .red{
      color: #E11E34;
    }
  }
  .right{
    width: 185px;
    height: 100%;
    line-height: 144px;
    text-align: center;
    font-size: 23px;
    color: #2676F7;
  }
}
</style>