import request from '@/utils/request'

export function getCaptchaApi (data = {}) {
  return request({
    method: 'get',
    url: 'user/getCaptcha',
    data: data,
    isToken: false
  })
}

export function loginApi (data) {
  return request({
    method: 'post',
    url: 'user/login',
    data: data,
    isToken: false
  })
}

export function sendCodeApi (data) {
  return request({
    method: 'post',
    url: 'sms/send',
    data: data,
    isToken: false
  })
}

export function mobileLoginApi (data) {
  return request({
    method: 'post',
    url: 'user/mobilelogin',
    data: data,
    isToken: false
  })
}

export function regApi (data) {
  return request({
    method: 'post',
    url: 'user/register',
    data: data,
    isToken: false
  })
}
