<template>
  <div class='collection-item' >
    <div class="left" @click="itemClick()">
      <img :src="imageUrl" alt="">
      <div>
        <div class="goods-name">{{ item.goods.title }}</div>
        <div class="detail">喜欢{{ item.goods.like }} 付款{{ item.goods.payment }}</div>
        <div class="amount">￥<span>{{ item.goods.price }}</span> </div>
      </div>
    </div>
    <div class="right">
      <div @click.stop="goShop()">
        <img src="../../assets/images/shop-icon.png" alt="">
        <span>店铺</span>
      </div>
      <div>
        <el-popconfirm title="确定要删除吗？" @confirm="deleteItem()">
          <div slot="reference">
            <img src="../../assets/images/delete-gray.png" alt="">
            <span>删除</span>
          </div>
        </el-popconfirm>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  methods: {
    itemClick() {
      this.$emit('itemClick', this.item)
    },
    goShop() {
      this.$emit('goShop', this.item)
    },
    deleteItem() {
      this.$emit('deleteItem', this.item)
    },
    
  },
  computed: {
    imageUrl() {
      return this.$store.getters.imgeCDN + this.item.goods.image
    }
  }
}
</script>

<style lang="less" scoped>
.collection-item {
  width: 100%;
  padding: 30px;
  // margin-bottom: 20px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #F1F1F1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left{
    min-width: 70%;
    display: flex;
    align-items: center;  
    cursor: pointer;

    img{
      width: 80px;
      height: 80px;
      margin-right: 20px;
      border-radius: 4px;
    }

    .goods-name{
      font-weight: 500;
      font-size: 18px;
      color: #222222;
      margin-bottom: 8px;
    }
    .detail {
      font-size: 14px;
      color: #999999;
      margin-bottom: 8px;
    }
    .amount {
      font-weight: 500;
      font-size: 11px;
      color: #EB1001;

      span {
        font-size: 16px;
      }
    }
    
  }
  .right {
    display: flex;
    align-items: center;

    div{
      margin-left: 16px;
      font-size: 14px;
      color: #666666;
      display: flex;
      align-items: center;
      cursor: pointer;

      img{
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
}
</style>
<style>
.el-popconfirm__action {
  margin-top: 10px!important;
}

</style>