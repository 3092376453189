<template>
  <div class='after-sale-service'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <div class="view" v-for="(item, index) in dataList" :key="index">
          <div class="shop-name">
            <img class="shop-icon" src="../../../assets/images/shop-icon.png"  alt="">
            <div>{{ item.shop.shopname }}</div>
          </div>
          <div class="goods" @click="btnClick(item, 2)">
            <div class="section1">
              <img class="goods-img" :src="imageUrl(item.goods.image)" alt="">
              <div>{{  item.goods.title }}</div>
            </div>
            <div class="section2">{{  item.goods.difference }}</div>
            <!-- <div class="section3">x{{  item.goods.count }}</div> -->
            <div class="section4"><span>￥</span>{{  item.goods.price }}
            </div>
            <div class="section5">{{ item.type_text }}</div>
          </div>
          <div class="status">
            <div class="left">
              <div class="status-title">{{getStateText(item.state)}}</div>
              <div>{{getStateInfo(item.state)}}</div>
            </div>
            <div class="right">退款：¥{{ item.price }}</div>
          </div>
          <div class="bottom">
            <div class="btn default-btn" v-if="item.state == 0 || item.state == 1 || item.state == 2 || item.state == 3 || item.state == 6" @click="btnClick(item, 1)">关闭退款</div>
            <div class="btn active-btn" @click="btnClick(item, 2)">查看详情</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import { refundList, closeRefund } from '@/api/shoppingMall'

export default {
  components: {
    crumbsVue,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '退货/售后',
        },
      ],
      dataList: [],
    }
  },
  methods: {
    btnClick(item, type) {
      if(type == 1) {
        // 关闭退款
        let params = {
          id: item.id
        }
        closeRefund(params).then(res=>{
          if(res.code == 1) {
            this.getList()
          }
        })
      } else if (type == 2) {
        // 查看详情
        this.$router.push({path: 'refund-detail', query: {id : item.id}})
      }
    },
    getList() {
      let params = {
        page: 1
      }
      refundList(params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data.data
        }
      })
    },
    getStateText(state){
			return ["等待卖家同意", "等待买家退货", "卖家拒绝退款", "平台介入", "退款完成", "退款关闭", "等待卖家签收退货", "退款中", "退款失败"][state];
		},
		getStateInfo(state){
			return ["成功发起退款等待卖家同意", "卖家已同意，请退货", "您可以修改退货申请再次发起", "已申请平台介入，请等待平台判定", "退款完成", "您已关闭退款申请", "等待商家收到货物并退款给您", "第三方支付退款中将在24小时内原路返还", "第三方退款失败，请联系商家"][state];
		},
  },
  created() {
    this.getList()
  },
  computed: {
    imageUrl: function(){
      return function (img) {
        if(img) {
          return this.$store.getters.imgeCDN + img
        }
        return ''
      }
    },
  }
}
</script>

<style lang="less" scoped>
.after-sale-service {
  .view {
    margin-bottom: 20px;
    padding: 33px 30px 2px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    .shop-name{
      padding-bottom: 30px;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      display: flex;
      align-items: center;
      .shop-icon {
        margin-right: 10px;
      }
    }
    .goods {
      border-top: 1px solid #E9E9E9;
      padding:  20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .section1 {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        color: #222222;
        width: 30%;
        .goods-img {
          width: 60px;
          height: 60px;
          margin-right: 60px;
        }
      }
      .section2 {
        font-size: 16px;
        color: #999999;
      }
      .section3 {
        font-weight: 500;
        font-size: 16px;
        color: #222222;
      }
      .section4 {
        font-size: 16px;
        font-weight: 600;
        color: #EB1001;
        span {
          font-size: 11px;
        }
      }
      .section5 {
        font-weight: 500;
        font-size: 16px;
        color: #FF8019;
      }
    }

    .status {
      padding: 18px 30px;
      margin-bottom: 30px;
      background: #F8F8F8;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #999999;
        .status-title {
          font-weight: 500;
          font-size: 18px;
          color: #333330;
          margin-right: 25px;
        }
      }
      .right {
        font-weight: 500;
        font-size: 18px;
        color: #EB1001;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 30px;
      .btn {
        height: 40px;
        padding: 0 24px;
        margin-left: 20px;
        line-height: 40px;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        border-radius: 20px;
        cursor: pointer;
      }
      .default-btn {
        color: #666666;
        border: 1px solid #E3E3E3;
      }
      .active-btn {
        color: #fff;
        border: 1px solid #2676F7;
        background: #2676F7;
      }
    }
  }
}
</style>