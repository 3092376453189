/*
 * @Author: angel~dongsanling
 * @Date: 2024-08-01 13:21:25
 * @Description: 用户信息
 */
import request from '@/utils/request'

export function changeIdentity (data = {}) {
  return request({
    method: 'post',
    url: 'user/changeIdentity',
    data: data,
    isToken: true
  })
}

/**
 * @Author: angel~dongsanling
 * @description: 用户信息
 * @param {*} data 参数
 */
export function userInfo (data = {}) {
  return request({
    method: 'get',
    url: 'user/userInfo',
    data: data,
    isToken: true
  })
}


export function getInfoByPhone (data = {}) {
  return request({
    method: 'get',
    url: 'jobseeker/getInfoByPhone',
    data: data,
    isToken: true
  })
}


/**
 * @Author: angel~dongsanling
 * @description: 修改手机号
 * @param {*} data 参数
 */
export function changemobile (data = {}) {
  return request({
    method: 'post',
    url: 'user/changemobile',
    data: data,
    isToken: true
  })
}

/**
 * @Author: angel~dongsanling
 * @description: 修改密码
 * @param {*} data 参数
 */
export function resetpwd (data = {}) {
  return request({
    method: 'post',
    url: 'user/resetpwd',
    data: data,
    isToken: true
  })
}

/**
 * @Author: angel~dongsanling
 * @description: 在线简历
 * @param {*} data 参数
 */
export function resumeRead (data = {}) {
  return request({
    method: 'get',
    url: 'resume/read',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 学历列表
 * @param {*} data 参数
 */
export function companyGetConfig (data = {}) {
  return request({
    method: 'get',
    url: 'company/getConfig',
    data: data,
    isToken: true
  })
}

/**
 * @Author: angel~dongsanling
 * @description: 个人资料保存
 * @param {*} data 参数
 */
export function userProfile (data = {}) {
  return request({
    method: 'post',
    url: 'user/profile',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 培训经历保存
 * @param {*} data 参数
 */
export function resumeIndex (data = {}) {
  return request({
    method: 'post',
    url: 'resume/index',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 项目经历保存
 * @param {*} data 参数
 */
export function resumeAddProjectExperience (data = {}) {
  return request({
    method: 'post',
    url: 'resume/addProjectExperience',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 教育经历保存
 * @param {*} data 参数
 */
export function resumeAddEducationalExperience (data = {}) {
  return request({
    method: 'post',
    url: 'resume/addEducationalExperience',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 工作经历保存
 * @param {*} data 参数
 */
export function resumeAddWorkExperience (data = {}) {
  return request({
    method: 'post',
    url: 'resume/addWorkExperience',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 删除单个项目经历
 * @param {*} data 参数
 */
export function deleteProjectExperience (data = {}) {
  return request({
    method: 'post',
    url: 'resume/deleteProjectExperience',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 删除工作经历
 * @param {*} data 参数
 */
export function deleteWorkExperience (data = {}) {
  return request({
    method: 'post',
    url: 'resume/deleteWorkExperience',
    data: data,
    isToken: true
  })
}

// 实名认证
export function authentication (data = {}) {
  return request({
    method: 'post',
    url: 'user/realName',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 公司信息
 * @param {*} data 参数
 */
export function companyProfile (data = {}) {
  return request({
    method: 'post',
    url: 'company/profile',
    data: data,
    isToken: true
  })
}

// 会话列表
export function chatList (data = {}) {
  return request({
    method: 'post',
    url: 'user/getChatList',
    data: data,
    isToken: true
  })
}

// 删除列表
export function deleteChat (data = {}) {
  return request({
    method: 'post',
    url: 'user/delChat',
    data: data,
    isToken: true
  })
}