import { render, staticRenderFns } from "./shoppingMallLayout.vue?vue&type=template&id=0a805957&scoped=true"
import script from "./shoppingMallLayout.vue?vue&type=script&lang=js"
export * from "./shoppingMallLayout.vue?vue&type=script&lang=js"
import style0 from "./shoppingMallLayout.vue?vue&type=style&index=0&id=0a805957&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a805957",
  null
  
)

export default component.exports