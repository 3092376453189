/**
	 * 加法精度计算
	 * @author 深圳前海万联科技有限公司 <www.wanlshop.com>
	 * 
	 * @param {Object} number 数字
	 */
export function bcadd(a, b) {
  var c, d, e;
  try {
    c = a.toString().split(".")[1].length
  } catch (f) {
    c = 0
  }
  try {
    d = b.toString().split(".")[1].length
  } catch (f) {
    d = 0
  }
  return e = Math.pow(10, Math.max(c, d)), (bcmul(a, e) + bcmul(b, e)) / e
}

/**
   * 减法精度计算
   * @author 深圳前海万联科技有限公司 <www.wanlshop.com>
   * 
   * @param {Object} number 数字
   */
export function bcsub(a, b) {
  var c, d, e;
  try {
    c = a.toString().split(".")[1].length
  } catch (f) {
    c = 0
  }
  try {
    d = b.toString().split(".")[1].length
  } catch (f) {
    d = 0
  }
  return e = Math.pow(10, Math.max(c, d)), (bcmul(a, e) - bcmul(b, e)) / e
}

/**
* 乘法精度计算
* @author 深圳前海万联科技有限公司 <www.wanlshop.com>
* 
* @param {Object} number 数字
*/
export function bcmul(a, b) {
  var c = 0,
    d = a.toString(),
    e = b.toString();
  try {
    c += d.split(".")[1].length
  } catch (f) { }
  try {
    c += e.split(".")[1].length
  } catch (f) { }
  return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c)
}

/**
* 除法精度计算
* @author 深圳前海万联科技有限公司 <www.wanlshop.com>
* 
* @param {Object} number 数字
*/
export function bcdiv(a, b) {
  var c, d, e = 0,
    f = 0;
  try {
    e = a.toString().split(".")[1].length
  } catch (g) { }
  try {
    f = b.toString().split(".")[1].length
  } catch (g) { }
  return c = Number(a.toString().replace(".", "")), d = Number(b.toString().replace(".", "")), bcmul(c / d, Math.pow(10, f - e))
}