<template>
  <div class='job-search'>
    <div class="page-header mb20">
      <div class="page-header-content">
        <div class="back" @click="back()">返回</div>
        <div class="header-search">
          <el-input placeholder="搜索职位" v-model="params.keyword"></el-input>
          <div class="search-btn" @click="search()">搜索</div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="page-content ">
        <div class="content">
          <jobItemPerson :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in jobs" :key="index" :item="item" @itemClick="goJobDetail"></jobItemPerson>
        </div>
        <el-pagination
          class="page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.page"
          :page-sizes="[9, 18, 27, 36]"
          :page-size="params.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
    
  </div>
</template>

<script>
import jobItemPerson from '@/components/jobItemPerson.vue';
import { jobseekerIndex, getIndexTotal } from '@/api/jobSeeker'

export default {
  components: {
    jobItemPerson,
  },
  data() {
    return {
      params: {
        keyword: '',
        limit: 9,
        page: 1
      },
      jobs: [],
      total: 0
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.params.limit = val
      this.params.page = 1
      this.getDataList()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.params.page = val
      this.getDataList()
    },
    getDataList() {
      jobseekerIndex(this.params).then(res=>{
        if(res.code == 1) {
          this.jobs = res.data
        }
      })
      this.getTotal()
    },
    search() {
      this.params.page = 1
      this.getDataList()
    },
    goJobDetail(item) {
      this.$router.push({path: 'jobdetail', query: {id: item.id, companyId: item.company_id}})
    },
    // 热招职位总数
    getTotal() {
      let params = {
        keyword: this.params.keyword
      }
      getIndexTotal(params).then(res=>{
        if(res.code == 1) {
          this.total = res.data
        }
      })
    }
  },
  created() {
    let route = this.$route
    if(route && route.query && route.query.keyword) {
      this.params.keyword = route.query.keyword
    }
    this.getDataList()
  }
}
</script>

<style lang="less" scoped>
.job-search {
  .header-search {
    width: 867px;
    height: 55px;
    background: #F7F7F7;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;

    .search-btn {
      width: 130px;
      height: 55px;
      line-height: 55px;
      text-align: center;
      background: #2676F7;
      font-size: 17px;
      color: #FFFFFF;
      border-radius: 0 10px 10px 0;
      cursor: pointer;
    }

    /deep/.el-input {
      width: 737px;
      height: 55px;
      margin-top: -30px;
    }
    /deep/.el-input__inner {
      width: 737px;
      height: 55px;
      border: none;
      background-color: #F7F7F7;
      font-size: 20px;
      border-radius: 10px 0px 0px 10px;
    }
  }
  .content{
    display: flex;
    flex-wrap: wrap;
    .mr0 {
      margin-right: 0;
    }
  }

  .page {
    padding-bottom: 30px;
  }
  /deep/.el-pagination span:not([class*=suffix]){
    font-size: 20px;
  }
  /deep/.el-pagination .el-select .el-input .el-input__inner{
    font-size: 20px;
    width: 130px;
    height: 32px;
  }
  /deep/.el-pagination .el-select .el-input{
    width: 130px;
    height: 32px;
  }
  /deep/.el-pager li{
    font-size: 20px;
  }
  /deep/.el-pagination__editor.el-input .el-input__inner{
    font-size: 20px;
  }
}
</style>