<template>
  <div class='workplace-forum'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">职场论坛</div>
    </div>
    <div class="page">
      <div class="page-content ">
        <div class="content" v-for="(item, index) in dataList" :key="index">
          <div class="header">
            <div>在{{ item.company_name }}工作的感受</div>
            <img src="../../../assets/images/arrow-right.png" @click="moreFeel(item)" alt="">
          </div>
          <div class="item" v-for="(forumItem, index) in item.list" :key="index">
            <div class="top">
              <img :src="avatarUrl(forumItem.avatar)" alt="">
              <div class="detail">
                <div>{{ forumItem.name }}</div>
                <div><span>{{ item.company_name }}</span> <span>在职{{forumItem.duration}}年</span> </div>
              </div>
            </div>
            <div class="detail-content">{{ forumItem.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkFeelList } from '@/api/jobSeeker'

export default {
  data() {
    return {
      params: {
        page: 1,
        row: 100,
      },
      dataList: []
    }
  },
  methods: {
    getWorkFeel() {
      getWorkFeelList(this.params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data
        }
      })
    },
    moreFeel(item) {
      this.$router.push({path: 'workingfeel', query: {id: item.company_id}})
    }
  },
  created() {
    this.getWorkFeel()
  },
  computed: {
    avatarUrl: function(){
			return function (avatar) {
        return this.$store.getters.imgeCDN + avatar
      }
    },
  }
}
</script>

<style lang="less" scoped>
.workplace-forum {
  .content {
    background: #FFFFFF;
    border-radius: 13px 13px 13px 13px;
    padding: 40px;
    margin-bottom: 20px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      font-size: 27px;
      color: #222222;
      margin-bottom: 20px;

      img{
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
    }

    .item {
      width: 100%;
      padding: 40px;
      margin-bottom: 20px;
      border-radius: 13px 13px 13px 13px;
      border: 1px solid #E8E8E8;

      .top {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        img {
          width: 73px;
          height: 73px;
          border-radius: 37px;
          margin-right: 27px;
        }

        .detail {
          font-size: 27px;
          color: #222222;

          span {
            font-size: 20px;
            color: #999999;
            margin-right: 20px;
          }
        }
      }

      .detail-content {
        font-size: 23px;
        color: #222222;
        line-height: 35px;
      }
    }
  }
}

</style>