<template>
  <div class='workplace-news'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">职场资讯</div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="item" v-for="(item, index) in dataList" :key="index" @click="goDetail(item)">
          <img :src="imgUrl(item.images)" alt="">
          <div class="detail">
            <div>{{ item.name }}</div>
            <div class="sub-title">{{ item.intro }}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { information } from "@/api/jobSeeker";

export default {
  data() {
    return {
      params: {
        page: 1,
        limit: 10
      },
      dataList: []
    }
  },
  methods: {
    goDetail(item) {
      this.$router.push({path: 'newsdetail', query: { id: item.id }})
    },
    getInformation() {
      information(this.params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data
        }
      })
    }
  },
  created() {
    this.getInformation()
  },
  computed: {
    imgUrl: function() {
      return function(imgs) {
        let imgArr = imgs.split(',')
        if(imgArr.length) {
          return this.$store.getters.imgeCDN + imgArr[0]
        }
        return ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.workplace-news {
  .item {
    width: 100%;
    padding: 33px;
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 13px 13px 13px 13px;
    display: flex;
    cursor: pointer;

    img {
      width: 267px;
      height: 165px;
      margin-right: 33px;
      border-radius: 13px 13px 13px 13px;
    }

    .detail {
      font-weight: 600;
      font-size: 23px;
      color: #222222;

      .sub-title {
        font-weight: 400;
        margin-top: 13px;
        font-size: 20px;
        color: #999999;
      }
    }
  }
}

</style>