<template>
  <div class='online-resume'>
    <div class=" page-header">
      <div class="page-header-content ">
        <span class="back el-icon-setting cursor" @click="back()">返回</span>
        在线简历
        <div class="right-btn">
          <img src="../../../assets/images/information.png" @click="report()" alt="">
          <img v-if="isCollected" src="../../../assets/images/star_yellow.png" @click="collect()" alt="">
          <div v-else class="el-icon-star-off collect" @click="collect()"></div>
          <div class="btn-save cursor" @click="operate('save')">立即沟通</div>
        </div>
      </div>
    </div>
    <div class="online-resume-main width1200">
      <div class="online-resume-main-box">
        <!-- 姓名 start -->
        <div class="online-resume-main-box-item" v-for="(item,index) in messageList" :key="index">
          <div class="online-resume-main-box-item-left">
            <div class="online-resume-main-box-item-left-name">
              <span>{{ item.label }}</span>
            </div>
            <div class="online-resume-main-box-item-left-content">
              {{ item.value }}
            </div>
          </div>
          <div class="online-resume-main-box-item-right" v-if="item.url">
            <img :src="item.url" alt="">
          </div>
        </div>
        <!-- 姓名 end -->
        <!-- 工作经历 start -->
        <div class="online-resume-main-box-before-item" v-for="(item,index) in workerList" :key="'work' + index">
          <div class="online-resume-main-box-before-item-name">
            <div class="online-resume-main-box-before-item-left">
              {{ item.title }}
            </div>
          </div>
          <div class="online-resume-main-box-before-item-content" v-for="(subItem,subIndex) in item.children" :key="'worksub' + subIndex">
            <div class="online-resume-main-box-before-item-content-left">
              <div class="online-resume-main-box-before-item-content-left-name" v-if="item.type === 'worker'">
                {{ subItem.name }}
              </div>
              <div class="online-resume-main-box-before-item-content-left-job" v-if="item.type === 'worker'">
                <span class="mr20">{{ subItem.job }}</span>
                <span>{{ subItem.date }}</span>
              </div>
              <div class="online-resume-main-box-before-item-content-left-job" v-if="item.type === 'product'">
                项名称：{{ subItem.job }}
              </div>
              <div class="online-resume-main-box-before-item-content-left-work" v-if="subItem.content && item.type === 'worker'">
                工作内容：{{ subItem.content }}
              </div>
              <div class="online-resume-main-box-before-item-content-left-work" v-if="subItem.content && item.type === 'product'">
                项目内容：{{ subItem.content }}
              </div>
            </div>
          </div>
        </div>
        <!-- 工作经历 end -->

        <!-- 教育经历 start -->
        <div class="online-resume-main-box-item" :class="{'borderTop' : index == 0}" v-for="(item,index) in educationList" :key="'edu' + index">
          <div class="online-resume-main-box-item-left">
            <div class="online-resume-main-box-item-left-name">
              <span>{{ item.label }}</span>
            </div>
            <div class="online-resume-main-box-item-left-content" v-for="(subItem,subIndex) in item.children" :key="'edusub' + subIndex">
              <div v-if="item.type != 'school'">
                {{ subItem.value }}
              </div>
              <div v-else>
                {{ subItem.schoolName }}　{{ subItem.grade }}　{{ subItem.speciality }}
              </div>
            </div>
          </div>
          <div class="online-resume-main-box-item-right" v-if="item.url">
            <img :src="item.url" alt="">
          </div>
        </div>
        <!-- 教育经历 end -->
        <!-- 获得证书 start -->
        <div class="online-resume-main-box-item certificate">
          <div class="online-resume-main-box-item-left">
            <div class="online-resume-main-box-item-left-name">
              <span>获得证书</span>
            </div>
            <div class="online-resume-main-box-item-left-content">
              <img :src="item.url" alt=""  v-for="(item,index) in certificateList" :key="index">
            </div>
          </div>
        </div>
        <!-- 获得证书 end -->
        <!-- 视频介绍 start -->
        <div class="online-resume-main-box-item certificate video">
          <div class="online-resume-main-box-item-left">
            <div class="online-resume-main-box-item-left-name">
              <span>视频介绍</span>
            </div>
            <div class="online-resume-main-box-item-left-content">
              <template v-if="videoList.length">
                <img :src="item.url" alt=""  v-for="(item,index) in videoList" :key="index">
              </template>
              <!-- <div class="empey-video" v-else>
                <img src="../../../assets/images/add.png" alt="">
              </div> -->
            </div>
          </div>
        </div>
        <!-- 视频介绍 end -->
      </div>
    </div>

    <el-dialog class="dialog" :visible.sync="showDialog" :title="'举报'">
      <div>举报该记录，请将举报信息及截图发送至客服邮箱：<span>hdfhiehfe@qq.com</span>，平台收到后将在15个工作日内进行处理并反馈。 </div>
      <div class="oper">
        <div class="confirm-btn" @click="showDialog=false">我知道了</div>
      </div>
    </el-dialog>

    <selectCompanyJobsDialog v-if="isShowSelectCompanyJobsDialog" :companyId="companyId" @closed="isShowSelectCompanyJobsDialog=false" @selectedJob="selectedJob"></selectCompanyJobsDialog>

  </div>
</template>

<script>
import selectCompanyJobsDialog from '@/components/selectCompanyJobsDialog.vue'
import { userInfo, resumeRead } from '@/api/userInfo'
import { checkCollect, handelCollect } from '@/api/company'
import { jobseekerIndex } from '@/api/jobSeeker'

export default {
  name: 'accountSecurity',
  components: {
    selectCompanyJobsDialog,
  },
  data() {
    return {
      showDialog: false,
      isShowSelectCompanyJobsDialog: false,
      messageList: [{
        label: '',
        value: '',
        type: 'name',
        url: ''
      }, {
        label: '求职状态',
        value: '',
        type: 'status'
      }, {
        label: '个人特长',
        value: '',
        type: 'speciality'
      }, {
        label: '求职期望',
        value: '',
        type: 'hope'
      }],
      workerList: [{
        title: '工作经历',
        type: 'worker',
        children: []
      },{
        title: '项目经历',
        type: 'product',
        children: []
      }],
      educationList: [{
        label: '教育经历',
        type: 'school',
        children: []
      },{
        label: '培训经历',
        type: 'train',
        children: [{
          value: '',
        }]
      },{
        label: '自我描述',
        type: 'self',
        children: [{
          value: '',
          grade: '',
          speciality: '',
        }]
      }],
      certificateList: [],
      videoList: [],
      title: '',
      ruleForm: {
        textarea: ''
      },
      hopeList: [{
        label: '期望职位选择',
        value: '',
        option: [{
          lable: '请选择',
          value: ''
        },{
          lable: 'web前端工程师',
          value: '1'
        },{
          lable: 'java工程师',
          value: '2'
        }]
      },{
        label: '期望薪资',
        value: '',
        option: [{
          lable: '请选择',
          value: ''
        },{
          lable: '10k-15k',
          value: '1'
        },{
          lable: '15k-20k',
          value: '2'
        }]
      },{
        label: '工作城市',
        value: '',
        option: [{
          lable: '请选择',
          value: ''
        },{
          lable: '北京',
          value: '1'
        },{
          lable: '天津',
          value: '2'
        }]
      }],
      isCollected: false,
      userId: '',
      userinfo: {},
      companyId: 0,
    }
  },
  methods: {  
    back() {
      this.$router.back()
    },
    report() {
      this.showDialog = true
    },
    operate (type, data) {
      switch (type) {
        case 'save': // 立即沟通
          this.isShowSelectCompanyJobsDialog = true
          break
      }
    },
    getCheckCollect() {
      checkCollect({apply_user_id: this.userId}).then(res=>{
        if(res.code == 1) {
          this.isCollected = res.data
        }
      })
    },
    getUserInfo() {
      userInfo({user_id: this.userId}).then(res=>{
        if(res.code == 1) {
          let info = res.data.userinfo
          this.messageList[0].label = info.nickname
          this.messageList[0].value  = `${info.work_time_text}年工作经验/${info.education_text}/${info.age_text}岁`
          this.messageList[0].url = this.$store.getters.imgeCDN + info.avatar
          this.messageList[1].value = info.job_status_text

          this.userinfo = info
        }
      })
    },
    getResumeInfo() {
      resumeRead({user_id: this.userId}).then(res=>{
        if(res.code == 1) {
          let info = res.data
          this.dealResumInfoData(info)
        }
      })
    },
    dealResumInfoData(info) {
      this.messageList[2].value = info.speciality
      let city = ''
      if(info.area.mergename) {
        let arr = info.area.mergename.split(',')
        if(arr.length == 1) {
          city = arr[0]
        } else {
          city = arr[1]
        }
      }
      this.messageList[3].value = `${info.post_name} ${info.salary_name} ${city}`
      // 工作经历
      let work_experiences = info.work_experiences
      for(let i = 0; i < work_experiences.length; i++) {
        let ex = work_experiences[i]
        let chi = {
          name: ex.company_name,
          job: ex.position,
          date: ex.service_time,
          content: ex.content
        }
        this.workerList[0].children.push(chi)
      }

      // 项目经历
      let project_experiences = info.project_experiences
      for(let i = 0; i < project_experiences.length; i++) {
        let ex = project_experiences[i]
        let chi = {
          job: ex.company_name,
          content: `项目内容${ex.content}`
        }
        this.workerList[1].children.push(chi)
      }

      // 教育经历
      let educational_experiences = info.educational_experiences
      for(let i = 0; i < educational_experiences.length; i++) {
        let ex = educational_experiences[i]
        let chi = {
          schoolName: ex.school,
          grade: ex.education_text,
          speciality: ex.subject
        }
        this.educationList[0].children.push(chi)
      }
      // 培训经历
      this.educationList[1].children[0].value = info.training_experience
      // 自我描述
      this.educationList[2].children[0].value = info.self_description
      // 证书
      if (info.certificate_images.length) {
        info.certificate_images.map((item,index) =>{
          if (item){
            this.certificateList.push({
              url: `${this.$store.getters.imgeCDN}${item}`
            })
          }
        })
      }
      // 视频介绍
      if (info.introduction_file) {
        this.videoList.push(`${this.$store.getters.imgeCDN}${info.introduction_file}`)
      } else {
        this.videoList = []
      }
    },
    collect(){
      handelCollect({apply_user_id: this.userId}).then(res=>{
        if(res.code == 1) {
          this.getCheckCollect()
        }
      })
    },
    selectedJob(item) {
      this.isShowSelectCompanyJobsDialog = false          
      this.$router.push({path: 'message', query: {mobile: this.userinfo.mobile, position_id: item.id}})
    }
  },
  created() {
    if(this.$route.query.id) {
      this.userId = this.$route.query.id
      this.getCheckCollect()
      this.getUserInfo()
      this.getResumeInfo()
    }

    this.companyId = this.$store.getters.userInfo.company_id
  },
}
</script>

<style scoped lang="less">
.online-resume{
  background: #F7F7F7;
  .right-btn {
    width: 280px;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    img{
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
    img:nth-child(1) {
      margin-right: 30px;
    }
    .btn-save{
      position: static;
      margin-left: 12px;
      transform: translateY(0) !important;
    }

    .collect {
      color: gray;
      font-size: 34px;
      cursor: pointer;
    }
  }
  .online-resume-main{
    padding: 10px 40px 40px;
    margin-top: 12px;
    background: #fff;
    box-sizing: border-box;
    .online-resume-main-box{
      .online-resume-main-box-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 0px;
        border-bottom: 1px solid #e8e8e8;
        &.borderTop{
          border-top: 1px solid #e8e8e8;
        }
        .online-resume-main-box-item-left{
          .online-resume-main-box-item-left-name{
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .online-resume-main-box-item-left-content{
            font-size: 18px;
            color: #585858;
          }
        }
        .online-resume-main-box-item-right{
          width: 80px;
          height: 80px;
          img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        &.certificate{
          .online-resume-main-box-item-left-content{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img{
              width: 200px;
              height: 140px;
              margin-right: 30px;
            }
            .empey-video{
              width: 100px;
              height: 100px;
              background: #F7F7F7;
              border-radius: 7px 7px 7px 7px;
              display: flex;
              align-items: center;
              justify-content: center;
              img{
                width: 22px;
                height: 22px;
                margin: 0px auto;
              }
            }
          }
          &.video{
            border-bottom: none;
          }
        }
      }
      .online-resume-main-box-before-item{
        .online-resume-main-box-before-item-name{
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 10px;
          .online-resume-main-box-before-item-left{
            font-size: 22px;
            font-weight: 600;
          }
        }
        .online-resume-main-box-before-item-content{
          height: 190px;
          padding: 30px;
          border-radius: 13px 13px 13px 13px;
          border: 1px solid #E8E8E8;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          .online-resume-main-box-before-item-content-left{
            flex: 1;
            .online-resume-main-box-before-item-content-left-name{
              margin-bottom: 16px;
              font-size: 22px;
              font-weight: 400;
            }
            .online-resume-main-box-before-item-content-left-job{
              margin-bottom: 16px;
              font-size: 18px;
              color: #585858;
            }
          }
        }
      }
      .online-resume-main-box-education{
        padding: 30px 0px;
        border-top: 1px solid #E8E8E8;
        border-bottom: 1px solid #E8E8E8;
        .online-resume-main-box-education-name{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 22px;
          font-weight: 600;
        }
        .online-resume-main-box-education-content{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .online-resume-main-box-education-content-left{
            font-size: 18px;
            color: #585858;
            
          }
          .online-resume-main-box-education-content-right{
            width: 32px;
            height: 32px;
            margin-top: 20px;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .custom-header{
    .custom-header-box{
      .custom-header-box-content{
        position: relative;
        height: 150px;
        background: #F7F7F7;
        border-radius: 7px 7px 7px 7px;
        /deep/.el-textarea{
          height: 150px;
          background: #F7F7F7;
          .el-textarea__inner{
            height: 150px;
            font-size: 14px;
            background: #F7F7F7;
          }
        }
        .custom-header-box-content-txt{
          position: absolute;
          right: 13px;
          bottom: 13px;
        }
        &.hope{
          background: #fff;
          .custom-header-box-content-item{
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .custom-header-box-content-item-label{
              width: 130px;
              text-align: right;
              padding-right: 30px;
            }
            .custom-header-box-content-item-value{
              flex: 1;
            }
          }
          &.worker{
            height: auto;
            .custom-header-box-content-item-textarea{
              height: 180px;
              /deep/.el-textarea{
                height: 180px;
                background: #F7F7F7;
                .el-textarea__inner{
                  height: 180px;
                  font-size: 14px;
                  background: #F7F7F7;
                }
              }
            }
            .custom-header-box-content-item{
              height: 90px;
              display: block;
              .el-select{
                width: 100%;
              }
              .custom-header-box-content-item-label{
                display: block;
                text-align: left;
                font-size: 13px;
                margin-bottom: 13px;
              }
              .custom-header-box-content-item-value{
                height: 58px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }
          }
        }
        
      }
    }
  }

  .dialog{
    .oper {
      display: flex;
      justify-content: end;
      margin-top: 20px;
      .confirm-btn {
        width: 80px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        background: #2676F7;
        border-radius: 7px 7px 7px 7px;
        font-size: 12px;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  /deep/.el-dialog {
    width: 367px;
    border-radius: 7px;
  }
  /deep/.el-textarea{
    font-size: 17px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
    font-size: 13px;

    span{
      color: #2676F7;
    }
  }
}
</style>