<template>
  <div class='crumbs'>
    <img @click="back()" src="../../assets/images/arrow-left.png" alt="">
    <div v-for="(item, index) in navs" :key="index">
      <span v-if="index != 0">/</span> <span class="cu-pointer" :class="{'current-page': index == navs.length-1}" @click="goPath(item)">{{ item.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navs: {
      type: Array
    }
  },
  data() {
    return {
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    goPath(item) {
      this.$router.push(item.path)
    }
  }
}
</script>

<style lang="less" scoped>
.crumbs {
  width: 100%;
  height: 22px;
  line-height: 22px;
  margin: 18px 0 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0,0,0,0.45);

  img{
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  span {
    margin-left: 5px;
  }
  .cu-pointer {
    cursor: pointer;
  }
  .current-page {
    color: rgba(0, 0, 0, 0.65);
    cursor: text;
  }
}
</style>