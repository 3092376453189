<template>
  <div class='order-detail'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <div class="view1">
          <!-- 等你付款/付款成功/等待您收货/等待您的评价 -->
          <div>{{ orderDetail.state | orderDetailState }}</div>
          <div class="time">{{ orderDetail.statetime }}</div>
        </div>
        <div class="view2">
          <el-table
            class="table"
            :data="orderDetail.goods"
            :border="true"
            style="width: 100%">
            <el-table-column
              label="店铺名称"
              class="font-weight500"
              width="180">
              <template>
                <div class="font-weight500">{{ orderDetail.shop.shopname }}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="商品"
              width="280">
              <template slot-scope="scope">
                <div class="flex padding15"> 
                  <img class="goods-img" :src="imageUrl(scope.row.image)" alt="">
                  <div class="font-weight500">{{ scope.row.title }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="number"
              label="数量">
            </el-table-column>
            <el-table-column
              prop="price"
              label="单价">
            </el-table-column>
            <el-table-column
              prop="difference"
              label="规格">
            </el-table-column>
            <el-table-column
              label="小计">
              <template slot-scope="scope">
                <div class="flex padding15">
                  <div class="font-weight500">{{ scope.row.price * scope.row.number }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <div class="flex padding15" v-if="orderDetail.state==2 || orderDetail.state==3 || orderDetail.state==4 || orderDetail.state==6">
                  <div class="op-btn default-btn"  @click="onRefund(scope.row)">{{ getRefund(scope.row.refund_status) }}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- mb36 -->
          <el-row class="row">
            <el-col :span="3" class="font-16-58">商品总价</el-col>
            <el-col :span="21" class="font-16-222">¥{{ orderDetail.pay.order_price }}</el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="3" class="font-16-58">运费</el-col>
            <el-col :span="21" class="font-16-222">¥{{ orderDetail.pay.freight_price }}</el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="3" class="font-16-58">优惠</el-col>
            <el-col :span="21" class="font-16-222">¥{{ orderDetail.pay.discount_price }}</el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="3" class="font-16-58">总价</el-col>
            <el-col :span="3" class="font-16-f3">¥{{ orderDetail.pay.price }}</el-col>
            <!-- <el-col :span="18" class="flex flex-end"> -->
              <!-- <div class="op-btn default-btn" v-if="orderDetail.state==2 || orderDetail.state==3 || orderDetail.state==4 || orderDetail.state==6" @click="btnClick(1)">退款</div> -->
              <!-- <div class="op-btn default-btn" @click="btnClick(2)">退货</div> -->
              <!-- <div class="op-btn active-btn" v-if="orderDetail.state==4" @click="btnClick(3)">去评价</div> -->
            <!-- </el-col> -->
          </el-row>
          <el-row class="row" v-if="orderDetail.pay.actual_payment != 0">
            <el-col :span="3" class="font-16-58">实付款</el-col>
            <el-col :span="21" class="font-16-222">¥{{ orderDetail.pay.actual_payment }}</el-col>
          </el-row>
          
          <div v-if="orderDetail.order_no">
            <el-row class="row order-info flex-start mb20" >
              <el-col :span="3" class="font-18-58">订单信息</el-col>
              <el-col :span="21" class="font-18-222">
                <!-- <div class="mb15">备注：这是备注信息</div> -->
                <div class="mb15" v-if="orderDetail.state != 7 && orderDetail.state != 1 && orderDetail.state != 2">{{orderDetail.logistics.status}} - {{orderDetail.logistics.context}}</div>
                <div class="mb18">收货地址：{{orderDetail.address.address}}，{{ orderDetail.address.name }} {{ orderDetail.address.mobile }}</div>
                <!-- <div class="font-16-999">福建省厦门市集美区李冰大道500号</div> -->
              </el-col>
            </el-row>
            <el-row class="row mb20">
              <el-col :span="3" class="font-18-58">订单编号</el-col>
              <el-col :span="21" class="font-18-222">{{ orderDetail.order_no }} </el-col>
            </el-row>
            <el-row class="row mb20">
              <el-col :span="3" class="font-18-58">支付交易号</el-col>
              <el-col :span="21" class="font-18-222">{{orderDetail.pay.pay_no}} </el-col>
            </el-row>
            <el-row class="row mb20">
              <el-col :span="3" class="font-18-58">创建时间</el-col>
              <el-col :span="21" class="font-18-222">{{orderDetail.createtime_text}} </el-col>
            </el-row>
            <el-row class="row mb20" v-if="orderDetail.paymenttime">
              <el-col :span="3" class="font-18-58">付款时间</el-col>
              <el-col :span="21" class="font-18-222">{{orderDetail.paymenttime_text}} </el-col>
            </el-row>
            <el-row class="row mb20" v-if="orderDetail.delivertime">
              <el-col :span="3" class="font-18-58">发货时间</el-col>
              <el-col :span="21" class="font-18-222">{{orderDetail.delivertime_text}} </el-col>
            </el-row>
            <el-row class="row mb20" v-if="orderDetail.taketime">
              <el-col :span="3" class="font-18-58">收货时间</el-col>
              <el-col :span="21" class="font-18-222">{{orderDetail.taketime_text}} </el-col>
            </el-row>
          </div>
          
        </div>
      </div>
    </div>
    <goodsEvaluateDialog v-if="isShowEvaluateDialog" @confirm="evaluateConfirm" @cancel="evaluateCancel"></goodsEvaluateDialog>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import goodsEvaluateDialog from '@/components/shoppingMall/goodsEvaluateDialog.vue';
import { orderDetail } from '@/api/shoppingMall'

export default {
  components: {
    crumbsVue,
    goodsEvaluateDialog,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '订单详情',
        },
      ],
      order_id: 0,
      isShowEvaluateDialog: false,
      orderDetail: {
        shop: {},
        pay: {},
        address: {}
      }
    }
  },
  methods: {
    onRefund(item) {
      if(item.refund_status == 0) { //申请退款
        let obj = {
          order_id: this.orderDetail.id,
          order_type: 'goods',
          goods: item,
          freight_type: this.orderDetail.freight_type,
          discount_price: this.orderDetail.pay.discount_price,
          goods_number: this.orderDetail.goods.length
        }
        this.$router.push({path: 'apply-after-sale-service', query: { item: JSON.stringify(obj) }})
      } else {// 查看详情
        this.$router.push({path: 'refund-detail', query: { id: item.refund_id }})
      }
      // if(type == 3) {
      //   this.isShowEvaluateDialog = true
      // }
    },
    getRefund(status) {
			return ["退款", "退款中", "待退货", "退款完成", "退款关闭", "退款被拒"][status];
		},
    evaluateConfirm(params) {
      this.isShowEvaluateDialog = false
    },
    evaluateCancel() {
      this.isShowEvaluateDialog = false
    },
    getOrderDetail() {
      let params = {
        id: this.order_id
      }
      orderDetail(params).then(res=>{
        if(res.code == 1) {
          this.orderDetail = res.data

          if(res.data.state == 0){
						this.orderDetail.statetime = '';
					} else if(res.data.state == 1){
						var diff = (res.data.createtime + res.data.config.cancel * 86400) - Date.parse(new Date()) / 1000;
						var days = Math.floor(diff / 86400);
						var hours = Math.floor(diff % 86400 / 3600); 
						var minutes = Math.floor(diff % 86400 % 3600 / 60);
						if (minutes > 0) {
							this.orderDetail.statetime = '还剩' +minutes+ '分自动关闭订单';
						}
						if (hours > 0) {
							this.orderDetail.statetime = '还剩' +hours+ '天' +minutes+ '分自动关闭订单';
						}
						if (days > 0) {
							this.orderDetail.statetime = '还剩' +days+ '天' +hours+ '小时' +minutes+ '分自动关闭订单';
						}
          } else if(res.data.state == 2) {
						this.orderDetail.statetime = '等待卖家发货';
					} else if(res.data.state == 3) {
						var diff = (res.data.delivertime + res.data.config.receiving * 86400) - Date.parse(new Date()) / 1000;
						var days = Math.floor(diff / 86400);
						var hours = Math.floor(diff % 86400 / 3600); 
						var minutes = Math.floor(diff % 86400 % 3600 / 60);
						if (minutes > 0) {
							this.orderDetail.statetime = '还剩' +minutes+ '分自动确认收货';
						}
						if (hours > 0) {
							this.orderDetail.statetime = '还剩' +hours+ '天' +minutes+ '分自动确认收货';
						}
						if (days > 0) {
							this.orderDetail.statetime = '还剩' +days+ '天' +hours+ '小时' +minutes+ '分自动确认收货';
						}
					} else if(res.data.state == 4) {
						var diff = (res.data.taketime + res.data.config.comment * 86400) - Date.parse(new Date()) / 1000;
						var days = Math.floor(diff / 86400);
						var hours = Math.floor(diff % 86400 / 3600); 
						var minutes = Math.floor(diff % 86400 % 3600 / 60);
						if (minutes > 0) {
							this.orderDetail.statetime = '还剩' +minutes+ '分自动评论';
						}
						if (hours > 0) {
							this.orderDetail.statetime = '还剩' +hours+ '天' +minutes+ '分自动评论';
						}
						if (days > 0) {
							this.orderDetail.statetime = '还剩' +days+ '天' +hours+ '小时' +minutes+ '分自动评论';
						}
					} else if(res.data.state == 6) {	
						this.orderDetail.statetime = res.data.dealtime_text;
					} else if(res.data.state == 7) {	
						this.orderDetail.statetime = '订单已取消';
					}
        }
      })
    }
  },
  created() {
    this.order_id = this.$route.query.id
    this.getOrderDetail()
  },
  computed: {
    imageUrl: function(){
			return function (img) {
        if(img) {
          return this.$store.getters.imgeCDN + img
        }
        return ''
      }
    },
  }
}
</script>

<style lang="less" scoped>
.order-detail {
  .view1 {
    margin-bottom: 20px;
    padding: 22px 30px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F3F3F3;
    font-size: 24px;
    color: #333333;
    .time {
      margin-top: 8px;
      font-size: 18px;
      color: #999999;
    }
  }
  .view2 {
    margin-bottom: 20px;
    padding: 30px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F3F3F3;

    .table{
      margin-bottom: 30px;
    }
    .font-weight500 {
      font-weight: 500;
    }
    .flex{
      display: flex;
      align-items: center;
      .goods-img{
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
      .more-img{
        width: 20px;
        height: 20px;
      }
    }
    .flex-end {
      justify-content: flex-end;
    }
    .padding15{
      padding: 15px 0;
    }
    .row{
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .font-16-58{
        font-size: 16px;
        color: #585858;
      }
      .font-16-222 {
        font-size: 16px;
        color: #222;
      }
      .font-16-f3{
        font-size: 16px;
        color: #FF3333;
      }

      .font-18-58{
        font-size: 18px;
        color: #585858;
      }
      .font-18-222 {
        font-size: 16px;
        color: #222;
      }
      .font-16-999{
        font-size: 16px;
        color: #999;
      }
    }
    .mb36 {
      margin-bottom: 36px;
    }
    .mb30 {
      margin-bottom: 30px;
    }
    .mb15 {
      margin-bottom: 15px;
    }
    .flex-start{
      justify-content: flex-start;
    }

    .order-info {
      padding-top: 30px;
      border-top: 1px solid #EEEEEE;
    }

    .op-btn {
      height: 32px;
      line-height: 32px;
      // margin-left: 15px;
      padding: 0 24px;
      font-size: 14px;
      cursor: pointer;
    }
    .default-btn {
      color: #666666;
      background: #F6F6F6;
      border: 1px solid #E2E2E2;
    }
    .active-btn {
      color: #FFFFFF;
      background: #2676F7;
      border: 1px solid #2676F7;
    }
  }

  /deep/.el-table th.el-table__cell.is-leaf{
    text-align: center;
    border-bottom: 1px solid #ECECEC;
    font-size: 18px;
    color: #666666;
  }
  /deep/.el-table th.el-table__cell {
    background: #FAFAFA;
  }
  /deep/.el-table--enable-row-transition .el-table__body td.el-table__cell{
    text-align: center;
    font-size: 16px;
    color: #333;
  }
  /deep/.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
    background: #fff;
  }
}

</style>