<template>
  <div class='select-job-dia'>
    <el-dialog class="dialog" 
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="closedDialog"
    title="选择职位">
      <div class="content">
        <div class="cate">
          <div class="cate-item" :class="{'cate-item-active': cateActiveIndex==item.id}" v-for="(item, index) in jobCates" :key="index" @click="handleCate(item)">{{ item.name }}</div>
        </div>
        <div class="subCate">
          <div class="sub-cate-item" :class="{'sub-cate-item-active': subCateActiveIndex==item.id}" v-for="(item, index) in subCate" :key="'sub'+index" @click="handleSubCate(item)">{{ item.name }}</div>
        </div>
      </div>
      <div class="bottom">
        <span class="clear" @click="clear()">清除</span>
        <span class="confirm" @click="confirm()">确定</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { gradePost, subPost } from '@/api/jobSeeker'

export default {
  data() {
    return {
      showDialog: true,
      cateActiveIndex: null,
      subCateActiveIndex: null,
      currentCate: null,  //记录的是父级对象
      currentSubCate: null,//记录的是子级对象
      jobCates: [
        // {
        //   title: '设计师',
        //   id: 1,
        //   children: [
        //     {
        //       title: 'UI设计师',
        //       id: 1,
        //     },{
        //       title: '美工',
        //       id: 2,
        //     },{
        //       title: '动效设计师',
        //       id: 3,
        //     },{
        //       title: '设计师123',
        //       id: 4,
        //     },{
        //       title: '美工456',
        //       id: 5,
        //     },{
        //       title: '平面设计师',
        //       id: 6,
        //     },{
        //       title: '设计师123',
        //       id: 16,
        //     },{
        //       title: '美工456',
        //       id: 17,
        //     },{
        //       title: '平面设计师',
        //       id: 18,
        //     },
        //   ]
        // },{
        //   title: '销售',
        //   id: 2,
        //   children: [
        //     {
        //       title: '销售1',
        //       id: 7,
        //     },{
        //       title: '销售2',
        //       id: 8,
        //     },{
        //       title: '销售3',
        //       id: 9,
        //     },
        //   ]
        // },{
        //   title: '开发',
        //   id: 3,
        //   children: [
        //     {
        //       title: 'JAVA开发',
        //       id: 10,
        //     },{
        //       title: '前端开发',
        //       id: 11,
        //     },{
        //       title: 'iOS开发',
        //       id: 12,
        //     },{
        //       title: 'Android开发',
        //       id: 13,
        //     },{
        //       title: 'Python',
        //       id: 14,
        //     },{
        //       title: 'Go',
        //       id: 15,
        //     },
        //   ]
        // }
      ],
      subCate: []
    }
  },
  methods: {
    closedDialog() {
      this.$emit('closed')
    },
    handleCate(item) {
      this.currentCate = item
      this.cateActiveIndex = item.id
      this.currentSubCate = null
      this.getSubPost(item.id)
    },
    handleSubCate(item) {
      this.currentSubCate = item
      this.subCateActiveIndex = item.id
    },
    clear() {
      this.cateActiveIndex = null
      this.subCateActiveIndex = null
    },
    confirm() {
      if(!this.currentSubCate) {
        this.$message.warning('请选择职位')
        return
      }
      this.$emit('confirm', this.currentCate, this.currentSubCate)
    },
    // 职位类型
    getGradePost() {
      let params = {
        grade: 1
      }
      gradePost(params).then(res=>{
        if(res.code == 1) {
          this.jobCates = res.data
          if(this.jobCates.length) {
            this.getSubPost(this.jobCates[0].id)

            this.cateActiveIndex = this.jobCates[0].id
            this.currentCate = this.jobCates[0]
          }
        }
      })
    },
    getSubPost(id) {
      let params = {
        parent_id: id
      }
      subPost(params).then(res=>{
        if(res.code == 1) {
          this.subCate = res.data
        }
      })
    }
  },
  created() {
    this.getGradePost()
  }
}
</script>

<style lang="less" scoped>
.select-job-dia{

  .dialog{
    .content {
      display: flex;
      font-size: 17px;
      color: #585858;
      padding-bottom: 50px;
      .cate {
        min-width: 30%;
        margin-right: 20px;
        .cate-item {
          // width: 60px;
          height: 38px;
          margin-bottom: 13px;
          line-height: 38px;
          text-align: center;
          cursor: pointer;
        }
        .cate-item-active {
          background: rgba(38,118,247,0.08);
          border-radius: 7px 7px 7px 7px;
          color: #2676F7;
        }
      }
      .subCate {
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        align-self: baseline;
        .sub-cate-item{
          height: 38px;
          line-height: 38px;
          padding: 0 13px;
          background: #F7F7F7;
          border-radius: 7px 7px 7px 7px;
          margin: 0 13px 13px 0;
          cursor: pointer;
        }
        .sub-cate-item-active {
          background: #2676F7;
          color: white;
        }
      }
    }

    .bottom {
      margin-top: 30px;
      margin-right: 30px;
      display: flex;
      justify-content: right;
      span {
        display: inline-block;
        height: 27px;
        line-height: 27px;
        padding: 0 17px;
        text-align: center;
        border-radius: 7px 7px 7px 7px;
        cursor: pointer;
      }
      .clear {
        margin-right: 10px;
        background: rgba(38,118,247,0.08);
        color: #2676F7;
      }
      .confirm {
        background: #2676F7;
        color: white;
      }
    }
  }
  /deep/.el-dialog {
    width: 520px;
    border-radius: 7px;
  }
  /deep/.el-textarea{
    font-size: 17px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
  }
}
</style>