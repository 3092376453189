<template>
  <div class='select-address'>
    <el-dialog 
    class="dialog" 
    :visible.sync="showDialog" 
    :close-on-click-modal="false"
    :close-on-press-escape="false">
    <div>
      <div class="dia-title">选择地址</div>
      <addressItem v-for="(item, index) in addressList" :key="index" :item="item" @selectAddressItem="selectAddressItem"></addressItem>
    </div>
  </el-dialog>
  </div>
</template>

<script>
import addressItem from '@/components/shoppingMall/addressItem.vue';

export default {
  components: {
    addressItem
  },
  props: {
    addressList: {
      type: Array
    }
  },
  data() {
    return {
      showDialog: true,
    }
  },
  methods: {
    selectAddressItem(item) {
      this.$emit('selectAddressItem', item)
    }
  }
}
</script>

<style lang="less" scoped>
.select-address {
  .dialog {
    .dia-title {
      margin-bottom: 20px;
      font-size: 18px;
      color: #222222;
      font-weight: 600;
    }
  }

  /deep/.el-dialog {
    width: 380px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.15);
    border-radius: 4px;
  }
  /deep/.el-dialog__header{
    display: none;
  }
  /deep/.el-dialog__body{
    padding: 25px 25px 20px;
  }
}
</style>