<template>
  <div class='cart'>
    <div class="page">
      <div class="page-content">
        <div class="top">
          <crumbsVue :navs="navs"></crumbsVue>
          <div class="manage-btn" v-if="manageStatus==1" @click="manageStatus=2">管理</div>
          <div class="manage-btn" v-else @click="manageStatus=1">取消管理</div>
        </div>
        <div class="view" v-for="(item, index) in dataList" :key="index">
          <div class="shop-name" @click="goShop(item)">
            <div v-if="!item.checked" class="un-select" @click.stop="shopHandel(item)"></div>
            <div v-else @click.stop="shopHandel(item)">
              <img src="../../../assets/images/shop-selected.png" alt="">
            </div>
            <img class="shop-icon" src="../../../assets/images/shop-icon.png"  alt="">
            <div>{{ item.shop_name }}</div>
          </div>
          <div class="goods" v-for="(goodsItem, ind) in item.goods" :key="ind" >
            <div class="section1">
              <div v-if="!goodsItem.checked" class="un-select" @click.stop="goodsHandel(item, goodsItem)"></div>
              <img v-else class="op-img" src="../../../assets/images/shop-selected.png" @click.stop="goodsHandel(item, goodsItem)" alt="">
              <img class="goods-img" :src="imageUrl(goodsItem.image)" alt="">
            </div>
            <div class="section2" @click="goGoodsDetail(goodsItem)">{{ goodsItem.title }}</div>
            <div class="section3" @click="goGoodsDetail(goodsItem)">规格：{{ goodsItem.sku.difference.join(' ') }}</div>
            <div class="section4" @click="goGoodsDetail(goodsItem)"><span>￥</span>{{ goodsItem.sku.price }}</div>
            <div class="section5">
              <div class="img-div" @click.stop="handelCount(goodsItem, 'bcsub')">
                <img src="../../../assets/images/subtract.png"  alt="">
              </div>
              <el-input placeholder="请输入数量" oninput="value = value.replace(/^\D*([0-9]\d*\.?\d{0,4})?.*$/,'$1')" v-model="goodsItem.number"></el-input>
              <div class="img-div"  @click.stop="handelCount(goodsItem, 'bcadd')">
                <img class="add-img" src="../../../assets/images/add-icon.png" alt="">
              </div>
            </div>
            <!-- <div class="section6">
              <el-popconfirm title="确定要删除吗？" @confirm="deleteGoods(item, goodsItem)">
                <div slot="reference">
                  <img src="../../../assets/images/delete-close.png" alt="">
                </div>
              </el-popconfirm>
            </div> -->
          </div>
        </div>
        <div class="bottom">
          <div class="flex-center">
            <div v-if="!isAllSelected" class="un-select" @click="allSelect()"></div>
            <img v-else src="../../../assets/images/shop-selected.png" @click="allSelect()" alt="">
            <div>全选</div>
          </div>
          <div class="flex-center right">
            <div>
              <div class="total">合计：<span>￥{{ total }}</span></div>
              <div>不含运费</div>
            </div>
            <div class="btn" :class="{'active': selectedArr.length > 0}" @click="bottomBtnClick()">
              {{ manageStatus == 1 ? '结算' : '删除' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import { shoppingCart, wanlshopCartStorage } from '@/api/shoppingMall'

export default {
  components: {
    crumbsVue,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '购物车',
        },
      ],
      manageStatus: 1,  //1非管理状态，显示“管理”，下方按钮显示“结算”  2管理状态，显示“取消管理”，下方按钮显示“删除”
      dataList: [],
      isAllSelected: false,
      selectedArr: [],
      total: 0,
    }
  },
  methods: {
    // 去店铺
    goShop(item) {
      this.$router.push({path: 'come-in-shop', query: {shop_id: item.shop_id}})
    },
    // 商品详情
    goGoodsDetail(goodsItem) {
      goodsItem.id = goodsItem.goods_id
      this.$router.push({path: 'product-detail', query: {type: 'product',item: JSON.stringify(goodsItem)}})
    },
    // 店铺选中/取消选中
    shopHandel(item) {
      item.checked = !item.checked

      this.selectedArr = this.selectedArr.filter(itemO=>{
        return itemO.shop_id != item.shop_id
      })
      item.goods.map(obj=>{
        obj.checked = item.checked
      })
      
      if(item.checked) {
        item.goods.map(obj=>{
          this.selectedArr.push(obj)
        })
      } else {
        this.isAllSelected = false
      }

      // 如果所有店铺都选中，底部全选按钮则选中
      let unSelectedShopCount = this.dataList.filter(obj=>{
        return obj.checked == false
      }).length
      this.isAllSelected = unSelectedShopCount == 0
      this.calTotal()
    },
    goodsHandel(item, goodsItem) {
      goodsItem.checked = !goodsItem.checked
      // 商品选中加入选中的数组中，未选中则在数组中删除
      if(goodsItem.checked) {
        this.selectedArr.push(goodsItem)
      } else {
        let arr = []
        for(let i = 0; i < this.selectedArr.length; i++) {
          if(this.selectedArr[i].goods_id == goodsItem.goods_id && this.selectedArr[i].index == goodsItem.index) {
          } else {
            arr.push(this.selectedArr[i])
          }
        }
        this.selectedArr = JSON.parse(JSON.stringify(arr))
        // this.selectedArr = this.selectedArr.filter(obj=>{
        //   return obj.goods_id != goodsItem.goods_id && obj.index == goodsItem.index
        // })
      }
      // 如果某个店铺的所有商品都选中，则店铺选中
      let unSelectedGoodCount = item.goods.filter(obj=>{
        return obj.checked == false
      }).length
      item.checked = unSelectedGoodCount == 0

      // 如果所有店铺都选中，底部全选按钮则选中
      let unSelectedShopCount = this.dataList.filter(obj=>{
        return obj.checked == false
      }).length
      this.isAllSelected = unSelectedShopCount == 0

      // 计算总金额
      this.calTotal()
    },
    // 商品数量加/减
    handelCount(goodsItem, type) {
      if(type == 'bcsub') {
        if(goodsItem.number == 1) return
        goodsItem.number -= 1
      } else {
        goodsItem.number += 1
      }
      goodsItem.sum = goodsItem.sku.price * goodsItem.number
      this.calTotal()

      let params = {
        goods_id: goodsItem.goods_id,
        number: goodsItem.number,
        sku_id: goodsItem.sku_id,
        sum: goodsItem.sku.price * goodsItem.number,
        type: type
      }
      this.updateCart(params)
    },
    allSelect() {
      this.selectedArr = []
      this.isAllSelected = !this.isAllSelected
      this.dataList.map(item=>{
        item.checked = this.isAllSelected
        item.goods.map(good=>{
          good.checked = this.isAllSelected
          if(this.isAllSelected) {
            this.selectedArr.push(good)
          }
        })
      })
      this.calTotal()
    },
    // 计算总金额
    calTotal() {
      this.total = this.selectedArr.reduce((sum, current) => sum + current.sku.price * current.number, 0);
    },
    deleteGoods(item, goodsItem) {
      item.goods = item.goods.filter(obj=>{
        return obj.id != goodsItem.id
      })
      if(item.goods.length == 0) {
        this.dataList = this.dataList.filter(obj=>{
          return obj.id != item.id
        })
      }
      this.selectedArr = this.selectedArr.filter(obj=>{
        return obj.id != goodsItem.id
      })
      this.calTotal()
    },
    bottomBtnClick() {
      if(this.selectedArr.length == 0) return
      if(this.manageStatus == 1) {
        let buyArr = []
        if (this.dataList.length) {
          this.dataList.map((item, index) => {
            if (item.goods.length) {
              item.goods.map((subItem, subIndex) => {
                if (subItem.checked) {
                  buyArr.push({
                    number: subItem.number,
                    goods_id: subItem.goods_id,
                    sku_id: subItem.sku.id
                  })
                }
              })
            }
          })
        }
        // 去结算
        this.$router.push({
          path: 'confirm-order', 
          query: { 
            navType: '订单结算',
            data: JSON.stringify(buyArr),
            source: 'cart',
            selectedArr: JSON.stringify(this.selectedArr)
          }
        })
      } else {
        // 删除
        let params = {
          data: this.selectedArr,
          type: 'del'
        }
        this.updateCart(params)
      }
    },
    getList() {
      let params = {
        cart: null,
      }

      shoppingCart(params).then(res=>{
        if(res.code == 1) {
          let data = res.data
          let map = {}, dest = [];
          for (let i = 0; i < data.length; i++) {
            let cart = data[i];
            cart.index = i
            cart.checked = false;
            if (!map[cart.shop_id]) {
              dest.push({
                shop_id: cart.shop_id,
                shop_name: cart.shop_name,
                goods: [cart],
                checked: false,
                choose: 0
              });
              map[cart.shop_id] = cart;
            } else {
              for (let j = 0; j < dest.length; j++) {
                let dj = dest[j];
                if (dj.shop_id == cart.shop_id) {
                  dj.goods.push(cart);
                  break;
                }
              }
            }
          }

          this.dataList = dest
        }
      })
    },
    updateCart(params) {
      wanlshopCartStorage(params).then(res=>{
        if(res.code == 1) {
          this.$message.success(res.msg)
          this.getList()
        }
      })
    }
  },
  created() {
    this.getList()
  },
  computed: {
    imageUrl: function(){
      return function (img) {
        if(img) {
          return this.$store.getters.imgeCDN + img
        }
        return ''
      }
    },
  }
}
</script>

<style lang="less" scoped>
.cart {
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .crumbs {
      width: 50%;
    }

    .manage-btn {
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      cursor: pointer;
    }
  }
  .view {
    margin-bottom: 20px;
    padding: 33px 30px 2px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    .shop-name{
      padding-bottom: 30px;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      display: flex;
      align-items: center;
      cursor: pointer;

      img{
        width: 20px;
        height: 20px;
        margin-right: 24px;
        cursor: pointer;
      }

      .shop-icon {
        margin-right: 10px;
      }
    }
    .goods {
      border-top: 1px solid #E9E9E9;
      padding:  20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .section1 {
        display: flex;
        align-items: center;
        .op-img {
          width: 20px;
          height: 20px;
          margin-right: 24px;
          cursor: pointer;
        }
        .goods-img {
          width: 60px;
          height: 60px;
        }
      }
      .section2 {
        font-weight: 500;
        font-size: 16px;
        color: #222222;
        width: 30%;
      }
      .section3 {
        font-size: 16px;
        color: #999999;
        width: 20%;
      }
      .section4 {
        font-size: 16px;
        font-weight: 600;
        color: #EB1001;
        span {
          font-size: 11px;
        }
      }
      .section5 {
        display: flex;
        align-items: center;
        .img-div {
          width: 30px;
          height: 30px;
          // background-color: red;
          margin-right: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            width: 14px;
            height: 3px;
          }
          .add-img{
            width: 14px;
            height: 14px;
            cursor: pointer;
          }
        }
        
        
        /deep/.el-input{
          margin-right: 11px;
          width: 64px;
          height: 33px;
          border-radius: 4px;
        }
        /deep/.el-input__inner{
          height: 33px;
          line-height: 33px;
          background: #F6F6F6;
          text-align: center;
        }
      }
      .section6 {
        img {
          width: 22px;
          height: 22px;
          cursor: pointer;
        }
      }
    }
  }

  .un-select {
    width: 20px;
    height: 20px;
    margin-right: 24px;
    background: #F3F3F3;
    border-radius: 10px;
    border: 1px solid #EAEAEA;
    cursor: pointer;
  }
  .bottom {
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    padding: 20px 30px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: #666666;
    .flex-center {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 24px;
        cursor: pointer;
      }
    }
    .right {
      .total {
        font-weight: 500;
        font-size: 22px;
        color: #333333;
        span {
          color: #FF1B1B;
          font-weight: 600;
        }
      }
      .btn {
        width: 112px;
        height: 40px;
        line-height: 40px;
        margin-left: 60px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #666666;
        background: #ECECEC;
        border-radius: 20px;
      }
      .active {
        background: #FF2626;
        color: white;
        cursor: pointer;
      }
    }
  }
}
</style>