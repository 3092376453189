import { render, staticRenderFns } from "./onlineResume.vue?vue&type=template&id=fba4fcda&scoped=true"
import script from "./onlineResume.vue?vue&type=script&lang=js"
export * from "./onlineResume.vue?vue&type=script&lang=js"
import style0 from "./onlineResume.vue?vue&type=style&index=0&id=fba4fcda&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fba4fcda",
  null
  
)

export default component.exports