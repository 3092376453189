/*
 * @Author: angel~dongsanling
 * @Date: 2024-07-25 12:28:18
 * @Description: 
 */
import shoppingMallLayout from '@/view/shoppingMall/shoppingMallLayout.vue'
import shoppingMallHome from '@/view/shoppingMall/home/index.vue'
import followedStore from '@/view/shoppingMall/my/myFollowStore.vue'
import myCollection from '@/view/shoppingMall/my/myCollection.vue'
import myPoints from '@/view/shoppingMall/my/myPoints.vue'
import refundHistory from '@/view/shoppingMall/my/refundHistory.vue'
import productDetail from '@/view/shoppingMall/product/productDetail.vue'
import pointsShop from '@/view/shoppingMall/pointsShop/index.vue'
import confirmOrder from '@/view/shoppingMall/pointsShop/confirmOrder.vue'
import refundDetail from '@/view/shoppingMall/my/refundDetail.vue'
import shopCart from '@/view/shoppingMall/shoppingCart/index.vue'
import cashier from '@/view/shoppingMall/shoppingCart/cashier.vue'
import myOrder from '@/view/shoppingMall/my/myOrder.vue'
import orderDetail from '@/view/shoppingMall/my/orderDetail.vue'
import comeInShop from '@/view/shoppingMall/comeInShop/comeInShop.vue'
import addressList from '@/view/shoppingMall/my/addressList.vue'
import editAddress from '@/view/shoppingMall/my/addAndEditAddress.vue'
import applyAfterSaleService from '@/view/shoppingMall/my/applyAfterSaleService.vue'
import editAfterSaleService from '@/view/shoppingMall/my/editAfterSaleService.vue'
import afterSaleService from '@/view/shoppingMall/my/afterSaleService.vue'
import coupons from '@/view/shoppingMall/home/coupons.vue'
import allProducts from '@/view/shoppingMall/product/allProducts.vue'
import Category from '@/view/shoppingMall/product/category.vue'
import CategoryDetails from '@/view/shoppingMall/product/categoryDetails.vue'
const shopRoutes = {
  path: '/shoppingMall',
  component: shoppingMallLayout,
  children: [
    {
      path: 'home',
      component: shoppingMallHome, //首页1
    },{
      path: 'followed-store',
      component: followedStore, //我的关注店铺1
    },{
      path: 'my-collection',
      component: myCollection, //我的收藏1
    },{
      path: 'my-points',
      component: myPoints, //我的积分
    },{
      path: 'refund-history',
      component: refundHistory, //退款历史1
    },{
      path: 'product-detail',
      component: productDetail, //商品详情1
    },{
      path: 'points-shop',
      component: pointsShop,  //积分商城
    },{
      path: 'confirm-order',
      component: confirmOrder,  //确认订单
    },{
      path: 'refund-detail',
      component: refundDetail, //退款详情1
    },{
      path: 'shop-cart',
      component: shopCart, //购物车1
    },{
      path: 'cashier',
      component: cashier, //收银台
    },{
      path: 'my-order',
      component: myOrder, //我的订单1
    },{
      path: 'order-detail',
      component: orderDetail, //订单详情1
    },{
      path: 'address',
      component: addressList, //收货地址1
    },{
      path: 'come-in-shop',
      component: comeInShop, //进店逛逛1
    },{
      path: 'edit-address',
      component: editAddress, //编辑/新增地址1
    },{
      path: 'apply-after-sale-service',
      component: applyAfterSaleService, //申请售后1
    },{
      path: 'edit-after-sale-service',
      component: editAfterSaleService, //修改售后申请1
    },{
      path: 'after-sale-service',
      component: afterSaleService, //退货/售后1
    },{
      path: "coupons",
      component: coupons, //红包卡券1
    },{
      path: 'all-products',
      component: allProducts, // 全部商品1
    },{
      path: 'category',
      component: Category, // 商品分类1
    },{
      path: 'category-details',
      component: CategoryDetails, // 类目详情1
    }
  ]
}
export default shopRoutes