<template>
  <div class='re-interview-detail'>
    <div class="page-header">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        面试详情
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="company-info">
          <div class="status">{{ detailInfo.status | interviewStatus }}<span>({{ date.date }})</span> </div>
          <div class="info">
            <img :src="avatarUrl"  alt="">
            <div class="detail">
              <div class="con-name">{{ detailInfo.real_name }}</div>
              <div class="person">
                <!-- /23岁 -->
                <div>{{detailInfo.work_year}}年工作经验/{{detailInfo.education_text}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="record-detail">
          <div>
            <span class="mr67">时间</span>
            <span class="color222">{{ date.fullDate }}</span>
          </div>
          <div>
            <span class="mr67">职位</span>
            <span class="color222">{{ detailInfo.name }}</span>  <span class="color-red">{{ detailInfo.salary_text }}</span> 
          </div>
        </div>
        <div class="warning-tip" v-if="detailInfo.status==5">
          <div class="txt">待处理提示！</div>
          <div>对方已参加面试，请根据对方表现，判断是否通过面试</div>
          <div class="btn-div">
            <div class="btn dafault" @click="dealReview(7)">未通过</div>
            <div class="btn confirm" @click="dealReview(6)">通过</div>
          </div>
        </div>
        <div class="bottom-status" v-if="detailInfo.status==8">
          <div class="btn-div">
            <div class="btn confirm cu" @click="dealReview(5)">已参加面试</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog 
    class="dialog" 
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="showDialog=false"
    title="提示">
      <div class="content">
        <div v-if="detailInfo.status==5">确认对方{{ dealReviewType == 6 ? '已通过' : '未通过' }}本次面试吗？</div>
        <div v-if="detailInfo.status==8">确认已经参加了面试吗？</div>
      </div>
      <div class="bottom">
        <span class="clear" @click="showDialog=false">取消</span>
        <span class="confirm" @click="confirm()">确定</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deliverInfo, updateReviewStatus } from '@/api/jobSeeker'

export default {
  data() {
    return {
      deliverId: null,
      detailInfo: {},
      showDialog: false,
      dealReviewType: 6
    }
  },
  methods: {
    back(){
      this.$router.back()
    },
    getDeliverInfo() {
      let params = {
        deliver_id: this.deliverId
      }
      deliverInfo(params).then(res=>{
        if(res.code == 1) {
          this.detailInfo = res.data
        }
      })
    },
    dealReview(type) {
      this.dealReviewType = type
      this.showDialog = true
    },
    confirm() {
      let params = {
        send_history_id: this.deliverId,
        status: this.dealReviewType
      }
      updateReviewStatus(params).then(res=>{
        if(res.code == 1) {
          this.getDeliverInfo()
          this.showDialog = false
        }
      })
    }
  },
  created() {
    this.deliverId = this.$route.query.id
    this.getDeliverInfo()
  },
  computed: {
    avatarUrl() {
      return this.$store.getters.imgeCDN + this.detailInfo.avatar
    },

    date() {
      var time = new Date(parseInt(this.detailInfo.interview_time) * 1000);
      var y = time.getFullYear();
      var m = time.getMonth()+1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();

      if(m < 10){
        m = "0" + m;
      }
      if(d < 10){
        d = "0" + d;
      }
      if(h < 10){
        h = "0" + h;
      }
      if(mm < 10){
        mm = "0" + mm;
      }
      if(s < 10){
        s = "0" + s;
      }
      if(s == 60){
        s = "00"
      }
      return {
        fullDate: `${y}-${m}-${d} ${h}:${mm}`,
        date: `${m}月${d}日`,
        time: `${h}:${mm}`
      }
    }
    // certificateImages() {
    //   let imageArr = []
    //   if(this.item.certificate_images) {
    //     imageArr = this.item.certificate_images.split(',')
    //     imageArr = imageArr.map(item=>{
    //       return this.$store.getters.imgeCDN + item
    //     })
    //   }
    //   return imageArr
    // }
  }
}
</script>

<style lang="less" scoped>
.re-interview-detail{
  .company-info{
    width: 100%;
    height: 276px;
    margin: 20px 0;
    background: #FFFFFF;
    border-radius: 13px;

    .status{
      width: 100%;
      height: 72px;
      line-height: 72px;
      background: #F9FBFF;
      text-align: center;
      color: #2676F7;
      font-weight: 600;
      font-size: 23px;

      span{
        font-weight: 500;
      }
    }

    .info{
      padding: 40px 0 0  67px;
      display: flex;
      img{
        width: 123px;
        height: 123px;
        background: #FFFFFF;
        border-radius: 62px;
        margin-right: 47px;
      }

      .detail{
        margin-top: 14px;
        .con-name{
          font-weight: 500;
          font-size: 30px;
          color: #222222;
          margin-bottom: 25px
        }
        .person{
          font-weight: 400;
          font-size: 23px;
          color: #585858;
        }
      }
    }
  }

  .record-detail{
    width: 100%;
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 13px;
    padding: 40px 67px 13px;

    div{
      margin-bottom: 27px;
    }

    span{
      font-weight: 400;
      font-size: 23px;
      color: #999999;
    }

    .mr67{
      margin-right: 67px;
    }
    .color222{
      color: #222;
      margin-right: 33px;
    }

    .color-red{
      color: #E11E34;
    }
  }

  .warning-tip {
    margin-bottom: 20px;
    background: #fdf6ec;
    border-radius: 13px;
    padding: 20px 20px 20px 67px;
    color: #f9ae3d;
    font-size: 20px;
    .txt {
      font-weight: 600;
    }
    .btn-div {
      margin-top: 20px;
      display: flex;
      align-items: center;

      .btn {
        width: 200px;
        height: 50px;
        line-height: 50px;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
      }
      .dafault {
        margin-right: 20px;
        background-color: #fff;
        border: 1px solid #ebedf0;
        color: #222;
      }
      .confirm {
        background: #2676F7;
        border: 1px solid #2676F7;
        color: #fff;
      }
    }
  }

  .bottom-status {
    margin-bottom: 20px;
    background: #fff;
    border-radius: 13px;
    padding: 20px 20px 20px 67px;
    font-size: 20px;
    .btn-div {
      margin-top: 20px;
      display: flex;
      align-items: center;

      .btn {
        width: 200px;
        height: 50px;
        line-height: 50px;
        border-radius: 5px;
        text-align: center;
      }
      .refuse {
        margin-right: 20px;
        background-color: #fff;
        border: 1px solid #ebedf0;
        color: #222;
      }

      .confirm {
        background: #2676F7;
        border: 1px solid #2676F7;
        color: #fff;
      }
    }

    .cu {
      cursor: pointer;
    }
  }

  .dialog{
    .content {
      display: flex;
      font-size: 17px;
      color: #585858;
      padding-bottom: 50px;
    }

    .bottom {
      margin-top: 30px;
      margin-right: 30px;
      display: flex;
      justify-content: right;
      span {
        display: inline-block;
        height: 27px;
        line-height: 27px;
        padding: 0 17px;
        text-align: center;
        border-radius: 7px 7px 7px 7px;
        cursor: pointer;
      }
      .clear {
        margin-right: 10px;
        background: rgba(38,118,247,0.08);
        color: #2676F7;
      }
      .confirm {
        background: #2676F7;
        color: white;
      }
    }
  }
  /deep/.el-dialog {
    width: 520px;
    border-radius: 7px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
  }
}
</style>