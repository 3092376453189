<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-24 19:19:20
 * @Description: 商品卡片
-->
<template>
  <div class="product-card">
    <div class="product-card-item cursor" 
      v-for="(item,index) in sellArr" 
      :key="index"
      @click="productDetailHandle(item)">
      <div class="product-card-item-img">
        <img :src="item.img" alt="">
      </div>
      <div class="product-card-item-content">
        <div class="product-card-item-content-title">
          {{ item.title }}
        </div>
        <div class="product-card-item-content-main flex space-between">
          <div class="product-card-item-content-main-left">
            ¥{{ item.price }}
          </div>
          <div class="product-card-item-content-main-right">
            {{ item.comment }}评价
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    sellArr: {
      type: Array,
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    /**
     * @Author: angel~dongsanling
     * @description: 商品详情
     * @param {*} data 当前商品数据
     */    
    productDetailHandle (data) {
      this.$router.push({
        path: '/shoppingMall/product-detail',
        query: {
          type: 'product',
          item: JSON.stringify(data)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.product-card{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // justify-content: space-between !important;
  .product-card-item{
    width: 285px;
    height: 326px;
    position: relative;
    margin-right: 20px;
    margin-bottom: 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(102,102,102,0.2);
    border-radius: 8px;
    .product-card-item-img{
      width: 285px;
      height: 240px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .product-card-item-content{
      height: 86px;
      padding: 0px 15px;
      background: #fff;
      border-radius: 0px 0px 8px 8px;
      .product-card-item-content-title{
        height: 50px;
        font-size: 18px;
        color: #222222;
        line-height: 24px;
        text-align: left;
        display: flex;
        align-items: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .product-card-item-content-main{
        height: 36px;
        .product-card-item-content-main-left{
          color: #FF0909;
          font-size: 16px;
          font-weight: 400;
        }
        .product-card-item-content-main-right{
          color: #999;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  .product-card-item:nth-child(4n){
    margin-right: 0px !important;
  }
  .product-card-item::before{
    content: '';
    width: 108px;
    height: 30px;
    background: url(../../assets/images/card-icon.png) no-repeat center center;
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
        
</style>