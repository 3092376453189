<template>
  <div class='edit-after-sale-service'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <div class="content">
          <div class="goods">
            <img :src="imageUrl" alt="">
            <div>
              <div class="goods-name">{{refund.goods.title}}</div>
              <div class="size">规格：{{refund.goods.difference}}</div>
            </div>
          </div>
          <el-row class="row">
            <el-col :span="3" class="title">物流状态</el-col>
            <el-col :span="7" class="to-select">
              <el-select v-model="refund.expressType" placeholder="是否收到货">
                <el-option
                  v-for="item in logisticsStatusOp"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="3" class="title">退款类型</el-col>
            <el-col :span="7" class="to-select">
              <el-select v-model="refund.type" placeholder="选择退款类型">
                <el-option
                  v-for="item in refundTypeOp"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="row boder mb20">
            <el-col :span="3" class="title">退款原因</el-col>
            <el-col :span="7" class="to-select">
              <el-select v-model="refund.reason" placeholder="选择退款原因">
                <el-option
                  v-for="item in refundReasonOp"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="row boder mb20">
            <el-col :span="5" class="title">退款金额</el-col>
            <el-col :span="5" class="to-select">
              <el-input :placeholder="amount.info" v-model="refund.price" oninput="value = value.replace(/^\D*([0-9]\d*\.?\d{0,4})?.*$/,'$1')" :disabled="amount.total == 0"></el-input>
            </el-col>
          </el-row>
          <div class="upload-title mb20">上传凭证</div>
          <el-upload
          class="boder mb20"
          :action="action"
          list-type="picture-card"
          :file-list="imgs"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :on-success="uploadSuccess"
          :limit="9"
          :on-exceed="imgExceed">
          <i class="el-icon-plus"></i>
          </el-upload>
          <el-row class="row mb20">
            <el-col :span="5" class="title">退款理由</el-col>
            <el-col :span="5" class="to-select">
              <el-input placeholder="选填" v-model="refund.refund_content"></el-input>
            </el-col>
          </el-row>
          <div class="bottom-btn">
            <div @click="confirm()">确定</div>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import { refundDetail, editRefund } from '@/api/shoppingMall'

export default {
  components: {
    crumbsVue,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '申请售后',
        },
      ],
      action: 'https://zhp.digginggoldroad.com/api/common/upload',
      logisticsStatusOp: [{
        value: '0',
        label: '未收到货'
      }, {
        value: '1',
        label: '已收到货'
      }],
      refundTypeOp: [
        {
          value: '0',
          label: '我要退款(无需退货)'
        }, {
          value: '1',
          label: '退货退款'
        }
      ],
      refundReasonOp: [
        {
          value: '0',
          label: '不喜欢'
        }, {
          value: '1',
          label: '空包裹'
        }, {
          value: '2',
          label: '一直未送达'
        }, {
          value: '3',
          label: '颜色/尺码不符'
        }, {
          value: '4',
          label: '质量问题'
        }, {
          value: '5',
          label: '少件漏发'
        }, {
          value: '6',
          label: '假冒品牌'
        }
      ],
      dialogImageUrl: '',
      dialogVisible: false,
      imgs: [],
      // 退款
			refund:{
				expressType: -1,
				type: -1,
				reason: -1,
				images: [],
				goods: {},
				freight_type: 0,
			},
			// 价格
			amount:{
				total: 0,
				freight: 0,
				info: ''
			}
    }
  },
  methods: {
    init(id) {
      refundDetail({id: id}).then(res=>{
        if(res.code == 1) {
          this.refund = res.data;
          const imgs = this.refund.images
          for (let i = 0; i < imgs.length; i++) {
            this.imgs.push({ url: imgs[i]})
          }
					var price = res.data.goods.price * res.data.goods.number;
					var freight_price = parseInt(res.data.goods.freight_price);
					this.amount.freight = freight_price;
					//计算运费价格,当只有一个商品或 运费策略累加运费可全退
					if(res.data.goods_number == 1 || res.data.freight_type == 2){
						this.amount.info = `最多退款${price + freight_price}元，包含运费${freight_price}元`;
						this.amount.total = price + freight_price;
					} else {
						this.amount.info = `最多退${price}元，运费策略不含运费${freight_price}元`;
						this.amount.total = price;
					}
        }
      })
    },
    handleRemove(file, fileList) {
      const newArr = this.imgs.filter(item => {
        return item.url != file.url
      })
      this.imgs = newArr
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    imgExceed (files, fileList) {
      this.$message.warning(`最多上传9张图片`)
    },
    uploadSuccess(response, file, fileList) {
      // this.refund.images.push(response.data.fullurl);
      this.imgs.push({ url: response.data.fullurl})
    },
    confirm() {
      if (this.refund.price <= 0) {
				this.$message.warning('价格不能为空');
				return false;
			}
			if (this.refund.price > this.amount.total) {
				this.$message.warning('退款不能超过￥'+this.amount.total+'元');
				return false;
			}
      const imageArr = this.imgs.map(obj => obj.url);
      this.refund.images = imageArr

      let params = {
        id: this.refund.id,
        expressType: this.refund.expressType,
        type: this.refund.type,
        reason: this.refund.reason,
        price: this.refund.price,
        images: this.refund.images,
        refund_content: this.refund.refund_content
      }
      editRefund(params).then(res=>{
        if(res.code == 1) {
          this.$router.push({path: 'refund-detail', query: { id : res.data}})
        }
      })
    }
  },
  created() {
    this.init(JSON.parse(this.$route.query.id))
  },
  computed: {
    imageUrl() {
      if(this.refund.goods.image) {
        return this.$store.getters.imgeCDN + this.refund.goods.image
      }
      return ''
    }
  }
}
</script>

<style lang="less" scoped>
.edit-after-sale-service {
  .content {
    margin-bottom: 50px;
    padding: 30px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F1F1F1;

    .goods {
      padding: 30px;
      margin-bottom: 30px;
      background: #F5F5F5;
      border-radius: 2px;
      border: 1px solid #F1F1F1;
      display: flex;
      align-items: center;

      img {
        width: 60px;
        height: 60px;
        margin-right: 30px;
      }

      .goods-name {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 18px;
        color: #222222;
      }

      .size {
        font-size: 16px;
        color: #999999;
      }
    }

    .row {
      display: flex;
      align-items: center;

      .title {
        font-size: 16px;
        color: #585858;
      }

      .to-select {
        font-size: 16px;
        color: #999999;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        img{
          width: 20px;
          height: 20px;
        }
      }
    }

    .boder {
      padding-bottom: 20px;
      border-bottom: 1px solid #EEEEEE;
    }

    .upload-title {
      font-size: 18px;
      color: #222222;
    }

    .bottom-btn {
      display: flex;
      justify-content: flex-end;

      div{
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        font-size: 16px;
        color: #fff;
        background: #2676F7;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    /deep/.el-input__inner {
      border: none;
      font-size: 16px;
      color: #999999;
    }
    /deep/.el-upload--picture-card{
      width: 120px;
      height: 120px;
      line-height: 120px;
    }
    /deep/.el-upload-list--picture-card .el-upload-list__item{
      width: 120px;
      height: 120px;
    }
  }
}
</style>