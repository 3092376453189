<template>
  <div class='withdraw-record'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        提现记录
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="tab mb20">
          <div class="tab-item" :class="{ 'active-tab': activeIndex == item.index }" v-for="(item, index) in tabs" :key="index" @click="handleTabItem(item)">{{ item.title }}
          </div>
        </div>
        <div class="data-list mb20">
          <incomeItem v-for="(item, index) in dataList" :key="index" :item="item" :type="2"></incomeItem>
        </div>
      </div>
    </div>
    <selectTimeDialog v-if="isShowCustomTimeDialog" @closed="isShowCustomTimeDialog=false" @confirm="selectedTime"></selectTimeDialog>
  </div>
</template>

<script>
import incomeItem from '@/components/incomeItem.vue';
import selectTimeDialog from '@/components/selectTimeDialog.vue';
import { withdrawList } from '@/api/jobSeeker'

export default {
  components: {
    incomeItem,
    selectTimeDialog,
  },
  data() {
    return {
      activeIndex: 1,
      params: {
        page: 1,
        limit: 100,
        search_start: '',
        search_end: ''
      },
      tabs: [
        {
          title: '昨天',
          index: 1
        }, {
          title: '近7天',
          index: 2
        }, {
          title: '近一个月',
          index: 3
        }, {
          title: '自定义时间',
          index: 4
        }
      ],
      dataList: [],
      isShowCustomTimeDialog: false,
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    handleTabItem(item) {
      this.activeIndex = item.index
      if(item.index == 4) {
        this.isShowCustomTimeDialog = true
      } else {
        this.getWithdrawList()
      }
    },
    calTime() {
      if(this.activeIndex == 1) {
        this.params.search_start = this.getDay(-1) + '+00:00:00'
        this.params.search_end = this.getDay(-1) + '+23:59:59'
      } else if(this.activeIndex == 2) {
        this.params.search_start = this.getDay(-7) + '+00:00:00'
        this.params.search_end = this.getDay(0) + '+23:59:59'
      } else if(this.activeIndex == 3) {
        this.params.search_start = this.getDay(-30) + '+00:00:00'
        this.params.search_end = this.getDay(0) + '+23:59:59'
      } else { }
    },
    getDay(day) {
      let today = new Date()
      let targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds)
      let y = today.getFullYear()
      let m = this.padNumber(today.getMonth() + 1, 2)
      let d = this.padNumber(today.getDate(), 2)
      return `${y}-${m}-${d}`
    },
    padNumber(num, length) {
      return String(num).padStart(length, '0');
    },
    filterTimeStampToString(timeStamp) {
      var time = new Date(timeStamp);
      var y = time.getFullYear();
      var m = this.padNumber(time.getMonth() + 1, 2);
      var d = this.padNumber(time.getDate(), 2);
      var h = this.padNumber(time.getHours(), 2);
      var mm = this.padNumber(time.getMinutes(), 2);
      var s = this.padNumber(time.getSeconds(), 2);
      return `${y}-${m}-${d}+${h}:${mm}:${s}`
    },
    selectedTime(time) {
      this.params.search_start = this.filterTimeStampToString(time[0])// + '+00:00:00'
      this.params.search_end = this.filterTimeStampToString(time[1])// + '+23:59:59'
      this.isShowCustomTimeDialog = false
      this.getWithdrawList()
    },
    getWithdrawList() {
      this.calTime()
      withdrawList(this.params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data
        }
      })
    }
  },
  created() {
    this.getWithdrawList()
  }
}
</script>

<style lang="less" scoped>
.withdraw-record{
  .tab{
    width: 100%;
    height: 92px;
    background: #FFFFFF;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 58px;
    margin-bottom: 20px;

    .tab-item{
      width: 167px;
      height: 45px;
      line-height: 45px;
      font-size: 20px;
      color: #585858;
      text-align: center;
      background: #F7F7F7;
      border-radius: 7px;
      cursor: pointer;
    }

    .active-tab{
      background: rgba(38,118,247,0.08);
      border-radius: 7px 7px 7px 7px;
      color: #2676F7;
    }

  }

  .data-list{
    padding: 40px;
    background: white;
  }
}

</style>