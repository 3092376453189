<template>
  <div class='message-detail'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        通知详情
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="content">
          <div>{{ detail.info }}</div>
          <!-- <img src="../../../assets/images/av-test.png" alt=""> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { noticeDetail } from '@/api/jobSeeker'

export default {
  data() {
    return {
      id: 0,
      detail: {}
    }
  },
  methods: {
    back(){
      this.$router.back()
    },
    getNoticeDetail() {
      noticeDetail({id: this.id}).then(res=>{
        if(res.code == 1) {
          this.detail = res.data
        }
      })
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getNoticeDetail()
  }
}
</script>

<style lang="less" scoped>
.message-detail {
  .content {
    width: 100%;
    padding: 40px;
    margin-bottom: 30px;
    font-size: 23px;
    color: #222222;
    background: #FFFFFF;
    border-radius: 13px 13px 13px 13px;

    img{
      width: 100%;
      margin-top: 33px;
      height: 235px;
      border-radius: 13px 13px 13px 13px;
    }
  }
}
</style>