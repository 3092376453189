export function msgMap() {
  return {
    type: {
      "我想和您交换联系方式，您是否同意？": "mobile",
      "我想和您交换微信，您是否同意？": "wechat",
      "我想给您发一份简历，您是否同意？": "resume",
      "我想要一份您的简历，您是否同意？": "ask_resume",
      "已经向您发送我的简历，请注意查收": "resume_preview",
      "邀请您参加我公司的面试，您是否同意？面试ID=": "interview",
    },
    send: {// 发送
      mobile: '我想和您交换联系方式，您是否同意？',
      wechat: '我想和您交换微信，您是否同意？',
      resume: '我想给您发一份简历，您是否同意？',
      ask_resume: '我想要一份您的简历，您是否同意？',
      interview: '邀请您参加我公司的面试，您是否同意？面试ID=',
    },
    reply_to: {// 回复,消息接收方，同意处理方
      mobile: {
        agree: '我的联系方式为：',
        notAgree: '已拒绝',
      },
      wechat: {
        agree: '我的微信号为：',
        notAgree: '已拒绝',
      },
      resume: {
        agree: '已同意',
        notAgree: '已拒绝',
      },
      ask_resume: {
        agree: '已同意',
        notAgree: '已拒绝',
      },
      interview: {
        agree: '已同意',
        notAgree: '已拒绝',
      }
    },
    reply_from: {// 回复,消息发送方，对方同意后回复内容
      mobile: '我的联系方式为：',
      wechat: '我的微信号为：',
      resume: '已经向您发送我的简历，请注意查收',
      ask_resume: '已经向您发送我的简历，请注意查收',
      interview: '已同意您的面试邀请'
    },
    show: {// 展示
      image: {
        mobile: '/static/index/im1.png',
        wechat: '/static/index/im2.png',
        resume: '/static/index/im3.png',
        resume_preview: '/static/index/im3.png',
        ask_resume: '/static/index/im3.png',
        interview: '/static/index/im5.png',
      },
      mySend: {
        mobile: '我想和您交换联系方式，您是否同意？',
        wechat: '我想和您交换微信，您是否同意？',
        resume: '我想给您发一份简历，您是否同意？',
        ask_resume: '我想要一份您的简历，您是否同意？',
        resume_preview: '已经向您发送我的简历，请注意查收',
        interview: '面试邀请',
      },
      toMe: {
        mobile: "对方想要与您交换联系方式，是否同意？",
        wechat: "对方想要与您交换微信，是否同意？",
        resume: "对方想给您发一份简历，是否同意？",
        ask_resume: "对方想要您的简历，是否同意？",
        resume_preview: "已经向您发送我的简历，请注意查收",
        interview: "恭喜您收到一条面试邀请，可查看详情回复",
      }
    }
  }
}

export function getMobile(str) {
  return str.substr(str.length - 11)
}