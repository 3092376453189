<template>
  <div class='refund-detail'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <div class="view padding22-30 mb20 refund-status">
          <div class="title">{{getStateText(refundData.state)}}</div>
          <div class="time">{{refundData.statetime}}</div>
        </div>
        <div class="view padding-30-21 mb20 status-detail">
          <div class="top">
            <!-- 退款状态:0=申请退款,1=卖家同意,2=卖家拒绝,3=申请平台介入,4=成功退款,5=退款已关闭,6=已提交退货,7=第三方退款中 -->
           
            <div class="title mb12">{{getStateInfo(refundData.state)}}</div>

            <div class="sub-title" v-if="refundData.state == 0">
              <div>
                <div>卖家同意或超时未处理，系统将自动确认</div>
                <div>如果退款被拒绝，您可以修改申请重新发起</div>
              </div>
              <div class="right">
                <div class="close" @click="closeRefund()">关闭退款</div>
                <div class="edit" @click="editApply()">修改申请</div>
              </div>
            </div>

            <div v-if="refundData.state == 1">
              <div class="address-info mb20"  >
                <div>收货地址：{{refundData.shopConfig.returnAddr}}, {{refundData.shopConfig.returnName}} {{refundData.shopConfig.returnPhoneNum}}</div>
                <div class="full-address">{{refundData.shopConfig.returnAddr}}</div>
              </div>
              <div class="sub-title">请勿使用平邮或到付，以免商家无法收到退货</div>
              <div class="sub-title">请填写真实快递信息，如超时则关闭退款</div>
              <div class="track-num">
                <el-row class="num-row">
                  <el-col :span="4">快递单号</el-col>
                  <el-col :span="8">
                    <el-input placeholder="请填写快递单号" v-model="returnData.express_no"></el-input>
                  </el-col>
                </el-row>
              </div>
              <div class="track-company">
                <div class="comp-left">
                  <el-row class="company-row">
                    <el-col :span="5">快递公司</el-col>
                    <el-col :span="5" class="select">
                      <el-select v-model="returnData.express_name" placeholder="请选择">
                        <el-option
                          v-for="item in refundData.kuaidi"
                          :key="item.name"
                          :label="item.name"
                          :value="item.name">
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </div>
                <div class="comp-right" @click="confirmReturnGoods()">确认退货</div>
              </div>
            </div>

            <div class="sub-title" v-if="refundData.state == 2">
              <div>拒绝理由: {{refundData.refuse_content}}</div>
              <div class="right">
                <div class="edit" @click="editApply()">修改申请</div>
              </div>
            </div>
            <div  v-if="refundData.state == 3">
              <div class="sub-title">客服正在审核退款详情及退款历史记录</div>
              <div class="sub-title">大概1-3个工作日做出答复，请耐心等待</div>
            </div>
            <div  v-if="refundData.state == 6">
              <div>退货物流: {{refundData.express_name}}({{refundData.express_no}}) </div>
              <div class="sub-title">如果退款被拒绝，您可以修改申请重新发起</div>
              <div class="sub-title">卖家超时未处理，系统将自动确认</div>
            </div>
          </div>
          <div class="refund-history">
            <div @click="goHistory()">
              <el-row class="history-row">
                <el-col :span="4">退款历史</el-col>
                <el-col :span="4" class="select">
                  <img src="../../../assets/images/arrow-right.png"  alt="">
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="view padding-30 mb20 refund-detail-view">
          <div class="title mb20">退款详情</div>
          <div class="goods">
            <div class="name">
              <img :src="imageUrl(refundData.goods.image)" alt="">
              <div>{{refundData.goods.title}}</div>
            </div>
            <div class="standards">规格：{{refundData.goods.difference}}</div>
            <div class="count">x{{ refundData.goods.number }}</div>
            <div class="amount">
              <span>￥</span><span>{{refundData.goods.price}}</span>
            </div>
          </div>
          <el-row class="row">
            <el-col :span="2" class="font-18-58">退款类型</el-col>
            <el-col :span="22" class="font-18-222 ">{{refundData.type_text}}</el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="2" class="font-18-58">退款原因</el-col>
            <el-col :span="22" class="font-18-222 ">{{refundData.reason_text}}</el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="2" class="font-18-58">退款金额</el-col>
            <el-col :span="22" class="font-18-222 ">¥{{refundData.price}}</el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="2" class="font-18-58">物流状态</el-col>
            <el-col :span="22" class="font-18-222 ">{{refundData.expressType_text}}</el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="2" class="font-18-58">退款时间</el-col>
            <el-col :span="22" class="font-18-222 ">{{refundData.createtime_text}}</el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import { refundDetail, toExpress, closeRefund } from '@/api/shoppingMall'

export default {
  components: {
    crumbsVue,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '退款详情',
        },
      ],
      refundData: {
				statetime: '',
				goods:{
					
				}
			},
      returnData:{
				id: 0,
				express_no: '',
				express_name: ''
			},
    }
  },
  methods: {
    // 关闭退款
    closeRefund() {
      closeRefund({id: this.refundData.id}).then(res=>{
        if(res.code == 1) {
          this.getDetail(res.data)
        }
      })
    },
    // 修改申请
    editApply() {
      this.$router.push({path: 'edit-after-sale-service', query: { id: this.refundData.id }})
    },
    // 确认退货，提交物流
    confirmReturnGoods() {
      if (!this.returnData.express_no) {
				this.$message.warning('运单号不能为空');
				return false;
			}
			if (!this.returnData.express_name) {
				this.$message.warning('请选择快递公司');
				return false;
			}
      toExpress(this.returnData).then(res=>{
        if(res.code == 1) {
          this.getDetail(res.data)
        }
      })
    },
    goHistory() {
      this.$router.push({path: 'refund-history', query: {id: this.refundData.id}})
    },
    getDetail(id) {
      refundDetail({id: id}).then(res=>{
        if(res.code == 1) {
          this.refundData = res.data;
					this.returnData.id = res.data.id;
					// 时间差的毫秒数 
					if(res.data.state == 0){
						var diff = (res.data.createtime + res.data.config.autoagree * 86400) - Date.parse(new Date()) / 1000;
						var days = Math.floor(diff / 86400);
						var hours = Math.floor(diff % 86400 / 3600); 
						var minutes = Math.floor(diff % 86400 % 3600 / 60);
						if (minutes > 0) {
							this.refundData.statetime = '还剩' +minutes+ '分';
						}
						if (hours > 0) {
							this.refundData.statetime = '还剩' +hours+ '天' +minutes+ '分';
						}
						if (days > 0) {
							this.refundData.statetime = '还剩' +days+ '天' +hours+ '小时' +minutes+ '分';
						}
					}else if(res.data.state == 1){
						var diff = (res.data.agreetime + res.data.config.returntime * 86400) - Date.parse(new Date()) / 1000;
						var days = Math.floor(diff / 86400);
						var hours = Math.floor(diff % 86400 / 3600); 
						var minutes = Math.floor(diff % 86400 % 3600 / 60);
						if (minutes > 0) {
							this.refundData.statetime = '还剩' +minutes+ '分';
						}
						if (hours > 0) {
							this.refundData.statetime = '还剩' +hours+ '天' +minutes+ '分';
						}
						if (days > 0) {
							this.refundData.statetime = '还剩' +days+ '天' +hours+ '小时' +minutes+ '分';
						}
					}else if(res.data.state == 2){
						this.refundData.statetime = res.data.rejecttime_text;
					}else if(res.data.state == 6){
						var diff = (res.data.returntime + res.data.config.receivingtime * 86400) - Date.parse(new Date()) / 1000;
						var days = Math.floor(diff / 86400);
						var hours = Math.floor(diff % 86400 / 3600); 
						var minutes = Math.floor(diff % 86400 % 3600 / 60);
						if (minutes > 0) {
							this.refundData.statetime = '还剩' +minutes+ '分';
						}
						if (hours > 0) {
							this.refundData.statetime = '还剩' +hours+ '天' +minutes+ '分';
						}
						if (days > 0) {
							this.refundData.statetime = '还剩' +days+ '天' +hours+ '小时' +minutes+ '分';
						}
					}else if(res.data.state == 3){
						this.refundData.statetime = '等待平台处理';
					}else if(res.data.state == 4){
						this.refundData.statetime = res.data.completetime_text;
					}else if(res.data.state == 5){	
						this.refundData.statetime = res.data.closingtime_text;
					// 1.1.6升级
					}else if(res.data.state == 7){
						this.refundData.statetime = '预计24小时内原路返还';
					}else if(res.data.state == 8){
						this.refundData.statetime = '请联系平台客服处理';
					}
        }
      })
    },
    getStateText(state){
			return ["等待卖家同意", "等待买家退货", "卖家拒绝退款", "平台介入", "退款完成", "退款关闭", "等待卖家收取退货", "第三方支付退款中", "退款失败"][state];
		},
		getStateInfo(state){
			return [
				"您已成功发起退款，等待卖家同意", 
				"您发起的退款卖家已同意，请退货", 
				"您可以修改退货申请再次发起", 
				"您已申请平台介入，请等待平台对此判定", 
				"退款完成", 
        "您已关闭本次退款申请", 
				"如果商家确认收到货物，并核查没有问题，将退款给您", 
				"因第三方快捷支付订单，退款将在24小时内原路返还", 
				"快捷支付退款失败，请联系商家", 
			][state];
		},
  },
  created() {
    this.getDetail(this.$route.query.id)
  },
  computed: {
    imageUrl: function(){
      return function (img) {
        if(img) {
          return this.$store.getters.imgeCDN + img
        }
        return ''
      }
    },
  }
}
</script>

<style lang="less" scoped>
.refund-detail {
  .view {
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F3F3F3;
  }
  .padding22-30 {
    padding: 22px 30px;
  }
  .padding-30-21 {
    padding: 30px 30px 21px;
  }
  .padding-30 {
    padding: 30px;
  }

  .refund-status {
    .title {
      font-size: 24px;
      color: #333333;
      margin-bottom: 8px;
      font-weight: 600;
    }
    .time {
      font-size: 18px;
      color: #999999;
    }
  }

  .status-detail {
    .top {
      padding-bottom: 20px;
      border-bottom: 1px solid #EEEEEE;
      .title{
        font-size: 16px;
        color: #333333;
      }
      .mb12{
        margin-bottom: 12px;
      }
      .address-info {
        font-size: 16px;
        color: #222222;
        .full-address{
          margin-top: 9px;
          font-size: 16px;
          color: #999999;
        }
      }
      .sub-title {
        font-size: 14px;
        color: #666666;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right {
          display: flex;
          align-items: center;

          .close {
            height: 40px;
            line-height: 40px;
            margin-right: 20px;
            padding: 0 24px;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            color: #666666;
            background: #fff;
            border-radius: 20px;
            border: 1px solid #E3E3E3;
            cursor: pointer;
          }
          .edit {
            height: 40px;
            line-height: 40px;
            padding: 0 24px;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            background: #2676F7;
            border-radius: 20px;
            border: 1px solid #2676F7;
            cursor: pointer;
          }
        }
      }
    }

    .view-logistics {
      margin-top: 20px;

      .font-16-58 {
        font-size: 16px;
        color: #585858;
      }
      .font-16-999 {
        font-size: 16px;
        color: #999;
      }
      .flex{
        display: flex;
        align-items: center;
        .more-img{
          width: 20px;
          height: 20px;
        }
      }
      .cu {
        cursor: pointer;
      }
    }

    .track-num {
      padding: 20px 0;
      border-bottom: 1px solid #EEEEEE;
      font-size: 16px;
      color: #585858;
      .num-row {
        display: flex;
        align-items: center;
      }
    }

    .track-company {
      padding: 20px 0;
      border-bottom: 1px solid #EEEEEE;
      font-size: 16px;
      color: #585858;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .comp-left {
        width: 80%;
        .company-row {
          display: flex;
          align-items: center;

          .select {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      .comp-right {
        height: 40px;
        line-height: 40px;
        padding: 0 24px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        background: #2676F7;
        border-radius: 20px;
        border: 1px solid #2676F7;
        cursor: pointer;
      }
      
    }
    .refund-history {
      padding: 20px 0;
      border-bottom: 1px solid #EEEEEE;
      font-size: 16px;
      color: #585858;
      .history-row {
        display: flex;
        align-items: center;
        .select {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          
          img {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }
      }
    }
    
  }

  .refund-detail-view {
    .title {
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      padding-bottom: 28px;
      border-bottom: 1px solid #E9E9E9;
    }

    .goods {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #E9E9E9;
      .name{
        font-weight: 500;
        font-size: 16px;
        color: #222222;
        display: flex;
        align-items: center;
        img {
          width: 60px;
          height: 60px;
          margin-right: 30px;
        }
      }

      .standards {
        font-size: 16px;
        color: #999999;
      }
      .count {
        font-weight: 500;
        font-size: 16px;
        color: #222222;
      }
      .amount{
        font-weight: 500;
        color: #EB1001;
        span:nth-child(1) {
          font-size: 11px;
        }
        span:nth-child(2) {
          font-size: 16px;
        }
      }
    }

    .row{
      margin-top: 20px;
      display: flex;
      align-items: center;
      .font-18-58 {
        font-size: 18px;
        color: #585858;;
      }
      .font-18-222 {
        font-size: 18px;
        color: #222;
      }
    }
  }

  /deep/.el-input__inner{
    font-size: 16px;
    border: none;
  }
}
</style>