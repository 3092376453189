<template>
  <div class='apply-withdraw'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        实名认证
        <div class="btn-save" @click="save()">保存</div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="content">
          <div class="center">
            <el-row class="row">
              <el-col :span="6" class="txt-right">姓名</el-col>
              <el-col :span="14">
                <el-input placeholder="请输入姓名" v-model="params.real_name"></el-input>
              </el-col>
              <el-col :span="4"></el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="6" class="txt-right">身份证号</el-col>
              <el-col :span="14">
                <el-input placeholder="请输入身份证号" v-model="params.IDcard"></el-input>
              </el-col>
              <el-col :span="4"></el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="6" class="txt-right">手机号</el-col>
              <el-col :span="14">
                <el-input placeholder="请输入手机号" v-model="params.mobile" :disabled="true">
                  <template slot="suffix">
                    <button class="phoneCode" :disabled="countdown > 0" @click="sendCode">
                      {{ countdown > 0 ? `${countdown}秒后获取` : '获取验证码' }}
                    </button>
                  </template>
                </el-input>
              </el-col>
              <el-col :span="4"></el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="6" class="txt-right">验证码</el-col>
              <el-col :span="14">
                <el-input placeholder="请输入验证码" v-model="params.code"></el-input>
              </el-col>
              <el-col :span="4"></el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authentication } from '@/api/userInfo'
import { sendCodeApi } from '@/api/login'
import { userInfo } from '@/api/userInfo'

export default {
  data() {
    return {
      countdown: 0,
      params: {
        IDcard: '',
        real_name: '',
        mobile: '',
        code: '',
        id: null,
      }
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    sendCode() {
      const send = {mobile: this.params.mobile, event: 'real'}
      sendCodeApi(send).then(data => {
        if (data.code === 1) {
          this.countdown = 60
          const intervalId = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown -= 1
            } else {
              clearInterval(intervalId)
            }
          }, 1000)
        }
      })
    },
    save() {
      authentication(this.params).then(res=>{
        if(res.code == 1) {
          this.getUserInfo()
        }
      })
    },
    getUserInfo() {
      userInfo().then(res=>{
        if(res.code == 1) {
          this.$store.commit('setUserInfo', res.data.userinfo)
          this.$router.push('/')
        }
      })
    }
  },
  mounted() {
    let userInfo = this.$store.getters.userInfo
    if(userInfo) {
      this.params.id = userInfo.id
      this.params.mobile = userInfo.mobile
    }
  }
}
</script>

<style lang='less' scoped>
.apply-withdraw {

  .content {
    width: 100%;
    border-radius: 13px 13px 13px 13px;
    padding: 33px 20px 250px;
    margin-bottom: 41px;
    background: white;
    display: flex;
    justify-content: center;

    .center {
      width: 80%;
    }
    .tip {
      height: 50px;
      line-height: 50px;
      padding: 0 40px;
      margin-bottom: 27px;
      background: rgba(225,30,52,0.06);
      border-radius: 7px 7px 7px 7px;
      font-size: 17px;
      color: #E11E34;
    }

    .row {
      height: 53px;
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #222222;
      margin-bottom: 20px;

      .txt-right {
        text-align: right;
        padding-right: 33px;
      }
      .txt-blue {
        color: #2676F7;
        padding-left: 20px;
        cursor: pointer;
      }

      .sub-info {
        font-size: 17px;
        color: #999999;

        .amount {
          color: #222222;
        }

        span:nth-child(1){
          margin-left: 10px;
        }
        span:nth-child(2){
          margin-left: 13px;
          padding-left: 0px;
        }
      }

      .apply-btn {
        width: 400px;
        height: 65px;
        line-height: 65px;
        text-align: center;
        background: #2676F7;
        border-radius: 13px 13px 13px 13px;
        font-weight: 500;
        font-size: 23px;
        color: #FFFFFF;
        margin-top: 40px;
        cursor: pointer;
      }

      .phoneCode {
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 10px;
        width: 140px;
        border: none; // 去掉插槽按钮和input中的边框
        color: #2676F7;
        font-size: 20px;
        border-radius: 0px 0px 12px 12px;
        background-color: #F5F7FA;
      }

    }

    .mb0 {
      margin-bottom: 0px;
    }
  }

  /deep/.el-input__inner{
    height: 53px;
    line-height: 53px;
    font-size: 20px;
  }
}

</style>