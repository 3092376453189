<template>
  <div class='points-shop-goods-item' @click="goDetail()">
    <img :src="imageUrl" alt="">
    <div class="detail">
      <div class="title">{{ item.title }}</div>
      <div class="bottom">
        <!-- +积分xxx -->
        <div class="amount">¥{{item.price}}</div>
        <div class="btn" @click.stop="exchange()">去兑换</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  methods: {
    goDetail() {
      this.$emit('itemClick', this.item)
    },
    exchange() {
      this.$emit('exchange', this.item)
    }
  },
  computed: {
    imageUrl() {
      return this.$store.getters.imgeCDN + this.item.image
    }
  }
}
</script>

<style lang="less" scoped>
.points-shop-goods-item {
  width: 294px;
  background: #FFFFFF;
  border-radius: 6px;
  margin-right: 8px;
  margin-bottom: 30px;
  cursor: pointer;

  img{
    width: 294px;
    height: 240px;
    border-radius: 6px 6px 0 0;
  }

  .detail{
    padding: 14px 14px 20px 20px;

    .title{
      font-size: 18px;
      color: #222222;
      margin-bottom: 6px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .bottom{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .amount{
        font-size: 16px;
        color: #FF0909;
      }

      .btn{
        width: 74px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        background: #2676F7;
        border-radius: 15px;
        cursor: pointer;
      }
    }
  }
}
</style>