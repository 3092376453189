<template>
  <div class='home-index'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <span class="title" @click="isShowSelectJobDialog=true">{{ jobTitle }}</span>
        <div class="header-right" @click="isShowFilterJobDialog=true">
          <img src="../../../assets/images/filter-icon.png" alt="">
          <div>筛选</div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="page-content ">
        <div class="content">
          <jobItemPerson :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in jobs" :key="index" :item="item" @itemClick="goDetail"></jobItemPerson>
        </div>
        <el-pagination
          class="page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="jobParams.page"
          :page-sizes="[9, 18, 27, 36]"
          :page-size="jobParams.row"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <selectJobDialog v-if="isShowSelectJobDialog" @closed="isShowSelectJobDialog=false" @confirm="selectedJob"></selectJobDialog>
    <filterJobDialog v-if="isShowFilterJobDialog" :type="2" @closed="isShowFilterJobDialog=false" @confirm="filteredJob"></filterJobDialog>
    <checkResultDialog v-if="isShowCheckResultDialog" @closed="isShowCheckResultDialog=false" @check="goCheck"></checkResultDialog>

  </div>
</template>

<script>
import filterJobDialog from '@/components/filterJobDialog.vue'
import selectJobDialog from '@/components/selectJobDialog.vue';
import jobItemPerson from '@/components/reJobItemPerson.vue'
import checkResultDialog from '@/components/checkResultDialog.vue';
import { companyIndex, totalCount, getCompanyCheckData } from '@/api/company'

export default {
  components: {
    filterJobDialog,
    jobItemPerson,
    selectJobDialog,
    checkResultDialog,
  },
  data() {
    return {
      isShowCheckResultDialog: false,
      jobTitle: '请选择职位',
      jobParams: {
        page: 1,
        limit: 9,
        row: 9,
        work_city: 2297, //默认三亚
      },
      isShowSelectJobDialog: false,
      isShowFilterJobDialog: false,
      jobs: [],
      total: 0
    }
  },
  methods: {
    filteredJob(para) {
      this.isShowFilterJobDialog = false
      this.jobParams.page = 1
      if(Object.keys(para).length) {
        this.jobParams = Object.assign(this.jobParams, para)
      }
      let _ = require('lodash')
      this.jobParams = _.omitBy(this.jobParams, (value) => value === null || value === undefined || (_.isObject(value) && _.isEmpty(value)));
      this.getJobs()
    },
    goDetail(item) {
      this.$router.push({path: 'onlineResume',  query: {id: item.uid}})
    },
    handleSizeChange(val) {
      this.jobParams.limit = val
      this.jobParams.row = val
      this.jobParams.page = 1
      this.getJobs()
    },
    handleCurrentChange(val) {
      this.jobParams.page = val
      this.getJobs()
    },
    getJobs() {
      companyIndex(this.jobParams).then(res=>{
        if(res.code == 1) {
          this.jobs = res.data
        }
      })

      this.getTotalCount()
    },
    getTotalCount() {
      let para = JSON.parse(JSON.stringify(this.jobParams))
      delete para.page
      delete para.limit
      delete para.row
      totalCount(para).then(res=>{
        if(res.code == 1){
          this.total = res.data
        }
      })
    },
    updateCity(item){
      this.jobParams.work_city = item.id
      this.jobParams.page = 1
      this.getJobs()
    },
    selectedJob(parent, child) {
      this.jobTitle = child.name
      this.isShowSelectJobDialog = false
      this.jobParams.position_id = child.id
      this.jobParams.page = 1
      this.getJobs()
    },
    // 获取审核结果
    getInfo() {
      getCompanyCheckData().then(res=>{
        if(res.code == 1) {
          if(res.data.status != 1) {
            // 弹出查看框
            this.isShowCheckResultDialog = true
          }
        }
      })
    },
    goCheck() {
      this.$router.push('review-infomation')
    },
  },
  created() {
    this.getInfo()
    this.getJobs()
  }
}
</script>

<style lang="less" scoped>
.home-index {
  .title {
    cursor: pointer;
  }
  .header-right {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #222222;
    cursor: pointer;

    img{
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;

    .mr0 {
      margin-right: 0;
    }
  }
  .page {
    padding-bottom: 30px;
  }
  /deep/.el-pagination span:not([class*=suffix]){
    font-size: 20px;
  }
  /deep/.el-pagination .el-select .el-input .el-input__inner{
    font-size: 20px;
    width: 130px;
    height: 32px;
  }
  /deep/.el-pagination .el-select .el-input{
    width: 130px;
    height: 32px;
  }
  /deep/.el-pager li{
    font-size: 20px;
  }
  /deep/.el-pagination__editor.el-input .el-input__inner{
    font-size: 20px;
  }
}
</style>