import { render, staticRenderFns } from "./refundHistoryItem.vue?vue&type=template&id=bee8e784&scoped=true"
import script from "./refundHistoryItem.vue?vue&type=script&lang=js"
export * from "./refundHistoryItem.vue?vue&type=script&lang=js"
import style0 from "./refundHistoryItem.vue?vue&type=style&index=0&id=bee8e784&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bee8e784",
  null
  
)

export default component.exports