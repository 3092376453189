<template>
  <div class='message-index'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="left-btn">
          <div class="tab-item" :class="{'tab-item-active': activeIndex==item.index}" v-for="(item, index) in tabs" :key="index" @click="handleTab(item)">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <IMView v-if="activeIndex == 1" @customTypeClick="customTypeClick"></IMView>
        <div v-if="activeIndex == 2">
          <noticeItem v-for="(item, index) in noticeDataList" :key="index" :item="item" @itemClick="noticeItemClick"></noticeItem>
        </div>
      </div>
    </div>
    <el-dialog class="dialog" :visible.sync="isShowReportDialog" :title="'举报'">
      <div>举报该记录，请将举报信息及截图发送至客服邮箱：<span>hdfhiehfe@qq.com</span>，平台收到后将在15个工作日内进行处理并反馈。 </div>
      <div class="oper">
        <div class="confirm-btn" @click="isShowReportDialog=false">我知道了</div>
      </div>
    </el-dialog>
    <!-- <reportDialog v-if="isShowReportDialog" @closed="isShowReportDialog=false" @confirm="confirmReport"></reportDialog> -->
  </div>
</template>

<script>
import noticeItem from '@/components/noticeItem.vue'
import IMView from './IMMessage.vue'
//import reportDialog from '@/components/reportDialog.vue'
import { noticeList } from '@/api/jobSeeker'

export default {
  components: {
    noticeItem,
    IMView,
   // reportDialog,
  },
  data() {
    return {
      activeIndex: 1,
      tabs: [
        {
          index: 1,
          title: '聊天消息'
        },{
          index: 2,
          title: '通知消息'
        },
      ],
      noticeParams: {
        page: 1,
        limit: 100
      },
      noticeDataList: [],
      isShowReportDialog: false,
    }
  },
  methods: {
    handleTab(item) {
      this.activeIndex = item.index
      if(this.activeIndex == 1) {

      } else if(this.activeIndex == 2) {
        this.getNoticeList()
      }
    },
    noticeItemClick(item){
      this.$router.push({path: 'messagedetail', query: {id: item.id}})
    },
    customTypeClick(type) {
      console.log(type);
      if(type === 'report') {
        this.isShowReportDialog = true
      }
    },
    confirmReport(params) {
      console.log(params);
      this.isShowReportDialog = false
    },
    msgExchageBtnClick(type) {  //1拒绝  2同意
      console.log(type);
    },
    getNoticeList() {
      noticeList(this.noticeParams).then(res=>{
        if(res.code == 1) {
          this.noticeDataList = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.message-index {
  .left-btn {
    position: absolute;
    left: 0;
    display: flex;

    .tab-item {
      width: 113px;
      height: 45px;
      line-height: 45px;
      margin-right: 40px;
      text-align: center;
      font-size: 20px;
      color: #585858;
      background: #F7F7F7;
      border-radius: 7px 7px 7px 7px;
      cursor: pointer;
    }

    .tab-item-active {
      background: #2676F7;
      color: #FFFFFF;
    }
  }

  .dialog{
    .oper {
      display: flex;
      justify-content: end;
      margin-top: 20px;
      .confirm-btn {
        width: 80px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        background: #2676F7;
        border-radius: 7px 7px 7px 7px;
        font-size: 12px;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  /deep/.el-dialog {
    width: 367px;
    border-radius: 7px;
  }
  /deep/.el-textarea{
    font-size: 17px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
    font-size: 13px;

    span{
      color: #2676F7;
    }
  }
}
</style>