<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-24 19:19:20
 * @Description: 全部商品
-->
<template>
  <div class="all-products">
    <!-- 搜索 start -->
    <div class="all-products-search">
      <div class="all-products-search-box width1200">
        <search ref="search" @searchBtnClick="search"></search>
      </div>
    </div>
    <!-- 搜索 end -->
    
    <!-- 面包屑 start -->
    <div class="all-products-crumbs">
      <div class="all-products-crumbs-box width1200">
        <crumbsVue ref="crumbsVue" :navs="navs"></crumbsVue>
      </div>
    </div>
    <!-- 面包屑 end -->

    <!-- 搜索后排序 start -->
    <div class="home-index-main-sort width1200">
      <div class="home-index-main-sort-box flex flex-start">
        <div class="home-index-main-sort-box-item cursor" 
          :class="{'active': currentSortIndex === index}" 
          v-for="(item,index) in sortArr" 
          :key="index"
          @click="sortHandle(index)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 搜索后排序 end -->

    <!-- 商品盒子 start -->
    <div class="come-in-shop-box-content-shop-card width1200 flex">
      <productCard ref="productCard" :sellArr="sellArr"></productCard>
    </div>
    <!-- 商品盒子 end -->
  </div>
</template>

<script>
import search from '@/components/shoppingMall/search.vue'
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import productCard from '@/components/shoppingMall/productCard.vue'
import { wanlshopProductLists, } from '../../../api/shoppingMall'
export default {
  components: {
    search,
    crumbsVue,
    productCard,
  },
  props:{
    
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '全部商品',
        },
      ],
      sortArr: [{
        name: '综合',
        type: 1,
        field: 'weigh'
      },{
        name: '销量',
        type: 2,
        field: 'sales'
      },{
        name: '新上架',
        type: 3,
        field: 'createtime'
      },{
        name: '价格',
        type: 4,
        field: 'price'
      }],
      currentSortIndex: 0,
      sellArr: [],
      shop_id: ''
    }
  },
  created () {
    this.shop_id = this.$route.query.shop_id ? this.$route.query.shop_id : ''
    if (this.shop_id) {
      this.getWanlshopProductLists(this.shop_id)
    }
  },
  methods: {
    /**
     * @Author: angel~dongsanling
     * @description: 搜索回调
     * @param {*} data 搜索框内容
     */    
    search (data) {
      this.$router.push({
        path: 'home',
        query: {
          'search': data
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 当前店铺全部商品
     * @param {*} id 当前店铺id
     */    
    getWanlshopProductLists (id) {
      this.sellArr = []
      let params = {
        search: '',
        sort: this.sortArr[this.currentSortIndex].field,
        order: 'asc',
        page: 1,
        filter: {
          shop_id: id ? id : ''
        },
        op: {
          shop_id: 'in'
        }
      }
      wanlshopProductLists(params).then(res => {
        if (res.code == 1) {
          if (res.data.data.length) {
            res.data.data.map((item, index) => {
              item.img =  `${this.$store.getters.imgeCDN}${item.image}`
            })
          }
          this.sellArr = res.data.data
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 排序点击选中
     * @param {*} index 当前选中的index
     */    
    sortHandle (index) {
      this.currentSortIndex = index
      this.getWanlshopProductLists(this.shop_id)
    }
  }
}
</script>

<style lang="less" scoped>
.all-products{
  .all-products-search{
    background: #fff !important;
    padding-bottom: 35px;
  }
  .all-products-crumbs{
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .home-index-main-sort{
    width: 1200px;
    height: 106px;
    padding: 0px 50px;
    background: #FFFFFF;
    border-radius: 4px;
    .home-index-main-sort-box{
      flex: 1;
      height: 100%;
      padding-right: 80px;
      box-sizing: border-box;
      align-items: center !important;
      .home-index-main-sort-box-item{
        font-size: 20px;
        font-weight: 500;
        margin-right: 190px;
        &.active{
          color: #2676F7;
          font-size: 20px;
          font-weight: 500;
          position: relative;
        }
        &.active::after{
          content: '';
          width: 32px;
          height: 20px;
          background: url(../../../assets/images/active-bottom-icon.png) no-repeat center center;
          background-size: 80%; 
          position: absolute;
          left: 50%;
          bottom: -15px;
          transform: translateX(-50%);
        }
      }
    }
  }
  .come-in-shop-box-content-shop-card{
    margin-top: 30px;
    padding-bottom: 30px;
  }
}
</style>