<template>
  <div class='news-detail'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        资讯详情
      </div>
    </div>
    <div class="page">
      <div class="page-content content">
        <div class="title">{{ detailInfo.name }}</div>
        <div class="detail" v-html="detailInfo.content"></div>
        <img v-for="(item, index) in imgs" :src="item" :key="index" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { informationDetail } from '@/api/jobSeeker'

export default {
  data() {
    return {
      detailInfo: {}
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    getDetail(id) {
      let params = {
        id: id
      }
      informationDetail(params).then(res=>{
        if(res.code == 1) {
          this.detailInfo = res.data
        }
      })
    }
  },
  created() {
    let id = this.$route.query.id
    if(id) {
      this.getDetail(id)
    }
  },
  computed: {
    imgs: function() {
      let imgArr = []
      if(this.detailInfo.images){
        imgArr = this.detailInfo.images.split(',')
        imgArr = imgArr.map(item=>{
          return this.$store.getters.imgeCDN + item
        })
      }
      return imgArr
    }
  }
}
</script>

<style lang="less" scoped>
.news-detail {
  .content {
    padding: 40px;
    background-color: white;

    .title {
      font-weight: 600;
      font-size: 27px;
      color: #222222;
      margin-bottom: 20px;
    }

    .detail {
      font-weight: 400;
      font-size: 23px;
      color: #222222;
      margin-bottom: 33px;
    }

    img {
      width: 100%;
      // height: 235px;
      border-radius: 13px 13px 13px 13px;
    }
  }
}

</style>