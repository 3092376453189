<template>
  <div class='publish-job'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        职位发布
        <div class="btn-save" @click="publish()">发布</div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="content">
          <div class="input-content">
            <el-row class="row">
              <el-col :span="3">职位分类</el-col>
              <el-col :span="21">
                <el-select v-model="postName" @focus="seleJobCate" placeholder="请选择职位分类">
                </el-select>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="3">职位名称</el-col>
              <el-col :span="21">
                <el-input placeholder="请输入职位名称" v-model="params.name"></el-input>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="3">工作地点</el-col>
              <el-col :span="21">
                <el-select v-show="isShowAddress" v-model="workAddress" @focus="seleAddress" placeholder="请选择工作地点">
                  <!-- <el-option
                    v-for="item in workAddressOp"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option> -->
                </el-select>
                <areaProvince v-show="isShowArea" ref="area" @handleChangeProp="selectedAddress"></areaProvince>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="3">薪资范围</el-col>
              <el-col :span="21">
                <el-select v-model="params.salary" placeholder="请选择薪资范围">
                  <el-option
                    v-for="item in salaryRangeOp"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="3">经验要求</el-col>
              <el-col :span="21">
                <el-select v-model="params.work_experience" placeholder="请选择经验要求">
                  <el-option
                    v-for="item in experienceOp"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="3">最低学历</el-col>
              <el-col :span="21">
                <el-select v-model="params.education" placeholder="请选择最低学历">
                  <el-option
                    v-for="item in educationalOp"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row class="row ali-top">
              <el-col :span="3">岗位职责</el-col>
              <el-col :span="21">
                <!-- <el-input
                  type="textarea"
                  :autosize="{ minRows: 5}"
                  placeholder="请输入岗位职责"
                  v-model="params.responsibility">
                </el-input> -->
                <div class="editor">
                  <editor-content :editor="responsibilityEditor" />
                </div>
              </el-col>
            </el-row>
            <el-row class="row ali-top">
              <el-col :span="3">任职要求</el-col>
              <el-col :span="21">
                <!-- <el-input
                  :class="{'display-none': requirementContent.length != 0}"
                  type="textarea"
                  :autosize="{ minRows: 5}"
                  placeholder="请输入任职要求"
                  v-model="requirementCont">
                </el-input> -->
                <div class="editor">
                  <editor-content :editor="requirementEditor" />
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>

    <selectJobDialog v-if="isShowSelectJobDialog" @closed="isShowSelectJobDialog=false" @confirm="selectedJob"></selectJobDialog>
  </div>
</template>

<script>
import selectJobDialog from '@/components/selectJobDialog.vue';
import areaProvince from '@/components/area.vue'
import { Editor, EditorContent } from 'tiptap'
import { Placeholder} from 'tiptap-extensions';
import { companyConfig, jobDetail } from '@/api/jobSeeker'
import { postPosition } from '@/api/company'

export default {
  components: {
    selectJobDialog,
    areaProvince,
    EditorContent,
  },
  data() {
    return {
      jobId: '',
      isShowSelectJobDialog: false,
      workAddressOp: [],
      salaryRangeOp: [],
      experienceOp: [],
      educationalOp: [],
      postName: '',
      workAddress: '',
      params: {
        post_id: '',
        name: '',
        area_id: null,
        salary: null,
        work_experience: null,
        education: null,
        responsibility: '',
        requirement: ''
      },
      requirementEditor: null,
      responsibilityEditor: null,
      isShowArea: false,
      isShowAddress: true,
      // requirementCont: ''
    }
  },
  methods: {
    publishCheck() {
      if(!this.params.post_id) {
        this.$message.warning('请选择职位分类')
        return false
      }
      if(!this.params.area_id) {
        this.$message.warning('请选择工作地点')
        return false
      }
      return true
    },
    publish() {
      this.params.requirement = this.requirementEditor.getHTML()
      this.params.responsibility = this.responsibilityEditor.getHTML()
      if(this.jobId) {
        this.params.position_id = this.jobId
      }
      if(!this.publishCheck()) return

      postPosition(this.params).then(res=>{
        if(res.code == 1) {
          this.$message.success('发布成功')
          if(!this.jobId) {
            Object.keys(this.params).forEach(key => {
              this.params[key] = null;
            });
            this.postName = ''
            this.$refs.area.clear()
            this.destroyEditor()
            this.createEditor()
          }
        }
      })
    },
    seleJobCate() {
      this.isShowSelectJobDialog = true
    },
    // 选择职位回调
    selectedJob(parent, child) {
      this.postName = child.name
      this.params.post_id = child.id
      this.isShowSelectJobDialog = false
    },
    seleAddress() {
      this.$refs.area.selectArea()

      this.isShowAddress = false
      this.isShowArea = true
    },
    // 选择地址回调
    selectedAddress(data) {
      if(data.length == 0) {
        if(this.jobId) {
          this.isShowAddress = true
          this.isShowArea = false
        }
      } else {
        this.params.area_id = data[2]
        this.isShowAddress = false
        this.isShowArea = true
      }
      
    },
    getConfig() {
      let params = {
        type: 'all'
      }
      companyConfig(params).then(res=>{
        if(res.code == 1) {
          this.detailData(res.data.salary, this.salaryRangeOp)
          this.detailData(res.data.work_experience, this.experienceOp)
          this.detailData(res.data.education, this.educationalOp)
        }
      })
    },
    detailData(data, cateItem) {
      let keys = Object.keys(data)
      for(let i = 0; i < keys.length; i++) {
        let obj = {
          label: data[keys[i]],
          value: keys[i],
        }
        cateItem.push(obj)
      }
    },
    // 获取职位详情， 编辑重新发布
    getDetail() {
      jobDetail({id: this.jobId}).then(res=>{
        if(res.code == 1) {
          this.params.requirement = res.data.requirement
          this.params.responsibility = res.data.responsibility

          this.requirementEditor = new Editor({
            content: res.data.requirement,
            extensions: [
              new Placeholder({
                placeholder: {
                  text: '这里输入文本...',
                },
              }),
            ],
          })

          this.responsibilityEditor = new Editor({
            content: res.data.responsibility,
            extensions: [
              new Placeholder({
                placeholder: {
                  text: '这里输入文本...',
                },
              }),
            ],
          })

          this.params.salary = String(res.data.salary_setting_id)
          this.params.education = String(res.data.min_education)
          this.params.work_experience = String(res.data.work_setting_id)
          this.params.name = res.data.name
          this.params.post_id = res.data.post_id
          this.postName = res.data.post_name
          this.params.area_id = res.data.area_id
          this.workAddress = res.data.area
          // this.$refs.area.update(res.data.area)
        }
      })
    },
    createEditor()  {
      this.requirementEditor = new Editor({
        content: '',
        extensions: [
          new Placeholder({
            placeholder: {
              text: '这里输入文本...',
            },
          }),
        ],
      })

      this.responsibilityEditor = new Editor({
        content: '',
        extensions: [
          new Placeholder({
            placeholder: {
              text: '这里输入文本...',
            },
          }),
        ],
      })
    },
    destroyEditor() {
      this.responsibilityEditor.destroy()
      this.requirementEditor.destroy()
    }
  },
  created() {
    this.getConfig()
    if(this.$route.query.id) {
      this.jobId = this.$route.query.id
      this.getDetail()
      this.isShowArea = false
    }
  },
  mounted() {
    if(!this.$route.query.id) {
      this.createEditor()
    }
  },
  beforeDestroy() {
    this.destroyEditor()
  },
  // computed: {
  //   requirementContent() {
  //     if(!this.requirementEditor) return
  //     console.log(this.requirementEditor.getHTML());
  //     if(this.requirementEditor.getHTML() == '<p></p>') {
  //       // this.requirementEditor = new Editor({
  //       //   content: '<p>请输入内容</p>',
  //       // })
  //       return ''
  //     }
  //     return this.requirementEditor.getHTML()
  //   }
  // },
  // watch: {
  //   content(newVal, oldVal) {
  //     debugger
  //   }
  // }
}
</script>

<style lang="less" scoped>
.publish-job {
  .content {
    width: 100%;
    background: #FFFFFF;
    padding: 40px 0;
    border-radius: 13px 13px 13px 13px;
    display: flex;
    justify-content: center;

    .input-content {
      width: 60%;
      .row {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #222222;
        margin-bottom: 20px;
      }
      .ali-top {
        align-items: flex-start;
      }

      .editor {
        padding: 20px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
      }

      .display-none {
        display: none;
      }
    }
  }

  /deep/.el-select{
    width: 100%
  }
  /deep/.el-select>.el-input{
    width: 100%;
  }
  /deep/.el-input--suffix .el-input__inner {
    height: 53px;
    line-height: 53px;
    font-size: 20px;
  }
  /deep/.el-textarea__inner{
    font-size: 20px;
  }
  /deep/.el-input__inner{
    height: 53px;
    line-height: 53px;
    font-size: 20px;
  }
  // /deep/.ProseMirror {
  //   font-size: 20px;
  // }
}
</style>
<style>
.el-select-dropdown__item{
  font-size: 20px!important;
}
.ProseMirror {
    font-size: 20px;
  }
</style>