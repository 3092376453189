<template>
  <div class='filter-job-dia'>
    <el-dialog class="dialog" 
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="closedDialog"
    title="职位筛选">
      <div class="content">
        <div class="cate" v-if="type == 1">
          <div class="cate-item" v-for="(item, index) in filterCates" :key="index" @click="handleCate(item)">{{ item.title }}</div>
        </div>
        <div class="all-content" v-if="type == 1">
          <div class="item" :id="item.id" v-for="(item, index) in filterCates" :key="index">
            <div class="title">{{ item.title }}</div>
            <div class="sub-cate">
              <div class="sub-cate-item" :class="{'sub-cate-item-active': item.activeIndex == subItem.id}" v-for="(subItem, indx) in item.children" :key="indx" @click="handleSubCate(item, subItem)">
                {{ subItem.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="cate" v-if="type == 2">
          <div class="cate-item" v-for="(item, index) in reFilterCates" :key="index" @click="handleCate(item)">{{ item.title }}</div>
        </div>
        <div class="all-content" v-if="type == 2">
          <div class="item" :id="item.id" v-for="(item, index) in reFilterCates" :key="index">
            <div class="title">{{ item.title }}</div>
            <div class="sub-cate">
              <div class="sub-cate-item" :class="{'sub-cate-item-active': item.activeIndex == subItem.id}" v-for="(subItem, indx) in item.children" :key="indx" @click="handleSubCate(item, subItem)">
                {{ subItem.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <span class="clear" @click="clear()">清除</span>
        <span class="confirm" @click="confirm()">确定</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { companyConfig, gradePost} from '@/api/jobSeeker'

export default {
  props: {
    type: {
      type: Number,
      default: 1, //1求职端 2企业端
    }
  },
  data() {
    return {
      showDialog: true,
      filterCates: [
        {
          title: '公司行业',
          id: 'section1',
          activeIndex: null,
          children: [
            {
              name: '不限',
              isSelected: false,
              id: null,
            }
          ]
        },{
          title: '职业类型',
          id: 'section2',
          activeIndex: null,
          children: [
            {
              name: '不限',
              isSelected: false,
              id: null,
            }
          ]
        },
        {
          title: '学历',
          id: 'section3',
          activeIndex: null,
          children: [
            {
              name: '不限',
              isSelected: false,
              id: null,
            }
          ]
        },{
          title: '工作经验',
          id: 'section4',
          activeIndex: null,
          children: [
            {
              name: '不限',
              isSelected: false,
              id: null,
            }
          ]
        },
        {
          title: '公司福利',
          id: 'section5',
          activeIndex: null,
          children: [
            {
              name: '不限',
              isSelected: false,
              id: null,
            }
          ]
        },
        {
          title: '薪资',
          id: 'section6',
          activeIndex: null,
          children: [
            {
              name: '不限',
              isSelected: false,
              id: null,
            }
          ]
        },
      ],
      reFilterCates: [ //企业端
        {
          title: '学历',
          id: 'section3',
          activeIndex: null,
          children: [
            {
              name: '不限',
              isSelected: false,
              id: null,
            }
          ]
        },{
          title: '工作经验',
          id: 'section4',
          activeIndex: null,
          children: [
            {
              name: '不限',
              isSelected: false,
              id: null,
            }
          ]
        },{
          title: '薪资',
          id: 'section6',
          activeIndex: null,
          children: [
            {
              name: '不限',
              isSelected: false,
              id: null,
            }
          ]
        },
      ],
      params: {
        company_category: null, //公司行业
        post_id: null,//职业类型
        min_education: null, //学历
        work_setting_id: null, //工作经验
        welfare_setting: null, //公司福利
        salary_setting_id: null, //薪资
      }
    }
  },
  methods: {
    closedDialog() {
      this.$emit('closed')
    },
    handleCate(item) {
      let element = document.getElementById(item.id)
      if(element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    handleSubCate(item, subItem) {
      item.activeIndex = subItem.id
      item.children.map(obj=>{
        return obj.isSelected = false
      })
      subItem.isSelected = true

      if(item.id == 'section1') {
        this.params.company_category = subItem.id
      } 
      //职业类型
      if(item.id == 'section2') {
        this.params.post_id = subItem.id
      }
      //学历
      if(item.id == 'section3') {
        this.params.min_education = subItem.id
      }
      // 工作经验
      if(item.id == 'section4') {
        this.params.work_setting_id = subItem.id
      } 
      // 公司福利
      if(item.id == 'section5') {
        this.params.welfare_setting = subItem.id
      } 
      //薪资
      if(item.id == 'section6') {
        this.params.salary_setting_id = subItem.id
      }
    },
    clear() {
      this.params.company_category = null
      this.params.post_id = null
      this.params.min_education = null
      this.params.work_setting_id = null
      this.params.welfare_setting = null
      this.params.salary_setting_id = null

      if(this.type == 1) {
        this.filterCates = this.filterCates.map(obj => {
          return { ...obj, activeIndex: null };
        });
      } else {
        this.reFilterCates = this.reFilterCates.map(obj => {
          return { ...obj, activeIndex: null };
        });
      }
      
    },
    confirm() {
      let _ = require('lodash')
      let para = {}
      if(this.type == 2) {
        para.education_id = this.params.min_education
        para.work_experience = this.params.work_setting_id
        para.salary = this.params.salary_setting_id
      } 
      // let result = {}
      // if(this.type == 1) {
      //   result = _.omitBy(this.params, (value) => value === null || value === undefined || (_.isObject(value) && _.isEmpty(value)));
      // } else {
      //   result = _.omitBy(para, (value) => value === null || value === undefined || (_.isObject(value) && _.isEmpty(value)));
      // }
      // this.$emit('confirm', result)
      if(this.type == 1) {
        this.$emit('confirm', this.params)
      } else {
        this.$emit('confirm', para)
      }
    },
    // 职位类型
    getGradePost() {
      let params = {
        grade: 1
      }
      gradePost(params).then(res=>{
        if(res.code == 1) {
          this.filterCates[1].children = this.filterCates[1].children.concat(res.data)
          this.filterCates[1].children = this.filterCates[1].children.map(item => ({ ...item, isSelected: false }))
        }
      })
    },
    // 公司情况
    companyConfig() {
      let params = {
        type: 'all'
      }
      companyConfig(params).then(res=>{
        if(res.code == 1) {
          this.setCompanyData(res.data)
        }
      })
    },
    setCompanyData(data) {
      if(this.type == 1) {
        this.detailData(data.company_category, this.filterCates[0])
        this.detailData(data.education, this.filterCates[2])
        this.detailData(data.work_experience, this.filterCates[3])
        this.detailData(data.welfare, this.filterCates[4])
        this.detailData(data.salary, this.filterCates[5])
      } else if(this.type == 2) {
        this.detailData(data.education, this.reFilterCates[0])
        this.detailData(data.work_experience, this.reFilterCates[1])
        this.detailData(data.salary, this.reFilterCates[2])
      }
      
    },
    detailData(data, cateItem) {
      let keys = Object.keys(data)
      for(let i = 0; i < keys.length; i++) {
        let obj = {
          name: data[keys[i]],
          isSelected: false,
          id: keys[i],
        }
        cateItem.children.push(obj)
      }
    }
  },
  created() {
    this.companyConfig()
    if(this.type == 1) {
      this.getGradePost()
    }
  }
}
</script>

<style lang="less" scoped>
.filter-job-dia{

  .dialog{
    .content {
      display: flex;
      font-size: 17px;
      color: #585858;
      padding-bottom: 50px;
      .cate {
        min-width: 20%;
        margin-right: 40px;
        .cate-item {
          // width: 60px;
          height: 38px;
          margin-bottom: 13px;
          line-height: 38px;
          text-align: center;
          cursor: pointer;
        }
        .cate-item-active {
          background: rgba(38,118,247,0.08);
          border-radius: 7px 7px 7px 7px;
          color: #2676F7;
        }
      }
      .all-content {
        width: 70%;
        height: 500px;
        overflow-y: auto;
        .item {
          margin-bottom: 20px;
          .title {
            font-weight: 500;
            font-size: 17px;
            color: #222222;
            margin-bottom: 13px;
          }

          .sub-cate {
            display: flex;
            flex-wrap: wrap;
            .sub-cate-item{
              height: 38px;
              line-height: 38px;
              padding: 0 13px;
              background: #F7F7F7;
              border-radius: 7px 7px 7px 7px;
              margin: 0 13px 13px 0;
              cursor: pointer;
            }
            .sub-cate-item-active {
              background: #2676F7;
              color: white;
            }
          }
          
        }
      }
    }

    .bottom {
      margin-top: 30px;
      margin-right: 30px;
      display: flex;
      justify-content: right;
      span {
        display: inline-block;
        height: 27px;
        line-height: 27px;
        padding: 0 17px;
        text-align: center;
        border-radius: 7px 7px 7px 7px;
        cursor: pointer;
      }
      .clear {
        margin-right: 10px;
        background: rgba(38,118,247,0.08);
        color: #2676F7;
      }
      .confirm {
        background: #2676F7;
        color: white;
      }
    }
  }
  /deep/.el-dialog {
    width: 520px;
    border-radius: 7px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
  }
  ::-webkit-scrollbar{
    display: none;
  }
}
</style>