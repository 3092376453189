<template>
  <div class='collect-seeker-item' @click="btnClick(0)">
    <div class="top">
      <div class="job-name">{{ item.nickname }}</div>
      <div class="num">{{ item.salary_text }}</div>
    </div>
    <div class="detail">
      <div class="introduce">
        <span>{{ item.education_text }}</span><span>{{item.work_year}}年</span><span>{{item.age}}岁</span>
      </div>
      <div class="work-time">
        <span>{{ item.post_text }}</span> <span>{{ item.job_status_text }}</span>
      </div>
      <div class="op">
        <div class="delete-btn" @click.stop="btnClick(1)">删除</div>
          <div class="go-chat-btn" @click.stop="btnClick(2)">立即沟通</div>
        </div>
    </div>

    <el-dialog class="dialog" :visible.sync="showDialog" title="提示">
      <div>
        确定要删除吗
      </div>
      <div class="btn">
        <div class="confirm" @click="confirmDelete()">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      showDialog: false,
    }
  },
  methods: {
    btnClick(type) {
      if(type == 1) {
        this.showDialog = true
      } else{
        this.$emit('btnClick', this.item, type)
      }
    },
    confirmDelete() {
      this.showDialog = false
      this.$emit('btnClick', this.item, 1)
    }
  }
}
</script>

<style lang="less" scoped>
.collect-seeker-item{
  width: 386px;
  // height: 273px;
  // padding: 27px 27px 0 27px;
  background: #FFFFFF;
  border-radius: 13px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  .top-content{
    text-align: left;
    
  }
  .top{
    height: 80px;
    padding: 0 27px;
    border-radius: 13px 13px 0 0;
    background: #F9FBFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .job-name{
      font-weight: 500;
      font-size: 20px;
      color: #222222;
      display: flex;
      align-items: center;
      img{
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin-right: 17px;
      }
    }
    .num{
      font-weight: 500;
      font-size: 20px;
      color: #E11E34;
    }
  }
  .detail{
    padding: 20px 27px 27px;

    .introduce{
      span{
        padding: 4px 10px;
        font-weight: 400;
        font-size: 15px;
        color: #585858;
        background-color: #F7F7F7;
        margin-right: 13px;
        border-radius: 3px;
      }
    }
    .work-time{
      margin: 20px 0;
      font-size: 20px;
      color: #222222;

      span{
        margin-right: 20px;
      }

      span:nth-child(2) {
        color: #999999;
      }
    }
  }
  .op{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .delete-btn {
      width: 133px;
      height: 43px;
      line-height: 43px;
      font-size: 17px;
      color: #585858;
      text-align: center;
      cursor: pointer;
      border-radius: 7px;
      border: 1px solid #E8E8E8;
    }

    .go-chat-btn {
      width: 180px;
      height: 43px;
      line-height: 43px;
      font-size: 17px;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
      background: #2676F7;
      border-radius: 7px;
      border: 1px solid #2676F7;
    }
  }

  .dialog{
    .btn {
      display: flex;
      justify-content: flex-end;
      .confirm{
        width: 57px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        font-size: 12px;
        color: #FFFFFF;
        background: #2676F7;
        border-radius: 7px 7px 7px 7px;
        cursor: pointer;
      }
    }
  }
  /deep/.el-dialog {
    width: 520px;
    border-radius: 7px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
  }
}

</style>