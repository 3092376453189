<template>
  <div class='seeker-item' @click="itemClick()">
    <div class="top">
      <div>{{ item.real_name }}</div>
      <img :src="avatarUrl" alt="">
    </div>
    <div class="detail">
      <div class="intro">
        <span>{{item.education_text}}</span><span>{{item.work_year}}年</span><span>{{ item.salary_text }}</span>
      </div>
      <div class="com-job">
        <div class="com-name">{{ item.company_name }}</div>
        <div>{{ item.name }}</div>
      </div>
      <!-- <div class="com-job">
        <div>界外动画</div>
        <div>JAVA工程师</div>
      </div> -->
      <div class="imgs" v-if="certificateImages.length">
        <img v-for="(item, index) in certificateImages" :src="item" :key="index" alt="">
      </div>
      <div class="bottom">
        <div class="time">{{ item.createtime | toBlockTime2 }}</div>
        <div class="btns-div btns">
          <div class="default" v-if="item.status == 1" @click.stop="btnHandel(1)">不合适</div>
          <div class="active" v-if="item.status == 1" @click.stop="btnHandel(2)">约面试</div>
          <div class="active" v-if="item.status == 3" @click="btnHandel(3)">已预约面试</div>
          <div class="default cursor-txt" v-if="item.status == 4">已标记为不合适</div>
          <div class="active cursor-txt" v-if="item.status == 5"> {{ item.status_text }}</div>
          <div class="active cursor-txt" v-if="item.status == 6">{{ item.status_text }}</div>
          <div class="active cursor-txt" v-if="item.status == 8"> {{ item.status_text }}</div>
          <div class="active cursor-txt" v-if="item.status == 9"> {{ item.status_text }}</div>
        </div>
      </div>
    </div>

    <el-dialog 
    class="dialog" 
    :visible.sync="isShowDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="isShowDialog=false"
    title="提示">
      <div class="content">
        <div>确认修改为不合适吗</div>
      </div>
      <div class="bottom">
        <span class="clear" @click="isShowDialog=false">取消</span>
        <span class="confirm" @click="confirm()">确定</span>
      </div>
    </el-dialog>

    <el-dialog 
    class="dialog" 
    :visible.sync="isShowInterviewialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="isShowInterviewialog=false"
    title="约面试">
      <div class="content">
        <div class="view">
          <div class="title">公司信息</div>
          <div class="info">
            <div>{{ userInfo.companyInfo.company_name }}</div>
            <div>{{ userInfo.nickname }}·{{ userInfo.position }}</div>
          </div>
        </div>
        <div class="view">
          <div class="title">职位信息</div>
          <div class="info">
            <div>{{ item.name }}</div>
            <div class="red">{{ item.salary_text }}</div>
          </div>
        </div>
        <div class="view">
          <div class="title">面试地点</div>
          <div class="info">
            <div>{{ item.company_area_text }} {{ item.address }}</div>
          </div>
        </div>
        <div class="view">
          <div class="title">邀约用户信息</div>
          <div class="info">
            <div>{{item.real_name}}</div>
          </div>
        </div>
        <div class="view">
          <div class="title">面试时间</div>
          <div class="info">
            <div>
              <el-date-picker
              v-model="reviewTime"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <span class="clear" @click="isShowInterviewialog=false">取消</span>
        <span class="confirm" @click="confirmInterview()">确定</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      userInfo: {
        companyInfo: {}
      },
      isShowDialog: false,
      isShowInterviewialog: false,
      reviewTime: ''
    }
  },
  methods: {
    initUserInfo() {
      this.userInfo = this.$store.getters.userInfo
    },
    itemClick() {
      this.$emit('itemClick', this.item)
    },

    btnHandel(type) {
      if(type == 1) { //不合适
        this.isShowDialog = true
      } else if (type == 2) { //约面试
        this.isShowInterviewialog = true
      } else if (type == 3) { //查看面试详情
        this.$emit('btnItemClick', -1, this.item)
      }
    },
    // 不合适调接口
    confirm() {
      this.isShowDialog = false
      this.$emit('btnItemClick', 4, this.item)
    },
    // 约面试
    confirmInterview() {
      if(!this.reviewTime) {
        this.$message.warning('请选择面试时间')
        return
      }
      let time = new Date(this.reviewTime).getTime() / 1000
      this.$emit('btnItemClick', 3, this.item, time)
      this.isShowInterviewialog = false
    }
  },
  created() {
    this.initUserInfo()
  },
  computed: {
    avatarUrl() {
      if(this.item.avatar) {
        return this.$store.getters.imgeCDN + this.item.avatar
      }
      return ''
    },
    certificateImages() {
      let imageArr = []
      if(this.item.certificate_images) {
        imageArr = this.item.certificate_images.split(',')
        imageArr = imageArr.map(item=>{
          return this.$store.getters.imgeCDN + item
        })
      }
      return imageArr
    }
  }
}
</script>

<style lang="less" scoped>
.seeker-item{
  width: 386px;
  // height: 195px;
  background: #FFFFFF;
  border-radius: 13px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  .top {
    width: 100%;
    height: 80px;
    background: #F9FBFF;
    border-radius: 13px 13px 0 0;
    padding: 0 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 23px;
    color: #222222;

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }

  .detail {
    width: 100%;
    padding: 17px 27px 20px;
    .intro {
      margin-bottom: 17px;
      span{
        padding: 4px 10px;
        font-weight: 400;
        font-size: 15px;
        color: #585858;
        background-color: #F7F7F7;
        margin-right: 13px;
        border-radius: 3px;
      }
    }

    .com-job {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 20px;
      color: #222222;
      div:nth-child(1){
        margin-right: 20px;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      div:nth-child(2) {
        max-width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .imgs{
      display: flex;
      align-items: center;
      img{
        width: 123px;
        height: 73px;
        border-radius: 7px 7px 7px 7px;
        margin-right: 20px;
      }
    }

    .bottom {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .time {

      }

      .btns-div {
        display: flex;
        align-items: center;
        .active {
          height: 30px;
          line-height: 30px;
          margin-left: 10px;
          padding: 0 15px;
          text-align: center;
          border-radius: 15px;
          font-size: 14px;
          background: #2676F7;
          border: 1px solid #2676F7;
          color: #fff;
        }
        .default{
          height: 30px;
          line-height: 30px;
          margin-left: 10px;
          padding: 0 15px;
          text-align: center;
          border-radius: 15px;
          font-size: 14px;
          background: #FAFAFA;
          border: 1px solid #DDDDDD;
          color: #666666;
        }
        
        .cursor-txt{
          cursor: text;
        }
      }
    }
  }

  .dialog{
    .content {
      // display: flex;
      font-size: 17px;
      color: #585858;
      padding-bottom: 50px;

      .view {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #DDDDDD;
        cursor: text;
        .title {
          font-size: 18px;
          color: #303133;
          margin-bottom: 10px;
        }
        .info {
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999;
        }
        .red {
          color: #f04e3f;
        }
      }
    }

    .bottom {
      margin-top: 30px;
      margin-right: 30px;
      display: flex;
      justify-content: right;
      span {
        display: inline-block;
        height: 27px;
        line-height: 27px;
        padding: 0 17px;
        text-align: center;
        border-radius: 7px 7px 7px 7px;
        cursor: pointer;
      }
      .clear {
        margin-right: 10px;
        background: rgba(38,118,247,0.08);
        color: #2676F7;
      }
      .confirm {
        background: #2676F7;
        color: white;
      }
    }
  }
  /deep/.el-dialog {
    width: 520px;
    border-radius: 7px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 23px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
  }
}

</style>