import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: { // user.identity 身份 1=求职者，2=企业
      isLogin: false,
    },
    isLogin: true,
    imageCdn: 'https://juejinzhilu.oss-cn-beijing.aliyuncs.com',
  },
  getters: {
    userInfo(state) {
      return state.user
    },
    imgeCDN(state) {
      return state.imageCdn
    },
    userIsLogin(state) {
      return state.isLogin
    }
  },
  mutations: {
    setUserInfo(state, payload) {
      state.user = payload
      if (payload.token) {
        state.user.isLogin = true
        state.isLogin = true
      }
      // console.log('state.user',state.user)
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    isLogin(state) {
      return state.user.isLogin
    },
    getUserInfo(state) {
      return state.user
    }
  },
  actions: {},
  modules: {}
})
