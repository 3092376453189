<template>
  <div class='exciting-record'>
    <div class="page-header mb20">
      <div class="page-header-content">
        <div class="back" @click="back()">返回</div>
        <div class="header-search">
          <el-input placeholder="搜索录播" v-model="recordParams.title"></el-input>
          <div class="search-btn" @click="search()">搜索</div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="top-cate">
          <div class="cate">
            <div class="container">
              <div class="cate-div">
                <span class="category-item" :class="{'item-active': recordCategoryActiveIndex == item.id}" v-for="(item, index) in recordCategory" :key="index" @click="handleCateItem(item)">
                  {{ item.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="view">
          <excitingRecordItem :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in records" :item="item" :key="index" @itemClick="goLessonDetail"></excitingRecordItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import excitingRecordItem from '@/components/excitingRecordItem.vue'
import { lessonsCategory, liveLessons } from '@/api/jobSeeker'

export default {
  components: {
    excitingRecordItem
  },
  data() {
    return {
      recordCategoryActiveIndex: 0,
      recordCategory: [
        {
          id: 0,
          name: '全部',
        }
      ],
      recordParams: {
        row: 9,
        page: 1,
        status: 2,
        title: ''
      },
      records: []
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    handleCateItem(item) {
      this.recordCategoryActiveIndex = item.id
      this.recordParams.page = 1
      this.getLiveLessons()
    },
    goLessonDetail(item) {
      this.$router.push({path: 'lessondetail', query: {id: item.id}})
    },
    // 录播分类
    getLessonsCategory() {
      lessonsCategory().then(res=>{
        if(res.code == 1) {
          this.recordCategory = this.recordCategory.concat(res.data)
          if(this.recordCategory.length>0) {
            this.recordCategoryActiveIndex = this.recordCategory[0].id
            this.getLiveLessons()
          }
        }
      })
    },
    // 录播数据
    getLiveLessons() {
      this.recordParams.category_id = this.recordCategoryActiveIndex
      liveLessons(this.recordParams).then(res=>{
        if(res.code == 1) {
          this.records = res.data
        }
      })
    },
    // 搜索
    search() {
      this.recordParams.page = 1
      this.getLiveLessons()
    }
  },
  mounted() {
    this.getLessonsCategory()
  }
}
</script>

<style lang="less" scoped>
.exciting-record {
  .header-search {
    width: 867px;
    height: 55px;
    background: #F7F7F7;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;

    .search-btn {
      width: 130px;
      height: 55px;
      line-height: 55px;
      text-align: center;
      background: #2676F7;
      font-size: 17px;
      color: #FFFFFF;
      border-radius: 0 10px 10px 0;
      cursor: pointer;
    }
  }

  .top-cate {
    width: 100%;
    height: 87px;
     margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 10px 10px 10px 10px;

    .cate {
      height: 100%;
      margin-left: 58px;
      .container {
        width: 1100px;
        height: 100%;
        overflow-x: auto;
        scrollbar-width: none;

        .cate-div {
          height: 100%;
          display: flex;
          align-items: center;

          .category-item {
            background: #F7F7F7;
            border-radius: 7px 7px 7px 7px;
            height: 45px;
            line-height: 45px;
            margin-right: 38px;
            min-width: 120px;
            text-align: center;
            font-size: 20px;
            cursor: pointer;
          }

          .item-active {
            background: #2676F7;
            border-radius: 7px 7px 7px 7px;
            color: white;
          }
        }
      }
    }
  }
  .view{
    width: 100%;
    padding: 33px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    flex-wrap: wrap;
    .mr0 {
      margin-right: 0;
    }
  }

  /deep/.el-input {
    width: 737px;
    height: 55px;
    margin-top: -30px;
  }
  /deep/.el-input__inner {
    width: 737px;
    height: 55px;
    border: none;
    background-color: #F7F7F7;
    font-size: 20px;
    border-radius: 10px 0px 0px 10px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
</style>