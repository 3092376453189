<template>
  <div class='ex-record-item' @click="itemClick()">
    <div class="base-info">
      <img class="bg-img" :src="imgUrl" alt="">
      <div class="pay" v-if="Number(item.price) > 0">付费</div>
      <div class="bottom">
        <div class="left">
          <img src="../assets/images/video-play.png" alt="">
          <div>{{ item.introduce }}</div>
        </div>
      </div>
    </div>
    <div class="intro">
      <div>{{ item.title }}</div>
      <div class="name">{{ item.nickname }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  methods: {
    itemClick() {
      this.$emit('itemClick', this.item)
    }
  },
  computed: {
    imgUrl() {
      if(this.item.cover_image) {
        return this.$store.getters.imgeCDN + this.item.cover_image
      }
      return ''
    }
  }
}
</script>

<style lang="less" scoped>
.ex-record-item{
  width: 360px;
  margin-bottom: 27px;
  margin-right: 27px;
  cursor: pointer;
  .base-info{
    width: 100%;
    height: 213px;
    border-radius: 13px;
    margin-bottom: 20px;
    // cursor: pointer;
    // background-color: cadetblue;
    position: relative;

    .bg-img{
      width: 100%;
      height: 100%;
      border-radius: 13px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .pay {
      width: 50px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      background: linear-gradient( 90deg, #EC5829 0%, #FF0C28 100%);
      border-radius: 7px 7px 7px 7px;
      position: absolute;
      top: 20px;
      left: 20px;
    }
    .bottom{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 60px;
      padding: 0 20px;
      font-weight: 400;
      font-size: 13px;
      color: #FFFFFF;
      border-radius: 0 0 13px 13px;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left{
        display: flex;
        align-items: center;

        img{
          width: 17px;
          height: 17px;
          margin-right: 11px;
          cursor: pointer;
        }
      }
    }
  }

  .intro {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 20px;
    color: #222222;

    .name {
      font-size: 20px;
      color: #999999;
    }
  }
  
}
</style>