<template>
  <div class='personal-center'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">个人中心</div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="info">
          <div class="base-info">
            <img class="av" :src="avatar" @click="goPersonProfile()" alt="">
            <div class="info-detail">
              <div class="name">{{ userInfo.nickname }}</div>
              <div class="sub">{{ userInfo.work_time_text }}年工作经验/{{ userInfo.education_text }}/{{ userInfo.age_text }}岁</div>
              <div class="change-btn" @click="changeRole()">切换为“招聘者”身份</div>
            </div>
          </div>
          <div class="file-div">
            <div class="top">
              <div>附件管理</div>
              <el-upload
              class="upload"
              ref="upload-file"
              :action="action"
              :show-file-list="false"
              :on-success="handleFileSuccess"
              :before-upload="beforeFileUpload"
              >
            </el-upload>
            <img src="../../../assets/images/add.png" @click="selectFile()" alt="">
            </div>
            <div class="file" v-if="attachResumeList.length">
              <img class="file-icon" src="../../../assets/images/file-icon.png" alt="">
              <div class="detail">
                <div>{{ attachResumeList[0].name }}</div>
                <div class="time">{{ attachResumeList[0].createtime | toBlockTime2 }}</div>
              </div>
              <img class="delete-icon" src="../../../assets/images/delete.png" @click="deleteResume()" alt="">
            </div>
          </div>
        </div>
        <div class="tabs">
          <div class="tab-item" :class="{'active': activeIndex == item.index}" v-for="(item, index) in tabs" :key="index" @click="handleTabItem(item)">
            {{ item.title }} 
            <span class="num" :class="{'active': activeIndex == item.index}">({{ item.count }})</span> 
          </div>
        </div>
        <div class="view" v-if="activeIndex==1">
          <deliverItem :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in jobs" :item="item" :key="index" @itemClick="goJobDetail"></deliverItem>
        </div>
        <div class="view" v-if="activeIndex == 2">
          <div class="interview-record-tab">
            <div class="record-tab-item" :class="{'active-tab': recordActiveIndex == item.index}" v-for="(item, index) in interViewRecordTab" :key="index" @click="handleRecordItem(item)">{{ item.title }}</div>
          </div>
          <interviewRecordItem v-for="(item, index) in recordDatas" :key="index" :item="item" @itemClick="goInterviewDetail"></interviewRecordItem>
        </div>

        <div class="view" v-if="activeIndex == 3">
          <div class="interview-record-tab justify-content">
            <div class="record-tab-item collect-tab-item" :class="{'active-tab': collectActiveIndex == item.index}" v-for="(item, index) in collectTab" :key="index" @click="handleCollectItem(item)">{{ item.title }}</div>
          </div>
          <template v-if="collectActiveIndex == 1">
            <jobCollectItem :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in collectJobData" :key="index" :item="item" @itemClick="goJobDetail" @chatBtnClick="goChat" @deleteItem="deleteCollectItem"></jobCollectItem>
          </template>
          <template v-if="collectActiveIndex == 2">
            <companyCollectItem v-for="(item, index) in collectCompanyData" :key="index" :item="item" @itemClick="goCompanyDetail" @deleteItem="deleteCollectItem"></companyCollectItem>
          </template>
        </div>
      </div>
    </div>
    <changeRoleDialog v-if="changeRoleDialog" @change="goRecruiter"></changeRoleDialog>
    <el-dialog 
    class="dialog" 
    :visible.sync="isShowDeleteDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="isShowDeleteDialog=false"
    title="提示">
      <div class="content">
        <div>确认删除该简历吗？</div>
      </div>
      <div class="bottom">
        <span class="clear" @click="isShowDeleteDialog=false">取消</span>
        <span class="confirm" @click="confirmDeleteResume()">确定</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import deliverItem from '@/components/deliverItem.vue';
import interviewRecordItem from '@/components/interviewRecordItem.vue'
import jobCollectItem from '@/components/jobCollectItem.vue'
import companyCollectItem from '@/components/companyCollectItem.vue'
import changeRoleDialog from '@/components/changeRoleDialog.vue';
import { changeIdentity } from '@/api/userInfo'
import { summary, collectList, deleteCollect, deliverList, attachResume, attachResumeList } from '@/api/jobSeeker'

export default {
  components: {
    deliverItem,
    interviewRecordItem,
    jobCollectItem,
    companyCollectItem,
    changeRoleDialog,
  },
  data() {
    return {
      action: 'https://zhp.digginggoldroad.com/api/common/upload',
      userInfo: {},
      activeIndex: 1,
      changeRoleDialog: false,
      resumeFile: null,
      attachResumeList: [],
      tabs: [
        {
          title: '已投递',
          count: '0',
          index: 1,
        },{
          title: '面试记录',
          count: '0',
          index: 2,
        },{
          title: '收藏',
          count: '0',
          index: 3,
        },
      ],
      jobsParams: {
        limit: 100,
        row: 100,
        attr: 1,//先默认给个1吧,好像是没啥用
        page: 1
      },
      jobs: [],
      recordActiveIndex: 3,
      interViewRecordTab: [
        {
          title: '待处理',
          index: 3
        },{
          title: '待面试',
          index: 8
        },{
          title: '已参加',
          index: 5
        },{
          title: '已通过',
          index: 6
        },{
          title: '未通过',
          index: 7
        },
      ],
      recordParams: {
        limit: 100,
        attr: 1,//先默认给个1吧,好像是没啥用
        page: 1,
        status: 3
      },
      recordDatas: [],
      collectActiveIndex: 1,
      collectTab: [
        {
          title: '职位收藏',
          index: 1
        },{
          title: '公司收藏',
          index: 2
        },
      ],
      collectParams: {
        page: 1,
        row: 100,
        type: 1
      },
      collectJobData: [],
      collectCompanyData: [],
      isShowDeleteDialog: false,
    }
  },
  methods: {
    initUserInfo() {
      this.userInfo = this.$store.getters.userInfo
    },
    goPersonProfile() {
      this.$router.push({path: 'personalData'})
    },
    changeRole(){
      this.changeRoleDialog = true
    },
    goRecruiter() {
      changeIdentity().then(res=>{
        if (res.code === 1) {
          this.$store.commit('setUserInfo', res.data.userinfo)
          this.$router.replace('recruiter/home')
        }
      })
    },
    handleTabItem(item){
      this.activeIndex = item.index
      if(this.activeIndex == 1) {
        this.jobs = []
        this.jobsParams.page = 1
        this.getDeliverList()
      } else if(this.activeIndex == 2) {
        this.recordDatas = []
        this.recordActiveIndex = 3
        this.recordParams.status = this.recordActiveIndex
        this.recordParams.page = 1
        this.getInterviewRecord()
      } else {
        this.collectActiveIndex = 1
        this.collectParams.type = this.collectActiveIndex
        this.collectParams.page = 1
        this.collectJobData = []
        this.collectCompanyData = []
        this.getCollectList()
      }
    },
    handleRecordItem(item){
      this.recordActiveIndex = item.index
      this.recordDatas = []
      this.recordParams.status = this.recordActiveIndex
      this.recordParams.page = 1
      this.getInterviewRecord()
    },
    goInterviewDetail(item) {
      this.$router.push({path: 'interview-detail', query: {id: item.id}})
    },
    handleCollectItem(item) {
      this.collectActiveIndex = item.index
      this.collectParams.type = this.collectActiveIndex
      this.collectParams.page = 1
      this.getCollectList()
    },
    goJobDetail(item) {
      if(this.activeIndex == 1) {
        this.$router.push({path: 'jobdetail', query: {id: item.positionlist_id, companyId: item.id}})
      } else if( this.activeIndex == 3) {
        this.$router.push({path: 'jobdetail', query: {id: item.id, companyId: item.company_id}})
      }
    },
    goCompanyDetail(item) {
      this.$router.push({path: 'companydetail', query: {id: item.id} })
    },
    goChat(item) {
      this.$router.push({path: 'message', query: {mobile: item.mobile}})
    },
    
    // 附件简历
    getAttachResumeList() {
      attachResumeList().then(res=>{
        if(res.code == 1) {
          this.attachResumeList = res.data
        }
      })
    },
    selectFile() {
      this.$refs['upload-file'].$refs['upload-inner'].handleClick()
    },
    beforeFileUpload(file) {
      const allowedExtensions = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      const isFile = allowedExtensions.includes(file.type)
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isFile) {
        this.$message.error('请上传docx,pdf 格式的文件!')
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      this.resumeFile = file
      return isFile && isLt10M
    },
    handleFileSuccess(res) {
      this.saveResume(res.data)
    },
    // 保存简历
    saveResume(res) {
      let params = {
        name: this.resumeFile.name,
        url: res.url
      }
      attachResume(params).then(res=>{
        if(res.code == 1) {
          this.getAttachResumeList()
        }
      })
    },
    // 删除简历
    deleteResume() {
      this.isShowDeleteDialog = true
    },
    confirmDeleteResume() {
      let params = {
        id: this.attachResumeList[0].id
      }
      attachResume(params).then(res=>{
        if(res.code == 1) {
          this.isShowDeleteDialog = false
          this.getAttachResumeList()
        }
      })
    },
    // 投递、面试、收藏 数量
    getSummary() {
      summary().then(res=>{
        if(res.code == 1) {
          this.tabs[0].count = res.data.delivered
          this.tabs[1].count = res.data.interviewed
          this.tabs[2].count = res.data.collected
        }
      })
    },
    // 已投递
    getDeliverList() {
      deliverList(this.jobsParams).then(res=>{
        if(res.code == 1) {
          this.jobs = res.data
        }
      })
    },
    // 面试记录
    getInterviewRecord() {
      deliverList(this.recordParams).then(res=>{
        if(res.code == 1) {
          this.recordDatas = res.data
        }
      })
    },
    // 收藏列表
    getCollectList() {
      collectList(this.collectParams).then(res=>{
        if(res.code == 1) {
          if(this.collectActiveIndex == 1) {
            this.collectJobData = res.data
          } else {
            this.collectCompanyData = res.data
          }
        }
      })
    },
    // 删除收藏职位/公司
    deleteCollectItem(item) {
      let params = {
        id: item.id,
        type: this.collectActiveIndex
      }
      deleteCollect(params).then(res=>{
        if(res.code == 1) {
          if(this.collectActiveIndex == 1) {
            this.collectJobData = this.collectJobData.filter(obj=>{
              return obj.id != item.id
            })
          } else {
            this.collectCompanyData = this.collectCompanyData.filter(obj=>{
              return obj.id != item.id
            })
          }
        }
      })
    },
  },
  created() {
    this.initUserInfo()
    this.getAttachResumeList()
    this.getSummary()
    this.getDeliverList()
  },
  computed: {
    avatar() {
      if(this.userInfo.avatar) {
        return this.$store.getters.imgeCDN + this.userInfo.avatar
      }
      return ''
    },
  }
}
</script>

<style lang="less" scoped>
.personal-center{
  .info{
    height: 191px;
    display: flex;
    justify-content: space-between;

    .base-info{
      width: 810px;
      height: 100%;
      background: #FFFFFF;
      border-radius: 13px;
      display: flex;
      align-items: center;
      padding-left: 33px;
      .av{
        width: 100px;
        height: 100px;
        border-radius: 50px;
        margin-right: 33px;
        cursor: pointer;
      }

      .info-detail{
        .name{
          font-weight: 500;
          font-size: 27px;
          color: #222222;
          margin-bottom: 7px;
        }
        .sub { 
          font-size: 20px;
          color: #585858;
          margin-bottom: 13px;
        }

        .change-btn{
          width: 205px;
          height: 38px;
          line-height: 38px;
          border-radius: 7px 7px 7px 7px;
          border: 1px solid #2676F7;
          font-size: 17px;
          color: #2676F7;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .file-div{
      width: 370px;
      height: 191px;
      background: #FFFFFF;
      border-radius: 13px;
      padding: 20px 20px 0 20px;

      .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        font-size: 20px;
        color: #222222;
        margin-bottom: 20px;

        .upload {
          display: none;
        }

        img{
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
      }

      .file{
        width: 330px;
        height: 102px;
        padding: 22px 20px 0 22px;
        background: rgba(38,118,247,0.04);
        border-radius: 13px;
        display: flex;
        justify-content: space-between;

        .file-icon{
          width: 27px;
          height: 27px;
        }

        .detail{
          font-size: 20px;
          color: #222222;
          margin-right: 30px;
          .time{
            margin-top: 4px;
            font-size: 17px;
            color: #999999;
          }
        }

        .delete-icon{
          width: 20px;
          height: 20px;
          margin-top: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .tabs{
    width: 100%;
    height: 93px;
    margin-top: 20px;
    background: #FFFFFF;
    border-radius: 13px 13px 13px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tab-item{
      width: 33%;
      text-align: center;
      font-size: 23px;
      color: #222222;
      cursor: pointer;
      .num{
        color: #999999;
      }

      .active{
        color: #2676F7!important;
      }

    }
    
    .active{
      color: #2676F7!important;
    }
  }

  .view{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    .interview-record-tab{
      width: 100%;
      height: 92px;
      background: #FFFFFF;
      border-radius: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 58px;
      margin-bottom: 20px;

      .record-tab-item{
        width: 167px;
        height: 45px;
        line-height: 45px;
        font-size: 20px;
        color: #585858;
        text-align: center;
        background: #F7F7F7;
        border-radius: 7px;
        cursor: pointer;
      }

      .active-tab{
        background: rgba(38,118,247,0.08);
        border-radius: 7px 7px 7px 7px;
        color: #2676F7;
      }

    }

    .justify-content{
      justify-content: left;

      .collect-tab-item{
        margin-right: 67px;
      }
    }
  }
  .mr0 {
    margin-right: 0;
  }

  .dialog{
    .content {
      display: flex;
      font-size: 17px;
      color: #585858;
      padding-bottom: 50px;
    }

    .bottom {
      margin-top: 30px;
      margin-right: 30px;
      display: flex;
      justify-content: right;
      span {
        display: inline-block;
        height: 27px;
        line-height: 27px;
        padding: 0 17px;
        text-align: center;
        border-radius: 7px 7px 7px 7px;
        cursor: pointer;
      }
      .clear {
        margin-right: 10px;
        background: rgba(38,118,247,0.08);
        color: #2676F7;
      }
      .confirm {
        background: #2676F7;
        color: white;
      }
    }
  }
  /deep/.el-dialog {
    width: 520px;
    border-radius: 7px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
  }
}
</style>