<template>
  <div class='income-item'>
    <div class="left">
      <div class="title"> {{ type == 0 ? '佣金金额' : '提现金额' }} </div>
      <div class="time">{{ item.createtime | toBlockTime }}</div>
    </div>
    <div class="amount">{{ type == 0 ? '+' : '-' }}  {{ item.amount }}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    type: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.income-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 20px;
  .left {
    .title {
      font-weight: 600;
      font-size: 23px;
      color: #222222;
      margin-bottom: 13px;
    }
    .time {
      font-size: 20px;
      color: #999999;
      padding-bottom: 15px;
    }
  }

  .amount{
    font-weight: bold;
    font-size: 30px;
    color: #FF1B36;
  }
}
</style>