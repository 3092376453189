/*
 * @Author: angel~dongsanling
 * @Date: 2024-07-14 22:43:24
 * @Description:
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/filter/filter'
import '@/assets/style/global.less'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { TUIComponents, TUIChatKit, genTestUserSig } from "./TUIKit";
import { TUILogin } from "@tencentcloud/tui-core";
import store from './store'
import BaiduMap from 'vue-baidu-map'

// const SDKAppID = 1600010151; // Your SDKAppID
// const secretKey = "c5f504c5ff161f472138af0fd0f8abbdf8aa96cd0fbe363364cea5873d1db886"; //Your secretKey
// const userID = ""; // User ID

// TUIChatKit.components(TUIComponents, Vue);

// TUIChatKit.init();
// TUILogin.login({
//   SDKAppID,
//   userID,
//   // UserSig 是用户登录即时通信 IM 的密码，其本质是对 UserID 等信息加密后得到的密文。
//   // 该方法仅适合本地跑通 Demo 和功能调试，详情请参见 https://cloud.tencent.com/document/product/269/32688
//   userSig: genTestUserSig({
//     SDKAppID,
//     secretKey,
//     userID,
//   }).userSig,
//   // 如果您需要发送图片、语音、视频、文件等富媒体消息，请设置为 true
//   useUploadPlugin: true,
//   // 本地审核可识别、处理不安全、不适宜的内容，为您的产品体验和业务安全保驾护航
//   // 此功能为增值服务，请参考：https://cloud.tencent.com/document/product/269/79139
//   // 如果您已购买内容审核服务，开启此功能请设置为 true
//   useProfanityFilterPlugin: false,
//   framework: "vue2",
// });

// TUIChatKit.init();
// TUILogin.login({
//   SDKAppID,
//   userID,
//   // UserSig 是用户登录即时通信 IM 的密码，其本质是对 UserID 等信息加密后得到的密文。
//   // 该方法仅适合本地跑通 Demo 和功能调试，详情请参见 https://cloud.tencent.com/document/product/269/32688
//   userSig: genTestUserSig({
//     SDKAppID,
//     secretKey,
//     userID,
//   }).userSig,
//   // 如果您需要发送图片、语音、视频、文件等富媒体消息，请设置为 true
//   useUploadPlugin: true,
//   // 本地审核可识别、处理不安全、不适宜的内容，为您的产品体验和业务安全保驾护航
//   // 此功能为增值服务，请参考：https://cloud.tencent.com/document/product/269/79139
//   // 如果您已购买内容审核服务，开启此功能请设置为 true
//   useProfanityFilterPlugin: false,
//   framework: "vue2",
// });
Vue.use(ElementUI)
Vue.config.productionTip = false;

Vue.use(BaiduMap, {
  ak: 'v3SM35uYmEzdMOwafTZX9GUB89tFQoqe' // 替换成你自己的百度地图开发密钥
})

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export default vm
