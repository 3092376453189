<template>
  <div class='company-collect-item' @click="itemClick()">
    <div class="company-info">
      <div class="info">
        <img :src="companyLogoImageUrl" alt="">
        <div class="detail">
          <div class="con-name">{{ item.company_name }}</div>
          <div class="con-info">
            <!-- <span>未融资</span>  -->
            <!-- <span>20-99人</span> <span>物联网服务业</span> -->
          </div>
        </div>
        <img class="delete-btn" src="../assets/images/delete.png" @click.stop="showDialog=true" alt="">
      </div>
    </div>
    <el-dialog class="dialog" :visible.sync="showDialog" title="提示">
      <div>确定要删除该收藏吗？</div>
      <div class="oper">
        <div class="confirm-btn" @click="confirm()">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      showDialog: false,
    }
  },
  methods: {
    itemClick() {
      this.$emit('itemClick', this.item)
    },
    confirm() {
      this.showDialog = false
      this.$emit('deleteItem', this.item)
    }
  },
  computed: {
    companyLogoImageUrl() {
      if(this.item.company_logo_image) {
        return this.$store.getters.imgeCDN + this.item.company_logo_image
      }
      return ''
    },
  }
}
</script>

<style lang="less" scoped>
.company-collect-item {
  width: 100%;
  background: #FFFFFF;
  border-radius: 13px 13px 13px 13px;
  margin-bottom: 20px;
  padding: 40px 40px 46px 40px;
  cursor: pointer;
  .company-info {
    width: 100%;
    background: #FFFFFF;
    border-radius: 13px;

    .info {
      display: flex;
      align-items: center;

      img {
        width: 90px;
        height: 90px;
        background: #FFFFFF;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #E8E8E8;
        margin-right: 47px;
      }

      .detail {
        width: 90%;
        margin-top: 7px;
        .con-name {
          font-size: 27px;
          color: #222222;
          margin-bottom: 10px
        }
        .con-info {
          font-weight: 400;
          font-size: 20px;
          color: #999999;

          span{
            margin-right: 13px;
          }
        }
      }

      .delete-btn{
        width: 27px;
        height: 27px;
        margin-right: 0;
        cursor: pointer;
      }
    }
  }

  .dialog{
    .oper {
      display: flex;
      justify-content: end;
      margin-top: 20px;
      .confirm-btn {
        width: 80px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        background: #2676F7;
        border-radius: 7px 7px 7px 7px;
        font-size: 12px;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  /deep/.el-dialog {
    width: 352px;
    border-radius: 7px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
    font-size: 12px;
    color: #222222;
  }
}

</style>