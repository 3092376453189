<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-19 14:20:35
 * @Description: 账号安全
-->
<template>
  <div class='account-security'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        账号安全
      </div>
    </div>
    <div class="account-security-main width1200">
      <div class="account-security-main-item cursor" 
        v-for="(item, index) in list" :key="index" @click="operate(item.type, item)">
        <div class="account-security-main-item-label">{{ item.label }}</div>
        <div class="account-security-main-item-value flex space-between">
          <div class="account-security-main-item-value-text" :class="{ 'notice' : item.type === 'logOff'}">
            {{ item.value }}
          </div>
          <img src="../../../assets/images/arrow-right.png" alt="">
        </div>
      </div>
    </div>
    <!-- 修改手机号弹框 start -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      class="custom-header"
      :before-close="handleClose">
      <div class="custom-header-box">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="user-box-form-ruleForm">
          <el-form-item label="账号密码" prop="password" v-if="dialogVisiblePhone">
            <el-input v-model="ruleForm.password" type="password" autocomplete="new-password" clearable class="custom-input" placeholder="请输入账号密码"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile" v-if="dialogVisiblePhone || dialogVisiblePassword">
            <el-input v-model="ruleForm.mobile" clearable class="custom-input" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="captcha" class="msg" v-if="dialogVisiblePhone">
            <el-input v-model="ruleForm.captcha" class="custom-input" placeholder="请输入短信验证码"></el-input>
            <span :class="countdown > 0 ? 'gray send cursor' : 'send cursor'" @click="operate(countdown > 0 ? '' : 'changeMobileSend')">
              {{ countdown > 0 ? `${countdown}秒后获取` : '发送验证码' }}
            </span>
          </el-form-item>
          <el-form-item label="验证码" prop="captcha" class="msg" v-if="dialogVisiblePassword">
            <el-input v-model="ruleForm.captcha" class="custom-input" placeholder="请输入短信验证码"></el-input>
            <span :class="countdown > 0 ? 'gray send cursor' : 'send cursor'" @click="operate(countdown > 0 ? '' : 'loginSend')">
              {{ countdown > 0 ? `${countdown}秒后获取` : '发送验证码' }}
            </span>
          </el-form-item>
          <el-form-item label="密码" prop="password" v-if="dialogVisiblePassword">
            <el-input v-model="ruleForm.password" type="password" autocomplete="new-password" clearable class="custom-input" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="newpassword" v-if="dialogVisiblePassword">
            <el-input v-model="ruleForm.newpassword" type="password" autocomplete="new-password" clearable class="custom-input" placeholder="请输入密码"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit('ruleForm')">确定</el-button>
      </div>
    </el-dialog>
    <!-- 修改手机号弹框 end -->

    <!-- 注销弹框 start -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleLogOff"
      width="600px"
      class="custom-header"
      :before-close="handleCloseLogOff">
      <div class="custom-header-box">
        确认注销账号吗？注销后不可恢复，请谨慎操作。
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="btn-cancel" @click="operate('cancel')">取消</el-button>
        <el-button type="primary" class="btn-red" @click="operate('sure')">确定</el-button>
      </div>
    </el-dialog>
    <!-- 注销弹框 end -->
  </div>
</template>

<script>
import { userInfo, changemobile,resetpwd  } from '@/api/userInfo'
import { sendCodeApi, } from '@/api/login'
export default {
  name: 'accountSecurity',
  data() {
    return {
      list: [{
        label: '更换手机号',
        value: '15555555555',
        type: 'changeMobile'
      }, {
        label: '修改密码',
        value: '点击修改密码',
        type: 'changePassword'
      }
      // , {
      //   label: '注销账号',
      //   value: '注销后不可恢复，请谨慎操作',
      //   type: 'logOff'
      // }
      ],
      dialogVisible: false,
      dialogVisiblePhone: false,
      dialogVisiblePassword: false,
      dialogVisibleLogOff: false,
      ruleForm: {
        mobile: '',
        captcha: '',
        newpassword: ''
      },
      title: '更换手机号',
      countdown: 0,
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        newpassword: [
          { required: true, message: '请再次确认密码', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getUserInfo()
  },
  methods: {
    /**
     * @Author: angel~dongsanling
     * @description: 发送验证码 
     * @param {*} type 根据类型判断是修改手机号还是修改密码
     */    
    sendCode (type) {
      sendCodeApi ({event: type,mobile: this.ruleForm.mobile}).then(res => {
        if (res.code === 1) {
          // 假设这是获取验证码的API调用
          this.countdown = 60 // 设置验证码有效时间，例如60秒
          const intervalId = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown -= 1
            } else {
              clearInterval(intervalId)
            }
          }, 1000)
          this.$message.success('发送成功')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 获取用户信息
     */    
    getUserInfo () {
      userInfo ().then(res => {
        if(res.code == 1) {
          this.list[0].value = res.data.userinfo.mobile
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 返回
     */    
    back () {
      this.$router.back()
    },
    // 注销弹框提示
    handleCloseLogOff (){
      this.dialogVisibleLogOff = false
    },
    /**
     * @Author: angel~dongsanling
     * @description: 关闭弹框
     */    
    handleClose () {
      this.dialogVisible = false
      this.dialogVisiblePhone = false
      this.dialogVisiblePassword = false
      this.$refs.ruleForm.resetFields() // 重置表单
    },
    /**
     * @Author: angel~dongsanling
     * @description: 提交
     * @param {*} formName
     */
     submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.dialogVisiblePhone) { // 更改手机号
            let params = {
              captcha: this.ruleForm.captcha,
              mobile: this.ruleForm.mobile,
              password: this.ruleForm.password
            }
            changemobile(params).then(res => {
              if (res.code === 1){
                this.$message.success('修改成功')
                localStorage.clear()
                this.$router.push({
                  path: '/login'
                })
              } else {
                this.$message.error(res.msg)
              }
            })
          } else if (this.dialogVisiblePassword) { // 更改密码
            if (this.ruleForm.password != this.ruleForm.newpassword){
              this.$message.error('两次密码不一致，请重新输入')
              return true
            }
            let params = {
              captcha: this.ruleForm.captcha,
              mobile: this.ruleForm.mobile,
              newpassword: this.ruleForm.password,
              type: 'mobile'
            }
            resetpwd(params).then(res =>{
              if (res.code === 1){
                this.$message.success('修改成功')
                localStorage.clear()
                this.$router.push({
                  path: '/login'
                })
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 操作
     * @param {*} type 根据类型判断是那个方法
     * @param {*} data 当前点击传的参数
     */    
    operate (type, data) {
      switch (type) {
        case 'changeMobile': // 修改手机号
          this.dialogVisible = true
          this.dialogVisiblePhone = true
          this.title = '更换手机号'
          break
        case 'changePassword': // 修改密码
          this.dialogVisible = true
          this.dialogVisiblePassword = true
          this.title = data.label
          break
        case 'logOff': // 注销
          console.log("是看你看怎么回事",type,data);
          this.dialogVisibleLogOff = true
          break
        case 'changeMobileSend': // 修改手机号发送验证码
          this.sendCode('changeMobile')
          break
        case 'loginSend':
          this.sendCode('login')
          break
        case 'cancel': // 取消
          this.dialogVisibleLogOff = false
          break
        case 'sure': // 确定
          this.handleCloseLogOff()
          break
      }
    }
  }
}
</script>

<style scoped lang="less">
.account-security{
  background: #F7F7F7;
  .account-security-header{
    width: 100%;
    height: 86px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .account-security-header-in{
      height: 86px;
      font-size: 22px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .back{
        width: 85%;
        height: 86px;
        padding-left: 32px;
        font-size: 16px;
        line-height: 86px;
        position: absolute;
        left: 0px;
        top: 0px;
      }
      .back::before{
        content: '';
        width: 28px;
        height: 28px;
        display: block;
        background: url(../../../assets/images/arrow-left.png) no-repeat center;
        background-size: 100%;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .account-security-main{
    height: 500px;
    padding: 0px 24px 200px;
    margin-top: 12px;
    background: #fff;
    box-sizing: border-box;
    .account-security-main-item{
      border-bottom: 1px solid #E8E8E8;
      padding-top: 18px;
      .account-security-main-item-label{
        font-weight: 600;
        font-size: 23px;
        color: #222222;
        margin-bottom: 20px;
      }
      .account-security-main-item-value{
        padding-bottom: 18px;
        .account-security-main-item-value-text{
          font-size: 20px;
          color: #585858;
          &.notice{
            color: #E11E34;
          }
        }
        img{
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  /deep/.el-dialog__wrapper{
    z-index: 100000;
    .el-dialog{
      width: 520px !important;
      background: #FFFFFF;
      box-shadow: 0px 15px 12px 0px rgba(72,72,72,0.15), 0px -6px 34px 0px rgba(101,101,101,0.17);
      border-radius: 4px;
      padding-bottom: 20px;
      .el-dialog__header{
        border-radius: 4px 4px 0px 0px;
        .el-dialog__title{
          font-size: 16px;
          color: #333;
        }
      }
      .el-dialog__body {
        padding: 10px 20px;
      }
      .custom-header-box{
        .user-box-form-ruleForm{
          .el-form-item{
            margin-bottom: 10px !important;
            .el-form-item__label{
              display: block;
            }
            .el-form-item__content{
              height: 100%;
              line-height: 0px;
              .custom-input{
                width: 100%;
                height: 100% !important;
                box-sizing: border-box;
                margin-right: 0px !important;
                .el-input__inner{
                  height: 40px !important;
                  border-radius: 0px !important;
                }
              }
            }
            &.msg{
              .el-form-item__content{
                position: relative;
                .send{
                  width: 80px;
                  height: 20px;
                  color: #2676F7;
                  font-size: 14px;
                  position: absolute;
                  right: 20px;
                  top: 50px;
                  &.gray{
                    color: #999;
                  }
                }
              }
            }
          }
          .remark{
            font-size: 14px;
            color: #666;
          }
        }
        .forget-box{
          margin-top: 15px;
          font-size: 14px;
          color: #2676F7;
          font-weight: 500;
        }
      }
      .el-dialog__footer{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 20px;
        .dialog-footer{
          // width: 57px;
          height: 28px;
          height: 28px;
          border-radius: 2px;
          display: flex;
          .el-button{
            width: 100%;
            height: 100%;
            font-size: 12px;
            color: #fff;
            background: #2676F7;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.btn-red{
              background: #E11E34;
              border-radius: 7px 7px 7px 7px;
              border: none;
            }
            &.btn-cancel{
              color: #222;
              background: #fff;
              border-radius: 7px 7px 7px 7px;
              border: 1px solid #E8E8E8;
            }
          }
        }
      }
      .dialog-footer-unbing{
        margin-top: 50px;
        .el-button{
          width: 60px;
          height: 30px;
          background: #2676F7;
          border-radius: 20px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          span{
            font-size: 14px;
          }
        }
        .cancel{
          width: 60px;
          height: 30px;
          color: #333;
          background: #FFFFFF;
          border-radius: 20px;
          border: 1px solid #E7E7E7;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>