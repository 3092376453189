<template>
  <div class='address-list'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <div class="content">
          <div class="btn font-20 mb20" @click="addAddress()">新增地址</div>
          <el-table
            :data="tableData"
            :border="true"
            style="width: 100%">
            <el-table-column
            prop="name"
              label="客户名称"
              class="font-weight500"
              width="180">
            </el-table-column>
            <el-table-column
              prop="mobile"
              label="电话号码"
              width="280">
            </el-table-column>
            <el-table-column
              prop="formatted_address"
              label="所在地区">
            </el-table-column>
            <el-table-column
              prop="address"
              label="详情地址">
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <div class="flex">
                  <div class="btn" @click="itemClick(scope.row, 1)">编辑</div>
                  <el-popconfirm title="确定要删除该地址吗？" @confirm="itemClick(scope.row, 2)">
                    <div class="btn" slot="reference">删除</div>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import { addressList, delAddress } from '@/api/shoppingMall'

export default {
  components: {
    crumbsVue,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '收货地址',
        },
      ],
      tableData: [],
    }
  },
  methods: {
    addAddress() {
      this.$router.push({path: 'edit-address'})
    },
    itemClick(item, type) {
      if(type === 1) {
        this.$router.push({path: 'edit-address', query: { item: item}})
      } else {
        delAddress({id: item.id}).then(res=>{
          if(res.code == 1) {
            this.getList()
          }
        })
      }
    },
    getList() {
      let params = {
        page: 1
      }
      addressList(params).then(res=>{
        if(res.code == 1) {
          this.tableData = res.data.data
        }
      })
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.address-list {
  .content {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    margin-bottom: 60px;
    padding: 30px;

    .flex {
      display: flex;
    }
    .btn {
      margin-left: 10px;
      color: #2676F7;
      cursor: pointer;
    }
    .font-20 {
      font-size: 20px;
    }
    
  }

  /deep/.el-table th.el-table__cell.is-leaf{
    text-align: center;
    border-bottom: 1px solid #ECECEC;
    font-size: 18px;
    color: #666666;
  }
  /deep/.el-table th.el-table__cell {
    background: #FAFAFA;
  }
  /deep/.el-table--enable-row-transition .el-table__body td.el-table__cell{
    text-align: center;
    font-size: 16px;
    color: #333;
  }
  /deep/.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
    background: #fff;
  }
  /deep/.el-table thead tr{
    height: 54px;
  }
  /deep/.el-table tbody tr{
    height: 62px;
  }
}
</style>