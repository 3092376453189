<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-24 19:19:20
 * @Description: 进店逛逛
-->
<template>
  <div class="come-in-shop">
    <div class="come-in-shop-box width1200">
      <!-- 面包屑 start -->
      <div class="come-in-shop-box-crumbs">
        <div class="come-in-shop-box-crumbs-box width1200">
          <crumbsVue ref="crumbsVue" :navs="navs"></crumbsVue>
        </div>
      </div>
      <!-- 面包屑 end -->
      <div class="come-in-shop-box-content">
        <!-- tab菜单 start -->
        <div class="come-in-shop-box-content-tab flex space-between">
          <div class="come-in-shop-box-content-tab-left flex flex-start">
            <div class="come-in-shop-box-content-tab-left-item cursor" 
              v-for="(item,index) in tabLeftArr" 
              :key="index"
              :class="{'active': currentIndexLeft === item.type}"
              @click="operate('tabLeft',item,index)">
              {{ item.name }}
            </div>
          </div>
          <div class="come-in-shop-box-content-tab-right flex space-between">
            <div class="come-in-shop-box-content-tab-right-item cursor" 
              v-for="(item,index) in tabRightArr" 
              :key="index"
              :class="{'active': currentIndexLeft === item.type}"
              @click="operate('tabLeft',item,index)">
              {{ item.name }}
            </div>
            <div class="come-in-shop-box-content-tab-right-search el-icon-search cursor" @click="operate('search')"></div>
          </div>
        </div>
        <!-- tab菜单 end -->

        <!-- 店铺名称 start -->
        <div class="come-in-shop-box-content-shop flex space-between">
          <div class="come-in-shop-box-content-shop-left">
            <div class="come-in-shop-box-content-shop-left-img">
              <img :src="shopDetail.avatarShow" alt="">
            </div>
            <div class="come-in-shop-box-content-shop-left-text">
              <div class="come-in-shop-box-content-shop-left-text-title">
                {{ shopDetail.shopname }}
              </div>
              <div class="come-in-shop-box-content-shop-left-text-txt">
                粉丝数：{{ shopDetail.find_user.fans }}
              </div>
            </div>
          </div>
          <div class="come-in-shop-box-content-shop-right cursor" :class="{ 'active': !shopDetail.isFollow }" @click="cancelFollow">
            {{ shopDetail.isFollow ? 'x 取消' : '+ 关注' }}
          </div>
        </div>
        <!-- 店铺名称 end -->

        <!-- 商品盒子 start -->
        <div class="come-in-shop-box-content-shop-card">
          <productCard ref="productCard" :sellArr="sellArr" v-if="sellArr.length"></productCard>
          <div v-else class="empty">没有更多商品了</div>
        </div>
        <!-- 商品盒子 end -->
      </div>
      
    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import productCard from '@/components/shoppingMall/productCard.vue'
import { wanlshopGetShopInfo, wanlshopProductLists, cancelFollow } from '../../../api/shoppingMall'
export default {
  components: {
    crumbsVue,
    productCard
  },
  props:{
    
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '店铺名称',
        },
      ],
      tabLeftArr: [{
        name: '推荐',
        type: '1'
      },{
        name: '新品',
        type: '2'
      }],
      tabRightArr: [{
        name: '全部商品',
        type: '3'
      },{
        name: '店铺分类',
        type: '4'
      }],
      sellArr: [],
      currentIndexLeft: '1',
      shop_id: '',
      shopDetail: {
        find_user: {}
      }
    }
  },
  created () {
    this.shop_id = this.$route.query.shop_id ? this.$route.query.shop_id : ''
    if (this.shop_id) {
      this.getWanlshopGetShopInfo(this.shop_id)
    }
  },
  methods: {
    /**
     * @Author: angel~dongsanling
     * @description: 关注
     * @param {*} item
     */    
    cancelFollow() {
      let params = {
        id: this.shopDetail.find_user.user_no,
        type: "follow"
      }
      cancelFollow(params).then(res=>{
        if(res.code == 1) {
          this.getWanlshopGetShopInfo(this.shop_id)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 当前店铺全部商品
     * @param {*} id 当前店铺id
     */    
    getWanlshopProductLists (type,id) {
      this.sellArr = []
      let params = {
        search: '',
        sort: type == 'new' ? 'createtime' : '',
        order: 'asc',
        page: 1,
        filter: {},
        op: {}
      }
      params.filter['shop_id'] = id ? id : ''
      params.op['shop_id'] = 'in'
      params.filter = params.filter ? JSON.stringify(params.filter) : {}
      params.op = params.op ? JSON.stringify(params.op) : {}
      wanlshopProductLists(params).then(res => {
        if (res.code == 1) {
          console.log(res,"");
          if (res.data.data.length) {
            res.data.data.map((item, index) => {
              item.img =  `${this.$store.getters.imgeCDN}${item.image}`
            })
          }
          this.sellArr = res.data.data
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 当前店铺全部商品
     * @param {*} id 当前店铺id
     */    
    getWanlshopGetShopInfo (id) {
      this.sellArr = []
      wanlshopGetShopInfo({ id: id }).then(res => {
        if (res.code == 1) {
          res.data.avatarShow =  `${this.$store.getters.imgeCDN}${res.data.avatar}`
          this.shopDetail = res.data
          this.getWanlshopProductLists('hot',this.shop_id)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 操作类型
     * @param {*} type 根据类型判断是哪个方法
     * @param {*} data 当前的数据
     * @param {*} index 当前的下标
     */    
    operate (type,data,index) {
      switch (type) {
        case 'tabLeft':
          this.currentIndexLeft = data.type
          if (data.type === '3') {
            this.$router.push({
              path: '/shoppingMall/all-products',
              query: {
                shop_id: this.shop_id
              }
            })
          } else if (data.type === '4') {
            this.$router.push({
              path: 'category-details',
              query: {
                // 'search': this.shopDetail.category[0].name,
                'id': this.shopDetail.category[0].id,
                // 'type': '当前店铺类目：',
                'shopCategory': 'shopCategory'
              }
            })
          }
          if (data.type === '2') {
            this.getWanlshopProductLists('new',this.shop_id)
          }
          if (data.type === '1') {
            // this.getWanlshopGetShopInfo(this.shop_id)
            this.getWanlshopProductLists('hot',this.shop_id)
          }
          break
        case "search":
          this.$router.push({
            path: 'category-details',
            query: {
              'shopType': 'shopType',
              'shop_id': this.shop_id
            }
          })
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.come-in-shop{
  .come-in-shop-box{
    .come-in-shop-box-crumbs{
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .come-in-shop-box-content{
    .come-in-shop-box-content-tab{
      height: 106px;
      padding-left: 50px;
      background: #FFFFFF;
      border-radius: 4px;
      .come-in-shop-box-content-tab-left{
        .come-in-shop-box-content-tab-left-item{
          font-weight: 400;
          font-size: 20px;
          color: #333;
          margin-right: 70px;
          &.active{
            color: #2676F7;
            font-size: 20px;
            font-weight: 500;
            position: relative;
          }
          &.active::after{
            content: '';
            width: 32px;
            height: 20px;
            background: url(../../../assets/images/active-bottom-icon.png) no-repeat center center;
            background-size: 80%; 
            position: absolute;
            left: 50%;
            bottom: -15px;
            transform: translateX(-50%);
          }
        }
      }
      .come-in-shop-box-content-tab-right{
        .come-in-shop-box-content-tab-right-item{
          font-weight: 400;
          font-size: 20px;
          color: #333;
          margin-right: 80px;
          &.active{
            color: #2676F7;
            font-size: 20px;
            font-weight: 500;
            position: relative;
          }
          &.active::after{
            content: '';
            width: 32px;
            height: 20px;
            background: url(../../../assets/images/active-bottom-icon.png) no-repeat center center;
            background-size: 80%; 
            position: absolute;
            left: 50%;
            bottom: -15px;
            transform: translateX(-50%);
          }
        }
        .come-in-shop-box-content-tab-right-search{
          margin-right: 30px;
          font-size: 24px;
        }
      }
    }
    .come-in-shop-box-content-shop{
      height: 140px;
      padding: 30px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #F1F1F1;
      margin-top: 30px;
      // margin-bottom: 30px;
      .come-in-shop-box-content-shop-left{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .come-in-shop-box-content-shop-left-img{
          width: 80px;
          height: 80px;
          margin-right: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .come-in-shop-box-content-shop-left-text{
          .come-in-shop-box-content-shop-left-text-title{
            font-size: 24px;
            font-weight: 500;
            color: #333;
            margin-bottom: 20px;
          }
          .come-in-shop-box-content-shop-left-text-txt{
            font-size: 16px;
            color: #666;
          }
        }
      }
      .come-in-shop-box-content-shop-right{
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #000;
        background: #ccc;
        border-radius: 20px;
        &.active{
          color: #fff;
          background: #2676F7;
        }
      }
    }
    .come-in-shop-box-content-shop-card{
      margin-top: 30px;
      padding-bottom: 30px;
    }
  }
}
        
</style>