<template>
  <div class='select-company-jobs-dialog'>
    <el-dialog class="dialog" 
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="closedDialog"
    title="沟通职位选择">
    <jobItemPerson v-for="(item, index) in dataList" :key="index" :item="item" @itemClick="itemClick"></jobItemPerson>
    </el-dialog>
  </div>
</template>

<script>
import { jobseekerIndex } from '@/api/jobSeeker'
import jobItemPerson from '@/components/jobItemPerson.vue'

export default {
  props: {
    companyId: {
      type: Number,
    }
  },
  components: {
    jobItemPerson,
  },
  data() {
    return {
      showDialog: true,
      dataList: []
    }
  },
  methods: {
    closedDialog() {
      this.$emit('closed')
    },
    getJobs() {
      let params = {
        company_id: this.companyId,
        limit: 100,
        page: 1
      }
      jobseekerIndex(params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data
        }
      })
    },
    itemClick(item) {
      this.$emit('selectedJob', item)
    }
  },
  created() {
    this.getJobs()
  }
}
</script>

<style lang="less" scoped>
.select-company-jobs-dialog {
  .dialog{
  }
  /deep/.el-dialog {
    width: 480px!important;
    border-radius: 7px;
  }
  /deep/.el-textarea{
    font-size: 17px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
    background-color: rgb(245, 246, 249);
  }
}
</style>