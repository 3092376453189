<template>
  <div class='my-promotion'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        我的推广</div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="info mb20">
          <div class="base-info">
            <div class="person">
              <img class="av" :src="avatarUrl" alt="">
              <div class="name">{{ userInfo.nickname }}</div>
            </div>
            <div class="bottom">
              <div>昨日收益： <span class="txt-blue">￥{{ balanceData.balance }}</span> </div>
              <div>累计收益： <span class="txt-blue">￥{{ balanceData.yesterday }}</span> </div>
              <div class="withdraw" @click="applyWithdraw()">申请提现</div>
            </div>
          </div>
          <div class="qr-code-div">
            <div class="title">推广二维码</div>
            <img :src="promotionQrcodeUrl" alt="">
          </div>
        </div>
        <div class="tab mb20">
          <div class="tab-item" :class="{ 'active-tab': activeIndex == item.index }" v-for="(item, index) in tabs" :key="index" @click="handleTabItem(item)">{{ item.title }}
          </div>
        </div>
        <div class="data-list mb20">
          <incomeItem v-for="(item, index) in dataList" :key="index" :item="item"></incomeItem>
        </div>
      </div>
    </div>
    <selectTimeDialog v-if="isShowCustomTimeDialog" @closed="isShowCustomTimeDialog=false" @confirm="selectedTime"></selectTimeDialog>
  </div>
</template>

<script>
import incomeItem from '@/components/incomeItem.vue';
import selectTimeDialog from '@/components/selectTimeDialog.vue';
import { promotionQrcode, balance, flowList } from '@/api/jobSeeker'

export default {
  components: {
    incomeItem,
    selectTimeDialog,
  },
  data() {
    return {
      userInfo: {},
      activeIndex: 1,
      tabs: [
        {
          title: '昨天',
          index: 1
        }, {
          title: '近7天',
          index: 2
        }, {
          title: '近一个月',
          index: 3
        }, {
          title: '自定义时间',
          index: 4
        }
      ],
      promotionQrcodeUrl: '',
      balanceData: {},
      flowListParams: {
        page: 1,
        limit: 100,
        search_start: '',
        search_end: ''
      },
      dataList: [],
      isShowCustomTimeDialog: false,
    }
  },
  methods: {
    back(){
      this.$router.back()
    },
    handleTabItem(item) {
      this.activeIndex = item.index
      if(item.index == 4) {
        this.isShowCustomTimeDialog = true
      } else {
        this.getFlowList()
      }
    },
    applyWithdraw() {
      this.$router.push({path: 'apply-withdraw'})
    },
    getUserInfo() {
      this.userInfo = this.$store.getters.userInfo
    },
    getBalance() {
      balance().then(res=>{
        if(res.code == 1) {
          this.balanceData = res.data
        }
      })
    },
    // 推广二维码
    getPromotionQrcode() {
      promotionQrcode().then(res=>{
        if(res.code == 1) {
          this.promotionQrcodeUrl = res.data
        }
      })
    },
    calTime() {
      if(this.activeIndex == 1) {
        this.flowListParams.search_start = this.getDay(-1) + ' 00:00:00'
        this.flowListParams.search_end = this.getDay(-1) + ' 23:59:59'
      } else if(this.activeIndex == 2) {
        this.flowListParams.search_start = this.getDay(-7) + ' 00:00:00'
        this.flowListParams.search_end = this.getDay(0) + ' 23:59:59'
      } else if(this.activeIndex == 3) {
        this.flowListParams.search_start = this.getDay(-30) + ' 00:00:00'
        this.flowListParams.search_end = this.getDay(0) + ' 23:59:59'
      } else { }
    },
    getDay(day) {
      let today = new Date()
      let targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds)
      let y = today.getFullYear()
      let m = this.padNumber(today.getMonth() + 1, 2)
      let d = this.padNumber(today.getDate(), 2)
      return `${y}-${m}-${d}`
    },
    padNumber(num, length) {
      return String(num).padStart(length, '0');
    },
    filterTimeStampToString(timeStamp) {
      var time = new Date(timeStamp);
      var y = time.getFullYear();
      var m = this.padNumber(time.getMonth() + 1, 2);
      var d = this.padNumber(time.getDate(), 2);
      var h = this.padNumber(time.getHours(), 2);
      var mm = this.padNumber(time.getMinutes(), 2);
      var s = this.padNumber(time.getSeconds(), 2);
      return `${y}-${m}-${d} ${h}:${mm}:${s}`
    },
    selectedTime(time) {
      this.flowListParams.search_start = this.filterTimeStampToString(time[0]) //+ ' 00:00:00'
      this.flowListParams.search_end = this.filterTimeStampToString(time[1]) //+ ' 23:59:59'
      this.isShowCustomTimeDialog = false
      this.getFlowList()
    },
    // 佣金明细
    getFlowList() {
      this.calTime()
      flowList(this.flowListParams).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data
        }
      })
    }
  },
  created() {
    this.getUserInfo()
    this.getBalance()
    this.getPromotionQrcode()
    this.getFlowList()
  },
  computed: {
    avatarUrl() {
      if(this.userInfo.avatar) {
        return this.$store.getters.imgeCDN + this.userInfo.avatar
      }
      return ''
    }
  }
}
</script>

<style lang="less" scoped>
.my-promotion {
  .info {
    height: 300px;
    display: flex;
    justify-content: space-between;

    .base-info {
      width: 913px;
      height: 100%;
      background: #FFFFFF;
      border-radius: 13px;
      padding: 40px 33px 0;

      .person {
        display: flex;
        align-items: center;
        margin-bottom: 35px;

        .av {
          width: 80px;
          height: 80px;
          border-radius: 40px;
          margin-right: 33px;
        }

        .name {
          font-weight: 500;
          font-size: 27px;
          color: #222222;
        }
      }

      .bottom {
        width: 847px;
        height: 107px;
        background: rgba(38, 118, 247, 0.06);
        border-radius: 13px 13px 13px 13px;
        padding: 0 40px 0 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 27px;
        color: #999999;

        .txt-blue {
          font-weight: 500;
          color: #2676F7;
        }

        .withdraw {
          width: 143px;
          height: 47px;
          line-height: 47px;
          text-align: center;
          background: #2676F7;
          font-weight: 500;
          font-size: 17px;
          color: #FFFFFF;
          border-radius: 7px 7px 7px 7px;
          cursor: pointer;
        }
      }
    }

    .qr-code-div {
      width: 267px;
      height: 100%;
      background: #FFFFFF;
      border-radius: 13px;
      padding: 20px 20px 0 20px;

      .title {
        font-weight: 600;
        font-size: 20px;
        color: #222222;
        margin-bottom: 25px;
      }

      img {
        width: 197px;
        height: 197px;
        margin-left: 15px;
      }
    }
  }

  .tab{
    width: 100%;
    height: 92px;
    background: #FFFFFF;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 58px;
    margin-bottom: 20px;

    .tab-item{
      width: 167px;
      height: 45px;
      line-height: 45px;
      font-size: 20px;
      color: #585858;
      text-align: center;
      background: #F7F7F7;
      border-radius: 7px;
      cursor: pointer;
    }

    .active-tab{
      background: rgba(38,118,247,0.08);
      border-radius: 7px 7px 7px 7px;
      color: #2676F7;
    }

  }

  .data-list{
    padding: 40px;
    background: white;
  }

}
</style>