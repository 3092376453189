<!--
 * @Author: angel~dongsanling
 * @Date: 2024-08-04 08:04:12
 * @Description: 省市县三级联动
-->
<template>
  <div class='area'>
    <el-cascader
      ref="cascader1"
      v-model="value"
      :props="props" 
      :options="areaArr" 
      @visible-change="visibleChange"
      @change="handleChange">
    </el-cascader>
  </div>
</template>

<script>
import { getArea } from '@/api/common'
export default {
  data() {
    return {
      areaArr: [],
      value: [],
      props: { // 省市县懒加载
        label: 'name',
        value: 'id',
        children: 'children',
        leaf: 'children',
        lazy: true,
        lazyLoad (node, resolve) {
          const { level } = node;
          if (level < 3) {
            getArea({ pid: node.value }).then(res => {
              if (res.code == 1) {
                if (node.level >= 3) {
                  resolve(res.data);
                } else {
                  // 对于非最后一级的节点，继续加载子节点
                  res.data.forEach(child => {
                    child.children = undefined; // 确保每个节点都有children属性
                  });
                  resolve(res.data);
                }
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            resolve([])
          }
        }
      }
    }
  },
  methods: {
    /**
     * @Author: angel~dongsanling
     * @description: 获取一级省份
     */    
    getArea () {
      getArea({ id: 0 }).then(res => {
        if (res.code == 1) {
          this.areaArr = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 选择城市
     * @param {*} data
     */    
    handleChange(data){
      this.value = data
      this.$emit('handleChangeProp',data)
    },
    clear() {
      this.value = []
    },
    selectArea() {
      this.$refs.cascader1.toggleDropDownVisible();
    },
    visibleChange(type) {
      if(!type && this.value.length==0){
        this.$emit('handleChangeProp', this.value)
      }
    }
  },
  created() {
    this.getArea()
  }
}
</script>

<style lang="less" scoped>
.area{
  width: 100%;

  /deep/.el-cascader{
    width: 100%;
  }
}
</style>