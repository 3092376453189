<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-24 19:19:20
 * @Description: 商品分类
-->
<template>
  <div class="all-products">
    <!-- 搜索 start -->
    <div class="all-products-search">
      <div class="all-products-search-box width1200">
        <search ref="search" @searchBtnClick="search"></search>
      </div>
    </div>
    <!-- 搜索 end -->
    
    <!-- 面包屑 start -->
    <div class="all-products-crumbs">
      <div class="all-products-crumbs-box width1200">
        <crumbsVue ref="crumbsVue" :navs="navs"></crumbsVue>
      </div>
    </div>
    <!-- 面包屑 end -->

    <!-- 搜索后排序 start -->
    <div class="home-index-main-sort width1200">
      <div class="home-index-main-sort-box flex space-between">
        <div class="home-index-main-sort-box-item cursor" 
          v-for="(item,index) in sortArr" 
          :class="{'active': currentSortIndex === index}" 
          :key="index"
          @click="sortHandle(index)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 搜索后排序 end -->

    <!-- 上装 start -->
    <div class="come-in-shop-box-content-shop-card width1200" 
      v-for="(item, index) in sortArr && sortArr[currentSortIndex] && sortArr[currentSortIndex].childlist" 
      :key="index">
      <div class="come-in-shop-box-content-shop-card-title cursor"
        @click="operate('productDetail',item)">
        {{ item.name }}
      </div>
      <div class="come-in-shop-box-content-shop-card-box">
        <template v-if="item.childlist.length">
          <div class="come-in-shop-box-content-shop-card-box-item cursor" 
            v-for="(subItem, subIndex) in item.childlist"
            :key="subIndex"
            @click="operate('productDetail',subItem)">
            <div class="come-in-shop-box-content-shop-card-item-img">
              <img :src="`${$store.getters.imgeCDN}${subItem.image}`" alt="">
            </div>
            <div class="come-in-shop-box-content-shop-card-item-txt">
              {{ subItem.name }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 上装 end -->
  </div>
</template>

<script>
import search from '@/components/shoppingMall/search.vue'
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import { wanlshopInit } from '../../../api/shoppingMall'

export default {
  components: {
    search,
    crumbsVue,
  },
  props:{
    
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '商品分类',
        },
      ],
      sortArr: [],
      currentSortIndex: 0,
    }
  },
  created () {
    this.getWanlshopInit()
  },
  methods: {
    /**
     * @Author: angel~dongsanling
     * @description: 搜索回调
     * @param {*} data 搜索框内容
     */    
    search (data) {
      this.$router.push({
        path: 'home',
        query: {
          'search': data
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 获取商品类目
     */    
    getWanlshopInit () {
      this.sortArr = []
      wanlshopInit({version: '1.1.9'}).then(res => {
        if (res.code == 1) {
          this.sortArr = res.data.modulesData.categoryModules
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 操作
     * @param {*} type 根据类型判断是哪个方法
     * @param {*} data 当前数据
     */    
    operate (type,data) {
      switch (type) {
        case 'productDetail':
          this.$router.push({
            path: 'category-details',
            query: {
              'search': data.name,
              'type': '类目：',
              'id': data.id
            }
          })
          break
      }
    },
    /**
     * @Author: angel~dongsanling
     * @description: 排序点击选中
     * @param {*} index 当前选中的index
     */    
    sortHandle (index) {
      this.currentSortIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.all-products{
  padding-bottom: 60px;
  .all-products-search{
    background: #fff !important;
    padding-bottom: 35px;
  }
  .all-products-crumbs{
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .home-index-main-sort{
    width: 1200px;
    height: 106px;
    padding: 0px 50px;
    background: #FFFFFF;
    border-radius: 4px;
    .home-index-main-sort-box{
      flex: 1;
      height: 100%;
      box-sizing: border-box;
      align-items: center !important;
      .home-index-main-sort-box-item{
        font-size: 20px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &.active{
          color: #2676F7;
          font-size: 20px;
          font-weight: 500;
          position: relative;
        }
        &.active::after{
          content: '';
          width: 32px;
          height: 20px;
          background: url(../../../assets/images/active-bottom-icon.png) no-repeat center center;
          background-size: 80%; 
          position: absolute;
          left: 50%;
          bottom: -15px;
          transform: translateX(-50%);
        }
      }
    }
  }
  .come-in-shop-box-content-shop-card{
    padding: 30px;
    margin-top: 30px;
    background: #fff;
    padding-bottom: 0px;
    // margin-bottom: 30px;
    .come-in-shop-box-content-shop-card-title{
      padding-bottom: 20px;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
    }
    .come-in-shop-box-content-shop-card-box{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .come-in-shop-box-content-shop-card-box-item{
        margin-right: 20px;
        .come-in-shop-box-content-shop-card-item-img{
          width: 270px;
          height: 270px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .come-in-shop-box-content-shop-card-item-txt{
          height: 80px;
          font-weight: 500;
          font-size: 22px;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: center
        }
      }
      .come-in-shop-box-content-shop-card-box-item:nth-child(4n){
        margin-right: 0px !important;
      }
    } 
  }
}
</style>