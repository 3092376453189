<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-24 19:14:45
 * @Description: 
-->
<template>
  <div class='shopping-mall-layout'>
    <!-- 头部 start -->
    <headerDom ref='headerDom' @headerItemClick="headerItemClick" @personalClick="goPersonalCenter" @setItemClick="setItemClick" @rightMenuClick="rightMenuClick"></headerDom>
    <!-- 头部 end -->

    <router-view class="router-view" />

    <!-- 底部 start -->
    <footerDom ref="footerDom" @footerTabClick="footerTabClick"></footerDom>
    <!-- 底部 end -->
  </div>
</template>

<script>
import headerDom from '@/components/shoppingMall/header.vue'
import footerDom from '@/components/shoppingMall/footer.vue'
import { TUILogin } from "@tencentcloud/tui-core";

export default {
  components: {
    headerDom,
    footerDom
  },
  data() {
    return {
      
    }
  },
  methods: {
    headerItemClick(item) {
      let path = `${window.location.origin}${item.path}`
      window.open(path, '_blank')
      // this.$router.push({path: item.path})
    },
    goPersonalCenter() {
      this.$router.push({path: '/personal-center'})
    },
    setItemClick(item) {
      if(item.type && item.type == 'logout'){
        localStorage.clear()
        this.$router.push({
          path: '/login'
        })
        TUILogin.logout().then(res=>{
          // debugger
        }).catch(err=>{
          // debugger
        })
      } else {
        this.$router.push({ path: item.path })
      }
    },
    rightMenuClick(item) {
      this.$router.push({ path: item.path })
    },
    footerTabClick(type) {
      this.$router.push('/agreement?type=' + type)
    }
  },
  watch: {
   
  }
}
</script>

<style lang="less" scoped>
.shopping-mall-layout {
  height: 100%;
  // background-color: #F7F7F7;
  .router-view {
    background-color: #F7F7F7;
    min-height: calc(100% - 321px);
  }
}

</style>