<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-24 19:19:20
 * @Description: 优惠券组件
-->
<template>
  <div class="coupon">
    <div :class="item.type ? 'coupon-item ' + item.type : 'coupon-item'" @click="selectItem(item)">
      <div class="coupon-item-left">
        <div class="coupon-item-left-price">
          ¥
          <strong>{{ item.price }}</strong>
        </div>
        <div class="full-use">
          {{ item.name }}
        </div>
      </div>
      <div class="coupon-item-right">
        <div class="coupon-item-right-shop">
          <img src="../../assets/images/icon-shop.png" alt="">
          {{ item.shop.shopname }}>
        </div>
        <div class="coupon-item-right-title">
          {{ endTime}}
        </div>
        <div class="coupon-item-right-num">
          <div v-if="item.drawlimit>0">{{ `每人限领${item.drawlimit}张`}}</div>
          <div v-else class="dis-none">x</div>
        </div>
        <div class="coupon-item-right-btn cursor" v-if="btn" @click.stop="receive()">
          {{ item.btnText ? item.btnText : '立即领取' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    btn:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
    }
  },
  methods: {
    selectItem(item) {
      this.$emit('selectCoupon', this.item)
    },
    receive() {
      this.$emit('receive', this.item, this.item.btnText == '立即使用' ? 0 : 1)
    }
  },
  computed: {
    endTime() {
      if(this.item.pretype_text == "领取后到期天数") {
        if(this.item.validity == 0) {
          return '未使用前 永久有效'
        } else {
          return `领取后${this.item.validity}天有效`
        }
      } else {
        return `${this.item.startdate}至${this.item.enddate}有效`
      }
    }
  }
}
</script>

<style lang="less" scoped>
.coupon {
  .coupon-item {
    width: 375px;
    height: 130px;
    padding: 13px 0px;
    display: flex;
    justify-content: flex-start;
    background: #FFF5F2;
    position: relative;
    border-radius: 5px;
    // margin-bottom: 20px;
    cursor: pointer;
    align-items: center;
    .coupon-item-left {
      width: 92px;
      position: relative;

      .coupon-item-left-price {
        color: #FF2424;
        text-align: center;

        strong {
          font-size: 30px;
          font-weight: 500;
          color: #FF2424;
        }
      }

      .full-use {
        font-size: 14px;
        color: #FF2424;
        text-align: center;
      }
    }

    .coupon-item-left::after {
      content: '';
      width: 0px;
      height: 99px;
      border-radius: 2px;
      border-left: 1px dotted #FFE6DF;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
    }

    .coupon-item-right {
      position: relative;
      padding-left: 18px;
      flex: 1;
      .coupon-item-right-shop {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        margin-bottom: 9px;
        margin-top: 10px;

        img {
          width: 14px;
          height: 14px;
          display: inline-block;
        }
      }

      .coupon-item-right-title {
        font-size: 18px;
        font-weight: 500;
        color: #333;
        margin-bottom: 6px;
      }

      .coupon-item-right-num {
        font-size: 14px;
        color: #999;

        .dis-none {
          visibility: hidden;
        }
      }

      .coupon-item-right-btn {
        width: 82px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #FF2424;
        border-radius: 16px;
        border: 1px solid #FF2424;
        position: absolute;
        right: 15px;
        bottom: -10px;
      }
    }

    .coupon-item-right::before {
      content: '';
      width: 15px;
      height: 15px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      left: -8px;
      top: -26px;
    }

    .coupon-item-right::after {
      content: '';
      width: 15px;
      height: 15px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      left: -8px;
      top: 100px;
    }
  }
  .reduction::after{
    content: '满减券';
    width: 61px;
    height: 70px;
    color: #fff;
    position: absolute;
    right: 0px;
    top: 10px;
    transform: rotate(45deg);
  }
  .discount::after{
    content: '折扣券';
    width: 61px;
    height: 70px;
    color: #fff;
    position: absolute;
    right: 0px;
    top: 10px;
    transform: rotate(45deg);
  }
  .shipping::after{
    content: '包邮券';
    width: 61px;
    height: 70px;
    color: #fff;
    position: absolute;
    right: 0px;
    top: 10px;
    transform: rotate(45deg);
  }
  .coupon-item::before {
    content: '';
    width: 61px;
    height: 58px;
    color: #fff;
    background: url(../../assets/images/coupon-type.png) no-repeat center center;
    position: absolute;
    right: 0px;
    top: 0px;
  }
}</style>