<template>
  <div class='job-item' @click="itemClick()">
    <div class="top-content">
      <div class="top">
        <div class="job-name">{{ item.name }}</div>
        <div class="num">{{ item.salary_name }}</div>
      </div>
      <div class="detail">
        <span>{{ city }}</span><span>{{ item.education_name }}</span><span>{{ item.work_name }}</span>
      </div>
    </div>
    <div class="bottom">
      <div class="company-name">{{ item.company_name }}</div>
      <div>{{ item.scale_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  methods: {
    itemClick() {
      this.$emit('itemClick', this.item)
    }
  },
  computed: {
    city() {
      let cityArr = this.item.mergename.split(',')
      if(cityArr.length == 3){
        return cityArr[1]
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.job-item{
  width: 386px;
  height: 195px;
  background: #FFFFFF;
  border-radius: 13px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  .top-content{
    height: 127px;
    padding: 27px 27px 0 27px;
    text-align: left;

    .top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .job-name{
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: 20px;
        color: #222222;
      }

      .num{
        font-weight: 500;
        font-size: 20px;
        color: #E11E34;
      }
    }
    .detail{
      span{
        padding: 4px 10px;
        font-weight: 400;
        font-size: 15px;
        color: #585858;
        background-color: #F7F7F7;
        margin-right: 13px;
        border-radius: 3px;
      }
    }
  }

  .bottom{
    height: 68px;
    background-color: #F9FBFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    color: #585858;
    padding: 0 27px;
    border-radius: 0 0 13px 13px;

    .company-name {
      max-width: 270px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    span{
      margin-right: 20px;
    }
  }
}

</style>