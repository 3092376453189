<template>
  <div class='apply-withdraw'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        申请提现
        <div class="btn-txt-white" @click="goWithdrawRecord()">提现记录</div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="content">
          <div>
            <div class="tip">提示：申请提现后，需等待后台审核，审核通过后才可以收到提现，请确认输入信息的准确，以免影响审核结果</div>
            <div>
              <el-row class="row mb0">
                <el-col :span="6" class="txt-right">提现金额</el-col>
                <el-col :span="14">
                  <el-input placeholder="请输入提现金额" v-model="params.amount"></el-input>
                </el-col>
                <!-- <el-col :span="4" class="txt-blue">提现至银行卡</el-col> -->
              </el-row>
              <el-row class="row mb0">
                <el-col :span="6"></el-col>
                <el-col :span="14" class="sub-info">
                  可提现金额 <span class="amount">¥{{ balanceData.balance }}</span> <span class="txt-blue" @click="withdrawAll()">全部提现</span>
                </el-col>
                <el-col :span="4"></el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="6" class="txt-right">银行名称</el-col>
                <el-col :span="14">
                  <el-input placeholder="请输入银行名称" v-model="params.bank"></el-input>
                </el-col>
                <el-col :span="4"></el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="6" class="txt-right">银行(支行)</el-col>
                <el-col :span="14">
                  <el-input placeholder="请输入银行支行名称" v-model="params.bankof"></el-input>
                </el-col>
                <el-col :span="4"></el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="6" class="txt-right">银行卡号</el-col>
                <el-col :span="14">
                  <el-input placeholder="请输入银行卡号" v-model="params.bank_card"></el-input>
                </el-col>
                <el-col :span="4"></el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="6" class="txt-right">持卡人姓名</el-col>
                <el-col :span="14">
                  <el-input placeholder="请输入持卡人姓名" v-model="params.name"></el-input>
                </el-col>
                <el-col :span="4"></el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="6" class="txt-right">银行手机号</el-col>
                <el-col :span="14">
                  <el-input placeholder="请输入银行卡手机号" v-model="params.mobile"></el-input>
                </el-col>
                <el-col :span="4"></el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="6"></el-col>
                <el-col :span="12">
                  <div class="apply-btn" @click="apply()">申请提现</div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { balance, applyWithdraw } from '@/api/jobSeeker'

export default {
  data() {
    return {
      params: {
        amount: '',
        bank: '',
        bankof: '',
        bank_card: '',
        name: '',
        mobile: ''
      },
      balanceData: {}
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    goWithdrawRecord() {
      this.$router.push({path: 'withdraw-record'})
    },
    getBalance() {
      balance().then(res=>{
        if(res.code == 1) {
          this.balanceData = res.data
        }
      })
    },
    withdrawAll() {
      this.params.amount = this.balanceData.balance
    },
    apply() {
      applyWithdraw(this.params).then(res=>{
        if(res.code == 1) {
          this.$message.success('申请成功，请等待后台审核')
          this.getBalance()
        }
      })
    }
  },
  created() {
    this.getBalance()
  }
}
</script>

<style lang='less' scoped>
.apply-withdraw {

  .content {
    border-radius: 13px 13px 13px 13px;
    padding: 20px 20px 45px;
    margin-bottom: 41px;
    background: white;
    display: flex;
    justify-content: center;

    .tip {
      height: 50px;
      line-height: 50px;
      padding: 0 40px;
      margin-bottom: 27px;
      background: rgba(225,30,52,0.06);
      border-radius: 7px 7px 7px 7px;
      font-size: 17px;
      color: #E11E34;
    }

    .row {
      height: 53px;
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #222222;
      margin-bottom: 20px;

      .txt-right {
        text-align: right;
        padding-right: 33px;
      }
      .txt-blue {
        color: #2676F7;
        padding-left: 20px;
        cursor: pointer;
      }

      .sub-info {
        font-size: 17px;
        color: #999999;

        .amount {
          color: #222222;
        }

        span:nth-child(1){
          margin-left: 10px;
        }
        span:nth-child(2){
          margin-left: 13px;
          padding-left: 0px;
        }
      }

      .apply-btn {
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #fff;
        background-color: #2676F7;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .mb0 {
      margin-bottom: 0px;
    }
  }

  /deep/.el-input__inner{
    height: 53px;
    line-height: 53px;
    font-size: 20px;
  }
}

</style>