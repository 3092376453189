<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-24 19:19:20
 * @Description: 类目详情
-->
<template>
  <div class="all-products">
    <!-- 搜索 start -->
    <div class="all-products-search">
      <div class="all-products-search-box width1200">
        <search ref="search" :searchInput="search" @searchBtnClick="searchHandle"></search>
      </div>
    </div>
    <!-- 搜索 end -->

    <!-- 面包屑 start -->
    <div class="all-products-crumbs">
      <div class="all-products-crumbs-box width1200">
        <crumbsVue ref="crumbsVue" :navs="navs"></crumbsVue>
      </div>
    </div>
    <!-- 面包屑 end -->

    <!-- 搜索后排序 start -->
    <div class="home-index-main-sort flex space-between width1200">
      <div class="home-index-main-sort-box flex space-between">
        <div class="home-index-main-sort-box-item cursor"
          :class="{'active': currentSortIndex === index}"
          v-for="(item,index) in sortArr"
          :key="index"
          @click="sortHandle(index,item)">
          {{ item.name }}
        </div>
      </div>
      <!-- <div class="home-index-main-sort-img cursor">
        <img src="../../../assets/images/icon-all.png" alt="">
      </div> -->
      <div class="screen" v-if="screenFlag">
        <div class="screen-city flex space-between">
          <div class="screen-city-label">
            发货地
          </div>
          <div class="screen-city-value cursor">
            <div class="sort-active" @click="selectCity">
              <span class="sort-active">{{ sendGoodAddress }}</span>
              <span class="el-icon-arrow-right sort-active"></span>
            </div>
            <div class="screen-city-box" v-if="cityFlag">
              <div class="screen-city-box-item sort-active"
                :class="{'active': currentIndexCity === index}"
                v-for="(item, index) in city"
                :key="index"
                @click="handleCity(index, item)">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="screen-price">
          <div class="screen-price-label">
            价格区间
          </div>
          <div class="screen-price-value flex space-between">
            <el-input v-model="minVal" placeholder="最低价" oninput="value = value.replace(/^\D*([0-9]\d*\.?\d{0,4})?.*$/,'$1')"></el-input>
            -　
            <el-input v-model="maxVal" placeholder="最高价" oninput="value = value.replace(/^\D*([0-9]\d*\.?\d{0,4})?.*$/,'$1')"></el-input>
          </div>
        </div>
        <div class="screen-btn flex flex-end">
          <div class="screen-btn-reset tcenter cursor" @click="operate('reset')">
            重置
          </div>
          <div class="screen-btn-save tcenter cursor" @click="operate('save')">
            确定
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索后排序 end -->
    <div class="all-products-product-content width1200">
      <productCard ref="productCard" :sellArr="sellArr" v-if="sellArr.length"></productCard>
      <div v-else class="empty">没有更多商品了</div>
    </div>
  </div>
</template>

<script>
import search from '@/components/shoppingMall/search.vue'
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import productCard from '@/components/shoppingMall/productCard.vue'
import { wanlshoSetSearch,wanlshopProductLists  } from '../../../api/shoppingMall'
export default {
  components: {
    search,
    crumbsVue,
    productCard
  },
  props:{

  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '搜索商品',
        },
      ],
      sortArr: [{
        name: '综合',
        type: 1,
        sort: 'weigh',
        order: 'desc',
      },{
        name: '销量',
        type: 2,
        sort: 'sales',
        order: 'desc',
      },{
        name: '新上架',
        type: 3,
        sort: 'createtime',
        order: 'desc',
      },{
        name: '价格',
        type: 4,
        sort: 'price',
        order: 'desc',
      },{
        name: '筛选',
        type: 5,
        sort: 'weigh',
        order: 'desc',
      }],
      currentSortIndex: 0,
      sellArr: [],
      minVal: '',
      maxVal: '',
      screenFlag: false,
      search: '',
      sendGoodAddress: '', // 发货地
      currentIndexCity: 0,
      cityFlag: false,
      city: [
        { name: '北京', choice: false },
        { name: '天津', choice: false },
        { name: '河北', choice: false },
        { name: '山西', choice: false },
        { name: '内蒙古', choice: false },
        { name: '山东', choice: false },
        { name: '江苏', choice: false },
        { name: '上海', choice: false },
        { name: '浙江', choice: false },
        { name: '安徽', choice: false },
        { name: '福建', choice: false },
        { name: '江西', choice: false },
        { name: '河南', choice: false },
        { name: '湖南', choice: false },
        { name: '湖北', choice: false },
        { name: '广东', choice: false },
        { name: '广西', choice: false },
        { name: '海南', choice: false },
        { name: '辽宁', choice: false },
        { name: '吉林', choice: false },
        { name: '黑龙江', choice: false },
        { name: '四川', choice: false },
        { name: '贵州', choice: false },
        { name: '云南', choice: false },
        { name: '重庆', choice: false },
        { name: '宁夏', choice: false },
        { name: '青海', choice: false },
        { name: '陕西', choice: false },
        { name: '甘肃', choice: false },
        { name: '新疆', choice: false },
        { name: '西藏', choice: false },
        { name: '香港', choice: false },
        { name: '澳门', choice: false },
        { name: '台湾', choice: false }
      ],
      shopType: '', // 当前店铺搜索
      shopCategory: '', // 店铺分类
    }
  },
  created () {
    this.search = this.$route.query.search ? this.$route.query.search : ''
    this.shopType = this.$route.query.shopType ? this.$route.query.shopType : ''
    this.shopCategory = this.$route.query.shopCategory ? this.$route.query.shopCategory : ''
    if (this.$route.query.type) {
      this.search = `${this.$route.query.type}${this.search}`
    }
    if (this.shopCategory == 'shopCategory') {
      this.search = ''
      this.setSearch(this.shopCategory)
    }
    if (this.search) {
      this.searchHandle(this.search)
    }
    if (this.shopType && this.shopType == 'shopType') {
      this.wanlshopProductLists('shopType')
    }
  },
  methods: {
    /**
     * @Author: angel~dongsanling
     * @description: 当前选择的城市
     * @param {*} index 当前选择的下标
     * @param {*} data 当前数据
     */
    handleCity (index, data) {
      this.currentIndexCity = index
      this.sendGoodAddress = data.name
      this.cityFlag = false
    },
    /**
     * @Author: angel~dongsanling
     * @description: 选择城市
     */
    selectCity () {
      this.cityFlag = true
    },
    /**
     * @Author: angel~dongsanling
     * @description: 筛选后调用接口
     */
    wanlshopProductLists (type) {
      let params = {
        search: this.search,
        sort: this.sortArr[this.currentSortIndex].sort,
        order: 'desc',
        page: 1,
        filter: {},
        op: {},
        type: 'goods'
      }
      if (type == 'shopType') {
        params.filter['shop_id'] = this.$route.query.shop_id ? this.$route.query.shop_id : ''
        params.op['shop_id'] = 'in'
      } else if (type == 'shopCategory') {
        params.filter['shop_category_id'] = this.$route.query.id ? this.$route.query.id : ''
        params.op['shop_category_id'] = 'FIND_IN_SET'
      } else {
        params.filter['category_id'] = this.$route.query.id ? this.$route.query.id : ''
        params.op['category_id'] = 'in'
        params.search = ''
      }
      if (this.minVal && this.maxVal) {
        params.filter['price'] = `${this.minVal} , ${this.maxVal}`
        params.op['price'] = 'BETWEEN'
      } else {
        delete params.filter.price;
				delete params.op.price;
      }
      if (this.sendGoodAddress) {
        params.filter['shop.city'] = '%' + this.sendGoodAddress + '%'
        params.op['shop.city'] = 'LIKE'
      } else {
        delete params.filter['shop.city']
				delete params.op['shop.city']
      }
      params.filter = params.filter ? JSON.stringify(params.filter) : {}
      params.op = params.op ? JSON.stringify(params.op) : {}
      params.search = params.search ? JSON.stringify(params.search) : ''
      wanlshopProductLists(params).then(res => {
        if (res.code == 1) {
          if (res.data.data.length) {
            res.data.data.map((item, index) => {
              item.img = `${this.$store.getters.imgeCDN}${item.image}`
            })
          }
          this.sellArr = res.data.data
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 搜索回调
     * @param {*} data 搜索框内容
     */
    searchHandle (data) {
      this.search = data
      this.setSearch(this.shopCategory)
    },
    /**
     * @Author: angel~dongsanling
     * @description: 商品热门搜索
     */
    setSearch (type) {
      wanlshoSetSearch({ keywords: this.search ? JSON.stringify(this.search) : '' }).then(res => {
        if (res.code == 1) {
          this.wanlshopProductLists(type)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 操作
     * @param {*} type 根据类型判断是哪个方法
     */
    operate (type) {
      switch (type) {
        case 'reset':
          this.screenFlag = false
          this.maxVal = ''
          this.minVal = ''
          this.sendGoodAddress = ''
          break
        case 'save':
          this.screenFlag = false
          if (this.shopType) {
            this.wanlshopProductLists(this.shopType)
          } else {
            this.wanlshopProductLists(this.shopCategory)
          }
          break
      }
    },
    /**
     * @Author: angel~dongsanling
     * @description: 排序点击选中
     * @param {*} index 当前选中的index
     */
    sortHandle (index,data) {
      this.currentSortIndex = index
      if (data.type === 5){
        this.screenFlag = true
      } else {
        this.screenFlag = false
      }
      if (this.shopType) {
        this.wanlshopProductLists(this.shopType)
      } else {
        this.wanlshopProductLists(this.shopCategory)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.all-products{
  padding-bottom: 30px;
  .all-products-search{
    background: #fff !important;
    padding-bottom: 35px;
  }
  .all-products-crumbs{
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .home-index-main-sort{
    width: 1200px;
    height: 106px;
    padding: 0px 50px;
    background: #FFFFFF;
    border-radius: 4px;
    position: relative;
    .screen{
      width: 337px;
      height: 226px;
      padding: 20px;
      background: #FFFFFF;
      box-shadow: 0px 9px 19px 0px rgba(0,0,0,0.14);
      border-radius: 0px 0px 10px 10px;
      position: absolute;
      right: 26px;
      top: 100px;
      box-sizing: border-box;
      z-index: 100;
      .screen-city{
        padding-bottom: 15px;
        border-bottom: 1px solid #F0F0F0;
        .screen-city-label{
          font-weight: 500;
          font-size: 16px;
          color: #333333;
        }
        .screen-city-value{
          font-weight: 400;
          font-size: 14px;
          color: #999999;
          .screen-city-box{
            width: 400px;
            height: 400px;
            overflow: auto;
            padding: 10px 10px;
            position: absolute;
            right: 0px;
            top: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            background: #fff;
            z-index: 1000;
            .screen-city-box-item{
              width: 110px;
              height: 40px;
              line-height: 40px;
              background: #f8f8f8;
              text-align: center;
              border-radius: 4px;
              margin-right: 10px;
              margin-bottom: 10px;
              &.active{
                background: #2676F7;
                color: #fff;
              }
            }
            .screen-city-box-item:nth-child(3n){
              margin-right: 0px;
            }
          }
        }
      }
      .screen-price{
        margin-bottom: 30px;
        margin-top: 14px;
        .screen-price-label{
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          margin-bottom: 10px;
        }
        .screen-price-value{
          /deep/.el-input{
            .el-input__inner{
              width: 130px;
              height: 32px;
            }
          }
        }
      }
      .screen-btn{
        .screen-btn-reset{
          width: 70px;
          height: 40px;
          line-height: 40px;
          font-weight: 500;
          font-size: 15px;
          color: #333333;
          background: #FFFFFF;
          border-radius: 1px;
          border: 1px solid #DDDDDD;
          margin-right: 15px;
        }
        .screen-btn-save{
          width: 100px;
          height: 40px;
          line-height: 40px;
          color: #fff;
          font-weight: 500;
          font-size: 15px;
          background: #2676F7;
          border-radius: 1px;
        }
      }
    }
    .home-index-main-sort-box{
      flex: 1;
      height: 100%;
      box-sizing: border-box;
      align-items: center !important;
      padding-right: 60px;
      .home-index-main-sort-box-item{
        font-size: 20px;
        font-weight: 500;
        &.active{
          color: #2676F7;
          font-size: 20px;
          font-weight: 500;
          position: relative;
        }
        &.active::after{
          content: '';
          width: 32px;
          height: 20px;
          background: url(../../../assets/images/active-bottom-icon.png) no-repeat center center;
          background-size: 80%;
          position: absolute;
          left: 50%;
          bottom: -15px;
          transform: translateX(-50%);
        }
      }
      .home-index-main-sort-img{
        width: 30px;
      }
    }
  }
  .all-products-product-content{
    margin-top: 30px;
    .empty{
      width: 100%;
      font-size: 16px;
      text-align: center;
    }
  }
}
</style>
