<template>
  <div class='points'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <div class="content">
          <div class="top">积分：{{ userInfo.score }}</div>
          <pointItem v-for="(item, index) in dataList" :key="index" :item="item"></pointItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import pointItem from '@/components/shoppingMall/pointItem.vue';
import { scoreLog } from '@/api/shoppingMall'
import { userInfo } from '@/api/userInfo'

export default {
  components: {
    crumbsVue,
    pointItem,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '我的积分',
        },
      ],
      userInfo: {},
      dataList: []
    }
  },
  methods: {
    getUserInfo() {
      userInfo().then(res=>{
        if(res.code == 1) {
          this.userInfo = res.data.userinfo
        }
      })
    },
    getList() {
      let params = {
        page: 1
      }
      scoreLog(params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data.data
        }
      })
    }
  },
  created() {
    this.getUserInfo()
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.points {
  .content {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 30px;
    margin-bottom: 30px;
    .top{
      font-weight: 600;
      font-size: 24px;
      color: #333333;
      padding-bottom: 20px;
      border-bottom: 1px solid #D8D8D8;
    }
  }
}
</style>