<template>
  <div class='collection'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <div class="content">
          <collectionItem class="mb20" v-for="(item, index) in dataList" :key="index" :item="item" @itemClick="itemClick" @goShop="goShop" @deleteItem="deleteItem"></collectionItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import collectionItem from '@/components/shoppingMall/collectionItem.vue'
import { collectList, deleteCollect } from '@/api/shoppingMall'

export default {
  components: {
    crumbsVue,
    collectionItem,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '我的收藏',
        },
      ],
      dataList: []
    }
  },
  methods: {
    itemClick(item) {
      item.id = item.goods_id
      this.$router.push({path: 'product-detail', query: {type: 'product',item: JSON.stringify(item)}})
    },
    goShop(item) {
      this.$router.push({path: 'come-in-shop', query: {shop_id: item.goods.shop_id}})
    },
    deleteItem(item) {
      let params = {
        id: item.goods_id
      }
      deleteCollect(params).then(res=>{
        if(res.code == 1) {
          this.getList()
        }
      })
    },
    getList() {
      let params = {
        type: 'goods',
        page: 1
      }
      collectList(params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data.data
        }
      })
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.collection {
  .content{
    margin-bottom: 30px;
  }
}
</style>