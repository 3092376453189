<template>
  <div class='coupons'>
    <!-- <div class="top">
      <search class="width1200" placeholder="搜索红包卡券" @searchBtnClick="search"></search>
    </div> -->
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <div class="content">
          <div class="tabs">
            <div class="tab-item" :class="{'active': activeIndex==item.index}" v-for="(item, indx) in tabs" :key="indx" @click="tabItemClick(item)">{{ item.title }}</div>
          </div>
          <div class="view">
            <coupon :class="{'mr16 mb16': (index + 1) % 3 != 0, 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in dataList" :key="index" :item="item" @receive="receive"></coupon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import search from '@/components/shoppingMall/search.vue'
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import coupon from '@/components/shoppingMall/coupon.vue';
import { couponList, couponReceive } from '@/api/shoppingMall'

export default {
  components: {
    // search,
    crumbsVue,
    coupon,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '红包卡券',
        },
      ],
      activeIndex: 0,
      tabs: [
        {
          index: 0,
          title: "满减券",
          type: 'reduction'
        },{
          index: 1,
          title: "折扣券",
          type: 'discount'
        },{
          index: 2,
          title: "包邮券",
          type: 'shipping'
        },
      ],
      dataList: []
    }
  },
  methods: {
    search(serachInput) {
    },
    tabItemClick(item) {
      this.activeIndex = item.index
      this.dataList = []
      this.getList()
    },
    getList() {
      let params = {
        type: this.tabs[this.activeIndex].type,
        page: 1
      }
      couponList(params).then(res=>{
        if(res.code == 1) {
          if (res.data.data.length) {
            res.data.data.map((item,index) => {
              item.btnText = '立即领取'
            })
          }
          this.dataList = res.data.data
        }
      })
    },
    receive(item, type) { //type==1 立即领取  0立即使用
      if(type == 1) {
        couponReceive({id: item.id}).then(res=>{
          if(res.code == 1) {
            this.$message.success(res.data.msg)
            item.btnText = '立即使用'
          }
        })
      } else {
        this.$router.push({path: 'come-in-shop', query: {shop_id: item.shop_id}})
      }
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.coupons {
  .top {
    width: 100%;
    height: 140px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .search{
      margin-top: 0;
    }
  }
  .content {
    margin-bottom: 60px;
    padding: 30px 20px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    .tabs {
      margin-left: 10px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      .tab-item {
        margin-right: 60px;
        padding-bottom: 7px;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
        border-bottom: 2px solid #fff;
        cursor: pointer;
      }
      .active {
        color: #2676F7;
        border-bottom: 2px solid #2676F7;
      }
    }

    .view {
      display: flex;
      flex-wrap: wrap;
    }
    .mr16 {
      margin-right: 16px;
    }
    .mb16 {
      margin-bottom: 16px;
    }
    .mr0 {
      margin-right: 0;
    }
  }
}
</style>