<template>
  <div class='work-feel'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        在职感受
        <div class="btn-txt-white" @click="addFeel()">添加</div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="working-feel">
          <div class="feel-item" v-for="(item, index) in dataList" :key="index" @click="updateFeel(item)">
            <div class="person">
              <div class="left">
                <img :src="imgUrl(item.avatar)" alt="">
                <div>
                  <div class="name">{{ item.name }}</div>
                  <div class="sub-info">
                    <span>{{ userInfo.companyInfo.company_name }}</span> <span>在职{{item.duration}}年</span>
                  </div>
                </div>
              </div>
              <div class="right" @click.stop="deleteFeel(item)">
                <img src="../../../assets/images/delete.png" alt="">
              </div>
            </div>
            <div class="feel">{{ item.content }}</div>
          </div>
        </div>
        <!-- <div class="page">
          <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400">
        </el-pagination>
        </div> -->
      </div>
    </div>

    <el-dialog class="dialog" :visible.sync="showDialog" title="发布在职感受">
      <div class="view">
        <div class="title">头像</div>
        <div class="img-div">
          <img class="av-img" :src="params.fullurl" alt="">
          <img class="camera" src="../../../assets/images/camera.png" @click="uploadAvImage()" alt="">
          <el-upload
            class="display-none"
            ref="upload-img"
            :action="action"
            :show-file-list="false"
            :on-success="handleAvatarSuccess">
          </el-upload>
        </div>
      </div>
      <div class="view">
        <div class="title">用户名称</div>
        <div>
          <el-input placeholder="请输入用户名称" v-model="params.name"></el-input>
        </div>
      </div>
      <div class="view">
        <div class="title">从事岗位</div>
        <div>
          <el-input placeholder="请输入从事岗位" v-model="params.position"></el-input>
        </div>
      </div>
      <div class="view">
        <div class="title">在职年限</div>
        <div>
          <el-input placeholder="请输入在职年限" type="number" v-model="params.duration"></el-input>
        </div>
      </div>
      <div class="view">
        <div class="title">在职感受内容</div>
        <div>
          <el-input
            type="textarea"
            placeholder="请输入在职感受"
            v-model="params.content"
            maxlength="300"
            :autosize="{ minRows: 4}"
            show-word-limit
          >
          </el-input>
        </div>
      </div>
      <div class="btn">
        <div class="confirm" @click="confirm()">确定</div>
      </div>
    </el-dialog>
    <el-dialog class="dialog" :visible.sync="isShowDeleteDialog" title="提示">
      <div class="view">
        确定要删除吗
      </div>
      <div class="btn">
        <div class="confirm" @click="confirmDelete()">确定</div>
      </div>
    </el-dialog>

    <el-dialog class="dialog" :visible.sync="isShowUpdateDialog" title="发布在职感受">
      <div class="view">
        <div class="title">头像</div>
        <div class="img-div">
          <img class="av-img" :src="updateParams.fullurl" alt="">
          <img class="camera" src="../../../assets/images/camera.png" @click="uploadAvImage()" alt="">
          <el-upload
            class="display-none"
            ref="upload-img"
            :action="action"
            :show-file-list="false"
            :on-success="handleUpdateAvatarSuccess">
          </el-upload>
        </div>
      </div>
      <div class="view">
        <div class="title">用户名称</div>
        <div>
          <el-input placeholder="请输入用户名称" v-model="updateParams.name"></el-input>
        </div>
      </div>
      <div class="view">
        <div class="title">从事岗位</div>
        <div>
          <el-input placeholder="请输入从事岗位" v-model="updateParams.position"></el-input>
        </div>
      </div>
      <div class="view">
        <div class="title">在职年限</div>
        <div>
          <el-input placeholder="请输入在职年限" type="number" v-model="updateParams.duration"></el-input>
        </div>
      </div>
      <div class="view">
        <div class="title">在职感受内容</div>
        <div>
          <el-input
            type="textarea"
            placeholder="请输入在职感受"
            v-model="updateParams.content"
            maxlength="300"
            :autosize="{ minRows: 4}"
            show-word-limit
          >
          </el-input>
        </div>
      </div>
      <div class="btn">
        <div class="confirm" @click="confirmUpdate()">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { workFeelList } from '@/api/jobSeeker'
import { publishWorkFeel, deleteWorkFeel } from '@/api/company'

export default {
  data() {
    return {
      action: 'https://zhp.digginggoldroad.com/api/common/upload',
      userInfo: {
        companyInfo: {}
      },
      dataList: [],
      currentPage: 1,
      showDialog: false,
      isShowDeleteDialog: false,
      isShowUpdateDialog: false,
      deleteItem: {},
      params: {
        fullurl: require('@/assets/images/default-avatar.png'),
        avatar: '',
        name: '',
        position: '',
        duration: '',
        content: ''
      },
      updateParams: {}
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    addFeel() {
      this.showDialog = true
    },
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    // },
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    // },
    deleteFeel(item) {
      this.isShowDeleteDialog = true
      this.deleteItem = item
    },
    confirmDelete() {
      deleteWorkFeel({id: this.deleteItem.id}).then(res=>{
        if(res.code == 1) {
          this.isShowDeleteDialog = false
          this.getList()
        }
      })
    },
    uploadAvImage() {
      this.$refs['upload-img'].$refs['upload-inner'].handleClick()
    },
    handleAvatarSuccess(res, file) {
      this.params.fullurl = res.data.fullurl
      this.params.avatar = res.data.url
    },
    handleUpdateAvatarSuccess(res, file) {
      this.updateParams.fullurl = res.data.fullurl
      this.updateParams.avatar = res.data.url
    },
    publishCheck() {
      if(!this.params.avatar) {
        this.$message.warning('请上传头像')
        return false
      }
      if(!this.params.name) {
        this.$message.warning('请上输入用户名称')
        return false
      }
      if(!this.params.position) {
        this.$message.warning('请上输入从事岗位')
        return false
      }
      if(!this.params.duration) {
        this.$message.warning('请上输入在职年限')
        return false
      }
      if(!this.params.content) {
        this.$message.warning('请上输入在职感受')
        return false
      }
      return true
    },
    confirm() {
      if(!this.publishCheck()) return
      let params = {
        avatar: this.params.avatar,
        name: this.params.name,
        position: this.params.position,
        duration: this.params.duration,
        content: this.params.content
      }
      publishWorkFeel(params).then(res=>{
        if(res.code == 1) {
          this.showDialog = false
          this.clear()
          this.getList()
        }
      })
    },
    clear() {
      this.params.avatar = ''
      this.params.name = ''
      this.params.position = ''
      this.params.duration = ''
      this.params.content = ''
      this.params.fullurl = require('@/assets/images/default-avatar.png')
    },
    getUserInfo() {
      this.userInfo = this.$store.getters.userInfo
    },
    getList() {
      if(!this.userInfo.companyInfo){
        return
      }
      let params = {
        company_id: this.userInfo.companyInfo.id,
        page: 1,
        limit: 100
      }
      workFeelList(params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data
        }
      })
    },
    // 
    updateFeel(item) {
      this.isShowUpdateDialog = true
      this.updateParams = JSON.parse(JSON.stringify(item))
      this.updateParams.fullurl = this.$store.getters.imgeCDN + item.avatar
    },
    confirmUpdate() {
      delete this.updateParams.fullurl
      publishWorkFeel(this.updateParams).then(res=>{
        if(res.code == 1) {
          this.isShowUpdateDialog = false
          this.getList()
        }
      })
    }
  },
  created() {
    // this.getUserInfo()
    // this.getList()
  },
  mounted() {
    this.getUserInfo()
    this.getList()
  },
  computed: {
    imgUrl: function() {
      return function(url) {
        if(url) {
          return this.$store.getters.imgeCDN + url
        }
        return ''
      }
    },
  }
}
</script>

<style lang="less" scoped>
.work-feel {
  .working-feel {
    width: 100%;
    padding: 40px;
    background: white;
    border-radius: 13px 13px 13px 13px;
    margin-bottom: 20px;
    .title {
      font-weight: 600;
      font-size: 27px;
      color: #222222;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img{
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
    }

    .feel-item {
      width: 100%;
      padding: 40px;
      border-radius: 13px 13px 13px 13px;
      border: 1px solid #E8E8E8;
      margin-bottom: 20px;
      cursor: pointer;
      .person{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          img{
            width: 73px;
            height: 73px;
            border-radius: 37px;
            margin-right: 27px;
          }
          .name {
            font-size: 27px;
            color: #222222;
            margin-bottom: 8px;
          }
          .sub-info {
            font-size: 20px;
            color: #999999;
            span{
              margin-right: 20px;
            }
          }
        }

        .right {
          img {
            width: 27px;
            height: 27px;
            cursor: pointer;
          }
        }
        
      }
      
      .feel {
        font-size: 23px;
        color: #222222;
      }
      
    }
  }

  .page {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  .dialog{
    .view {
      padding-bottom: 17px;
      .title {
        font-weight: 500;
        font-size: 13px;
        color: #222222;
        margin-bottom: 13px;
      }

      .img-div{
        width: 100px;
        height: 100px;
        position: relative;
        .av-img{
          width: 100px;
          height: 100px;
          border-radius: 50px;
        }
        .camera {
          width: 40px;
          height: 40px;
          position: absolute;
          bottom: 0;
          right: -18px;
          cursor: pointer;
        }
        }
    }
    .btn {
      display: flex;
      justify-content: flex-end;
      
      .confirm{
        width: 57px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        font-size: 12px;
        color: #FFFFFF;
        background: #2676F7;
        border-radius: 7px 7px 7px 7px;
        cursor: pointer;
      }
    }
  }

  /deep/.el-pagination span:not([class*=suffix]){
    font-size: 20px;
  }
  /deep/.el-pagination .el-select .el-input .el-input__inner{
    font-size: 20px;
    width: 130px;
    height: 32px;
  }
  /deep/.el-pagination .el-select .el-input{
    width: 130px;
    height: 32px;
  }
  /deep/.el-pager li{
    font-size: 20px;
  }
  /deep/.el-pagination__editor.el-input .el-input__inner{
    font-size: 20px;
  }
  // /deep/.el-select-dropdown__item{
  //   font-size: 20px;
  // }
  // /deep/.el-pagination__sizes .el-input .el-input__inner {
  //   font-size: 16px; /* 您想要的字体大小 */
  // }

  /deep/.el-dialog {
    width: 520px;
    border-radius: 7px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
  }

  /deep/.el-input__inner{
    height: 58px;
    line-height: 58px;
    font-size: 17px;
  }
  /deep/.el-textarea__inner{
    font-size: 17px;
  }
}
</style>