/*
 * @Author: angel~dongsanling
 * @Date: 2024-08-03 22:16:00
 * @Description: 
 */
export default {
  data: function () {
    return {
    }
  },
	methods: {
    /**
     * 确认删除
     * @param data {}
     * @param data.msg 提示语
     * @param data.callback 确定后回调
     */
    confirmModal (data,cancelText,isCenter) {
      this.$confirm(data.msg, '温馨提示', {confirmButtonText: '确定', cancelButtonText: cancelText ? cancelText : '我再想想',closeOnClickModal:false,
      cancelButtonClass:'dong-cancel',center: isCenter ? isCenter : false
        }).then(() => {
          // 确定
          this.confirmed(data)
        }).catch(() => {
          // 取消
        });
    },
  }
}