<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-24 19:19:20
 * @Description: 搜索组件
-->
<template>
  <div class="search flex">
    <el-input class="search-input" v-model="input" :placeholder="placeholder"></el-input>
    <div class="search-btn cursor" @click="searchBtnClick()">搜索</div>
  </div>
</template>

<script>
export default {
  props:{
    placeholder: {
      type: String,
      default: '搜索商品'
    },
    searchInput: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      input: ''
    }
  },
  methods: {
    searchBtnClick() {
      this.$emit('searchBtnClick', this.input)
    }
  },
  created() {
    this.input = this.searchInput
  }
}
</script>

<style lang="less" scoped>
.search{
  height: 70px;
  display: flex;
  background: #FFFFFF;
  margin-top: 35px;
  .search-input{
    height: 70px;
    color: #999;
    font-size: 28px;
    flex: 1;
    margin-right: 0px !important;
    /deep/.el-input__inner{
      height: 70px;
      padding-left: 40px;
      border: 1px solid #2676F7 !important;
    }
  }
  .search-btn{
    width: 256px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    background: #2676F7;
  }
}
        
</style>