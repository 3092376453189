<template>
  <div class='my-lesson'>
    <div class="page-header">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        我的课程
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="tab-div">
          <!-- <div class="tab-item" :class="{'active': index == activeIndex}" v-for="(item, index) in tabs" :key="index" @click="handleTabItem(item)">{{ item.title }}</div> -->
        </div>
        <div class="data-item" v-for="(item, index) in dataList" :key="index">
          <img :src="imgUrl(item.cover_image)" alt="">
          <div class="center">
            <div class="title">
                <div>{{ item.title }}</div> 
                <!-- <div class="finished" v-if="item.status==2">已完成</div> -->
            </div>
            <div class="name">{{ item.nickName }}</div>
          </div>
          <div class="right">
            <div class="check-cert" @click="checkCertificate(item)">查看证书 </div>
            <div class="review" @click="goLessonDetail(item)">观看</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { lessons } from '@/api/jobSeeker'

export default {
  data() {
    return {
      activeIndex: 0,
      params: {
        limit: 10,
        page: 1,
        status: 2, //wqto不知道啥意思，接口没区分是代付款还是已完成，数据怎么来
      },
      tabs: [
        {
          title: '全部',
          index: 0,
        },{
          title: '待付款',
          index: 1,
        },{
          title: '已完成',
          index: 2,
        },
      ],
      dialogVisible: false,
      dialogImageUrl: '',
      dataList: []
    }
  },
  methods: {
    back(){
      this.$router.back()
    },
    handleTabItem(item) {
      this.activeIndex = item.index
    },
    getLessons() {
      lessons(this.params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data
        }
      })
    },
    checkCertificate(item) {
      this.dialogImageUrl = this.$store.getters.imgeCDN + item.certificate_image
      this.dialogVisible = true
    },
    goLessonDetail(item) {
      this.$router.push({path: 'lessondetail', query: {id: item.id}})
    },
  },
  created() {
    this.getLessons()
  },
  computed: {
    imgUrl: function() {
      return function(url) {
        if(url) {
          return this.$store.getters.imgeCDN + url
        }
        return ''
      }
    },
  }
}
</script>

<style lang="less" scoped>
.my-lesson {
  .tab-div{
    width: 100%;
    // height: 92px;
    background: #FFFFFF;
    border-radius: 13px 13px 13px 13px;
    margin-bottom: 20px;
    // margin: 20px 0;
    // padding: 0 197px 0 117px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tab-item{
      font-size: 23px;
      color: #222222;
      cursor: pointer;
    }

    .active{
      color: #2676F7;
    }
  }

  .data-item{
    width: 100%;
    height: 176px;
    padding: 33px 40px;
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img{
      width: 107px;
      height: 107px;
      margin-right: 33px;
      border-radius: 50%;
    }

    .center {
      width: 70%;
      // background-color: red;
      font-size: 20px;
      color: #999999;
      .title {
        font-weight: 500;
        font-size: 23px;
        color: #222222;
        margin-top: 18px;
        display: flex;
        align-items: center;

        .finished {
          width: 72px;
          height: 27px;
          line-height: 27px;
          text-align: center;
          background: #2676F7;
          border-radius: 3px 3px 3px 3px;
          font-size: 15px;
          color: #FFFFFF;
          margin-left: 13px;
        }
      }

      .name {
        margin: 10px 0;
      }
    }

    .right {
      width: 18%;
      display: flex;
      // background: darkcyan;
      text-align: center;
      .check-cert {
        width: 127px;
        height: 42px;
        line-height: 42px;
        color: #2676F7;
        font-size: 17px;
        background: rgba(38,118,247,0.08);
        border-radius: 7px 7px 7px 7px;
        cursor: pointer;
      }

      .review{
        width: 127px;
        height: 42px;
        margin-left: 20px;
        line-height: 42px;
        color: #FFFFFF;
        font-size: 17px;
        background: #2676F7;
        border-radius: 7px 7px 7px 7px;
        cursor: pointer;
      }
    }
  }
}

</style>