<template>
  <div class='goods-evaluate'>
    <el-dialog 
    class="dialog" 
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <div class="content">
        <div class="dia-title">商品评价</div>
        <div class="detail">
          <div class="view">
            <el-row class="row">
              <el-col :span="4" class="title">描述相符：</el-col>
              <el-col :span="20">
                <el-rate v-model="shop.describe"></el-rate>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="4" class="title">物流服务：</el-col>
              <el-col :span="20">
                <el-rate v-model="shop.logistics"></el-rate>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="4" class="title">服务态度：</el-col>
              <el-col :span="20">
                <el-rate v-model="shop.service"></el-rate>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="4" class="title">发货速度：</el-col>
              <el-col :span="20">
                <el-rate v-model="shop.deliver"></el-rate>
              </el-col>
            </el-row>
          </div>
          <div v-for="(item, index) in goodsList" :key="index" class="view">
            <el-row class="row mb20">
              <el-col :span="4">
                <img class="goods-img" :src="imageUrl(item.image)" alt="">
              </el-col>
              <el-col :span="20">
                <div>{{ item.title }}</div>
                <div>{{ item.difference }}</div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="4" class="title">宝贝评价</el-col>
              <el-col :span="20" class="tabs">
                <div class="tab-item" v-for="(tabItem, tabIndex) in commentTabs" :key="tabIndex" @click="stateType(item, tabItem, index)">
                  <img v-if="item.commentActiveIndex == tabItem.index" :src="tabItem.activeImg" alt="">
                  <img v-else :src="tabItem.defaultImg" alt="">
                  <div class="tab-title" :class="{'tab-active': item.commentActiveIndex == tabItem.index}">{{ tabItem.title }}</div>
                </div>
              </el-col>
            </el-row> 
            <el-row class="row">
              <el-col :span="22">
                <el-input type="textarea" :autosize="{ minRows: 4}" placeholder="请输入您的评价" v-model="item.comment"></el-input>
              </el-col>
            </el-row>
            <div class="title mt20">添加图片</div>
            <div>
              <el-upload
              accept="image/jpeg,image/png"
              :action="action"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="(file)=>{return handleRemove(file, index)}"
              :on-success="(res,file)=>{return uploadSuccess(res,file, index)}"
              :limit="9"
              :on-exceed="()=>{return imgExceed(index)}">
              <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="op-btn active" @click="confirm()">确认</div>
          <div class="op-btn default" @click="cancel()">取消</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { orderDetail } from '@/api/shoppingMall'

export default {
  props: {
    id: {
      type: Number
    }
  },
  data() {
    return {
      showDialog: true,
      dialogImageUrl: '',
      dialogVisible: false,
      action: 'https://zhp.digginggoldroad.com/api/common/upload',
      commentTabs: [
        {
          index: 0,
          title: "好评",
          defaultImg: require('../../assets/images/favorable-comment-default.png'),
          activeImg: require('../../assets/images/favorable-comment-active.png')
        },{
          index: 1,
          title: "中评",
          defaultImg: require('../../assets/images/mid-comment-default.png'),
          activeImg: require('../../assets/images/mid-comment-active.png')
        },{
          index: 2,
          title: "差评",
          defaultImg: require('../../assets/images/bad-comment-default.png'),
          activeImg: require('../../assets/images/bad-comment-active.png')
        },
      ],
      detail: {},
      shop: {
				id: 0,
				describe: 0,
				logistics: 0,
				service: 0,
				deliver: 0,
			},
			goodsList: [],
    }
  },
  methods: {
    stateType(item, tabItem, index){
      item.commentActiveIndex = tabItem.index
			this.goodsList[index].state = tabItem.index;
		},
    handleRemove(file, index) {
      this.goodsList[index].imgList = this.goodsList[index].imgList.filter(item=>{
        return item != file.response.data.fullurl
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    imgExceed (index) {
      if(this.goodsList[index].imgList.length == 9) {
        this.$message.warning(`最多上传9张图片`)
      }
    },
    uploadSuccess(response, file ,index) {
      this.goodsList[index].imgList.push(response.data.fullurl);
    },
    confirm() {
      if (this.shop.describe == 0 || this.shop.logistics == 0 || this.shop.service == 0 || this.shop.deliver == 0) {
				this.$message.warning('请给店铺评分');
				return false;
			}
      let data = {
				order_id : this.id,
				shop : this.shop,
				goodsList : this.goodsList
			};
      let confirmParams = JSON.parse(JSON.stringify(data))
      this.deleteKey(confirmParams, 'commentActiveIndex')
      this.$emit('confirm', confirmParams)
    },
    deleteKey(obj, keyToDelete) {
      for (let key in obj) {
        if (key === keyToDelete) {
          delete obj[key];
        }
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.deleteKey(obj[key], keyToDelete);
        }
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    getDetail() {
      let params = {
        id: this.id
      }
      orderDetail(params).then(res=>{
        if(res.code == 1) {
          this.detail = res.data
          this.shop.id = this.detail.shop_id

          var newItems = [];
					res.data.goods.forEach((item, index)=>{
						newItems.push({
							id: item['id'],
							goods_id: item['goods_id'],
							difference: item['difference'],
							image: item['image'],
							title: item['title'],
							imgList: [],
							comment: '',
							state: 0,
              commentActiveIndex: null,
						})
					})
					this.goodsList = newItems;
        }
      })
    }
  },
  created() {
    this.getDetail()
  },
  computed: {
    imageUrl: function(){
			return function (img) {
        if(img) {
          return this.$store.getters.imgeCDN + img
        }
        return ''
      }
    },
  }
}
</script>

<style lang="less" scoped>
.goods-evaluate {
  .dialog {
    .dia-title {
      margin-bottom: 20px;
      font-size: 18px;
      color: #222222;
      font-weight: 600;
    }
    .detail {

      .view {
        background: white;
        padding: 10px;
        border-radius: 4px;
        margin-bottom: 20px;
      }
      .row {
        .title {
          // 
          font-size: 14px;
          color: #666666;
        }

        .tabs {
          display: flex;
          align-items: center;

          .tab-item {
            display: flex;
            align-items: center;
            margin-right: 20px;
            cursor: pointer;
            .tab-title {
              font-size: 14px;
              color: #333333;
            }
            
            img {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }

            .tab-active {
              color: #EA2224;
            }
          }
        }
        
        .goods-img {
          width: 60px;
          height: 60px;
          border-radius: 4px;
        }
      }

      .title {
        font-size: 14px;
        color: #222222;
        margin-bottom: 11px;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .op-btn {
        width: 65px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        margin-left: 8px;
        cursor: pointer;
      }

      .default{
        background: #FAFAFA;
        border: 1px solid #DDDDDD;
        color: #666666;
      }
      .active {
        border: 1px solid #2676F7;
        background: #2676F7;
        color: #fff;
      }
    }
    .mt20{
      margin-top: 20px;
    }
    .mb20 {
      margin-bottom: 20px!important;
    }
  }
  /deep/.el-dialog {
    width: 520px;
    background: #f7f7f7;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.15);
    border-radius: 4px;
  }
  /deep/.el-dialog__header{
    display: none;
  }
  /deep/.el-dialog__body{
    padding: 25px 25px 20px;
  }
  /deep/.el-textarea__inner{
    font-size: 14px;
  }
  /deep/.el-upload--picture-card{
    width: 74px;
    height: 74px;
    line-height: 74px;
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item{
    width: 74px;
    height: 74px;
  }
}

</style>