<template>
  <div class='common-phrase-dialog'>
    <el-dialog 
    class="dialog" 
    :visible.sync="isShowDialog" 
    title="常用语-快捷回复">
      <div class="set" @click="setPhrase()">去设置</div>
      <div class="phrase-item" v-for="(item, index) in dataList" :key="index" @click="itemClick(item)">
        {{ item.name }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { commonPhrase } from '@/api/jobSeeker'

export default {
  data() {
    return {
      isShowDialog: true,
      dataList: []
    }
  },
  methods: {
    getList() {
      commonPhrase().then(res=>{
        if(res.code == 1) {
          this.dataList = res.data
        }
      })
    },
    setPhrase() {
      this.$emit('setPhrase')
    },
    itemClick(item) {
      this.$emit('selectedItem', item.name)
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.common-phrase-dialog {
  .dialog{
    .set {
      color: #2676F7;
      font-size: 16px;
      margin-bottom: 20px;
      width: 100%;
      text-align: right;
      cursor: pointer;
    }
    .phrase-item {
      cursor: pointer;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      padding: 20px 0;
      font-size: 16px;
    }
  }

  /deep/.el-dialog {
    width: 520px!important;
    border-radius: 7px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
    font-size: 13px;

    span{
      color: #2676F7;
    }
  }
}
</style>