<template>
  <div class='job-detail'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        职位详情
        <div class="right-btn">
          <img src="../../../assets/images/information.png" @click="report()" alt="">
          <img v-if="detailData.collect" src="../../../assets/images/star_yellow.png" @click="collect()" alt="">
          <div v-else class="el-icon-star-off collect" @click="collect()"></div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="job-introduce">
          <div class="top">
            <div class="left">
              <div class="job-name">{{ detailData.name }} <span>{{ detailData.salary_name }}</span> </div>
              <div class="require">
                <div>{{ areaAndCity }}</div><div>{{ detailData.work_name }}</div><div>{{ detailData.education_name }}</div>
              </div>
            </div>
            <div class="right" @click="goChat()">立即沟通</div>
          </div>
          <div class="bottom">
            <img :src="avatarUrl" alt="">
            <div class="bottom-company">
              <div>{{ detailData.nickname }}</div>
              <div class="bottom-company-name">{{ detailData.company_name }}</div>
            </div>
          </div>
        </div>
        <div class="job-detail-require">
          <div class="title">职位详情</div>
          <div class="detail">
            <div v-html="detailData.requirement"></div>
          </div>
        </div>
        <div class="company" @click="goDetail">
          <div class="left">
            <img :src="companyLogoImage" alt="">
            <div>
              <div class="company-name">{{ detailData.company_name }}</div>
              <div class="company-info">
                <span>{{ detailData.scale_name }}</span><span>{{ companyInfo.company_category_id_text }}</span>
              </div>
            </div>
          </div>
          <div class="right">
            <img src="../../../assets/images/arrow-right.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <el-dialog class="dialog" :visible.sync="showDialog" :title="'举报'">
      <div>举报该记录，请将举报信息及截图发送至客服邮箱：<span>hdfhiehfe@qq.com</span>，平台收到后将在15个工作日内进行处理并反馈。 </div>
      <div class="oper">
        <div class="confirm-btn" @click="showDialog=false">我知道了</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { jobDetail, companyDetail, jobCollect } from '@/api/jobSeeker'

export default {
  data() {
    return {
      showDialog: false,
      id: null,
      companyId: null,
      detailData: {},
      companyInfo: {}
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    report() {
      this.showDialog = true
    },
    collect() {
      let params = {
        id: this.id,
        type: '1'
      }
      jobCollect(params).then(res=>{
        if(res.code == 1) {
          this.detailData.collect = !this.detailData.collect
          this.$message.success(this.detailData.collect ? '已收藏' : '取消收藏')
        }
      })
    },
    goDetail() {
      this.$router.push({path: 'companydetail', query: {id: this.companyId} })
    },
    goChat() {
      this.$router.push({path: 'message', query: {mobile: this.detailData.mobile}})
    },
    // 职位详情
    getDetail() {
      let params = {
        id: this.id
      }
      jobDetail(params).then(res=>{
        if(res.code == 1) {
          this.detailData = res.data
        }
      })
    },
    // 公司详情
    getCompanyDetail() {
      let params = {
        id: this.companyId
      }
      companyDetail(params).then(res=>{
        if(res.code == 1) {
          this.companyInfo = res.data
        }
      })
    }
  },
  created() {
    this.id = this.$route.query.id
    this.companyId = this.$route.query.companyId
    this.getDetail()
    this.getCompanyDetail()
  },
  computed: {
    avatarUrl() {
      if(this.detailData.avatar) {
        return this.$store.getters.imgeCDN + this.detailData.avatar
      }
      return ''
    },
    companyLogoImage() {
      if(this.detailData.company_logo_image) {
        return this.$store.getters.imgeCDN + this.detailData.company_logo_image
      }
      return require('@/assets/images/default-avatar.png')
    },
    areaAndCity() {
      if(!this.detailData.mergename) return
      let cityArr = this.detailData.mergename.split(',')
      if(cityArr.length == 3){
        return `${cityArr[0]} - ${cityArr[1]}`
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.job-detail{
  .right-btn {
    position: absolute;
    right: 0;
    display: flex;

    img{
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
    img:nth-child(1) {
      margin-right: 40px;
    }

    .collect {
      color: gray;
      font-size: 34px;
      cursor: pointer;
    }
  }

  .job-introduce{
    margin-bottom: 20px;
    padding: 40px;
    background: white;
    border-radius: 13px;
    .top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #E8E8E8;

      .left {
        .job-name {
          font-weight: 600;
          font-size: 33px;
          color: #222222;
          margin-bottom: 20px;

          span{
            margin-left: 40px;
            font-weight: 500;
            font-size: 33px;
            color: #E11E34;
          }
        }

        .require {
          display: flex;
          align-items: center;
          div {
            height: 38px;
            padding: 7px 13px;
            margin-right: 20px;
            line-height: 24px;
            font-size: 17px;
            color: #585858;
            text-align: center;
            background: #F7F7F7;
            border-radius: 7px;
          }
        }
      }

      .right {
        width: 200px;
        height: 65px;
        line-height: 65px;
        text-align: center;
        font-weight: 500;
        font-size: 23px;
        color: #FFFFFF;
        background: #2676F7;
        border-radius: 13px;
        cursor: pointer;
      }
    }

    .bottom {
      padding-top: 33px;
      display: flex;

      img {
        width: 73px;
        height: 73px;
        border-radius: 37px;
        margin-right: 27px;
      }
      .bottom-company {
        font-size: 27px;
        color: #222222;

        .bottom-company-name {
          margin-top: 7px;
          font-size: 20px;
          color: #999999;
        }
      }
    }
  }
  .job-detail-require{
    padding: 40px;
    background: white;
    border-radius: 13px;
    margin-bottom: 20px;
    .title {
      font-weight: 600;
      font-size: 27px;
      color: #222222;
      margin-bottom: 27px;
    }

    .detail {
      font-size: 23px;
      color: #222222;
      line-height: 40px;
    }
  }

  .company {
    width: 100%;
    height: 176px;
    padding: 0 40px;
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 13px 13px 13px 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .left {
      display: flex;
      align-items: center;
      img {
        width: 90px;
        height: 90px;
        margin-right: 30px;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #E8E8E8;
      }
      .company-name {
        font-size: 27px;
        color: #222222;
        margin-bottom: 10px;
      }
      .company-info {
        font-size: 20px;
        color: #999999;

        span{
          margin-right: 13px;
        }
      }
    }
    .right {
      img {
        width: 23px;
        height: 23px;
      }
    }
  }

  .dialog{
    .oper {
      display: flex;
      justify-content: end;
      margin-top: 20px;
      .confirm-btn {
        width: 80px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        background: #2676F7;
        border-radius: 7px 7px 7px 7px;
        font-size: 12px;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  /deep/.el-dialog {
    width: 367px;
    border-radius: 7px;
  }
  /deep/.el-textarea{
    font-size: 17px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
    font-size: 13px;

    span{
      color: #2676F7;
    }
  }
}
</style>