var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chat"},[_c('div',{class:['tui-chat', !_setup.isPC && 'tui-chat-h5']},[(!_setup.currentConversationID)?_c('div',{class:['tui-chat-default', !_setup.isPC && 'tui-chat-h5-default']},[_vm._t("default")],2):_vm._e(),(_setup.currentConversationID)?_c('div',{class:['tui-chat', !_setup.isPC && 'tui-chat-h5']},[_c(_setup.ChatHeader,{class:[
          'tui-chat-header',
          !_setup.isPC && 'tui-chat-H5-header',
          _setup.isUniFrameWork && 'tui-chat-uniapp-header',
        ],attrs:{"headerExtensionList":_setup.headerExtensionList},on:{"closeChat":_setup.closeChat}}),_c(_setup.Forward,{on:{"toggleMultipleSelectMode":_setup.toggleMultipleSelectMode}}),_c(_setup.MessageList,{ref:"messageListRef",class:['tui-chat-message-list', !_setup.isPC && 'tui-chat-h5-message-list'],attrs:{"isGroup":_setup.isGroup,"groupID":_setup.groupID,"isMultipleSelectMode":_setup.isMultipleSelectMode},on:{"handleEditor":_setup.handleEditor,"closeInputToolBar":() => _setup.changeToolbarDisplayType('none'),"toggleMultipleSelectMode":_setup.toggleMultipleSelectMode,"messageItemDeal":_setup.messageItemDeal,"downLoad":_setup.downLoad}}),(_setup.isMultipleSelectMode)?_c(_setup.MultipleSelectPanel,{on:{"oneByOneForwardMessage":_setup.oneByOneForwardMessage,"mergeForwardMessage":_setup.mergeForwardMessage,"toggleMultipleSelectMode":_setup.toggleMultipleSelectMode}}):[(_setup.isInputToolbarShow)?_c(_setup.MessageInputToolbar,{class:[
            'tui-chat-message-input-toolbar',
            !_setup.isPC && 'tui-chat-h5-message-input-toolbar',
            _setup.isUniFrameWork && 'tui-chat-uni-message-input-toolbar'
          ],attrs:{"displayType":_setup.inputToolbarDisplayType},on:{"insertEmoji":_setup.insertEmoji,"changeToolbarDisplayType":_setup.changeToolbarDisplayType,"scrollToLatestMessage":_setup.scrollToLatestMessage,"itemClick":_setup.itemClick}}):_vm._e(),_c(_setup.MessageInput,{ref:"messageInputRef",class:[
            'tui-chat-message-input',
            !_setup.isPC && 'tui-chat-h5-message-input',
            _setup.isUniFrameWork && 'tui-chat-uni-message-input',
            _setup.isWeChat && 'tui-chat-wx-message-input',
          ],attrs:{"isMuted":false,"muteText":_setup.TUITranslateService.t('TUIChat.您已被管理员禁言'),"placeholder":_setup.TUITranslateService.t('TUIChat.请输入消息'),"inputToolbarDisplayType":_setup.inputToolbarDisplayType},on:{"changeToolbarDisplayType":_setup.changeToolbarDisplayType}})]],2):_vm._e(),(_setup.isUniFrameWork && _setup.isGroup && _setup.headerExtensionList.length > 0)?_c('div',{staticClass:"group-profile",on:{"click":_setup.handleGroup}},[_vm._v(" "+_vm._s(_setup.headerExtensionList[0].text)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }