import request from '@/utils/request'

export function companyIndex (data = {}) {
  return request({
    method: 'get',
    url: 'company/index',
    data: data,
    isToken: true
  })
}

// 获取总数
export function totalCount (data) {
  return request({
    method: 'get',
    url: 'company/indexCount',
    data: data,
    isToken: true
  })
}

// 投递记录 
export function deliveredList (data = {}) {
  return request({
    method: 'get',
    url: 'company/delivered',
    data: data,
    isToken: true
  })
}

// 个人中心
export function companySummary (data) {
  return request({
    method: 'get',
    url: 'company/summary',
    data: data,
    isToken: true
  })
}
// 收藏
export function collectList (data) {
  return request({
    method: 'get',
    url: 'company/collectLst',
    data: data,
    isToken: true
  })
}

// VIP配置
export function VipPrices (data) {
  return request({
    method: 'get',
    url: 'company/getVipPrices',
    data: data,
    isToken: true
  })
}

// VIP充值
export function VipPay (data) {
  return request({
    method: 'get',
    url: 'company/order',
    data: data,
    isToken: true
  })
}

// 审核信息
export function getCompanyCheckData (data) {
  return request({
    method: 'get',
    url: 'company/getCompanyCheckData',
    data: data,
    isToken: true
  })
}

// 提交审核
export function updateCompanyCheckData (data) {
  return request({
    method: 'post',
    url: 'company/updateCompanyCheckData',
    data: data,
    isToken: true
  })
}

// 职位管理
export function positionList (data) {
  return request({
    method: 'get',
    url: 'company/getPositionLst',
    data: data,
    isToken: true
  })
}

// 职位状态修改
export function setPositionStatus (data) {
  return request({
    method: 'get',
    url: 'company/setPositionStatus',
    data: data,
    isToken: true
  })
}

// 发布在职感受
export function publishWorkFeel (data) {
  return request({
    method: 'post',
    url: 'company/workFeel',
    data: data,
    isToken: true
  })
}

// 删除在职感受
export function deleteWorkFeel (data) {
  return request({
    method: 'get',
    url: 'company/deleteWorkFeel',
    data: data,
    isToken: true
  })
}

// 收藏、取消收藏
export function handelCollect (data) {
  return request({
    method: 'post',
    url: 'company/collect',
    data: data,
    isToken: true
  })
}

// 发布职位
export function postPosition (data) {
  return request({
    method: 'post',
    url: 'company/postPosition',
    data: data,
    isToken: true
  })
}

// 是否收藏了某个求职者
export function checkCollect (data) {
  return request({
    method: 'get',
    url: 'company/checkCollect',
    data: data,
    isToken: true
  })
}