<template>
  <div class='points-shop'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <el-carousel >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <!-- <h3 class="small">{{ item }}</h3> -->
            <img class="banner-img" :src="imageUrl(item.images)" alt="">
          </el-carousel-item>
        </el-carousel>
        
        <div class="title">可兑换商品</div>
        <div class="view">
          <pointsShopGoodsItemVue :class="{ 'mr0': (index + 1) % 4 == 0 }" v-for="(item, index) in dataList" :key="index" :item="item" @itemClick="goDetail" @exchange="exchange"></pointsShopGoodsItemVue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import pointsShopGoodsItemVue from '@/components/shoppingMall/pointsShopGoodsItem.vue';
import { scoreGoodsList } from '@/api/shoppingMall'
import { getContentImage } from '@/api/common'

export default {
  components: {
    crumbsVue,
    pointsShopGoodsItemVue,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '积分商城',
        },
      ],
      bannerList: [],
      dataList: []
    }
  },
  methods: {
    getBanner() {
      getContentImage({name: 'score_shop_banner'}).then(res=>{
        if(res.code == 1) {
          this.bannerList = res.data
        }
      })
    },
    getList() {
      let params = {
        type: 1
      }
      scoreGoodsList(params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data
        }
      })
    },
    goDetail(item) {
      this.$router.push({ 
        path: 'product-detail', 
        query: {
          item: JSON.stringify(item)
        } 
      })
    },
    exchange(item) {
      this.$router.push({ 
        path: 'product-detail', 
        query: {
          item: JSON.stringify(item)
        } 
      })
    }
  },
  created() {
    this.getBanner()
    this.getList()
  },
  computed: {
    imageUrl: function(){
      return function (img) {
        return this.$store.getters.imgeCDN + img
      }
    },
  }
}
</script>

<style lang="less" scoped>
.points-shop {
  .banner-img{
    width: 100%;
    // height: 500px;
    // margin-bottom: 60px;
  }
  .title{
    font-weight: 500;
    font-size: 22px;
    color: #333333;
    margin-bottom: 30px;
  }

  .view{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .mr0 {
    margin-right: 0;
  }

  /deep/.el-carousel__container {
    height: 500px;
    margin-bottom: 60px;
  }
}
</style>