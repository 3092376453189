<template>
  <div class='personal-data'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        个人资料
        <div class="btn-save" @click="submit()">保存更改</div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="content">
          <div class="center">
            <el-row class="row">
              <el-col :span="24" class="flex">
                <div class="img-div">
                  <img class="av-img" :src="params.fullUrl" alt="">
                  <img class="camera" src="../../../assets/images/camera.png" @click="uploadAvImage()" alt="">
                  <el-upload
                    class="display-none"
                    ref="upload-img"
                    :action="action"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess">
                  </el-upload>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="6" class="txt-right">用户名</el-col>
              <el-col :span="18">
                <el-input placeholder="请输入用户名" v-model="params.nickname"></el-input>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="6" class="txt-right">性别</el-col>
              <el-col :span="18">
                <el-radio v-model="params.gender" label="1">男</el-radio>
                <el-radio v-model="params.gender" label="2">女</el-radio>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="6" class="txt-right">手机号</el-col>
              <el-col :span="18">
                <el-input placeholder="请输入手机号" v-model="params.mobile" :disabled="true"></el-input>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="6" class="txt-right">微信号</el-col>
              <el-col :span="18">
                <el-input placeholder="请输入微信号" v-model="params.wechat"></el-input>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="6" class="txt-right">担任职位</el-col>
              <el-col :span="18">
                <el-input placeholder="请输入担任职位" v-model="params.position"></el-input>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userInfo, userProfile } from '@/api/userInfo'

export default {
  data() {
    return {
      action: 'https://zhp.digginggoldroad.com/api/common/upload',
      params: {
        fullUrl: require('@/assets/images/av-test.png'),
        avatar: '',
        nickname: '',
        gender: '1',
        mobile: '',
        wechat: '',
        position: ''
      }
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    uploadAvImage() {
      this.$refs['upload-img'].$refs['upload-inner'].handleClick()
    },
    handleAvatarSuccess(res, file) {
      this.params.avatar = res.data.url;
      this.params.fullUrl = this.$store.getters.imgeCDN + this.params.avatar
    },
    getUserInfo() {
      userInfo().then(res=>{
        if(res.code == 1) {
          // this.params = res.data.userinfo
          this.params.avatar = res.data.userinfo.avatar
          this.params.nickname = res.data.userinfo.nickname
          this.params.mobile = res.data.userinfo.mobile
          this.params.wechat = res.data.userinfo.wechat
          this.params.position = res.data.userinfo.position
          this.params.gender = String(this.params.gender)
          this.params.fullUrl = this.$store.getters.imgeCDN + res.data.userinfo.avatar
        }
      })
    },
    submitCheck() {
      if(!this.params.avatar) {
        this.$message.warning('请上传头像')
        return false
      }
      if(!this.params.nickname) {
        this.$message.warning('请输入用户名')
        return false
      }
      if(!this.params.mobile) {
        this.$message.warning('请输入手机号')
        return false
      }
      if(!this.params.wechat) {
        this.$message.warning('请输入微信号')
        return false
      }
      if(!this.params.position) {
        this.$message.warning('请输入担任职位')
        return false
      }

      return true
    },
    submit() {
      // if(!this.submitCheck()) return
      debugger
      let params = JSON.parse(JSON.stringify(this.params))
      delete params.fullUrl
      userProfile(params).then(res=>{
        if(res.code == 1) {
          this.getUserInfo()
        }
      })
    },

  },
  created() {
    this.getUserInfo()
  }
}
</script>

<style lang="less" scoped>
.personal-data {
  .content {
    border-radius: 13px 13px 13px 13px;
    padding: 20px 20px 45px;
    margin-bottom: 41px;
    background: white;
    display: flex;
    justify-content: center;

    .center {
      width: 50%;
      // background-color: red;
      .row {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #222222;
        margin-bottom: 20px;

        .flex{
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .img-div{

          position: relative;
          .av-img{
            width: 100px;
            height: 100px;
            border-radius: 50px;
          }
          .camera {
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 0;
            right: -18px;
            cursor: pointer;
          }
        }

        .txt-right {
          text-align: right;
          padding-right: 33px;
        }

        .display-none {
          display: none;
        }
      }
    }
  }

  /deep/.el-input__inner{
    height: 53px;
    line-height: 53px;
    font-size: 20px;
  }
  /deep/.el-radio__label{
    font-size: 20px;
  }
  /deep/.el-radio{
    padding-right: 33px;
  }
}
</style>