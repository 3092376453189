<template>
  <div class='message-vc'>
    <div class="title">{{ title }}</div>
    <div class="review-vc" @click="downLoad()">点击预览附件简历</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    message: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  methods: {
    downLoad() {
      this.$emit('downLoad', this.message)
    }
  }
}
</script>

<style lang="less" scoped>
.message-vc {
  width: 233px;
  padding: 17px;
  background: #F7FAFF;
  border-radius: 10px 10px 10px 10px;
  border: 0px solid #E8E8E8;

  .title {
    font-weight: 500;
    font-size: 15px;
    color: #222222;
    margin-bottom: 20px;
  }
  .review-vc {
    width: 200px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    background: #2676F7;
    border-radius: 7px 7px 7px 7px;
    cursor: pointer;
  }
}
</style>