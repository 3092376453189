<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-24 19:19:20
 * @Description: 商城公用头部
-->
<template>
  <div class="header flex space-between">
    <div class="header-left flex flex-start cursor">
      <!-- <img class="position" src="../../assets/images/position.png" alt="">
      <span class="city-name">北京</span> -->
    </div>
    <div class="header-right flex">
      <div class="header-right-left flex">
        <div class="header-right-left-item cursor flex flex-start" v-for="(item,index) in menuArr" :key="index" @click="headerItemClick(item)">
          <img class="header-right-left-item-icon" :src="item.icon" alt="">
          <span class="header-right-left-item-name">{{ item.name }}</span>
        </div>
      </div>
      <div class="header-right-right flex">
        <div class="header-right-right-my cursor">
          <img class="header-right-right-my-icon" :src="avUrl" @click="personalClick()" alt="">
        </div>
        <div class="header-right-right-item cursor">
          <el-popover
            ref="setpopover"
            placement="bottom"
            popper-class="custom-popover-myself-shop-header"
            class="custom-popover"
            trigger="click">
            <div class="cont">
              <div class="item" v-for="(item, index) in setArr" :key="index"
                @click="setChange('setChange', item, index)">
                <img :src="item.icon" alt="">
                <span>{{ item.name }}</span>
              </div>
            </div>
            <img slot="reference" class="header-right-right-item-icon" src="../../assets/images/shopping-set.png" alt="">
          </el-popover>
        </div>
        <div class="header-right-right-item cursor" v-for="(item,index) in menuArrRight" :key="index" @click="rightMenuClick(item)">
          <img class="header-right-right-item-icon" :src="item.icon" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuArr: [{
        name: '首页',
        icon: require('@/assets/images/home.png'),
        path: '/'
      },{
        name: '职位',
        icon: require('@/assets/images/post.png'),
        path: '/job'
      },{
        name: '消息',
        icon: require('@/assets/images/message.png'),
        path: '/message'
      },{
        name: '职场咨询',
        icon: require('@/assets/images/workplace.png'),
        path: '/workplacenews'
      },{
        name: '职场论坛',
        icon: require('@/assets/images/forum.png'),
        path: '/workplaceforum'
      },{
        name: '商城',
        icon: require('@/assets/images/shopping.png'),
        path: '/shoppingMall/home'
      }],
      setArr: [{
        name: '账号安全',
        path: '/accountSecurity',
        icon: require('@/assets/images/set-acount.png')
      }, {
        name: '关于我们',
        path: '/agreement?type=3',
        icon: require('@/assets/images/set-about.png')
      }, {
        name: '用户协议',
        path: '/agreement?type=1',
        icon: require('@/assets/images/set-user.png')
      }, {
        name: '隐私政策',
        path: '/agreement?type=2',
        icon: require('@/assets/images/set-lock.png')
      }, {
        name: '平台资质',
        path: '/agreement?type=4',
        icon: require('@/assets/images/set-platform.png')
      }, {
        name: '退出登录',
        icon: require('@/assets/images/set-exit.png'),
        type: 'logout'
      }],
      menuArrRight: [
        // {
        //   name: '设置',
        //   icon: require('@/assets/images/shopping-set.png'),
        //   path: '/shoppingMall/shopping-set'
        // },
        {
          name: '其他',
          icon: require('@/assets/images/other.png'),
          path: 'category'
        }
      ]
    }
  },
  methods: {
    headerItemClick(item) {
      this.$emit('headerItemClick', item)
    },
    personalClick() {
      this.$emit('personalClick')
    },
    setChange (type, item) {
      this.$emit('setItemClick', item)
      this.$refs.setpopover.doClose()
    },
    rightMenuClick(item) {
      this.$emit('rightMenuClick', item)
    }
  },
  computed: {
    avUrl() {
      if(this.$store.getters.userInfo.avatar) {
        return this.$store.getters.imgeCDN + this.$store.getters.userInfo.avatar
      }
      return ''
    }
  },
}
</script>

<style lang="less" scoped>
.header{
  width: 100%;
  height: 60px;
  padding: 0px 60px;
  background: #2B303B;
  border-radius: 1px;
  .header-left{
    width: 70px;
    height: 100%;
    align-items: center !important;
    .position{
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
    .city-name{
      font-size: 14px;
      font-weight: 400;
      color: #fff;
    }
  }
  .header-right{
    .header-right-left{
      .header-right-left-item{
        align-items: center !important;
        padding: 0px 30px;
        border-right: 1px solid #fff;
        .header-right-left-item-icon{
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
        .header-right-left-item-name{
          color: #fff;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .header-right-left-item:last-child{
        border: none;
      }
    }
    .header-right-right{
      margin-left: 20px;
      .header-right-right-my{
        .header-right-right-my-icon{
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }
      .header-right-right-item{
        align-items: center !important;
        margin-left: 25px;
        .header-right-right-item-icon{
          width: 25px;
          height: 25px;
        }
      }
      // /deep/.el-popper {
      //   margin-top: -30px;
      // }
    }
  }
}
</style>