<template>
  <div class='job-collect-item' @click="itemClick()">
    <div class="top-content">
      <div class="top">
        <div class="job-name">{{ item.name }}</div>
        <div class="num">{{ item.salary_name }}</div>
      </div>
      <div class="detail">
        <div class="com">
          <!-- <span>无需融资</span> -->
          <div class="company-name">{{ item.company_name }}</div><div>{{ item.scale_name }}</div>
        </div>
        <span>{{ city }}</span><span>{{ item.education_name }}</span><span>{{ item.work_name }}</span>
      </div>
    </div>
    <div class="person">
      <div class="left"> 
        <img :src="avatar" alt=""> {{ item.nickname }}·{{ item.position }}
      </div>
      <div>{{ city }}</div>
    </div>
    <div class="op">
      <div class="delete-btn" @click.stop="showDialog=true">删除</div>
      <div class="go-chat-btn" @click.stop="goMessage()">立即沟通</div>
    </div>
    <el-dialog class="dialog" :visible.sync="showDialog" title="提示">
      <div>确定要删除该收藏吗？</div>
      <div class="oper">
        <div class="confirm-btn" @click="confirm()">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      showDialog: false,
    }
  },
  methods: {
    itemClick() {
      this.$emit('itemClick', this.item)
    },
    goMessage() {
      this.$emit('chatBtnClick', this.item)
    },
    confirm() {
      this.showDialog = false
      this.$emit('deleteItem', this.item)
    }
  },
  computed: {
    city() {
      let cityArr = this.item.mergename.split(',')
      if(cityArr.length == 3){
        return cityArr[1]
      } else {
        return ''
      }
    },
    avatar() {
      if(this.item.avatar) {
        return this.$store.getters.imgeCDN + this.item.avatar
      }
      return ''
    },
  }
}
</script>

<style lang="less" scoped>
.job-collect-item{
  width: 386px;
  padding: 27px 27px 0 27px;
  background: #FFFFFF;
  border-radius: 13px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  .top-content{
    text-align: left;

    .top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 13px;

      .job-name{
        font-weight: 500;
        font-size: 20px;
        color: #222222;
      }

      .num{
        font-weight: 500;
        font-size: 20px;
        color: #E11E34;
      }
    }
    .detail{
      .com{
        margin-bottom: 13px;
        display: flex;
        align-items: center;
        .company-name {
          max-width: 250px;
          margin-right: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      span{
        padding: 4px 10px;
        font-weight: 400;
        font-size: 15px;
        color: #585858;
        background-color: #F7F7F7;
        margin-right: 13px;
        border-radius: 3px;
      }
    }
  }

  .person{
    // height: 68px;
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    color: #585858;

    .left{
      display: flex;
      align-items: center;
      img{
        width: 33px;
        height: 33px;
        border-radius: 17px;
        margin-right: 13px;
      }
    }

    span{
      margin-right: 20px;
    }
  }

  .op{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .delete-btn {
      width: 133px;
      height: 43px;
      line-height: 43px;
      font-size: 17px;
      color: #585858;
      text-align: center;
      cursor: pointer;
      border-radius: 7px;
      border: 1px solid #E8E8E8;
    }

    .go-chat-btn {
      width: 180px;
      height: 43px;
      line-height: 43px;
      font-size: 17px;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
      background: #2676F7;
      border-radius: 7px;
      border: 1px solid #2676F7;
    }
  }

  .dialog{
    .oper {
      display: flex;
      justify-content: end;
      margin-top: 20px;
      .confirm-btn {
        width: 80px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        background: #2676F7;
        border-radius: 7px 7px 7px 7px;
        font-size: 12px;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  /deep/.el-dialog {
    width: 352px;
    border-radius: 7px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
    font-size: 12px;
    color: #222222;
  }
}

</style>