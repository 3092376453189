<template>
  <div class='change-role'>
    <el-dialog 
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <img v-if="identity==1" src="../assets/images/job-seeker.png" alt="">
      <img v-else src="../assets/images/recruiter.png" alt="">
      <div class="current-role">您当前的身份为“<span>{{ identity == 1 ? '求职者' : '招聘者' }}</span>”</div>
      <div class="confirm-btn" @click="changeRole()">切换为“<span>{{ identity == 1 ? '招聘者' : '求职者' }}</span>”身份</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    identity: {
      type: Number,
      default: 1, //1求职者  2招聘者
    }
  },
  data() {
    return {
      showDialog: true
    }
  },
  methods: {
    changeRole() {
      this.$emit('change')
    }
  }
}
</script>

<style lang="less" scoped>
.change-role{

  img {
    width: 168px;
    height: 137px;
    margin-bottom: 33px;
  }

  .current-role {
    font-weight: 500;
    font-size: 23px;
    color: #222222;
    margin-bottom: 33px;

    span {
      color: #2676F7;
    }
  }

  .confirm-btn {
    width: 333px;
    height: 53px;
    line-height: 53px;
    text-align: center;
    background: #2676F7;
    border-radius: 10px 10px 10px 10px;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    cursor: pointer;
  }

  /deep/.el-dialog {
    width: 400px;
    border-radius: 7px;
  }
  /deep/.el-dialog__header{
    padding: 0;
  }
  /deep/.el-dialog__headerbtn .el-dialog__close{
    display: none;
  }
  /deep/.el-dialog__body{
    padding: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

</style>