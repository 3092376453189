import { render, staticRenderFns } from "./personalData.vue?vue&type=template&id=589fceb4&scoped=true"
import script from "./personalData.vue?vue&type=script&lang=js"
export * from "./personalData.vue?vue&type=script&lang=js"
import style0 from "./personalData.vue?vue&type=style&index=0&id=589fceb4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "589fceb4",
  null
  
)

export default component.exports