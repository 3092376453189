<template>
  <div class="im-message">
    <div class="page">
      <div class="page-content">
        <div class="content">
          <div class="left">
            <div class="my-self">
              <img :src="imageUrl(userInfo.avatar)" alt="">
              <div>{{ userInfo.nickname }}</div>
            </div>
            <div class="conversation-item" v-for="(item, index) in conversationList" :key="index" @click="openChat(item.to_account)">
              <div class="left-info">
                <img :src="imageUrl(item.avatar)" alt="">
                <div>
                  <div class="boss">{{ item.nickname }} 
                    <!-- <span>界外动漫·HR</span> -->
                  </div>
                  <div class="msg">{{ item.identity == 2 ? item.company_name : item.post_name }}</div>
                  <!-- <div class="msg">您好，了解一下</div> -->
                </div>
              </div>
              <div class="right">
                <div class="time">{{ item.msg_time | time }}</div>
                <div @click.stop="deleteConversation(item)">
                  <img src="../../../assets/images/delete-white.png"  alt="">
                </div>
              </div>
            </div>
          </div>
          <div :class="['TUIKit', isH5 && 'TUIKit-h5']">
            <div class="TUIKit-main-container">
              <div
                v-show="!isH5 || !currentConversationID"
                :class="['navbar', isH5 && 'navbar-h5']"
              >
                <!-- <div class="navbar-button" @click="openChat">打开 1v1 聊天</div> -->
                <!-- <div class="navbar-button" @click="openGroupChat">打开群聊</div> -->
              </div>
              <TUIChat ref="UIChat" v-show="!isH5 || currentConversationID" @itemClick="itemClick" @messageItemDeal="messageItemDeal" @downLoad="downLoad">
                <!-- <h1>欢迎使用腾讯云即时通信 IM </h1> -->
              </TUIChat>
              <TUICallKit
                class="callkit-container"
                :allowedMinimized="true"
                :allowedFullScreen="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <commonPhraseDialog v-if="isShowCommonPhraseDialog" @setPhrase="setPhrase" @selectedItem="selectedPhrase"></commonPhraseDialog>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import commonPhraseDialog from "@/components/commonPhraseDialog.vue";
import { TUIStore, StoreName, TUIConversationService } from "@tencentcloud/chat-uikit-engine";
import { TUIComponents, TUIChatKit, genTestUserSig } from '@/TUIKit'
import { TUILogin } from "@tencentcloud/tui-core";
import { TUICallKit } from "@tencentcloud/call-uikit-vue2";
import { TUIChat } from "@/TUIKit";
import { isH5 } from "@/TUIKit/utils/env";
import { msgMap, getMobile } from '@/common/im'
import { imMessage, sendImMsgText } from '@/api/jobSeeker'
import { getInfoByPhone, userInfo, chatList, deleteChat } from '@/api/userInfo'

const SDKAppID = 1600010151;
const secretKey = "c5f504c5ff161f472138af0fd0f8abbdf8aa96cd0fbe363364cea5873d1db886";

export default Vue.extend({
  name: "App",
  components: {
    TUIChat,
    TUICallKit,
    commonPhraseDialog,
  },
  provide() {
    return {
      $_home: this
    }
  },
  data() {
    return {
      isShowCommonPhraseDialog: false,
      msgMap: {},
      isH5: isH5,
      currentConversationID: "",
      currentNavbar: "conversation",
      groupID: "", // 请填写会话 groupID
      toUserID: '',
      userInfo: {},
      conversationList: []
    };
  },
  methods: {
    getUserInfo() {
      userInfo().then(res=>{
        if(res.code == 1) {
          this.userInfo = res.data.userinfo
        }
      })
    },
    getChatList() {
      chatList().then(res=>{
        if(res.code == 1) {
          this.conversationList = res.data
        }
      })
    },
    loginIM () {
      let userInfo = this.$store.getters.userInfo
      if (userInfo && userInfo.mobile) {
        let userID = 'jjzl_' + userInfo.mobile
        TUIChatKit.init()
        TUILogin.login({
          SDKAppID,
          userID,
          // UserSig 是用户登录即时通信 IM 的密码，其本质是对 UserID 等信息加密后得到的密文。  
          // 该方法仅适合本地跑通 Demo 和功能调试，详情请参见 https://cloud.tencent.com/document/product/269/32688 
          userSig: genTestUserSig({
            SDKAppID,
            secretKey,
            userID
          }).userSig,
          // 如果您需要发送图片、语音、视频、文件等富媒体消息，请设置为 true
          useUploadPlugin: true,
          // 本地审核可识别、处理不安全、不适宜的内容，为您的产品体验和业务安全保驾护航
          // 此功能为增值服务，请参考：https://cloud.tencent.com/document/product/269/79139
          // 如果您已购买内容审核服务，开启此功能请设置为 true
          useProfanityFilterPlugin: false,
          framework: "vue2"
        }).then(res=>{
          console.log('登录IM成功', userID);
          if(this.toUserID) {
            this.openChat(this.toUserID)
          }
        }).catch(error=>{
          console.log('登录IM失败', error);
        })
      }
    },
    // 打开 1v1 聊天
    openChat(toUserID) {
      // 切换会话进入聊天
      TUIConversationService.switchConversation(`C2C${toUserID}`);
    },
    // 去设置常用语
    setPhrase() {
      this.$router.push({path: 'commonphrases'})
    },
    itemClick(type: String) {
      if(type == 'report'){
        this.$emit('customTypeClick', 'report')
      } else if(type == 'phrases') {
        this.isShowCommonPhraseDialog = true
      } else {
        let options = {
          payload: {
            text: this.msgMap.send[type]
          },
          type: 'text'
        }
        
        this.$emit('sendMessage', options)
      }
    },
    // 选择了常用语
    selectedPhrase(name) {
      this.isShowCommonPhraseDialog = false
      let options = {
        payload: {
          text: name
        },
        type: 'text'
      }
      
      this.$emit('sendMessage', options)
    },
    sendMsg(payload: Object) {
      let options = {
        payload: payload,
        type: 'text'
      }
      
      this.$emit('sendMessage', options)
    },
    // 消息item 按钮点击事件
    messageItemDeal(type: String, status: Number, message: Object) {
      if(status == 1) {
        if (type === 'ask_resume') {
          this.sendMyResume(getMobile(message.to), type)
          return 
        }
        this.getMyUserInfoAndSend(type, message, getMobile(message.to))
      } else {
        let payload =  {
          text: this.msgMap.reply_to[type].notAgree
        }
        this.sendMsg(payload) //拒绝
      }

      let params = {
        handle: "save",
        msg_id: message.ID,
        remark: `${getMobile(message.to)}~${getMobile(message.from)}~${this.msgMap.send[type]}`,
        status: status,
        type: type,
      }
      this.saveRes(params)
    },
    getMyUserInfoAndSend(type: String, message: Object, mobile: String) {
      let userInfoParmas = {
        mobile: mobile
      }
      getInfoByPhone(userInfoParmas).then(res=>{
        if(res.code == 1) {
          if(type == 'mobile') {
            let payload =  {
              text: this.msgMap.reply_to[type].agree + mobile
            }
            this.sendMsg(payload) //发送我的联系方式
            this.getToUserInfoAndSend(type, message)  //调接口回复消息
          } else {
            if (res.wechat === '') {
              // TODO::未设置，不回复
              return false
            } else {
              let payload =  {
                text: this.msgMap.reply_to[type].agree + res.data.wechat
              }
              this.sendMsg(payload) //发送我的微信
              this.getToUserInfoAndSend(type, message)  //调接口回复消息
            }
          }
        }
      })
    },
    getToUserInfoAndSend(type: String, message: Object){
      let userInfoParmas = {
        mobile: getMobile(message.from)
      }
      getInfoByPhone(userInfoParmas).then(res=>{
        if(res.code == 1) {
          let send_text = this.msgMap.reply_from[type]
          if(type == 'mobile') {
            send_text += res.data.mobile
          } else if(type == 'wechat') {
            if (res.wechat === '') {
              // this.$u.toast('对方未设置微信号')
              // 未设置，不发送
              return false
            }
            send_text += res.data.wechat
          } else if (this.handleType === 'resume') {
            if (res.attach === '') {
              // this.$u.toast('对方未上传附件简历')
              // 未设置，不发送
              return false
            }
            // this.doHandInResume(2, res.attach)//对方，投递了我的职位
          }
          let params = {
            mobile: getMobile(message.from),
            text: send_text,
            to_mobile: getMobile(message.to)
          }
          sendImMsgText(params).then(res=>{
            if(res.code == 1) {
              // debugger
            }
          })
        }
      })
    },
    saveRes(params) {
      imMessage(params).then(res=>{
        this.$emit('savedRes', params.msg_id)
      })
    },

    sendMyResume(mobile: String, type: String) {
      let userInfoParmas = {
        mobile: mobile
      }
      getInfoByPhone(userInfoParmas).then(res=>{
        if(res.code == 1) {
          if (!res.data.attach || res.data.attach === '') {
            this.$message.warning('请先上传您的附件简历')
            setTimeout(() => {
              this.$router.push({path: 'personal-center'})
            }, 1000)
            return
          } else {
            let options = {
              payload: {
                text: this.msgMap.reply_from[type]
              },
              type: 'text'
            }
            this.$emit('sendMessage', options)
          }
        }
      })
    },
    downLoad(message: Object) {
      let userInfoParmas = {
        mobile: getMobile(message.from)
      }
      getInfoByPhone(userInfoParmas).then(res=>{
        if(res.code == 1) {
          let url = `${this.$store.getters.imgeCDN}${res.data.attach}`
          window.open(url)
        }
      })
    },
    deleteConversation(item) {
      debugger
      deleteChat({user_ids: [item.user_id]}).then(res=>{
        if(res.code == 1) {
          this.getChatList()
        }
      })
    },
    saveRecordRes() {
//       let params = {
//         handle: "find"
// mobile
// : 
// "18889673307"
// to_mobile
// : 
// "17608926667"
//       }
    }
    
  },
  created() {
    let mobile = this.$route.query.mobile
    if(mobile) {
      this.toUserID = 'jjzl_' + mobile
    }
    this.getUserInfo()
    this.getChatList()
    this.loginIM()
  },
  mounted: function () {
    TUIStore.watch(StoreName.CONV, {
      currentConversationID: (id: string) => {
        this.currentConversationID = id;
      },
    });

    this.msgMap = msgMap()
  },
  computed: {
    imageUrl: function(){
      return function (img) {
        if(img) {
          return this.$store.getters.imgeCDN + img
        }
        return ''
      }
    },
  }
});
</script>
<style scoped lang="scss">
@import "@/TUIKit/assets/styles/common.scss";
@import "@/TUIKit/assets/styles/sample.scss";
.navbar {
  display: none;
  box-sizing: border-box;
  min-width: 285px;
  flex: 0 0 24%;
  border-right: 1px solid #f4f5f9;
  padding: 20px;
  .navbar-button {
    padding: 10px 40px;
    background-color: #006eff;
    color: #ffffff;
    font-size: 16px;
    margin-top: 65px;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
  }
}
.navbar-h5 {
  flex: 1;
  border-right: 1px solid #f4f5f9;
  .navbar-button {
    cursor: none;
  }
}
.chat {
  flex: 1;
}

.content {
  display: flex;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 16px;
  .left {
    width: 323px;
    border-radius: 16px 0 0 16px;
    background-color: rgba(0, 0, 0, 0.4);
    // padding: 0 20px 0 27px;
    .my-self {
      padding: 33px 20px 33px 27px;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 23px;
      color: #FFFFFF;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      img{
        width: 40px;
        height: 40px;
        margin-right: 23px;
        border-radius: 20px;
      }
    }
    .conversation-item {
      padding: 27px 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .left-info {
        display: flex;
        align-items: center;
        img {
          width: 48px;
          height: 48px;
          border-radius: 24px;
          margin-right: 17px;
        }
        .boss{
          max-width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
          font-size: 17px;
          color: #FFFFFF;
          margin-bottom: 7px;
          span{
            margin-left: 10px;
            font-size: 13px;
            opacity: 0.4;
          }
        }
        .msg{
          max-width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #FFFFFF;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .time {
          font-size: 13px;
          color: #FFFFFF;
          opacity: 0.4;
          margin-bottom: 9px;
        }
        img {
          width: 17px;
          height: 17px;
          cursor: pointer;
        }
      }
    }
  }
  .TUIKit {
    width: calc(100% - 323px);
  }


.avatar-container{
  width: 48px;
    height: 48px;
    border-radius: 24px;
}
}
.avatar-container{
  width: 48px;
    height: 48px;
    border-radius: 24px;
}
</style>
