<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-15 14:18:45
 * @Description: 入口文件
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // history: []
    }
  },
  mounted() {
    if(localStorage.getItem('user')) {
      this.$store.commit('setUserInfo', JSON.parse(localStorage.getItem('user')))

      let localUrl = window.location.href
      if(localUrl.indexOf('shoppingMall') == -1) {
        if(window.location.pathname == '/'){
          const centerUrl = this.$store.getters.userInfo.identity === 1 ? '/' : '/recruiter/home'
          this.$router.push(centerUrl)
        }
      }
    }
    // this.history.push(this.$route.path); // 将当前路由添加到历史记录中
    // this.unwatch = this.$router.afterEach((to, from) => {
    //   // 每次路由变化后，将路由路径添加到历史记录中
    //   this.history.push(to.path);
    // });
  },
  beforeDestroy() {
    // 组件销毁前，移除路由监听器
    // this.unwatch();
  }
}
</script>

<style lang="less">
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.custom-popover-myself{
  padding: 0px 20px !important;
  position: absolute;
  top: 90px !important;
  background: #fff;
  .cont{
    .item{
      height: 68px;
      padding-right: 60px;
      line-height: 67px;
      font-size: 12px;
      border-bottom: 1px solid #E8E8E8;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img{
        width: 28px;
        height: 28px;
        margin-right: 20px;
      }
      span{
        font-size: 16px;
      }
    }
    .item:last-child{
      border: none;
    }
  }
  .popper__arrow{
    display: none !important;
  }
}
.custom-popover-myself-shopping{
  width: 535px;
  // height: 204px;
  box-sizing: border-box;
  position: absolute !important;
  top: 170px !important;
  left: 1100px !important;
  .cont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px;
    .cont-item{
      padding: 7px 29px;
      background: #FAFAFA;
      border-radius: 17px;
      border: 1px solid #ECECEC;
      margin-bottom: 15px;
      margin-right: 20px;
      &.active{
        color: #01BFBF;
        background: #FFFFFF;
        border-radius: 17px;
        border: 1px solid #01BFBF;
      }
    }
  }
  .popper__arrow{
    left: 500px !important;
  }
}
.custom-popover-myself-shop-header {
  padding: 0px 20px !important;
  position: absolute;
  top: 48px !important;
  background: #fff;
  .cont{
    .item{
      height: 68px;
      padding-right: 60px;
      line-height: 67px;
      font-size: 12px;
      border-bottom: 1px solid #E8E8E8;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img{
        width: 28px;
        height: 28px;
        margin-right: 20px;
      }
      span{
        font-size: 16px;
      }
    }
    .item:last-child{
      border: none;
    }
  }
  .popper__arrow{
    display: none !important;
  }
}
</style>