<template>
  <div class='order-item'>
    <div class="shop-name">
      <!-- <div v-if="!orderItem.isSelected && orderItem.state==1" class="un-select" @click="shopHandel(orderItem)"></div>
      <img v-if="orderItem.isSelected && orderItem.state==1" src="../../assets/images/shop-selected.png" @click="shopHandel(orderItem)" alt=""> -->
      <div class="left">
        <img class="shop-icon" src="../../assets/images/shop-icon.png" alt="">
        <div>{{ orderItem.shop.shopname }}</div>
      </div>
      <div>{{ orderItem.state | orderState }}</div>
    </div>
    <div class="goods" v-for="(goodsItem, ind) in orderItem.goods" :key="ind" @click="itemClick(goodsItem)">
      <div class="section1">
        <!-- <div v-if="!goodsItem.isSelected && orderItem.state==1 " class="un-select" @click.stop="goodsHandel(orderItem, goodsItem)"></div>
        <img v-if="goodsItem.isSelected && orderItem.state==1" class="op-img" src="../../assets/images/shop-selected.png" @click="goodsHandel.stop(orderItem, goodsItem)"
          alt=""> -->
        <img class="goods-img" :src="imageUrl(goodsItem.image)" alt="">
      </div>
      <div class="section2">{{ goodsItem.title }}</div>
      <div class="section3">规格：{{ goodsItem.difference }}</div>
      <div class="section4">x{{ goodsItem.number }}</div>
      <div class="section5"><span>￥</span>{{ goodsItem.price }}</div>
      <div class="section6" :style="{color: statusTitleColor}">
        <span v-if="goodsItem.refund_status != 0">{{getRefund(goodsItem.refund_status)}}</span> 
      </div>
    </div>
    <div class="bottom">
      <div class="amount">￥<span>{{ total }}</span> </div>
      <div class="btn default-btn" v-if="isShowChangeAddress" @click.stop="btnClick(1)">修改地址</div>
      <div class="btn default-btn" v-if="orderItem.state==1" @click.stop="btnClick(2)">取消订单</div>
      <div class="btn active-btn" v-if="orderItem.state==1" @click.stop="btnClick(3)">继续付款</div>
      <!-- <div class="btn default-btn" v-if="orderItem.state==3" @click.stop="btnClick(5)">查看物流</div> -->
      <div class="btn active-btn" v-if="orderItem.state==3" @click.stop="btnClick(6)">确认收货</div>
      <div class="btn default-btn" v-if="orderItem.state==4" @click.stop="btnClick(7)">评论订单</div>
      
      <div class="btn default-btn" v-if="orderItem.state==7" @click.stop="btnClick(4)">删除订单</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      total: '0',
      orderItem: {},
      selectedArr: []
    }
  },
  methods: {
    // 店铺选中/取消选中
    shopHandel(item) {
      item.isSelected = !item.isSelected
      item.goods.map(obj=>{
        obj.isSelected = item.isSelected
        if(item.isSelected) {
          let length = this.selectedArr.filter(obj2=>{
            return obj2.id == obj.id
          }).length
          if(length == 0) {
            this.selectedArr.push(obj)
          }
        } else {
          this.selectedArr = this.selectedArr.filter(itemO=>{
            return itemO.id != obj.id
          })
          // this.isAllSelected = false
        }
      })
      this.calTotal()
    },
    goodsHandel(item, goodsItem) {
      goodsItem.isSelected = !goodsItem.isSelected
      // 商品选中加入选中的数组中，未选中则在数组中删除
      if(goodsItem.isSelected) {
        this.selectedArr.push(goodsItem)
      } else {
        this.selectedArr = this.selectedArr.filter(obj=>{
          return obj.id != goodsItem.id
        })
      }

      // 如果某个店铺的所有商品都选中，则店铺选中
      let unSelectedGoodCount = item.goods.filter(obj=>{
        return obj.isSelected == false
      }).length
      item.isSelected = unSelectedGoodCount == 0

      // 计算总金额
      this.calTotal()
    },
    // 计算总金额
    calTotal() {
      // if(this.orderItem.state == 2) { //待发货
      //   this.total = this.orderItem.pay.actual_payment
      // } else {
      //   this.total = this.selectedArr.reduce((sum, current) => sum + current.price * current.number, 0);
      // }
      this.total = this.orderItem.pay.price
    },
    itemClick(goodsItem) {
      this.$emit('goodsItemClick', this.item ,goodsItem)
    },
    btnClick(type) {
      // if(type == 3 && this.selectedArr.length == 0){
      //   this.$message.warning('请选择要继续付款的订单')
      //   return
      // }
      this.$emit('btnClick', type, this.orderItem)
    },
    getRefund(status) {
			return ["退款", "退款中", "待退货", "退款完成", "退款关闭", "退款被拒"][status];
		},
  },
  created() {
    this.orderItem = JSON.parse(JSON.stringify(this.item))
    this.calTotal()
  },
  computed: {
    imageUrl: function(){
			return function (img) {
        if(img) {
          return this.$store.getters.imgeCDN + img
        }
        return ''
      }
    },
    isShowChangeAddress() {
      if(this.orderItem.state == 1 || this.orderItem.state == 2) {
        return true
      }
      return false
    },
    statusTitleColor() {
      if(this.orderItem.state == 2 || this.orderItem.state == 3 || this.orderItem.state == 4 || this.orderItem.state == 6 || this.orderItem.state == 7) {
        return '#333'
      }
      return '#FF8019'
    }
  }
}
</script>

<style lang="less" scoped>
.order-item {
  .shop-name {
    padding-bottom: 30px;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 24px;
      }

      .shop-icon {
        margin-right: 10px;
      }
    }
    
  }
  .un-select {
    width: 20px;
    height: 20px;
    margin-right: 24px;
    background: #F3F3F3;
    border-radius: 10px;
    border: 1px solid #EAEAEA;
    cursor: pointer;
  }
  .goods {
    border-top: 1px solid #E9E9E9;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .section1 {
      display: flex;
      align-items: center;

      .op-img {
        width: 20px;
        height: 20px;
        margin-right: 24px;
        cursor: pointer;
      }

      .goods-img {
        width: 60px;
        height: 60px;
      }
    }

    .section2 {
      font-weight: 500;
      font-size: 16px;
      color: #222222;
      width: 20%;
    }

    .section3 {
      font-size: 16px;
      color: #999999;
    }

    .section4 {
      font-weight: 500;
      font-size: 16px;
      color: #222222;
    }

    .section5 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: #EB1001;

      span {
        font-size: 11px;
      }
    }

    .section6 {
      font-weight: 500;
      font-size: 16px;
      color: #FF8019;
      padding-right: 20px;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 33px 0;
    border-top: 1px solid #E9E9E9;

    .amount {
      color: #EB1001;
      font-weight: 500;
      font-size: 18px;
      margin-right: 80px;
      span{
        font-size: 24px;
      }
    }
    .btn {
      width: 112px;
      height: 40px;
      line-height: 40px;
      margin-left: 20px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      border-radius: 20px;
      cursor: pointer;
    }
    .default-btn {
      color: #666666;
      border: 1px solid #E3E3E3;
    }
    .active-btn {
      color: #FFFFFF;
      background: #2676F7;
    }
  }
}</style>