import Vue from 'vue'

Vue.filter("jobManageBtnTitle",function(status) {
	if(status == 2) {
    return '关闭职位'
  } else if (status == 1) {
    return '点击开放'
  } else if (status == 0) {
    return '查看详情'
  } else if (status == 3) {
    return '审核失败'
  } else if (status == -1) {
    return '开放职位'
  }
  return ''
})

Vue.filter("agreementType",function(type) {
	if(type == 1) {
    return '用户协议'
  } else if (type == 2) {
    return '隐私政策'
  } else if (type == 3) {
    return '关于我们'
  } else if (type == 4) {
    return '平台资质'
  }
  return ''
})

//Convert time to date yyyy-MM-dd
Vue.filter("toDay", function (nS) {
	if (nS) {
    let newNs = nS.length == 10 ? Number(nS*1000) : Number(nS)
		var time = new Date(newNs);
		var y = time.getFullYear();
		var m = time.getMonth() + 1;
		var d = time.getDate();
		var h = time.getHours();
		if (isNaN(y)) return "--";
		return y + '-' + m + '-' + d
	} else {
		return '--';
	}
})

//Convert time to date yyyy-MM-dd h:m:s
Vue.filter("toBlockTime",function(nS) {
	if(nS){
		var time = new Date(parseInt(nS) * 1000);
		var y = time.getFullYear();
		var m = time.getMonth()+1;
		var d = time.getDate();
		var h = time.getHours();
		var mm = time.getMinutes();
		var s = time.getSeconds();
		if(m < 10){
			m = "0" + m;
		}
		if(d < 10){
			d = "0" + d;
		}
		if(h < 10){
			h = "0" + h;
		}
		if(mm < 10){
			mm = "0" + mm;
		}
		if(s < 10){
			s = "0" + s;
		}
		if(s == 60){
			s = "00"
		}
		return y + "-" + m + "-" + d +" " + h + ":" + mm + ":" + s
	}else{
		return '--';
	}
})

//Convert time to date yyyy-MM-dd h:m
Vue.filter("toBlockTime2",function(nS) {
	if(nS){
		var time = new Date(parseInt(nS) * 1000);
		var y = time.getFullYear();
		var m = time.getMonth()+1;
		var d = time.getDate();
		var h = time.getHours();
		var mm = time.getMinutes();
		var s = time.getSeconds();
		if(m < 10){
			m = "0" + m;
		}
		if(d < 10){
			d = "0" + d;
		}
		if(h < 10){
			h = "0" + h;
		}
		if(mm < 10){
			mm = "0" + mm;
		}
		if(s < 10){
			s = "0" + s;
		}
		if(s == 60){
			s = "00"
		}
		return y + "-" + m + "-" + d +" " + h + ":" + mm// + ":" + s
	}else{
		return '--';
	}
})

//Convert time to date h:m
Vue.filter("time",function(nS) {
	if(nS){
		var time = new Date(parseInt(nS) * 1000);
		var y = time.getFullYear();
		var m = time.getMonth()+1;
		var d = time.getDate();
		var h = time.getHours();
		var mm = time.getMinutes();
		var s = time.getSeconds();
		if(m < 10){
			m = "0" + m;
		}
		if(d < 10){
			d = "0" + d;
		}
		if(h < 10){
			h = "0" + h;
		}
		if(mm < 10){
			mm = "0" + mm;
		}
		if(s < 10){
			s = "0" + s;
		}
		if(s == 60){
			s = "00"
		}

		return h + ":" + mm
		// return y + "-" + m + "-" + d +" " + h + ":" + mm// + ":" + s
	}else{
		return '--';
	}
})

Vue.filter("interviewStatus",function(status) {
	if(status == 3) {
    return '待处理'
  } else if (status == 8) {
    return '待面试'
  } 
	// else if (status == 3) {
  //   return '查看详情'
  // } 
	else if (status == 6) {
    return '已通过'
  } else if (status == 5) {
    return '已参加'
  } else if(status == 7){
		return '未通过'
	} else if(status == 9){
		return '已拒绝'
	}
  return ''
})

Vue.filter("checkStatus",function(status) {
	if(status == 0) {
    return '待审核'
  } else if (status == 1) {
    return '通过'
  } else if (status == 2) {
    return '未通过'
  }
  return ''
})

// 订单状态
Vue.filter("orderState",function(status) {
	if(status == 0) {
    return '00'
  } else if (status == 1) {
    return '等待支付'
  } else if (status == 2) {
    return '等待卖家发货'
  } else if (status == 3) {
    return '卖家已发货'
  } else if (status == 4) {
    return '交易成功'
  } else if (status == 6) {
    return '已完成'
  }else if (status == 7) {
    return '订单已关闭'
  }
  return ''
})

// 订单详情状态
Vue.filter("orderDetailState",function(status) {
	if(status == 0) {
    return '00'
  } else if (status == 1) {
    return '等待您付款'
  } else if (status == 2) {
    return '付款成功'
  } else if (status == 3) {
    return '待收货'
  } else if (status == 4) {
    return '待评论'
  } else if (status == 5) {
    return '退款订单'
  } else if (status == 6) {
    return '订单已完成'
  }else if (status == 7) {
    return '交易关闭'
  }
  return ''
})