<template>
  <div class='common-phrases'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        常用语
        <div class="btn-save" @click="add()">添加</div>
      </div>
    </div>
    <div class="page">
      <div class="page-content content" v-if="dataList.length>0">
        <div class="phrase-item" v-for="(item, index) in dataList" :key="index">
          <div class="detail">{{ item.name }}</div>
          <img class="delete" src="../../../assets/images/delete.png" @click="deleteCom(item)" alt="">
        </div>
      </div>
    </div>
    <el-dialog class="dialog" :visible.sync="showDialog" :title="'添加常用语'">
      <el-input
      type="textarea"
      :autosize="{ minRows: 4}"
      placeholder="请输入常用语"
      v-model="txtInput">
      </el-input>
      <div class="oper">
        <div class="confirm-btn" @click="confirm()">确定</div>
      </div>
    </el-dialog>

    <el-dialog class="dialog" :visible.sync="isShowDeleteDialog" title="提示">
      <div>确定要删除该常用语吗？</div>
      <div class="oper">
        <div class="default-btn" @click="isShowDeleteDialog=false">取消</div>
        <div class="confirm-btn" @click="confirmDelete()">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { saveCommonPhrase, commonPhrase} from '@/api/jobSeeker'

export default {
  components: {
    // baseDialog,
  },
  data() {
    return {
      showDialog: false,
      isShowDeleteDialog: false,
      dataList: [],
      txtInput: '',
      deleteItem: null
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    add() {
      this.showDialog = true
    },
    confirm() {
      let params = {
        name: this.txtInput
      }
      saveCommonPhrase(params).then(res=>{
        if(res.code == 1) {
          this.showDialog = false
          this.txtInput = ''
          this.$message.success('保存成功')
          this.getList()
        }
      })
    },
    getList() {
      commonPhrase().then(res=>{
        if(res.code == 1) {
          this.dataList = res.data
        }
      })
    },
    deleteCom(item) {
      this.deleteItem = item
      this.isShowDeleteDialog = true
    },
    confirmDelete() {
      let params = {
        id: this.deleteItem.id
      }
      saveCommonPhrase(params).then(res=>{
        if(res.code == 1) {
          this.isShowDeleteDialog = false
          this.getList()
        }
      })
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.common-phrases{
  .content {
    // width: 100%;
    margin-bottom: 20px;
    padding: 10px 40px 30px;
    background: #FFFFFF;
    border-radius: 13px 13px 13px 13px;
    .phrase-item{
      font-size: 20px;
      color: #222222;
      line-height: 24px;
      padding: 30px 0;
      border-bottom: 1px solid #E8E8E8;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .detail {
        margin-right: 100px;
      }
      .delete {
        width: 27px;
        height: 27px;
        cursor: pointer;
      }
    }
  }

  .dialog{
    .oper {
      display: flex;
      justify-content: end;
      margin-top: 20px;
      .confirm-btn {
        width: 57px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        background: #2676F7;
        border: 1px solid #2676F7;
        border-radius: 7px 7px 7px 7px;
        font-size: 12px;
        color: #FFFFFF;
        cursor: pointer;
      }
      .default-btn { 
        width: 57px;
        height: 27px;
        margin-right: 20px;
        line-height: 27px;
        text-align: center;
        background: #fff;
        color: #666666;
        border: 1px solid #E3E3E3;
        border-radius: 7px 7px 7px 7px;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }

  /deep/.el-dialog {
    width: 520px;
    border-radius: 7px;
  }
  /deep/.el-textarea{
    font-size: 17px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
  }
}
</style>