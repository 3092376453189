<template>
  <div class='address-item' @click="selectAddressItem()">
    <img v-if="item.isSelected" src="../../assets/images/address-selected.png" @click.stop="handelSelectAddress()" alt="">
    <div v-else class="select-add" @click.stop="handelSelectAddress()"></div>
    <div>
      <div class="contact">
        <div>{{ item.name }} {{ item.mobile }}</div>
        <div class="default" v-if="item.isDefault">默认</div>
      </div>
      <div class="address">{{ item.formatted_address }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  data() {
    return {
    }
  },
  methods: {
    selectAddressItem() {
      this.$emit('selectAddressItem', this.item)
    },
    handelSelectAddress() {
      this.$emit('handelSelectAddress', this.item, this.index)
    }
  }
}
</script>

<style lang="less" scoped>
.address-item {
  width: 311px;
  padding: 15px;
  margin-right: 45px;
  background: #F4F4F4;
  border-radius: 2px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  img{
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-right: 20px;
  }

  .select-add {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    border: 1px solid #E1E1E1;
    background: #E1E1E1;
    cursor: pointer;
    margin-right: 20px;
  }

  .contact {
    font-size: 14px;
    color: #222222;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .default {
      font-size: 14px;
      color: #FA8B2D;
    }
  }
  .address{
    margin-top: 10px;
    font-size: 14px;
    color: #999999;
  }
}
</style>