<template>
  <div class='company-detail'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        公司详情
        <div class="right-btn">
          <img src="../../../assets/images/information.png" @click="report()" alt="">
          <img v-if="detailInfo.collect" src="../../../assets/images/star_yellow.png" @click="collect()" alt="">
          <div v-else class="el-icon-star-off collect" @click="collect()"></div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="company">
          <div class="info">
            <img :src="companyLogoImage" alt="">
            <div>
              <div class="company-name">{{ detailInfo.company_name }}</div>
              <div class="company-info">
                <!-- <span>未融资</span> -->
                <span>{{ detailInfo.scale_text }}</span><span>{{ detailInfo.company_category_id_text }}</span>
              </div>
            </div>
          </div>
          <div class="detail">
            <div class="time">
              <span class="dot"></span>
              <span class="">{{ detailInfo.works }}</span>
              <span class="dot"></span>
              <span class="">{{ detailInfo.rests_text }}</span>
            </div>
            <div class="welfare">
              <span v-for="(item, indx) in detailInfo.welfare_text" :key="indx">{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="introduce">
          <div class="model">
            <div class="title">公司地址</div>
            <div class="detail-intro">{{ detailInfo.area ? detailInfo.area.mergename : '' }} {{ detailInfo.address }}</div>
          </div>
          <div class="model">
            <div class="title">公司介绍</div>
            <div class="detail-intro">{{ detailInfo.desc }}</div>
          </div>
          <div class="model pb0">
            <div class="title">公司相册</div>
            <div class="album-intro">
              <img :class="{'mr0': (index+1)%3==0}" v-for="(item, index) in album" :src="item" :key="index" alt="">
            </div>
          </div>
        </div>
        <div class="working-feel">
          <div class="title">
            <div>在职感受</div>
            <img src="../../../assets/images/arrow-right.png" @click="moreFeel()" alt="">
          </div>
          <div class="feel-item">
            <div class="person">
              <img :src="feelAvatar" alt="">
              <div>
                <div class="name">{{ workFeel.name }}</div>
                <div class="sub-info">
                  <span>{{ detailInfo.company_name }}</span> <span>在职{{workFeel.duration}}年</span>
                </div>
              </div>
            </div>
            <div class="feel">{{ workFeel.content }}</div>
          </div>
        </div>
        <div class="contacts">
          <div class="title">热招联系人</div>
          <div class="person">
            <img :src="contactAvatar" alt="">
            <div>
              <div class="name">{{ detailInfo.nickname }}</div>
              <div class="sub-info">
                <span>{{ detailInfo.company_name }}</span> <span>{{ detailInfo.position }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="business-info">
          <div class="title">工商信息</div>
          <div class="business-detail-info">
            <el-row class="row">
              <el-col :span="3">公司全称</el-col>
              <el-col :span="10" class="color222">{{ detailInfo.company_full_name }}</el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="3">法定代表人</el-col>
              <el-col :span="10" class="color222">{{ detailInfo.legal_person }}</el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="3">注册资金</el-col>
              <el-col :span="10" class="color222">{{detailInfo.registered_capital}}人民币</el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="3">成立日期</el-col>
              <el-col :span="10" class="color222">{{ detailInfo.establish_date | toDay }}</el-col>
            </el-row>
          </div>
        </div>
        <div class="hot-job">
          <div class="title">
            <div>热招职位（ {{ total }} ）</div>
            <img src="../../../assets/images/arrow-right.png" @click="moreJob()" alt="">
          </div>
          <div class="view">
            <hotjobItem :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in jobs" :key="index" :item="item" @itemClick="goJobDetail"></hotjobItem>
          </div>
        </div>
      </div>
    </div>

    <el-dialog class="dialog" :visible.sync="showDialog" :title="'举报'">
      <div>举报该记录，请将举报信息及截图发送至客服邮箱：<span>hdfhiehfe@qq.com</span>，平台收到后将在15个工作日内进行处理并反馈。 </div>
      <div class="oper">
        <div class="confirm-btn" @click="showDialog=false">我知道了</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import hotjobItem from '@/components/hotJobItem.vue'
import { companyDetail, workFeelList, jobseekerIndex, jobCollect, getIndexTotal } from '@/api/jobSeeker'

export default {
  components: {
    hotjobItem,
  },
  data() {
    return {
      showDialog: false,
      id: null,
      detailInfo: {},
      workFeel: {},
      jobs: [],
      total: 0
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    report() {
      this.showDialog = true
    },
    collect() {
      let params = {
        id: this.id,
        type: '2'
      }
      jobCollect(params).then(res=>{
        if(res.code == 1) {
          this.detailInfo.collect = !this.detailInfo.collect
          this.$message.success(this.detailInfo.collect ? '已收藏' : '取消收藏')
        }
      })
    },
    moreFeel() {
      // 
      this.$router.push({path: 'workingfeel', query: {id: this.id}})
    },
    moreJob() {
      this.$router.push({path: 'hotjobacancies', query: {id: this.id} })
    },
    goJobDetail(item) {
      this.$router.push({path: 'jobdetail', query: {id: item.id, companyId: item.company_id}})
    },
    // 公司详情
    getCompanyDetail() {
      let params = {
        id: this.id
      }
      companyDetail(params).then(res=>{
        if(res.code == 1) {
          this.detailInfo = res.data
        }
      })
    },
    // 在职感受
    getWorkFeel() {
      let params = {
        company_id: this.id,
        page: 1,
        row: 1,
      }
      workFeelList(params).then(res=>{
        if(res.code == 1) {
          if(res.data.length) {
            this.workFeel = res.data[0]
          }
        }
      })
    },
    // 热招职位
    hotJob() {
      let params = {
        company_id: this.id,
        limit: 3,
        page: 1
      }
      jobseekerIndex(params).then(res=>{
        if(res.code == 1) {
          this.jobs = res.data
        }
      })
    },
    // 热招职位总数
    getTotal() {
      let params = {
        company_id: this.id
      }
      getIndexTotal(params).then(res=>{
        if(res.code == 1) {
          this.total = res.data
        }
      })
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getCompanyDetail()
    this.getWorkFeel()
    this.hotJob()
    this.getTotal()
  },
  computed: {
    companyLogoImage() {
      if(this.detailInfo.company_logo_image) {
        return this.$store.getters.imgeCDN + this.detailInfo.company_logo_image
      }
      return ''
    },
    album() {
      let imageArr = []
      if(this.detailInfo.images) {
        imageArr = this.detailInfo.images.split(',')
        imageArr = imageArr.map(item=>{
          return this.$store.getters.imgeCDN + item
        })
      }
      return imageArr
    },
    feelAvatar() {
      if(this.workFeel.avatar) {
        return this.$store.getters.imgeCDN + this.workFeel.avatar
      }
      return ''
    },
    contactAvatar() {
      if(this.detailInfo.avatar) {
        return this.$store.getters.imgeCDN + this.detailInfo.avatar
      }
      return ''
    }
  }
}
</script>

<style lang="less" scoped>
.company-detail {
  .right-btn {
    position: absolute;
    right: 0;
    display: flex;

    img{
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
    img:nth-child(1) {
      margin-right: 40px;
    }
    .collect {
      color: gray;
      font-size: 34px;
      cursor: pointer;
    }
  }

  .company {
    width: 100%;
    padding: 40px;
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 13px 13px 13px 13px;

    .info {
      display: flex;
      align-items: center;
      margin-bottom: 33px;
      padding-bottom: 40px;
      border-bottom: 1px solid #E8E8E8;
      img {
        width: 90px;
        height: 90px;
        margin-right: 30px;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #E8E8E8;
      }
      .company-name {
        font-size: 27px;
        color: #222222;
        margin-bottom: 10px;
      }
      .company-info {
        font-size: 20px;
        color: #999999;

        span{
          margin-right: 13px;
        }
      }
    }

    .detail {
      padding: 33px 40px;
      background: rgba(38,118,247,0.04);
      border-radius: 13px 13px 13px 13px;

      .time {
        font-size: 23px;
        color: #2676F7;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        span{
          display: inline-block;
        }

        span:nth-child(2) {
          margin-right: 47px;
        }

        .dot {
          width: 7px;
          height: 7px;
          border-radius: 4px;
          background-color: #2676F7;
          margin-right: 13px;
        }
      }

      .welfare {
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          height: 38px;
          padding: 0 27px;
          line-height: 38px;
          border-radius: 7px;
          border: 1px solid #2676F7;
          font-size: 17px;
          color: #2676F7;
          margin-right: 20px;
        }
      }
    }
  }

  .introduce {
    width: 100%;
    padding: 0 40px 40px;
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 13px;

    .model {
      padding: 40px 0;
      border-bottom: 1px solid #E8E8E8;
      .title {
        font-weight: 600;
        font-size: 27px;
        color: #222222;
        margin-bottom: 27px;
      }

      .detail-intro {
        font-size: 23px;
        color: #222222;
      }

      .album-intro {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        img {
          width: 346px;
          height: 235px;
          margin-right: 40px;
          border-radius: 13px 13px 13px 13px;
        }

        .mr0{
          margin-right: 0;
        }
        
      }
    }
    
    .model:last-child{
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .working-feel {
    width: 100%;
    padding: 40px;
    background: white;
    border-radius: 13px 13px 13px 13px;
    margin-bottom: 20px;
    .title {
      font-weight: 600;
      font-size: 27px;
      color: #222222;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img{
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
    }

    .feel-item {
      width: 100%;
      padding: 40px;
      border-radius: 13px 13px 13px 13px;
      border: 1px solid #E8E8E8;
      .person{
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        img{
          width: 73px;
          height: 73px;
          border-radius: 37px;
          margin-right: 27px;
        }
        .name {
          font-size: 27px;
          color: #222222;
          margin-bottom: 8px;
        }

        .sub-info {
          font-size: 20px;
          color: #999999;
          span{
            margin-right: 20px;
          }
        }
      }
      
      .feel {
        font-size: 23px;
        color: #222222;
      }
      
    }
  }
  .contacts {
    width: 100%;
    padding: 40px;
    background: white;
    border-radius: 13px 13px 13px 13px;
    margin-bottom: 20px;

    .title {
      font-weight: 600;
      font-size: 27px;
      color: #222222;
      margin-bottom: 20px;
    }

    .person{
      width: 504px;
      height: 153px;
      margin-bottom: 20px;
      padding-left: 40px;
      display: flex;
      align-items: center;
      border-radius: 13px 13px 13px 13px;
      border: 1px solid #E8E8E8;

      img{
        width: 73px;
        height: 73px;
        border-radius: 37px;
        margin-right: 27px;
      }
      .name {
        font-size: 27px;
        color: #222222;
        margin-bottom: 8px;
      }

      .sub-info {
        font-size: 20px;
        color: #999999;
        span{
          margin-right: 20px;
        }
      }
    }
  }

  .business-info{
    width: 100%;
    padding: 40px;
    background: white;
    border-radius: 13px 13px 13px 13px;
    margin-bottom: 20px;

    .title {
      font-weight: 600;
      font-size: 27px;
      color: #222222;
      margin-bottom: 20px;
    }

    .business-detail-info{
      font-size: 23px;
      color: #999999;

      .row{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }

      .row:last-child{
        margin: 0;
      }

      .color222 {
        color: #222222;
      }
    }
    
  }

  .hot-job {
    width: 100%;
    padding: 40px;
    background: white;
    border-radius: 13px 13px 13px 13px;
    margin-bottom: 20px;

    .title {
      font-weight: 600;
      font-size: 27px;
      color: #222222;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img{
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
    }

    .view {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .mr0{
        margin-right: 0;
      }
    }
  }

  .dialog{
    .oper {
      display: flex;
      justify-content: end;
      margin-top: 20px;
      .confirm-btn {
        width: 80px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        background: #2676F7;
        border-radius: 7px 7px 7px 7px;
        font-size: 12px;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  /deep/.el-dialog {
    width: 367px;
    border-radius: 7px;
  }
  /deep/.el-textarea{
    font-size: 17px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
    font-size: 13px;

    span{
      color: #2676F7;
    }
  }
}

</style>