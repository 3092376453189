<template>
  <div class='record-item' @click="itemClick()">
    <img class="bg-img" :src="bgImg" alt="">
    <!-- <video ref="video" style="display: none;" @loadedmetadata="getDuration" :src="videoUrl"></video> -->
    <div class="bottom">
      <div class="left">
        <img src="../assets/images/video-play.png" alt="">
        <div>{{ item.introduce }}</div>
      </div>
      <div>{{ videoDuration }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      videoDuration: 0
    }
  },
  methods: {
    itemClick() {
      this.$emit('itemClick', this.item)
    },
    getDuration() {
      let url = this.videoUrl
      let audioElement = new Audio(url)
      let self = this
      let res;
      audioElement.addEventListener('loadedmetadata', function() {
        res = audioElement.duration //得到的时长为秒，小数
        let seconds = parseInt(res) //转成int
        // 转成分秒
        let mins = parseInt(seconds / 60)
        let second = self.padNumber(seconds % 60, 2) 
        self.videoDuration = `${mins}:${second}`
      })
    },
    // 秒是1位数时前面加0
    padNumber(num, length) {
      return String(num).padStart(length, '0');
    }
  },
  computed: { 
    bgImg() {
      if(this.item.cover_image) {
        return this.$store.getters.imgeCDN + this.item.cover_image
      }
      return ''
    },
    videoUrl() {
      if(this.item.recorded) {
        return this.$store.getters.imgeCDN + this.item.recorded
      }
      return ''
    }
  },
  created() {
    this.getDuration()
  }
}
</script>

<style lang="less" scoped>
.record-item{
  width: 386px;
  height: 228px;
  border-radius: 13px;
  margin-right: 20px;
  margin-bottom: 20px;
  // cursor: pointer;
  // background-color: cadetblue;
  position: relative;
  cursor: pointer;

  .bg-img{
    width: 100%;
    height: 100%;
    border-radius: 13px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bottom{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
    border-radius: 0 0 13px 13px;
    // background-color: chocolate;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.5);
    .left{
      display: flex;
      align-items: center;

      img{
        width: 17px;
        height: 17px;
        margin-right: 11px;
        cursor: pointer;
      }
    }
  }
}
</style>