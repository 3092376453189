<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-23 11:10:47
 * @Description: 关于我们
-->
<template>
  <div class='agreement'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        {{ type | agreementType }}
      </div>
    </div>
    <div class="page">
      <div class="page-content content">
        <div v-html="content"></div>
        <!-- <div class="title">如何像老板提问？</div> -->
        <!-- <div class="detail">这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案案这里是文案这案这里是文案这案这里是文案这案这里是文案这文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案案这里是文案这案这里是文案这案这里是文案这案这里是文案这</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getContentImage, } from '@/api/common'
export default {
  data() {
    return {
      type: null,
      content: '',
    }
  },
  created () {
    
  },
  methods: {
    back() {
      this.$router.back()
    },
    getContentImage(type){
      this.content = ''
      getContentImage({name:type}).then(res => {
        if (res.code === 1) {
          this.content = res.data[0].content
        }
      })
    }
  },
  watch: {
    $route: {
      handler (to, from) {
        this.type = to.query.type
        if (this.type == 1) {
          this.getContentImage('agreement')
        } else if (this.type == 2) {
          this.getContentImage('privacy')
        } else  if (this.type == 3) {
          this.getContentImage('about')
        } else if (this.type == 4) {
          this.getContentImage('平台资质')
        }
      },
      deep: true,
      immediate: true// 第一次初始化渲染就可以监听到
    }
  }
}
</script>

<style lang="less" scoped>
.agreement {
  .content {
    padding: 40px;
    background-color: white;

    .title {
      font-weight: 600;
      font-size: 27px;
      color: #222222;
      margin-bottom: 20px;
    }

    .detail {
      font-weight: 400;
      font-size: 23px;
      color: #222222;
      margin-bottom: 33px;
    }

    img {
      width: 100%;
      height: 235px;
      border-radius: 13px 13px 13px 13px;
    }
  }
}

</style>