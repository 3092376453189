/*
 * @Author: angel~dongsanling
 * @Date: 2024-08-01 13:47:01
 * @Description: 
 */
import request from '@/utils/request'

export function commonArea (data = {}) {
  return request({
    method: 'get',
    url: 'common/area',
    data: data,
    isToken: false
  })
}
// 搜索城市
export function citySearch (data) {
  return request({
    method: 'get',
    url: 'common/citySearch',
    data: data,
    isToken: false
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 关于我们
 * @param {*} data
 */
export function getContentImage (data) {
  return request({
    method: 'get',
    url: 'index/getContentImage',
    data: data,
    isToken: false
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 省份
 * @param {*} data
 */
export function getArea (data) {
  return request({
    method: 'get',
    url: 'index/area',
    data: data,
    isToken: false
  })
}
