<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-30 14:31:58
 * @Description: 
-->
<template>
  <div class='select-coupon-dialog'>
    <el-dialog 
      class="dialog" 
      title="选择优惠券"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
    <div>
      <!-- <div class="dia-title">选择优惠券</div> -->
      <coupon class="mb16" v-for="(item, index) in coupons" :key="index" :item="item" @receive="receive" @selectCoupon="selectCoupon"></coupon>
    </div>
  </el-dialog>
  </div>
</template>

<script>
import coupon from '@/components/shoppingMall/coupon.vue';

export default {
  props: {
    coupons: {
      type: Array
    } 
  },
  components: {
    coupon
  },
  data() {
    return {
      showDialog: true,
    }
  },
  methods: {
    selectCoupon(item) {
      this.$emit('selectCoupon', item)
    },
    receive (data) {
      this.$emit("handleReceive",data)
    }
  }
}
</script>

<style lang="less" scoped>
.select-coupon-dialog {
  .dialog {
    .dia-title {
      margin-bottom: 20px;
      font-size: 18px;
      color: #222222;
      font-weight: 600;
    }
    .mb16 {
      margin-bottom: 16px;
    }
  }
  /deep/.el-dialog {
    width: 425px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.15);
    border-radius: 4px;
  }
  /deep/.el-dialog__header{
    // display: none;
  }
  /deep/.el-dialog__body{
    padding: 25px 25px 20px;
  }
}
</style>