<template>
  <div class='refund-history'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <refundHistory class="mb20" v-for="(item, index) in dataList" :key="index" :item="item"></refundHistory>
      </div>
    </div>
    
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import refundHistory from '@/components/shoppingMall/refundHistoryItem.vue';
import { refundLog } from '@/api/shoppingMall'

export default {
  components: {
    crumbsVue,
    refundHistory,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '退款历史',
        },
      ],
      dataList: []
    }
  },
  methods: {
    getList(id) {
      refundLog({id: id}).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data
        }
      })
    }
  },
  created() {
    this.getList(this.$route.query.id)
  },
}
</script>

<style lang="less" scoped>
.refund-history {
  .content {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 30px;
    margin-bottom: 30px;
    .top{
      font-weight: 600;
      font-size: 24px;
      color: #333333;
      padding-bottom: 20px;
      border-bottom: 1px solid #D8D8D8;
    }
  }
}
</style>