import request from '@/utils/request'

// 首页热门职位
export function jobseekerIndex (data = {}) {
  return request({
    method: 'post',
    url: 'jobseeker/index',
    data: data,
    isToken: true
  })
}

// 获取总数
export function getIndexTotal (data = {}) {
  return request({
    method: 'post',
    url: 'jobseeker/getIndexTotal',
    data: data,
    isToken: true
  })
}

// 录播分类
export function lessonsCategory (data = {}) {
  return request({
    method: 'get',
    url: 'index/getLessonsCategory',
    data: data,
    isToken: true
  })
}

// 录播数据
export function liveLessons (data) {
  return request({
    method: 'get',
    url: 'index/liveLessons',
    data: data,
    isToken: true
  })
}

// 热门职位顶部分类
export function gradePost (data) {
  return request({
    method: 'get',
    url: 'company/getGradePost',
    data: data,
    isToken: true
  })
}

// 职位子级
export function subPost (data) {
  return request({
    method: 'get',
    url: 'company/getPost',
    data: data,
    isToken: true
  })
}


export function jobDetail (data) {
  return request({
    method: 'get',
    url: 'jobseeker/detail',
    data: data,
    isToken: true
  })
}

// 职位收藏
export function jobCollect (data) {
  return request({
    method: 'post',
    url: 'jobseeker/handelCollect',
    data: data,
    isToken: true
  })
}

// 公司详情
export function companyDetail (data) {
  return request({
    method: 'get',
    url: 'company/getDetail',
    data: data,
    isToken: true
  })
}

// 在职感受
export function workFeelList (data) {
  return request({
    method: 'get',
    url: 'company/workFeelLst',
    data: data,
    isToken: true
  })
}
// 在职感受 包含公司信息
export function getWorkFeelList (data) {
  return request({
    method: 'get',
    url: 'company/getWorkFeelLst',
    data: data,
    isToken: true
  })
}

// 课程详情
export function lessonDetail (data) {
  return request({
    method: 'get',
    url: 'jobseeker/getLessonDetail',
    data: data,
    isToken: true
  })
}

// 职位筛选  条件
// 公司情况
export function companyConfig (data) {
  return request({
    method: 'get',
    url: 'company/getConfig',
    data: data,
    isToken: true
  })
}

// 职场资讯
export function information (data) {
  return request({
    method: 'get',
    url: 'index/getInformation',
    data: data,
    isToken: true
  })
}

// 资讯详情
export function informationDetail (data) {
  return request({
    method: 'get',
    url: 'index/getInfoDetail',
    data: data,
    isToken: true
  })
}

// 保存常用语
export function saveCommonPhrase (data) {
  return request({
    method: 'post',
    url: 'user/saveCommonIm',
    data: data,
    isToken: true
  })
}

// 获取常用语列表
export function commonPhrase (data) {
  return request({
    method: 'get',
    url: 'user/getCommonIm',
    data: data,
    isToken: true
  })
}

// 我的推广
export function balance (data) {
  return request({
    method: 'get',
    url: 'jobseeker/balance',
    data: data,
    isToken: true
  })
}
// 推广二维码
export function promotionQrcode (data) {
  return request({
    method: 'get',
    url: 'index/qrcode',
    data: data,
    isToken: true
  })
}

// 佣金明细
export function flowList (data) {
  return request({
    method: 'get',
    url: 'jobseeker/flowLst',
    data: data,
    isToken: true,
    encode: true,
  })
}

// 我的课程
export function lessons (data) {
  return request({
    method: 'post',
    url: 'jobseeker/lesson',
    data: data,
    isToken: true
  })
}

// 颁发证书
export function addMyLesson (data) {
  return request({
    method: 'post',
    url: 'jobseeker/addMyLesson',
    data: data,
    isToken: true
  })
}


// 个人中心
export function summary (data) {
  return request({
    method: 'get',
    url: 'jobseeker/summary',
    data: data,
    isToken: true
  })
}

// 我的订单
export function orderList (data) {
  return request({
    method: 'post',
    url: 'jobseeker/orderLst',
    data: data,
    isToken: true
  })
}

// 收藏列表
export function collectList (data) {
  return request({
    method: 'get',
    url: 'jobseeker/collect_list',
    data: data,
    isToken: true
  })
}

// 删除收藏
export function deleteCollect (data) {
  return request({
    method: 'post',
    url: 'jobseeker/handelCollect',
    data: data,
    isToken: true
  })
}

// 已投递 / 面试记录
export function deliverList (data) {
  return request({
    method: 'get',
    url: 'jobseeker/deliver_list',
    data: data,
    isToken: true
  })
}

// 附件简历
export function attachResumeList (data) {
  return request({
    method: 'get',
    url: 'resume/attachResumeLst',
    data: data,
    isToken: true
  })
}

// 面试详情
export function deliverInfo (data) {
  return request({
    method: 'get',
    url: 'jobseeker/deliverInfo',
    data: data,
    isToken: true
  })
}

// 接受/拒绝面试/j
export function updateReviewStatus (data) {
  return request({
    method: 'post',
    url: 'jobseeker/updateStatus',
    data: data,
    isToken: true
  })
}

// 保存简历
export function attachResume (data) {
  return request({
    method: 'post',
    url: 'resume/attachResume',
    data: data,
    isToken: true
  })
}

// 申请提现
export function applyWithdraw (data) {
  return request({
    method: 'post',
    url: 'jobseeker/withdraw',
    data: data,
    isToken: true
  })
}

// 提现记录
export function withdrawList (data) {
  return request({
    method: 'get',
    url: 'jobseeker/withdrawLst',
    data: data,
    isToken: true,
    // encode: true,
  })
}

// 消息通知
export function noticeList (data) {
  return request({
    method: 'get',
    url: 'index/getNoticeLst',
    data: data,
    isToken: true,
  })
}
// 消息详情
export function noticeDetail (data) {
  return request({
    method: 'get',
    url: 'index/getNotice',
    data: data,
    isToken: true,
  })
}

// IM相关  
export function imMessage (data) {
  return request({
    method: 'post',
    url: 'jobseeker/imMessage',
    data: data,
    isToken: true,
  })
}

export function imRecord (data) {
  return request({
    method: 'post',
    url: 'jobseeker/imRecord',
    data: data,
    isToken: true,
  })
}

export function sendImMsgText (data) {
  return request({
    method: 'post',
    url: 'jobseeker/sendImMsgText',
    data: data,
    isToken: true,
  })
}

export function deliverPosition (data) {
  return request({
    method: 'post',
    url: 'jobseeker/deliver',
    data: data,
    isToken: true,
  })
}
