/*
 * @Author: angel~dongsanling
 * @Date: 2024-06-18 15:15:50
 * @Description: 路由
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import seekerLayout from '../view/jobSeekers/seekerLayout.vue'
import recruiterLayout from '@/view/recruiter/recruiterLayout.vue'
import indexpage from '@/view/jobSeekers/indexPage.vue'
import accountSecurity from '@/view/jobSeekers/personalCenter/accountSecurity.vue'
import accountSecurityRecruiter from '@/view/recruiter/personalCenter/accountSecurity.vue'
import job from '@/view/jobSeekers/job/job.vue'
import personalCenter from '@/view/jobSeekers/personalCenter/index.vue'
import personalData from '@/view/jobSeekers/personalCenter/personalData.vue'
import interviewDetail from '@/view/jobSeekers/personalCenter/interviewRecord/interviewRecordDetail.vue'
import onlineResume from '@/view/jobSeekers/personalCenter/onlineResume.vue'
import myOrder from '@/view/jobSeekers/personalCenter/myOrder.vue'
import myLesson from '@/view/jobSeekers/personalCenter/myLesson.vue'
import myPromotion from '@/view/jobSeekers/personalCenter/myPromotion.vue'
import applyWithdraw from '@/view/jobSeekers/personalCenter/withdraw/applyWithdraw.vue'
import withdrawRecord from '@/view/jobSeekers/personalCenter/withdraw/withdrawRecord.vue'
import authentication from '@/view/jobSeekers/personalCenter/authentication.vue'
import commonPhrases from '@/view/jobSeekers/personalCenter/commonPhrases.vue'
import workplaceForum from '@/view/jobSeekers/workplaceForum/index.vue'
import workplaceNews from '@/view/jobSeekers/workplaceNews/index.vue'
import workplaceNewsDetail from '@/view/jobSeekers/workplaceNews/detail.vue'
import jobSearch from '@/view/jobSeekers/home/search.vue'
import hotJobs from '@/view/jobSeekers/home/hotJobs.vue'
import jobDetail from '@/view/jobSeekers/job/jobDetail.vue'
import companyDetail from '@/view/jobSeekers/home/companyDetail.vue'
import workingFeel from '@/view/jobSeekers/job/workingFeel.vue'
import hotJobAcancies from '@/view/jobSeekers/job/hotJobvAcancies.vue'
import excitingRecord from '@/view/jobSeekers/home/excitingRecording.vue'
import lessonDetail from '@/view/jobSeekers/home/lessonDetail.vue'
import message from '@/view/jobSeekers/message/index.vue'
import messageDetail from '@/view/jobSeekers/message/messageDetail.vue'
import recHome from '@/view/recruiter/home/index.vue'
import publishJob from '@/view/recruiter/job/publishJob.vue'
import reMessage from '@/view/recruiter/message/index.vue'
import recOnlineResume from '@/view/recruiter/personalCenter/onlineResume.vue'
import rePersonalCenter from '@/view/recruiter/personalCenter/index.vue'
import reInterviewDetail from '@/view/recruiter/personalCenter/interviewRecord/interviewRecordDetail.vue'
import jobManage from '@/view/recruiter/job/jobMange.vue'
import memeberCenter from '@/view/recruiter/memberCenter.vue/index.vue'
import reviewInfomation from '@/view/recruiter/personalCenter/reviewInfomation.vue'
import personalProfile from '@/view/recruiter/personalCenter/personalData.vue'
import companyProfile from '@/view/recruiter/company/companyProfile.vue'
import workFeel from '@/view/recruiter/job/workingFeel.vue'
import reCommonPhrases from '@/view/recruiter/personalCenter/commonPhrases.vue'
import agreement from '@/view/agreement.vue'
import shopRouter from '@/router/moudules/shop'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

// 解决vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    component: indexpage,
  },{
    path: '/',
    component: seekerLayout,
    children: [
      {
        path: 'job',
        component: job,
      },{
        path: 'accountSecurity', // 账号安全
        component: accountSecurity
      },{
        path: 'personal-center',
        component: personalCenter
      },{
        path: 'personalData', // 个人资料
        component: personalData
      },{
        path: 'interview-detail',
        component: interviewDetail, //面试记录详情
      },{
        path: 'onlineResume',
        component: onlineResume, //在线简历
      },{
        path: 'myorder',
        component: myOrder, //我的订单
      },{
        path: 'mylesson',
        component: myLesson, //我的课程
      },{
        path: 'mypromotion',
        component: myPromotion, //我的推广
      },{
        path: 'apply-withdraw',
        component: applyWithdraw, //申请提现
      },{
        path: 'withdraw-record',
        component: withdrawRecord, //提现记录
      },{
        path: 'authentication',
        component: authentication, //实名认证
      },{
        path: 'commonphrases',
        component: commonPhrases, //常用语
      },{
        path: 'workplaceforum',
        component: workplaceForum, //职场论坛
      },{
        path: 'workplacenews',
        component: workplaceNews, //职场资讯
      },{
        path: 'newsdetail',
        component: workplaceNewsDetail, //职场资讯-详情
      },{
        path: 'jobsearchresult',
        component: jobSearch, //首页-搜索-职位列表
      },{
        path: 'hotjobs',
        component: hotJobs, //热门职位
      },{
        path: 'jobdetail',
        component: jobDetail, //职位详情
      },{
        path: 'companydetail',
        component: companyDetail, //公司详情
      },{
        path: 'workingfeel',
        component: workingFeel, //在职感受
      },{
        path: 'hotjobacancies',
        component: hotJobAcancies, //热招职位
      },{
        path: 'excitingrecord',
        component: excitingRecord, //精彩录播
      },{
        path: 'lessondetail',
        component: lessonDetail, //课程详情
      },{
        path: 'message',
        component: message,  //消息
      },{
        path: 'messagedetail',
        component: messageDetail, //消息详情
      },{
        path: 'agreement',
        component: agreement, //协议
      }
    ]
  },{
    path: '/recruiter',
    component: recruiterLayout,
    children: [
      {
        path: 'accountSecurityRecruiter',
        component: accountSecurityRecruiter, //账号安全
      },{
        path: 'home',
        component: recHome, //首页
      },{
        path: 'publishjob',
        component: publishJob, //职位
      },{
        path: 'message',
        component: reMessage,  //消息
      },{
        path: 'onlineResume',
        component: recOnlineResume, //在线简历
      },{
        path: 'personalcenter',
        component: rePersonalCenter, //个人中心
      },{
        path: 'interview-detail',  //面试记录详情
        component: reInterviewDetail,
      },{
        path: 'jobmanage',
        component: jobManage, //职位管理
      },{
        path: 'memeber-center',
        component: memeberCenter, //会员中心
      },{
        path: 'review-infomation',
        component: reviewInfomation, //审核信息
      },{
        path: "personal-profile",
        component: personalProfile, //个人资料
      },{
        path: "company-profile",
        component: companyProfile, //公司资料
      },{
        path: 'workfeel',
        component: workFeel, //在职感受
      },{
        path: 'commonphrases',
        component: reCommonPhrases, //常用语
      },{
        path: 'agreement',
        component: agreement, //协议
      }
    ]
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "about" */ '@/view/login/login.vue')
  },
  {
    path: '/login/mobile',
    component: () => import(/* webpackChunkName: "about" */ '@/view/login/mobile.vue')
  },
  {
    path: '/reg',
    component: () => import(/* webpackChunkName: "about" */ '@/view/login/register.vue')
  },
  {
    path: '/reg/company',
    component: () => import(/* webpackChunkName: "about" */ '@/view/login/regCompany.vue')
  },
  {
    path: '/reg/user',
    component: () => import(/* webpackChunkName: "about" */ '@/view/login/regUser.vue')
  },
  {
    path: '/userAgreement',
    component: () => import(/* webpackChunkName: "about" */ '@/view/login/UserAgreement.vue')
  },
  shopRouter
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 } // 将滚动位置设置为顶部
  },
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // let identity = store.getters.userInfo.identity
  // if(identity == 2 && to.path.indexOf('recruiter') == -1){
  //   next(false)
  // } else if (identity == 1 && to.path.indexOf('recruiter') != -1) {
  //   next(false)
  // } else {
  //   next()
  // }
  next()
})


export default router

