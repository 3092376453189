import { render, staticRenderFns } from "./allProducts.vue?vue&type=template&id=857e069a&scoped=true"
import script from "./allProducts.vue?vue&type=script&lang=js"
export * from "./allProducts.vue?vue&type=script&lang=js"
import style0 from "./allProducts.vue?vue&type=style&index=0&id=857e069a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "857e069a",
  null
  
)

export default component.exports