<template>
  <div class='city-dialog'>
    <el-dialog class="dialog" 
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="closedDialog"
    title="切换城市">
      <div class="content">
        <div class="search">
          <el-input placeholder="请输入搜索的城市" v-model="cityInput"></el-input>
          <div class="search-btn" @click="search()">查询</div>
        </div>
        <div class="search-result-title">搜索结果</div>
        <div class="flex-wrap">
          <span v-for="(item, index) in searchResultArr" :key="index" @click="selectedCity(item)">{{ item.name }}</span>
        </div>
        <div>
          <div class="model-title">当前城市</div>
          <div class="flex-wrap">
            <span @click="selectedCity(currentCity)">{{ currentCity.name }}</span>
          </div>
        </div>
        <div>
          <div class="model-title">热门城市</div>
          <div class="flex-wrap">
            <span v-for="(item, index) in hotCity" :key="index" @click="selectedCity(item)">{{ item.name }}</span>
          </div>
        </div>
        <div>
          <div class="model-title">按字母选择：
            <span class="letter" v-for="(item, index) in letterCity" :key="index" @click="selectLetter(item)">{{ item.title }}</span>
          </div>
        </div>
        <div>
          <div class="letter-city" :id="item.id" v-for="(item, index) in letterCity" :key="index" v-show="item.citys.length>0">
            <div class="letter">{{ item.title }}</div>
            <div class="center flex-wrap">
              <span v-for="(cityItem, index) in item.citys" :key="index" @click="selectedCity(cityItem)">{{ cityItem.name }}</span>
            </div>
            <div class="right" @click="showMore(item)">
              <div>{{ item.showMore ? '收起' : '更多' }}</div>
              <img :class="{'show': item.showMore}" src="../assets/images/arrow-down.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="bottom">
        <span class="clear" @click="clear()">清除</span>
        <span class="confirm" @click="confirm()">确定</span>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { commonArea, citySearch } from '@/api/common'

export default {
  props: {
    currentCity: {
      type: Object
    }
  },
  data() {
    return {
      showDialog: true,
      cityInput: '',
      searchResultArr: [],
      hotCity: [
        {id: 2, name: '北京'},
        {id: 20, name: '天津'},
        {id: 802, name: '上海'},
        {id: 1965, name: '广州'},
        {id: 934, name: '杭州'},
        {id: 1988, name: '深圳'},
        {id: 2324, name: '重庆'},
        {id: 2899, name: '西安'},
        {id: 1710, name: '武汉'},
        {id: 1376, name: '济南'},
        {id: 1376, name: '济南'},
        {id: 1387, name: '青岛'},
        {id: 2368, name: '成都'},
      ],
      letterCity: []
    }
  },
  methods: {
    closedDialog() {
      this.$emit('closed')
    },
    // 搜索城市
    search() {
      let params = {
        keyword: this.cityInput
      }
      citySearch(params).then(res=>{
        if(res.code == 1) {
          this.searchResultArr = res.data
        }
      })
    },
    selectLetter(item) {
      let element = document.getElementById(item.id)
      if(element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    showMore(item) {
      item.showMore = !item.showMore
      let element = document.getElementById(item.id)
      if(element) {
        if(item.showMore) {
          element.style.height = 'auto'
          element.style.overflowY = 'auto'
        } else {
          element.style.height = '36px'
          element.style.overflowY = 'hidden'
        }
        
      }
    },
    selectedCity(item) {
      this.$emit('selected', item)
    },
    // 获取所有城市数据
    geCommonArea() {
      commonArea().then(res=>{
        if(res.code == 1) {
          let arr = []
          for(let i = 0; i < res.data.index.length; i++) {
            let letter = res.data.index[i]
            let citys = res.data.data[i]
            let obj = {
              title: letter,
              id: `section${letter}`,
              showMore: false,
              citys: citys
            }
            arr.push(obj)
          }
          this.letterCity = arr
        }
      })
    },
  },
  created() {
    this.geCommonArea()
  }
}
</script>

<style lang="less" scoped>
.city-dialog {
  .dialog{
    .content {
      height: 700px;
      overflow-y: auto;
      .search{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .search-btn {
          width: 50px;
          height: 27px;
          line-height: 27px;
          text-align: center;
          font-size: 12px;
          color: #FFFFFF;
          background: #2676F7;
          border-radius: 3px 3px 3px 3px;
          cursor: pointer;
        }
      }
      .search-result-title{
        font-size: 13px;
        color: #999999;
      }
      .flex-wrap{
        display: flex;
        flex-wrap: wrap;
        margin-top: 13px;

        span {
          display: inline-block;
          height: 38px;
          line-height: 38px;
          padding: 0 13px;
          margin-right: 7px;
          margin-bottom: 13px;
          text-align: center;
          background: #F7F7F7;
          font-size: 17px;
          color: #585858;
          border-radius: 7px 7px 7px 7px;
          cursor: pointer;
        }
      }
      .model-title {
        margin-top: 20px;
        font-size: 13px;
        color: #222222;

        .letter {
          font-size: 13px;
          color: #999999;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      .letter-city{
        display: flex;
        align-items: baseline;
        height: 36px;
        overflow-y: hidden;
        // justify-content: ;
        .letter {
          font-weight: 600;
          font-size: 17px;
          color: #999999;
          margin-right: 20px;
        }

        .center {
          width: 505px;
          height: 38px;
          span{
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            color: #222222;
            background: none;
            margin-bottom: 0;
            padding: 0 5px;
          }
        }

        .right {
          width: 40px;
          // background: #2676F7;
          font-size: 12px;
          color: #999999;
          display: flex;
          align-items: center;
          cursor: pointer;

          img{
            width: 12px;
            height: 12px;
          }

          .show {
            -moz-transform:rotate(-180deg);
            -webkit-transform:rotate(-180deg);
          }
        }
      }
    }

    // .bottom {
    //   margin-top: 30px;
    //   // margin-right: 30px;
    //   display: flex;
    //   justify-content: right;
    //   span {
    //     display: inline-block;
    //     height: 27px;
    //     line-height: 27px;
    //     padding: 0 17px;
    //     text-align: center;
    //     border-radius: 7px 7px 7px 7px;
    //     cursor: pointer;
    //   }
    //   .clear {
    //     margin-right: 10px;
    //     background: rgba(38,118,247,0.08);
    //     color: #2676F7;
    //   }
    //   .confirm {
    //     background: #2676F7;
    //     color: white;
    //   }
    // }
  }
  /deep/.el-dialog {
    width: 633px!important;
    border-radius: 7px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-input{
    width: 267px;
  }
  /deep/.el-input__inner{
    height: 27px;
    line-height: 27px;
    font-size: 12px;
  }
  /deep/.el-dialog__body{
    padding: 27px;
  }
  ::-webkit-scrollbar{
    display: none;
  }
}
</style>