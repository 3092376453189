<template>
  <div class='refund-history-item'>
    <div class="top">
      <img :src="imageUrl(item.avatar)" alt="">
      <div>
        <div class="name">{{ item.name }}</div>
        <div class="time">{{ item.createtime_text }}</div>
      </div>
    </div>
    <div class="detail">{{ item.content }}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    imageUrl: function(){
      return function (img) {
        if(img) {
          return this.$store.getters.imgeCDN + img
        }
        return ''
      }
    },
  }
}
</script>

<style lang="less" scoped>
.refund-history-item {
  padding: 30px;
  background: #FFFFFF;
  border-radius: 10px;  

  .top {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    img{
      width: 60px;
      height: 60px;
      border-radius: 30px;
      margin-right: 19px;
    }

    .name {
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      margin-bottom: 7px;
    }
    .time {
      font-size: 14px;
      color: #999999;
    }
  }
  .detail {
    font-size: 16px;
    color: #666666;
  }
}

</style>