<template>
  <div class='select-job-dia'>
    <el-dialog class="dialog" 
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="closedDialog"
    title="选择时间">
      <div class="content">
        <el-date-picker
          v-model="time"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </div>
      <div class="bottom">
        <span class="clear" @click="cancel()">取消</span>
        <span class="confirm" @click="confirm()">确定</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { gradePost, subPost } from '@/api/jobSeeker'

export default {
  data() {
    return {
      showDialog: true,
      time: []
    }
  },
  methods: {
    closedDialog() {
      this.$emit('closed')
    },
    cancel() {
      this.$emit('closed')
    },
    confirm() {
      this.$emit('confirm', this.time)
    },
  },
  created() {
  }
}
</script>

<style lang="less" scoped>
.select-job-dia{

  .dialog{
    .content {
      display: flex;
      font-size: 17px;
      color: #585858;
      padding-bottom: 50px;
    }

    .bottom {
      margin-top: 30px;
      margin-right: 30px;
      display: flex;
      justify-content: right;
      span {
        display: inline-block;
        height: 27px;
        line-height: 27px;
        padding: 0 17px;
        text-align: center;
        border-radius: 7px 7px 7px 7px;
        cursor: pointer;
      }
      .clear {
        margin-right: 10px;
        background: rgba(38,118,247,0.08);
        color: #2676F7;
      }
      .confirm {
        background: #2676F7;
        color: white;
      }
    }
  }
  /deep/.el-dialog {
    width: 520px;
    border-radius: 7px;
  }
  /deep/.el-textarea{
    font-size: 17px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
  }
}
</style>