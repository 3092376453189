<template>
  <div class='notice-item' @click="itemClick">
    <div class="left">
      <img src="../assets/images/notice.png" alt="">
      <div>
        <div class="title">{{ item.title }} <span>{{ item.type }}</span> </div>
        <div class="sub-title">{{ item.intro }}</div>
      </div>
    </div>
    <div class="right">{{ item.createtime | toBlockTime }}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  methods: {
    itemClick() {
      this.$emit('itemClick', this.item)
    }
  }
}
</script>

<style lang="less" scoped>
.notice-item{
  width: 100%;
  padding: 33px 40px;
  margin-bottom: 20px;
  // height: 140px;
  background: #FFFFFF;
  border-radius: 13px 13px 13px 13px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;

  .left {
    display: flex;
    align-items: center;
    img {
      width: 73px;
      height: 73px;
      margin-right: 33px;
    }

    .title {
      font-weight: 500;
      font-size: 23px;
      color: #222222;
      margin-bottom: 13px;

      span{
        margin-left: 27px;
        font-size: 20px;
        color: #585858;
        font-weight: 400;
      }
    }

    .sub-title {
      font-size: 20px;
      color: #999999;
    }
  }

  .right {
    font-size: 20px;
    color: #585858;
  }
}
</style>