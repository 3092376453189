<template>
  <div class='type-item' :style="{backgroundColor: backgroundColor, color: textColor}" @click="itemClick(item)">
    <img :src="item.img" alt="">
    <div>{{ item.title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    backgroundColor: {
      type: String
    },
    textColor: {
      type: String
    }
  },
  data() {
    return {
    }
  },
  methods: {
    itemClick(item){
      this.$emit('itemClick', item)
    }
  },
  // created() {
  // }
}
</script>

<style lang="less" scoped>
.type-item {
  width: 223px;
  height: 179px;
  background: #FFEDE7;
  border-radius: 17px 17px 17px 17px;
  border: 2px solid #FFFFFF;
  cursor: pointer;
  font-weight: 600;
  font-size: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 67px;
    height: 67px;
    margin-bottom: 20px;
  }
}
</style>