<template>
  <div class="home-index">
    <div class="content">
      <div class="type-view">
        <typeItem class="pt20" v-for="(item, index) in types" :key="index" :item="item"
          :backgroundColor="item.backgroundColor" :textColor="item.textColor" @itemClick="typeItemClick"></typeItem>
      </div>
      <div class="modle">
        <div>热门职位</div>
        <div class="view">
          <jobItem :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in jobs" :key="index" :item="item" @itemClick="goJobDetail">
          </jobItem>
        </div>
        <div class="more-div">
          <div class="more" @click="moreJobs()">查看更多</div>
        </div>
      </div>
      <div class="modle pb24">
        <div>精彩录播</div>
        <div class="view">
          <div class="tabs">
            <div class="tab-item" :class="{ 'active': item.id == activeRecordIndex }" v-for="(item, index) in recordTabs"
              :key="index" @click="handleRecordTab(item)"> {{ item.name }}</div>
          </div>
          <recordingItem :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in recordData" :key="index"
            :item="item" @itemClick="goLessonDetail"></recordingItem>
        </div>
        <div class="more-div">
          <div class="more" @click="moreRecord()">查看更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import typeItem from '@/components/typeItem.vue';
import jobItem from '@/components/jobItem.vue';
import recordingItem from '@/components/recordingItem.vue'
import { jobseekerIndex, lessonsCategory, liveLessons } from '@/api/jobSeeker'

export default {
  components: {
    typeItem,
    jobItem,
    recordingItem,
  },
  data() {
    return {
      types: [
        {
          index: 1,
          title: '热门职位',
          path: 'hotjobs',
          img: require('@/assets/images/job.png'),
          backgroundColor: '#F7ECFF',
          textColor: '#9540C9'
        }, {
          index: 2,
          title: '职场资讯',
          path: 'workplacenews',
          img: require('@/assets/images/news.png'),
          backgroundColor: '#FFEDE7',
          textColor: '#AD6534'
        }, {
          index: 3,
          title: '课程',
          path: 'mylesson',
          img: require('@/assets/images/lesson.png'),
          backgroundColor: '#EEF2FF',
          textColor: '#4761AB'
        }, {
          index: 4,
          title: '职场论坛',
          path: 'workplaceforum',
          img: require('@/assets/images/comment.png'),
          backgroundColor: '#E6F7FA',
          textColor: '#00AFCA'
        }, {
          index: 5,
          title: '商城',
          path: 'shoppingMall/home',
          img: require('@/assets/images/shop.png'),
          backgroundColor: '#FFEFE8',
          textColor: '#EE4A03',
          type: 'shop'
        },
      ],
      jobs: [],
      activeRecordIndex: 0,
      recordTabs: [],
      recordData: []
    }
  },
  methods: {
    typeItemClick(item) {
      if(item.type && item.type == 'shop') {
        let path = `${window.location.origin}/${item.path}`
        window.open(path, '_blank')
      } else {
        this.$router.push({path: item.path})
      }
    },
    handleRecordTab(item) {
      this.activeRecordIndex = item.id
      this.recordData = []
      this.getLiveLessons()
    },
    goJobDetail(item) {
      this.$router.push({path: 'jobdetail', query: {id: item.id, companyId: item.company_id}})
    },
    moreJobs() {
      this.$router.push('hotjobs')
    },
    goLessonDetail(item) {
      this.$router.push({path: 'lessondetail', query: {id: item.id}})
    },
    moreRecord() {
      this.$router.push('excitingrecord')
    },
    // 热门职位
    getHotJobs(cityItem) {
      let params = {
        area_id: cityItem.id,
        attr: 1, //不知道啥意思wqtodo
        limit: 9,
        page: 1
      }
      jobseekerIndex(params).then(res=>{
        if(res.code == 1) {
          this.jobs = res.data
        }
      })
    },
    // 录播分类
    getLessonsCategory() {
      let params = {
        limit: 6,
      }
      lessonsCategory(params).then(res=>{
        if(res.code == 1) {
          this.recordTabs = res.data
          if(this.recordTabs.length>0) {
            this.activeRecordIndex = this.recordTabs[0].id
            this.getLiveLessons()
          }
        }
      })
    },
    // 录播数据
    getLiveLessons() {
      let params = {
        row: 9,
        page: 1,
        status: 2,
        category_id: this.activeRecordIndex
      }
      liveLessons(params).then(res=>{
        if(res.code == 1) {
          this.recordData = res.data
        }
      })
    }
  },
  mounted() {
    this.getLessonsCategory()
  }
}
</script>

<style lang="less" scoped>
.home-index {
  width: 100%;
  // height: 3000px;
  display: flex;
  justify-content: center;
  background-color: #F7F7F7;

  .content {
    width: 1200px;

    .type-view {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .pt20 {
        margin-top: 20px;
      }
    }

    .modle {
      margin-top: 40px;
      font-weight: 600;
      font-size: 30px;
      color: #222222;
      text-align: center;
    }

    .view {
      margin: 27px 0 0 0;
      display: flex;
      flex-wrap: wrap;
    }

    .tabs {
      width: 100%;
      height: 80px;
      margin-bottom: 40px;
      font-weight: 400;
      font-size: 24px;
      color: #585858;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #E8E8E8;

      .tab-item {
        height: 80px;
        line-height: 80px;
        border-bottom: 2px solid #F7F7F7;
        cursor: pointer;
      }

      .active {
        color: #2676F7;
        border-bottom: 2px solid #2676F7;
      }
    }

    .mr0 {
      margin-right: 0;
    }
    .pb24{
      padding-bottom: 24px;
    }

    .more-div {
      display: flex;
      justify-content: center;
      .more {
        width: 260px;
        height: 76px;
        line-height: 76px;
        background: rgba(38, 118, 247, 0.08);
        border-radius: 8px;
        font-size: 24px;
        color: #2676F7;
        cursor: pointer;
      }
    }
  }

}

</style>