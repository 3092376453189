<template>
  <div class='work-feel'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        在职感受
        <div class="btn-txt-white" @click="addGroup()">申请加群</div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="working-feel">
          <div class="title">
            <div>在职感受</div>
            <!-- <img src="../../../assets/images/arrow-right.png" @click="moreFeel()" alt=""> -->
          </div>
          <div class="feel-item" v-for="(item, index) in dataList" :key="index">
            <div class="person">
              <img :src="avatarUrl(item.avatar)" alt="">
              <div>
                <div class="name">{{ item.name }}</div>
                <div class="sub-info">
                  <span>{{ company.name }}</span> <span>在职{{item.duration}}年</span>
                </div>
              </div>
            </div>
            <div class="feel">{{ item.content }}</div>
          </div>
        </div>
        <!-- <div class="page">
          <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        </div> -->
      </div>
    </div>

    <el-dialog class="dialog" :visible.sync="showDialog" :title="'二维码'">
      <img :src="$store.getters.imgeCDN + company.group_qrcode_image" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { getWorkFeelList } from '@/api/jobSeeker'

export default {
  data() {
    return {
      params: {
        company_id: null,
        page: 1,
        row: 100,
      },
      company: {},
      dataList: [],
      total: 0,
      showDialog: false,
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    moreFeel() {
      this.$message.warning('感觉这个按钮应该隐藏')
    },
    addGroup() {
      this.showDialog = true
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
    },
    // 在职感受
    getWorkFeel() {
      getWorkFeelList(this.params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data.list
          this.company.name = res.data.company_name
          this.company.company_id = res.data.company_id
          this.company.group_qrcode_image = res.data.group_qrcode_image
        }
      })
    },
  },
  created() {
    this.params.company_id = this.$route.query.id
    this.getWorkFeel()
  },
  computed: {
    avatarUrl: function(){
			return function (avatar) {
        return this.$store.getters.imgeCDN + avatar
      }
    }
  }
}
</script>

<style lang="less" scoped>
.work-feel {
  .working-feel {
    width: 100%;
    padding: 40px;
    background: white;
    border-radius: 13px 13px 13px 13px;
    margin-bottom: 20px;
    .title {
      font-weight: 600;
      font-size: 27px;
      color: #222222;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img{
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
    }

    .feel-item {
      width: 100%;
      padding: 40px;
      border-radius: 13px 13px 13px 13px;
      border: 1px solid #E8E8E8;
      margin-bottom: 20px;
      .person{
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        img{
          width: 73px;
          height: 73px;
          border-radius: 37px;
          margin-right: 27px;
        }
        .name {
          font-size: 27px;
          color: #222222;
          margin-bottom: 8px;
        }

        .sub-info {
          font-size: 20px;
          color: #999999;
          span{
            margin-right: 20px;
          }
        }
      }
      
      .feel {
        font-size: 23px;
        color: #222222;
      }
      
    }
  }

  .page {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  .dialog{
    img{
      width: 193px;
      height: 193px;
    }
  }

  /deep/.el-pagination span:not([class*=suffix]){
    font-size: 20px;
  }
  /deep/.el-pagination .el-select .el-input .el-input__inner{
    font-size: 20px;
    width: 130px;
  }
  /deep/.el-pagination .el-select .el-input{
    width: 130px;
  }
  /deep/.el-pager li{
    font-size: 20px;
  }
  /deep/.el-pagination__editor.el-input .el-input__inner{
    font-size: 20px;
  }
  // /deep/.el-select-dropdown__item{
  //   font-size: 20px;
  // }
  // /deep/.el-pagination__sizes .el-input .el-input__inner {
  //   font-size: 16px; /* 您想要的字体大小 */
  // }

  /deep/.el-dialog {
    width: 400px;
    border-radius: 7px;
  }
  /deep/ .el-dialog__title{
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }
  /deep/.el-dialog__body{
    padding: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>