<template>
  <div class='hot-jobs'>
    <div class="page-header mb20">
      <div class="page-header-content">
        <div class="back" @click="back()">返回</div>
        <div class="cate">
          <div class="container">
            <div class="cate-div">
              <div class="category-item" :class="{'item-active': jobCategoryActiveIndex == item.id}" v-for="(item, index) in jobCategory" :key="index" @click="handleCateItem(item)">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="page-content ">
        <div class="content">
          <jobItemPerson :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in jobs" :key="index" :item="item" @itemClick="goJobDetail"></jobItemPerson>
        </div>
        <el-pagination
          class="page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="jobsParams.page"
          :page-sizes="[9, 18, 27, 36]"
          :page-size="jobsParams.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import jobItemPerson from '@/components/jobItemPerson.vue';
import { jobseekerIndex, getIndexTotal, gradePost }from '@/api/jobSeeker'

export default {
  components: {
    jobItemPerson,
  },
  data() {
    return {
      jobCategoryActiveIndex: 1,
      jobCategory: [
        {
          index: 1,
          title: 'JAVA开发',
        },{
          index: 2,
          title: '人力资源',
        },{
          index: 3,
          title: '运营',
        },{
          index: 4,
          title: '销售',
        },{
          index: 5,
          title: '前端开发',
        },{
          index: 6,
          title: '前端开发',
        },{
          index: 7,
          title: '前端开发',
        },{
          index: 8,
          title: 'UI设计',
        },{
          index: 9,
          title: '前端开发',
        },{
          index: 10,
          title: 'UI设计',
        }
      ],
      jobsParams: {
        page: 1,
        limit: 9,
      },
      jobs: [],
      total: 0
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    handleCateItem(item) {
      this.jobCategoryActiveIndex = item.id
      this.jobs = []
      this.getJobs()
    },
    goJobDetail(item) {
      this.$router.push({path: 'jobdetail', query: {id: item.id, companyId: item.company_id}})
    },
    // 分类
    getJobCategory() {
      let params = {
        grade: 1
      }
      gradePost(params).then(res=>{
        if(res.code == 1) {
          this.jobCategory = res.data
          if(this.jobCategory.length) {
            this.jobCategoryActiveIndex = this.jobCategory[0].id
            this.getJobs()
          }
        }
      })
    },
    getJobs() {
      let cityItemString = localStorage.getItem('jjzl_jobCityId')
      if(!cityItemString) {
        return
      }
      let cityItem = JSON.parse(cityItemString)
      this.jobsParams.area_id = cityItem.id
      this.jobsParams.attr = 1 //不知道啥意思 wqtodo
      this.jobsParams.post_id = this.jobCategoryActiveIndex

      jobseekerIndex(this.jobsParams).then(res=>{
        if(res.code == 1) {
          this.jobs = res.data
        }
      })
      this.getTotal()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.jobsParams.limit = val
      this.jobsParams.page = 1
      this.getJobs()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.jobsParams.page = val
      this.getJobs()
    },
    // 热招职位总数
    getTotal() {
      let para = JSON.parse(JSON.stringify(this.jobsParams))
      delete para.page
      delete para.limit
      getIndexTotal(para).then(res=>{
        if(res.code == 1) {
          this.total = res.data
        }
      })
    }
  },
  created() {
    this.getJobCategory()
  }
}
</script>


<style lang="less" scoped>
.hot-jobs {
  .cate {
    height: 100%;
    margin-left: 100px;
    .container {
      width: 1100px;
      height: 100%;
      overflow-x: auto;
      scrollbar-width: none;

      .cate-div {
        height: 100%;
        display: flex;
        align-items: center;

        .category-item {
          background: #F7F7F7;
          border-radius: 7px 7px 7px 7px;
          height: 45px;
          line-height: 45px;
          margin-right: 38px;
          min-width: 170px;
          text-align: center;
          font-size: 20px;
          cursor: pointer;
        }

        .item-active {
          background: #2676F7;
          border-radius: 7px 7px 7px 7px;
          color: white;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    .mr0 {
      margin-right: 0;
    }
  }

  .content-left {
    justify-content: left;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .page {
    padding-bottom: 30px;
  }
  /deep/.el-pagination span:not([class*=suffix]){
    font-size: 20px;
  }
  /deep/.el-pagination .el-select .el-input .el-input__inner{
    font-size: 20px;
    width: 130px;
    height: 32px;
  }
  /deep/.el-pagination .el-select .el-input{
    width: 130px;
    height: 32px;
  }
  /deep/.el-pager li{
    font-size: 20px;
  }
  /deep/.el-pagination__editor.el-input .el-input__inner{
    font-size: 20px;
  }
}
</style>