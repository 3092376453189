<template>
  <div class='followed-store-item'>
    <div class="left">
      <img :src="imageUrl" alt="">
      <div>
        <div class="store-name">{{ item.shop.shopname }}</div>
        <div>{{ item.shop.city }}</div>
      </div>
    </div>
    <div class="right" @click="cancelFollow()">取消关注</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  methods: {
    cancelFollow() {
      this.$emit('cancelFollow', this.item)
    }
  },
  computed: {
    imageUrl() {
      return this.$store.getters.imgeCDN + this.item.shop.avatar
    }
  }
}
</script>

<style lang="less" scoped>
.followed-store-item {
  width: 100%;
  padding: 30px 20px 30px 30px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #F1F1F1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left{
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;  
    img{
      width: 80px;
      height: 80px;
      margin-right: 20px;
    }

    .store-name{
      font-weight: 500;
      font-size: 24px;
      color: #333333;
      margin-bottom: 19px;
    }
  }
  .right { 
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    background: #2676F7;
    border-radius: 20px;
    cursor: pointer;
  }
}
</style>