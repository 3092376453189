/*
 * @Author: angel~dongsanling
 * @Date: 2024-08-13 14:48:29
 * @Description: 商城
 */

import request from '@/utils/request'

/**
 * @Author: angel~dongsanling
 * @description: 商品列表
 * @param {*} data 参数
 */
export function wanlshopList (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/product/likes',
    data: data,
    isToken: false
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商品类目
 * @param {*} data 参数
 */
export function wanlshopInit (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/common/init',
    data: data,
    isToken: false
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商品二级分类
 * @param {*} data 参数
 */
export function wanlshopCategory (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/common/category',
    data: data,
    isToken: false
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商品搜索历史
 * @param {*} data 参数
 */
export function wanlshoSearchListy (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/common/searchList',
    data: data,
    isToken: false
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商品热门搜索
 * @param {*} data 参数
 */
export function wanlshoSetSearch (data = {}) {
  return request({
    // method: 'post',
    method: 'get', // 乱码修改成post
    url: 'wanlshop/common/setSearch',
    data: data,
    isToken: false
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商品热门搜索后商品
 * @param {*} data 参数
 */
export function wanlshopProductLists (data = {}) {
  return request({
    method: 'get', // 乱码修改成post
    // method: 'post',
    url: 'wanlshop/product/lists',
    data: data,
    isToken: false
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商品详情
 * @param {*} data 参数
 */
export function wanlshopProductGoods (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/product/goods',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商品详情评论
 * @param {*} data 参数
 */
export function wanlshopProductComment (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/product/comment',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商品收藏
 * @param {*} data 参数
 */
export function wanlshopProductFollow (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/product/follow',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商品详情页面加入购物车
 * @param {*} data 参数
 */
export function wanlshopCartStorage (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/cart/storage',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商品详情页面进店逛逛接口
 * @param {*} data 参数
 */
export function wanlshopGetShopInfo (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/shop/getShopInfo',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 购买商品确认订单
 * @param {*} data 参数
 */
export function wanlshopGetOrderGoodsList (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/order/getOrderGoodsList',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 地址列表
 * @param {*} data 参数
 */
export function wanlshopGetaddress (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/address/getaddress',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 优惠券列表
 * @param {*} data 参数
 */
export function wanlshopQuery (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/coupon/query',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 提交订单
 * @param {*} data 参数
 */
export function wanlshopAddOrder (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/order/addOrder',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商城轮播图
 * @param {*} data 参数
 */
export function getContentImage (data = {}) {
  return request({
    method: 'get',
    url: 'index/getContentImage',
    data: data,
    isToken: true
  })
}

// 红包卡券
export function couponList (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/coupon/getList',
    data: data,
    isToken: false
  })
}

// 领取优惠券
export function couponReceive (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/coupon/receive',
    data: data,
    isToken: true
  })
}

// 收货地址
export function addressList (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/address/getaddress',
    data: data,
    isToken: true
  })
}

// 省市区三级
export function areaList (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/common/area',
    data: data,
    isToken: false
  })
}

// 新增地址
export function addAddress (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/address/address',
    data: data,
    isToken: true
  })
}

// 删除地址
export function delAddress (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/address/deladdress',
    data: data,
    isToken: true
  })
}

// 收藏列表
export function collectList (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/product/collect',
    data: data,
    isToken: true
  })
}

// 删除收藏
export function deleteCollect (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/product/follow',
    data: data,
    isToken: true
  })
}

// 关注店铺
export function shopList (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/find/user/getShopList',
    data: data,
    isToken: true
  })
}

// 取消关注店铺 
export function cancelFollow (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/find/user/setFindUser',
    data: data,
    isToken: true
  })
}

// 购物车
export function shoppingCart (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/cart/synchro',
    data: data,
    isToken: true
  })
}

// 我的订单
export function orderList (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/order/getOrderList',
    data: data,
    isToken: true
  })
}

// 我的订单-修改地址
export function orderChangeAddress (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/order/editOrderAddress',
    data: data,
    isToken: true
  })
}

// 我的订单-取消订单
export function cancelOrder (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/order/cancelOrder',
    data: data,
    isToken: true
  })
}
// 我的订单-删除订单
export function delOrder (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/order/delOrder',
    data: data,
    isToken: true
  })
}

// 我的订单-确认收货
export function confirmOrder (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/order/confirmOrder',
    data: data,
    isToken: true
  })
}

// 我的订单-订单详情
export function orderDetail (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/order/getOrderInfo',
    data: data,
    isToken: true
  })
}

// 我的订单-商品评价
export function commentOrder (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/order/commentOrder',
    data: data,
    isToken: true
  })
}

// 申请售后
export function refundApply (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/refund/addApply',
    data: data,
    isToken: true
  })
}

// 修改售后申请
export function editRefund (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/refund/editRefund',
    data: data,
    isToken: true
  })
}

// 售后列表 
export function refundList (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/refund/refundList',
    data: data,
    isToken: true
  })
}

// 关闭退款
export function closeRefund (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/refund/closeRefund',
    data: data,
    isToken: true
  })
}

// 售后详情
export function refundDetail (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/refund/getRefundInfo',
    data: data,
    isToken: true
  })
}

// 退款历史
export function refundLog (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/refund/getRefundLog',
    data: data,
    isToken: true
  })
}

// 确认退货，提交物流
export function toExpress (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/refund/toExpress',
    data: data,
    isToken: true
  })
}

// 我的积分
export function scoreLog (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/user/scoreLog',
    data: data,
    isToken: true
  })
}

// 积分商城
export function scoreGoodsList (data = {}) {
  return request({
    method: 'get',
    url: 'wanlshop/page/goodsList',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商城-订单支付
 * @param {*} data
 */
export function wanlshopPayment (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/pay/payment',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商城-继续支付前订单
 * @param {*} data
 */
export function wanlshopPayGetBalance (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/pay/getBalance',
    data: data,
    isToken: true
  })
}
/**
 * @Author: angel~dongsanling
 * @description: 商城-继续支付
 * @param {*} data
 */
export function wanlshopPayGetPay (data = {}) {
  return request({
    method: 'post',
    url: 'wanlshop/pay/getPay',
    data: data,
    isToken: true
  })
}
