<template>
  <div class='job-manage-item'>
    <div class="top">
      <div>{{ item.post_name }}</div>
      <div v-if="item.status == 2" class="status">开放中</div>
      <div v-if="item.status == -1">
        <img src="../assets/images/delete.png" @click="deleteJob()" alt="">
      </div>
    </div>
    <div class="detail">
      <div class="introduce">
        <span>{{ item.min_education }}</span><span>{{ item.work_setting_id }}</span><span>{{ item.salary_name }}</span>
      </div>
      <!-- 开放中  关闭职位 -->
      <div class="btn status-gray" @click="btnClick(-1)" v-if="item.status == 2">{{ item.status | jobManageBtnTitle }}</div>
      <!-- 待审核 查看详情  -->
      <div class="btn status-active" @click="btnClick(100)" v-else-if="item.status == 0">{{ item.status | jobManageBtnTitle }}</div>
      <!-- 审核失败  查看详情/审核失败 -->
      <div class="btn-bottom" v-else-if="item.status == 3">
        <div class="btn btn2 status-active" @click="btnClick(100)">查看详情</div>
        <div class="btn btn2 status-delete">{{ item.status | jobManageBtnTitle }}</div>
      </div>
      <!-- 审核成功/关闭中  查看详情/开放职位 -->
      <div class="btn-bottom" v-else>
        <div class="btn btn2 status-active" @click="btnClick(100)">查看详情</div>
        <div class="btn btn2" @click="btnClick(2)" :class="{'status-active': (item.status == 1 || item.status == -1)}">{{ item.status | jobManageBtnTitle }}</div>
      </div>
    </div>
    <el-dialog 
      class="dialog" 
      :visible.sync="showDialog" 
      :close-on-click-modal="false" 
      :close-on-press-escape="false"
      @closed="showDialog=false" 
      title="提示">
      <div class="content">
        确认操作吗？
      </div>
      <div class="bottom">
        <span class="confirm" @click="confirm()">确定</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    }
  },
  data() {
    return {
      showDialog: false,
      status: null,
    }
  },
  methods: {
    deleteJob() {
      this.status = -2
      this.showDialog = true
    },
    btnClick(status) {
      this.status = status
      if(status == -1 || status == 2) {
        this.showDialog = true
      } 
      if(status == 100) {
        this.$emit('btnClick', this.item, status)
      }
    },
    confirm() {
      this.showDialog = false
      this.$emit('btnClick', this.item, this.status)
    }
  }
}
</script>

<style lang="less" scoped>
.job-manage-item {
  width: 386px;
  background: #FFFFFF;
  border-radius: 13px;
  margin: 0 20px 20px 0;
  .top {
    width: 100%;
    height: 82px;
    padding: 0 27px;
    background: #F9FBFF;
    border-radius: 13px 13px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 20px;
    color: #222222;

    .status {
      color: #2676F7;
    }

    img {
      width: 27px;
      height: 27px;
      cursor: pointer;
    }
  }
  .detail {
    padding: 20px 27px 27px;
    .introduce{
      span{
        padding: 4px 10px;
        font-weight: 400;
        font-size: 15px;
        color: #585858;
        background-color: #F7F7F7;
        margin-right: 13px;
        border-radius: 3px;
      }
    }
    .btn {
      width: 100%;
      height: 43px;
      line-height: 43px;
      text-align: center;
      font-size: 17px;
      border-radius: 7px 7px 7px 7px;
      margin-top: 20px;
      cursor: pointer;
    }
    .status-gray {
      color: #585858;
      border: 1px solid #E8E8E8;
    }
    .status-active {
      color: #2676F7;
      border: 1px solid #2676F7;
    }
    .status-delete {
      color: #E11E34;
      border: 1px solid #E11E34;
      cursor: text;
    }

    .btn-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .btn2 {
      width: 45%;
    }
  }

  .dialog {
    .content {
      display: flex;
      font-size: 17px;
      color: #585858;
      padding-bottom: 50px;
    }

    .bottom {
      margin-top: 30px;
      margin-right: 30px;
      display: flex;
      justify-content: right;

      span {
        display: inline-block;
        height: 27px;
        line-height: 27px;
        padding: 0 17px;
        text-align: center;
        border-radius: 7px 7px 7px 7px;
        cursor: pointer;
      }

      .clear {
        margin-right: 10px;
        background: rgba(38, 118, 247, 0.08);
        color: #2676F7;
      }

      .confirm {
        background: #2676F7;
        color: white;
      }
    }
  }

  /deep/.el-dialog {
    width: 520px;
    border-radius: 7px;
  }

  /deep/ .el-dialog__title {
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }

  /deep/.el-dialog__body {
    padding: 20px;
  }
}
</style>