<template>
  <div class='re-personal-center'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">个人中心</div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="info">
          <div class="base-info">
            <img class="av" :src="avatar" @click="goPersonProfile()" alt="">
            <div class="info-detail">
              <div class="name">{{ userInfo.nickname }}</div>
              <div class="sub">{{ userInfo.companyInfo ? userInfo.companyInfo.company_name : '' }}-{{ userInfo.position }}</div>
              <div class="change-btn" @click="changeRole()">切换为“求职者”身份</div>
            </div>
          </div>
          <div class="file-div">
            <div class="top">
              <div>开通VIP</div>
              <div class="right" @click="goVIP()">开通VIP</div>
            </div>
            <div class="file">
              <img class="file-icon" src="../../../assets/images/VIP.png" alt="">
              <div class="detail" v-if="userInfo.is_vip">
                <div>我的VIP</div>
                <div class="time">{{ userInfo.vip_expire_time*1000 | toDay }} 到期</div>
              </div>
              <div class="detail" v-else>
                <div>暂未开通VIP</div>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs">
          <div class="tab-item" :class="{'active': activeIndex == item.index}" v-for="(item, index) in tabs" :key="index" @click="handleTabItem(item)">
            {{ item.title }} 
            <span class="num" :class="{'active': activeIndex == item.index}">({{ item.count }})</span> 
          </div>
        </div>
        <div class="view" v-if="activeIndex==1">
          <seekerItem :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in seekers" :item="item" :key="index" @itemClick="goSeekerDetail" @btnItemClick="seekerBtnItemClick"></seekerItem>
        </div>
        <div class="view" v-if="activeIndex == 2">
          <div class="interview-record-tab">
            <div class="record-tab-item" :class="{'active-tab': recordActiveIndex == item.index}" v-for="(item, index) in interViewRecordTab" :key="index" @click="handleRecordItem(item)">{{ item.title }}</div>
          </div>
          <interviewRecordItem v-for="(item, index) in recordDatas" :key="index" :item="item" @itemClick="goInterviewDetail"></interviewRecordItem>
        </div>
        <div class="view" v-if="activeIndex == 3">
          <seekerCollectItem :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in collectJobData" :key="index" :item="item" @btnClick="collectItemBtnClick"></seekerCollectItem>
        </div>
      </div>
    </div>
    <changeRoleDialog v-if="changeRoleDialog" :identity="2" @change="goJobSeeker"></changeRoleDialog>
  </div>
</template>

<script>
import seekerItem from '@/components/seekerItem.vue';
import interviewRecordItem from '@/components/reInterviewRecordItem.vue'
import seekerCollectItem from '@/components/seekerCollectItem.vue'
import changeRoleDialog from '@/components/changeRoleDialog.vue';
import { changeIdentity, userInfo } from '@/api/userInfo'
import { deliveredList } from '@/api/company'
import { updateReviewStatus } from '@/api/jobSeeker'
import { companySummary, collectList, handelCollect } from '@/api/company'

export default {
  components: {
    seekerItem,
    interviewRecordItem,
    seekerCollectItem,
    changeRoleDialog,
  },
  data() {
    return {
      userInfo: {},
      activeIndex: 1,
      changeRoleDialog: false,
      tabs: [
        {
          title: '已投递',
          count: '0',
          index: 1,
        },{
          title: '面试记录',
          count: '0',
          index: 2,
        },{
          title: '收藏',
          count: '0',
          index: 3,
        },
      ],
      seekersParams: {
        limit: 100,
        row: 100,
        attr: 1,
        page: 1,
      },
      seekers: [],
      recordActiveIndex: 3,
      interViewRecordTab: [
        {
          title: '待同意',
          index: 3
        },{
          title: '待面试',
          index: 8
        },{
          title: '已参加',
          index: 5
        },{
          title: '已通过',
          index: 6
        },{
          title: '未通过',
          index: 7
        },
      ],
      reviewParams: {
        row: 10,
        page: 1,
        status: 3
      },
      recordDatas: [],
      collectJobData: [],
      reviewerMobile: '', //消息聊天页面，点击约面试，传手机号
    }
  },
  methods: {
    initUserInfo() {
      userInfo().then(res=>{
        if(res.code == 1) {
          this.userInfo = res.data.userinfo
        }
      })
    },
    goPersonProfile() {
      this.$router.push({path: 'personal-profile'})
    },
    changeRole(){
      this.changeRoleDialog = true
    },
    goVIP() {
      this.$router.push({path: 'memeber-center'})
    },
    goJobSeeker() {
      changeIdentity().then(res=>{
        if (res.code === 1) {
          this.$store.commit('setUserInfo', res.data.userinfo)
          this.$router.replace('/')
        }
      })
      // sessionStorage.setItem('identity', 1)
      // this.$router.replace('/')
    },
    handleTabItem(item){
      this.activeIndex = item.index
      if(this.activeIndex == 1) {
        this.getDeliveredList()
      } else if(this.activeIndex == 2) {
        this.recordActiveIndex = 3
        this.reviewParams.status = this.recordActiveIndex
        this.getDeliveredList()
      } else {
        this.getCollectList()
      }
    },
    handleRecordItem(item){
      this.recordActiveIndex = item.index
      this.reviewParams.status = item.index
      this.getDeliveredList()
    },
    goInterviewDetail(item) {
      this.$router.push({path: 'interview-detail', query: {id: item.id}})
    },
    handleCollectItem(item) {
      this.collectActiveIndex = item.index
    },
    goSeekerDetail(item) {
      this.$router.push({path: 'onlineResume',  query: {id: item.user_id}})
    },
    collectItemBtnClick(item, type) {  //1删除 2沟通
      if(type == 1) {
        handelCollect({apply_user_id: item.company_id}).then(res=>{
          if(res.code == 1) {
            this.getCollectList()
          }
        })
      } else if(type == 2) {
        this.$router.push({path: 'message', query: {mobile: item.mobile}})
      } else {
        this.$router.push({path: 'onlineResume',  query: {id: item.apply_user_id}})
      }
    },
    // 投递、面试、收藏 数量
    getSummary() {
      companySummary().then(res=>{
        if(res.code == 1) {
          this.tabs[0].count = res.data.delivered
          this.tabs[1].count = res.data.interviewed
          this.tabs[2].count = res.data.collected
        }
      })
    },
    // 投递记录
    getDeliveredList() {
      if(this.activeIndex == 1) {
        if(this.reviewerMobile) {
          this.seekersParams.mobile = this.reviewerMobile
        }
        deliveredList(this.seekersParams).then(res=>{
          if(res.code == 1) {
            this.seekers = res.data
          }
        })
      } else if(this.activeIndex == 2) {
        deliveredList(this.reviewParams).then(res=>{
          if(res.code == 1) {
            this.recordDatas = res.data
          }
        })
      }
      
    },
    // 4不合适/3约面试/-1查看详情
    seekerBtnItemClick(status, item, time) {
      if(status == 4) {
        let params = {
          send_history_id: item.id,
          status: status
        }
        updateReviewStatus(params).then(res=>{
          if(res.code == 1) {
            this.getDeliveredList()
          } 
        })
      } else if(status == 3) {
        let params = {
          interview_time: time,
          send_history_id: item.id,
          status: status
        }
        updateReviewStatus(params).then(res=>{
          if(res.code == 1) {
            this.getDeliveredList()
          } 
        })
      } else if (status == -1) {
        this.$router.push({path: 'interview-detail', query: {id: item.id}})
      }
    },
    // 收藏
    getCollectList() {
      let params = {
        page: 1,
        row: 100,
      }
      collectList(params).then(res=>{
        if(res.code == 1) {
          this.collectJobData = res.data
        }
      })
    }
  },
  computed: {
    avatar() {
      if(this.userInfo.avatar) {
        return this.$store.getters.imgeCDN + this.userInfo.avatar
      }
      return ''
    },
  },
  created() {
    if(this.$route.query.mobile) {
      this.reviewerMobile = this.$route.query.mobile
    }
    this.initUserInfo()
    this.getSummary()
    this.getDeliveredList()
  }
}
</script>

<style lang="less" scoped>
.re-personal-center{
  .info{
    height: 191px;
    display: flex;
    justify-content: space-between;
    .base-info{
      width: 860px;
      height: 100%;
      background: #FFFFFF;
      border-radius: 13px;
      display: flex;
      align-items: center;
      padding-left: 33px;
      .av{
        width: 100px;
        height: 100px;
        border-radius: 50px;
        margin-right: 33px;
        cursor: pointer;
      }

      .info-detail{
        .name{
          font-weight: 500;
          font-size: 27px;
          color: #222222;
          margin-bottom: 7px;
        }
        .sub { 
          font-size: 20px;
          color: #585858;
          margin-bottom: 13px;
        }

        .change-btn{
          width: 205px;
          height: 38px;
          line-height: 38px;
          border-radius: 7px 7px 7px 7px;
          border: 1px solid #2676F7;
          font-size: 17px;
          color: #2676F7;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .file-div{
      width: 320px;
      height: 191px;
      background: #FFFFFF;
      border-radius: 13px;
      padding: 20px 20px 0 20px;

      .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        font-size: 20px;
        color: #222222;
        margin-bottom: 20px;

        .right{
          font-size: 17px;
          color: #F6AB66;
          cursor: pointer;
        }
      }

      .file{
        // width: 330px;
        height: 106px;
        padding: 0 20px;
        background: #FDF6F1;
        border-radius: 10px;
        display: flex;
        align-items: center;

        .file-icon{
          width: 67px;
          height: 67px;
          margin-right: 17px;
        }

        .detail{
          font-weight: 600;
          font-size: 20px;
          color: #222222;
          // margin-right: 30px;
          .time{
            margin-top: 7px;
            font-weight: 400;
            font-size: 15px;
            color: #999999;
          }
        }

        .delete-icon{
          width: 20px;
          height: 20px;
          margin-top: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .tabs{
    width: 100%;
    height: 93px;
    margin-top: 20px;
    background: #FFFFFF;
    border-radius: 13px 13px 13px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tab-item{
      width: 33%;
      text-align: center;
      font-size: 23px;
      color: #222222;
      cursor: pointer;
      .num{
        color: #999999;
      }

      .active{
        color: #2676F7!important;
      }

    }
    
    .active{
      color: #2676F7!important;
    }
  }

  .view{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    .interview-record-tab{
      width: 100%;
      height: 92px;
      background: #FFFFFF;
      border-radius: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 58px;
      margin-bottom: 20px;

      .record-tab-item{
        width: 167px;
        height: 45px;
        line-height: 45px;
        font-size: 20px;
        color: #585858;
        text-align: center;
        background: #F7F7F7;
        border-radius: 7px;
        cursor: pointer;
      }

      .active-tab{
        background: rgba(38,118,247,0.08);
        border-radius: 7px 7px 7px 7px;
        color: #2676F7;
      }

    }

    .justify-content{
      justify-content: left;

      .collect-tab-item{
        margin-right: 67px;
      }
    }
  }
  .mr0 {
    margin-right: 0;
  }
}
</style>