<!-- 收银台 -->
<template>
  <div class='cashier'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <div class="content">
          <div class="order-num">订单号：847464646464646</div>
          <img src="../../../assets/images/qrcode-test.png" alt="">
          <div class="amount">￥<span>900</span> </div>
          <div class="pay-type">推荐使用微信扫码支付</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';

export default {
  components: {
    crumbsVue,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '收银台',
        },
      ],
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.cashier {
  .content {
    width: 1200px;
    height: 637px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    margin-bottom: 50px;

    .order-num {
      font-weight: 500;
      font-size: 20px;
      color: #333333;
      margin-bottom: 30px;
    }
    img{
      width: 311px;
      height: 311px;
      margin-bottom: 50px;
      border: 1px solid #E0E0E0;
    }
    .amount {
      color: #FF2929;
      font-size: 20px;
      margin-bottom: 24px;
      span {
        font-size: 36px;
        font-weight: 500;
      }
    }
    .pay-type {
      font-size: 18px;
      color: #999999;
    }
  }
}
</style>