<template>
  <div class='store'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <followedStoreItem class="mb20" v-for="(item, index) in dataList" :key="index" :item="item" @cancelFollow="cancelFollow"></followedStoreItem>
      </div>
    </div>
    
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import followedStoreItem from '@/components/shoppingMall/followedStoreItem.vue';
import { shopList, cancelFollow } from '@/api/shoppingMall'

export default {
  components: {
    crumbsVue,
    followedStoreItem,
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '关注店铺',
        },
      ],
      dataList: []
    }
  },
  methods: {
    getShopList() {
      shopList().then(res=>{
        if(res.code == 1) {
          this.dataList = res.data.data
        }
      })
    },
    cancelFollow(item) {
      let params = {
        id: item.user_no,
        type: "follow"
      }
      cancelFollow(params).then(res=>{
        if(res.code == 1) {
          this.getShopList()
        }
      })
    },
  },
  created() {
    this.getShopList()
  }
}
</script>

<style lang="less" scoped>
.store {
  // min-height: 100%;
}
</style>