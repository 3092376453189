<template>
  <div class='hot-job-vacancies'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        热招职位（ {{ total }}  ）
      </div>
    </div>
    <div class="page">
      <div class="page-content content">
        <hotjobItem :class="{ 'mr0': (index + 1) % 3 == 0 }" v-for="(item, index) in jobs" :key="index" :item="item" @itemClick="goJobDetail"></hotjobItem>
      </div>
    </div>
  </div>
</template>

<script>
import hotjobItem from '@/components/hotJobItem.vue'
import { jobseekerIndex, getIndexTotal } from '@/api/jobSeeker'

export default {
  components: {
    hotjobItem,
  },
  data() {
    return {
      params: {
        company_id: null,
        page: 1,
        limit: 10,
      },
      total: 0,
      jobs: [],
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    goJobDetail(item) {
      this.$router.push({path: 'jobdetail', query: {id: item.id, companyId: item.company_id}})
    },
    hotJob() {
      jobseekerIndex(this.params).then(res=>{
        if(res.code == 1) {
          this.jobs = res.data
        }
      })
    },
    // 热招职位总数
    getTotal() {
      let params = {
        company_id: this.params.company_id
      }
      getIndexTotal(params).then(res=>{
        if(res.code == 1) {
          this.total = res.data
        }
      })
    }
  },
  created() {
    this.params.company_id = this.$route.query.id
    this.hotJob()
    this.getTotal()
  }
}
</script>

<style lang="less" scoped>
.hot-job-vacancies{
  .content {
    // width: 100%;
    background: #FFFFFF;
    border-radius: 13px 13px 13px 13px;
    padding: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    .mr0{
      margin-right: 0;
    }
  }
}
</style>