<template>
  <div class='re-job-item-person'  @click="itemClick()">
    <div class="top-content">
      <div class="top">
        <div class="left">
          <img :src="avatar" alt="">
          <div>{{ item.nickname }}</div>
        </div>
        <div class="num">{{ item.salary_text }}</div>
      </div>
      <div class="detail">
        <span>{{ item.work_city_text }}</span><span>{{ item.education_text }}</span><span>{{item.work_year}}年</span>
      </div>
    </div>
    <div class="bottom">
      <div class="left">{{ item.post_text }}</div>
      <div>{{ item.job_status_text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
  },
  data() {
    return {
    }
  },
  methods: {
    itemClick() {
      this.$emit('itemClick', this.item)
    }
  },
  computed: {
    city() {
      let cityArr = this.item.mergename.split(',')
      if(cityArr.length == 3){
        return cityArr[1]
      } else {
        return ''
      }
    },
    avatar() {
      if(this.item.avatar) {
        return this.$store.getters.imgeCDN + this.item.avatar
      }
      return ''
    }
  }
}
</script>

<style lang="less" scoped>
.re-job-item-person {
  width: 386px;
  background: #FFFFFF;
  border-radius: 13px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 22px 0 0;
  cursor: pointer;

  .top-content{
    width: 100%;
    text-align: left;
    padding: 0 27px;
    .top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;

      .left {
        font-weight: 500;
        font-size: 23px;
        color: #222222;
        display: flex;
        align-items: center;

        img {
          width: 53px;
          height: 53px;
          border-radius: 27px;
          margin-right: 13px;
        }
      }

      .num{
        font-weight: 500;
        font-size: 20px;
        color: #E11E34;
      }
    }
    .detail{
      margin-bottom: 17px;
      span{
        padding: 4px 10px;
        font-weight: 400;
        font-size: 15px;
        color: #585858;
        background-color: #F7F7F7;
        margin-right: 13px;
        border-radius: 3px;
      }
    }
  }

  .bottom{
    width: 100%;
    height: 68px;
    padding: 0 27px;
    background-color: #F9FBFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 17px;
    color: #999999;
    border-radius: 0 0 13px 13px;

    .left{
      font-weight: 500;
      font-size: 20px;
      color: #222222;
    }
  }
}
</style>