<template>
  <div class='order'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <div class="top">
          <div class="item" :class="{'active': activeIndex == item.index}" v-for="(item, index) in tabs" :key="index" @click="handelTab(item)">
            <div>{{ item.title }}</div>
            <div class="img-div">
              <img :class="{'display-none': activeIndex != item.index}" src="../../../assets//images/active-bottom-icon.png" alt="">
            </div>
          </div>
        </div>
        <div class="view" v-for="(item, index) in dataList" :key="index">
          <orderItem :item="item" @goodsItemClick="goodsItemClick" @btnClick="orderItemClick"></orderItem>
        </div>

        <el-pagination
          v-if="totalNum > 15"
          class="pagination"
		  		:current-page.sync="params.page" 
		  		@current-change="handleCurrentChange"
		    	layout="prev, pager, next"
		    	page-size="15"
		    	:total="totalNum">
		  	</el-pagination>
      </div>
      <selectAddressDialog v-if="isShowSelectAddressDialog" :addressList="addressList" @selectAddressItem="selectAddressItem"></selectAddressDialog>
      <goodsEvaluateDialog v-if="isShowEvaluateDialog" :id="handelOrderItem.id" @confirm="evaluateConfirm" @cancel="isShowEvaluateDialog=false"></goodsEvaluateDialog>

    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import orderItem from '@/components/shoppingMall/orderItem.vue';
import selectAddressDialog from '@/components/shoppingMall/selectAddressDialog.vue';
import goodsEvaluateDialog from '@/components/shoppingMall/goodsEvaluateDialog.vue';
import { orderList, orderChangeAddress, addressList, cancelOrder, delOrder, confirmOrder, commentOrder, 
  wanlshopPayGetBalance, wanlshopPayGetPay, wanlshopPayment } from '@/api/shoppingMall'

export default {
  components: {
    crumbsVue,
    orderItem,
    selectAddressDialog,
    goodsEvaluateDialog,
  },
  data() {
    return {
      isShowSelectAddressDialog: false,
      isShowEvaluateDialog: false,
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '我的订单',
        },
      ],
      activeIndex: 0,
      tabs: [
        {
          title: '全部',
          index: 0,
        },{
          title: '待支付',
          index: 1,
        },{
          title: '待发货',
          index: 2,
        },{
          title: '待收货',
          index: 3,
        },{
          title: '待评论',
          index: 4,
        },
      ],
      addressList: [],
      params: {
        state: 0,
        page: 1
      },
      totalNum: 0,
      dataList: [],
      handelOrderItem: {}
    }
  },
  methods: {
    handelTab(item) {
      this.activeIndex = item.index
      this.dataList = []
      this.params.state = this.activeIndex
      this.params.page = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.getList()
    },
    goodsItemClick(item, goodsItem) {
      this.$router.push({path: 'order-detail', query: { id: item.id }})
    },
    // 1修改地址  2取消订单  3继续付款  4删除订单  6确认收货   7评论订单
    orderItemClick(type, item) {
      this.handelOrderItem = item
      if(type == 1){
        this.isShowSelectAddressDialog = true
      } else if (type == 2){
        this.handelCancelOrder()
      } else if(type == 3) {
        // this.$router.push({path: 'cashier'})
        
        this.getWanlshopPayGetBalance(item)
      } else if(type == 4) {
        this.deleteOrder()
      } else if(type == 6) {
        this.confirmOrder()
      } else if(type == 7) {
        this.isShowEvaluateDialog = true
      }
    },
    /**
     * @Author: angel~dongsanling
     * @description: 商城-继续支付前订单
     */    
    getWanlshopPayGetBalance (params) {
      wanlshopPayGetBalance().then(res => {
        if (res.code == 1) {
          this.goOnWanlshopPayGetPay(params)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 商城-继续支付前订单获取订单id
     * @param {*} data
     */    
    goOnWanlshopPayGetPay (data) {
      wanlshopPayGetPay({ order_id: data.id, order_type: 'goods' }).then(res => {
        if (res.code == 1) {
          this.getWanlshopPayment(res.data)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 支付订单
     * @param {*} id 订单id
     */    
    getWanlshopPayment (params) {
      wanlshopPayment({ method: 'web', type: 'wechat', order_type: 'goods', order_id: params.order_id ,token: params && params.token }).then(res => {
        if (res.code == 1) {
          window.open(res.data, '_blank')
        }
      })
    },
    // 选择地址回调
    selectAddressItem(item) {
      this.isShowSelectAddressDialog = false

      let params = {
        address_id: item.id,
        id: this.handelOrderItem.id
      }
      orderChangeAddress(params).then(res=>{
        if(res.code == 1) {
          this.$message.success('地址修改成功')
        }
      })
    },
    getAddressList() {
      addressList().then(res=>{
        if(res.code == 1) {
          this.addressList = res.data.data
          this.addressList = this.replaceKey(res.data.data, 'default', 'isDefault');
          for(let i = 0; i < this.addressList.length; i++) {
            this.addressList[i].isSelected = this.addressList[i].isDefault == '1'
            this.addressList[i].isDefault = this.addressList[i].isDefault == '1'
          }
        }
      })
    },
    replaceKey(obj, oldKey, newKey) {
      if (typeof obj !== 'object' || obj === null) {
        return obj; // 不是对象则返回原值
      }
    
      // 初始化一个新对象
      let newObj = Array.isArray(obj) ? [] : {};
    
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key === oldKey) {
            newObj[newKey] = this.replaceKey(obj[key], oldKey, newKey); // 递归替换key
          } else {
            newObj[key] = this.replaceKey(obj[key], oldKey, newKey); // 递归复制其它属性
          }
        }
      }
    
      return newObj;
    },
    getList() {
      this.dataList = []
      orderList(this.params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data.data
          this.dataList = this.dataList.map(item => ({ ...item, isSelected: false }))
          for(let i = 0; i < this.dataList.length; i++) {
            this.dataList[i].goods = this.dataList[i].goods.map(item => ({ ...item, isSelected: false }))
          }
          this.totalNum = res.data.total
        }
      })
    },
    // 取消订单
    handelCancelOrder() {
      let params = {
        id: this.handelOrderItem.id
      }
      cancelOrder(params).then(res=>{
        if(res.code == 1) {
          this.getList()
          this.$message.success('取消成功')
        }
      })
    },
    // 删除订单
    deleteOrder() {
      let params = {
        id: this.handelOrderItem.id
      }
      delOrder(params).then(res=>{
        if(res.code == 1) {
          this.getList()
          this.$message.success('删除成功')
        }
      })
    },
    // 确认收货
    confirmOrder() {
      let params = {
        id: this.handelOrderItem.id
      }
      confirmOrder(params).then(res=>{
        if(res.code == 1) {
          this.getList()
        }
      })
    },
    // 
    evaluateConfirm(params) {
      this.isShowEvaluateDialog = false
      commentOrder(params).then(res=>{
        if(res.code == 1) {
          this.getList()
        }
      })
    },
  },
  created() {
    this.getAddressList()
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.order {
  .top {
    height: 106px;
    padding: 0 50px;
    margin-bottom: 20px;
    background: #FFFFFF;
    font-size: 20px;
    color: #333333;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .img-div{
        height: 14px;
        img{
          width: 27px;
          height: 14px;
        }
        .display-none {
          display: none;
        }
      }
    }
    .active {
      color: #2676F7;
    }
  }

  .view {
    margin-bottom: 20px;
    padding: 33px 30px 2px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
  }

  .pagination {
    margin-bottom: 20px;
    text-align: center;
  }
}
</style>